import React from 'react'

export default function BrandStep2() {
  return (
    <>
    <div className='container' style={{marginTop:'50px'}}>
        <h1 style={{textAlign:'center'}}>Featured Collections</h1>
        <div className='row __pf' id ='__pf'>
        <div className="sc-pbYdQ jklmHY pf-c pf-c-xs--12 pf-c-sm--6 pf-c-md--4 pf-c-lg--4">
            <div className="sc-pkUyL fTyjzF pf-069c06a5" data-pf-type="Column">
                <h3 data-pf-type="Heading" className="sc-oTLFK lcHmGL pf-b2b220d2">
                <span data-pf-type="Text" className="sc-ptSuy fnmilh pf-adf131a7">
                    Ethnic
                </span>
                </h3>
                <a
                href="/collections/lyla-ethnic-wear"
                target="_self"
                data-pf-type="Button"
                className="sc-pZdvY kuwSuz pf-1a069c06"
                >
                <span data-pf-type="Text" className="sc-ptSuy fnmilh pf-31a735b3">
                    SHOP NOW
                </span>
                </a>
            </div>
        </div>
                <div className="sc-pbYdQ jklmHY pf-c pf-c-xs--12 pf-c-sm--6 pf-c-md--8 pf-c-lg--8">
            <div className="sc-pkUyL fTyjzF pf-3605ef85" data-pf-type="Column">
                <h3 data-pf-type="Heading" className="sc-oTLFK lcHmGL pf-44e60184">
                <span data-pf-type="Text" className="sc-ptSuy fnmilh pf-35b3f14e">
                    Casuals
                </span>
                </h3>
                <a
                href="/collections/lyla-casuals"
                target="_self"
                data-pf-type="Button"
                className="sc-pZdvY kuwSuz pf-533605ef"
                >
                <span data-pf-type="Text" className="sc-ptSuy fnmilh pf-f14e6c7b">
                    SHOP NOW
                </span>
                </a>
            </div>
        </div>

        <div className="sc-pbYdQ jklmHY pf-c pf-c-xs--12 pf-c-sm--12 pf-c-md--12 pf-c-lg--12">
  <div className="sc-pkUyL fTyjzF pf-4d541d1d" data-pf-type="Column">
    <h3 data-pf-type="Heading" className="sc-oTLFK lcHmGL pf-843ea886">
      <span data-pf-type="Text" className="sc-ptSuy fnmilh pf-6c7ba5b8">
        New Arrivals
      </span>
    </h3>
    <a
      href="/collections/lyla-new-arrivals"
      target="_self"
      data-pf-type="Button"
      className="sc-pZdvY kuwSuz pf-b94d541d"
    >
      <span data-pf-type="Text" className="sc-ptSuy fnmilh pf-a5b87848">
        SHOP NOW
      </span>
    </a>
  </div>
</div>
        </div>
    </div>
    </>
  )
}
