import React, { Component } from "react"
import { NavLink } from "react-router-dom";

export default class CartBreadcrumb extends Component {
    render() {
        return (
            <div className="row">
                <div className="col">
                    <ul className="breadcrumb font-weight-bold text-6 justify-content-center my-2 mb-4">
                         <li className="text-transform-none me-2" >
                            <NavLink
                                className="text-decoration-none text-color-primary"
                                to={'/shipping'}
                                exact
                            >
                                Shipping Detail
                            </NavLink>
                        </li>

                        <li className="text-transform-none me-2" >
                            <NavLink
                                className="text-decoration-none text-color-primary"
                                to={'/cart'}
                                exact
                            >
                                Shopping Cart
                            </NavLink>
                        </li>
                        <li className="text-transform-none text-color-grey-lighten me-2" >
                            <NavLink
                                className="text-decoration-none text-color-grey-lighten text-color-hover-primary"
                                to={'/checkout'}
                                exact
                            >
                                Checkout
                            </NavLink>
                        </li>
                        <li className="text-transform-none text-color-grey-lighten" >
                            <a
                                href="#"
                                className="text-decoration-none text-color-grey-lighten text-color-hover-primary"
                            >
                                Order Complete
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}