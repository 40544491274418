import axios from "axios";
class Categories {
    constructor() {
       this.search = {
        "limit": 30,
        "pageNumber": 1,
        "source": "web",
        "type": 1,
        "level":1
      }
      try {
        this.categories = JSON.parse(localStorage.getItem("categories") || "{}");
      } catch(err){
        this.categories = null;
      }
    }
    async fetchCategories(){
        this.categories = await axios.post(process.env.REACT_APP_NODE_API_URL.trim()+'/sscapi/web/get-categories',this.search)
        .then(result => {
           return result.data.Response;
        }).catch(()=>{});
        localStorage.setItem("categories", JSON.stringify(this.categories));
        return this.categories;
    }
    async getCategories(){
      if(this.categories && this.categories.results && this.categories.results.length){
        return this.categories;
      }
      return this.fetchCategories();
    }
}

const catObj = new Categories();
export default catObj;