import React, { Component } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
var navigationmenus = [];
class HeaderNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menusList: {},
    };
    this.apiUrl =
      process.env.REACT_APP_NODE_API_URL.trim() +
      "/" +
      process.env.REACT_APP_NODE_API_PATH.trim().replace(/\/+/g, "/");
  }
  componentDidMount() {
    this.getMenusData();
    this?.props?.parentCallback &&
      this?.props?.parentCallback({ menu: navigationmenus });
  }

  handleMenuChange(menus) {
    navigationmenus = menus;
    this?.props?.parentCallback &&
      this?.props?.parentCallback({ menu: navigationmenus });
  }
  getMenusData() {
    axios
      .get(this.apiUrl + "/navigation-menu?limit=20")
      .then((result) => {
        delete result?.data?.Response?.categories?.["other"];
        this.setState({ menusList: result?.data?.Response?.categories });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  }

  buildMainMenu(input) {
    return Object.keys(input)
      ?.slice(0, 7)
      ?.map((el, key) => {
        let sc = Object.values(input[el]?.doclist?.subcats || {});
        let uri = this.uriBuilder("", el);
        return (
          <li className="dropdown" key={key.toString()}>
            <NavLink
              className="dropdown-item dropdown-toggle"
              to={uri}
              exact     
              onClick={() => this.handleMenuChange([el])}
            >
              {el}
              {sc.length ? <i className="fas fa-chevron-down"></i> : ""}
            </NavLink>
            {sc.length ? (
              <ul className="dropdown-menu">
                {this.buildMenu(input[el]?.doclist?.subcats, uri, [el])}
              </ul>
            ) : (
              ""
            )}
          </li>
        );
      });
  }
  buildMenu(input, uri, menu = []) {
    return Object.keys(input)?.map((el, key) => {
      let sc = Object.values(input?.[el] || {});
      let subcat = this.uriBuilder(uri, el);
      return (
        <li
          className={sc.length && uri.length ? "dropdown-submenu" : "dropdown"}
          key={key.toString()}
        >
          <NavLink
            className="dropdown-item"
            to={subcat}
            exact
            onClick={() => this.handleMenuChange([...menu, el])}
          >
            {el}
            <i className="fas fa-chevron-down"></i>
          </NavLink>
          {sc.length && uri.length ? (
            <ul className="dropdown-menu">
              {this.buildSubMenu(input[el], subcat, [...menu, el])}
            </ul>
          ) : (
            ""
          )}
        </li>
      );
    });
  }
  buildSubMenu(input, uri, menu = []) {
    return Object.keys(input)?.map((el, key) => {
      let subcat = this.uriBuilder(uri, el);
      return (
        <li key={key.toString()}>
          <NavLink
            className="dropdown-item"
            to={subcat}
            exact
            onClick={() => this.handleMenuChange([...menu, el])}
          >
            {el}
            <i className="fas fa-chevron-down"></i>
          </NavLink>
        </li>
      );
    });
  }
  uriBuilder(path, uri) {
    return (
      path +
      "/" +
      uri
        .toLowerCase()
        .replace(/[^a-z0-9_-]/g, "-")
        .replace(/-+/g, "-")
    );
  }
  render() {
    let entries = Object.entries(this.state.menusList);
    return (
      <div>
        <div className="header-nav-bar header-nav-bar-top-border">
          <div className="header-container container">
            <div className="header-row">
              <div className="header-column">
                <div className="header-row justify-content-end">
                  <div
                    className="header-nav header-nav-line header-nav-top-line header-nav-top-line-with-border justify-content-start"
                    data-sticky-header-style="{'minResolution': 991}"
                    data-sticky-header-style-active="{'margin-left': '120px'}"
                    data-sticky-header-style-deactive="{'margin-left': '0'}"
                  >
                    <div className="header-nav-main header-nav-main-square header-nav-main-dropdown-no-borders header-nav-main-effect-3 header-nav-main-sub-effect-1 w-100">
                      <nav className="collapse w-100">
                        <ul className="nav nav-pills w-100" id="mainNav">
                          {
                            // Previous code
                            // this.buildMainMenu(this.state.menusList)
                          }

                          <li className="dropdown" key={"8"}>
                            <NavLink to={"/search/Hotel Linen"} exact
                              className="dropdown-item dropdown-toggle"
                              // to={"/all"}
                              // exact
                              // onClick={"/search/Hotel Linen"}
                            >
                                 All categories
                              <i className="fas fa-chevron-down"></i>
                            </NavLink>
                            <ul className="dropdown-menu">
                              {/* <li className="dropdown-submenu">
                                <a
                                  className="dropdown-item"
                                  href="/search/Hotel Linen"
                                >
                                  Hotel Linen
                                </a>
                              </li> */}
                              <li className="dropdown-submenu">
                                <a
                                  className="dropdown-item"
                                  href="/search/Hotel Amenities"
                                >
                                Hotel Amenities
                                </a>
                              </li>
                              <li className="dropdown-submenu">
                                <a
                                  className="dropdown-item"
                                  href="/search/Guestroom Customized"
                                >
                                  Guestroom Customized
                                </a>
                              </li>
                              <li className="dropdown-submenu">
                                <a
                                  className="dropdown-item"
                                  href="/search/Guestroom Appliances"
                                >
                                     Guestroom Appliances
                                </a>
                              </li>
                              <li className="dropdown-submenu">
                                <a
                                  className="dropdown-item"
                                  href="/search/Cleaning Equipment"
                                >
                                  Cleaning Equipment
                                </a>
                              </li>
                              <li className="dropdown-submenu">
                                <a
                                  className="dropdown-item"
                                  href="/categories/clothing-accessories"
                                >
                                  Clothing Accessories
                                </a>
                              </li>
                              <li className="dropdown-submenu">
                                <a
                                  className="dropdown-item"
                                  href="/search/Public Item"
                                >
                                   Public Item
                                </a>
                              </li>
                              <li className="dropdown-submenu">
                                <a
                                  className="dropdown-item"
                                  href="/search/Buffet"
                                >
                                  Buffet
                                </a>
                              </li>
                              <li className="dropdown-submenu">
                                <a
                                  className="dropdown-item"
                                  href="/search/Furniture"
                                >
                                Furniture
                                </a>
                              </li>
                            </ul>
                          </li>
                          {/* <li className="dropdown">
                            {" "}
                            <a href="/search/Hotel Linen">
                            Hotel Linen
                            </a>
                          </li> */}
                          <li className="dropdown">
                            {" "}
                            <a href="/search/Hotel Amenities">
                            Hotel Amenities
                            </a>
                          </li>
                          <li className="dropdown">
                            {" "}
                            <a href="/search/Guestroom Customized">Guestroom Customized</a>
                          </li>
                          <li className="dropdown">
                            {" "}
                            <a href="/search/Cleaning Equipment">Cleaning Equipment</a>
                          </li>
                          <li className="dropdown">
                            {" "}
                            <a href="/search/Public Item">
                            Public Item
                            </a>
                          </li>
                          <li className="dropdown">
                            {" "}
                            <a href="/search/Dinnerware">
                            Dinnerware
                            </a>
                          </li>
                          <li className="dropdown">      
                           {" "}
                            <a href="/search/Furniture">Furniture</a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <button
                      className="btn header-btn-collapse-nav"
                      data-toggle="collapse"
                      data-target=".header-nav-main nav"
                    >
                      <i className="fas fa-bars"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default HeaderNavbar;
