import React from 'react'


export default function Branding() {
  return (
    <>
    <section
    className="u-align-right u-clearfix u-image u-section-1"
    id="carousel_d9b0"
  >
    <div className="u-clearfix u-sheet u-sheet-1">
      <div className="u-shape u-shape-svg u-text-white u-shape-1">
        <svg
          className="u-svg-link"
          preserveAspectRatio="none"
          viewBox="0 0 160 160"
          style={{}}
        >
          <use
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xlinkHref="#svg-0d86"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          xmlSpace="preserve"
          className="u-svg-content"
          viewBox="0 0 160 160"
          x="0px"
          y="0px"
          id="svg-0d86"
          style={{ enableBackground: "new 0 0 160 160" }}
        >
          <path
            d="M80,30c27.6,0,50,22.4,50,50s-22.4,50-50,50s-50-22.4-50-50S52.4,30,80,30 M80,0C35.8,0,0,35.8,0,80s35.8,80,80,80
	s80-35.8,80-80S124.2,0,80,0L80,0z"
          />
        </svg>
      </div>
      <h2 className="u-text u-text-body-alt-color u-text-1">
        A new style made for the runaway
      </h2>
    </div>
  </section>
  <section
    className="u-clearfix u-palette-4-base u-valign-bottom-lg u-valign-bottom-xl u-section-2"
    id="carousel_4af4"
  >
    <h1 className="u-text u-title u-text-1">70% off</h1>
    <div className="u-align-center-sm u-align-center-xs u-container-style u-expanded-width u-group u-white u-group-1">
      <div className="u-container-layout u-container-layout-1">
        <img
          src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/1661323082089h0U1Xo.png"
          alt=""
          className="u-image u-image-default u-image-1"
          data-image-width={896}
          data-image-height={1500}
        />
        <p className="u-textss u-text-2">
          New season, new wardrobe. As the temperatures continue to rise, have
          your style follow suit and revamp your racks with fresh, elevated
          summer looks.
        </p>
      </div>
    </div>
  </section>
  <section
    className="u-clearfix u-valign-middle-md u-valign-middle-sm u-valign-middle-xs u-section-3"
    id="carousel_e30d"
  >
    <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
      <div className="u-layout">
        <div className="u-layout-row">
          <div className="u-align-center-sm u-align-center-xs u-black u-container-style u-layout-cell u-left-cell u-size-45 u-layout-cell-1">
            <div className="u-container-layout u-valign-middle-md u-valign-middle-sm u-valign-middle-xs u-container-layout-1">
              <h1 className="u-text u-text-1">Black Friday Discount</h1>
              <h4 className="u-text u-text-2">Limited time offer</h4>
            </div>
          </div>
          <div className="u-align-center u-container-style u-layout-cell u-palette-4-base u-right-cell u-size-15 u-layout-cell-2">
            <div className="u-container-layout u-valign-middle-lg u-valign-middle-md u-valign-middle-xl u-valign-top-sm u-valign-top-xs u-container-layout-2">
              <h3 className="u-text u-text-3">80% off</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    className="u-align-center-xl u-align-left-lg u-align-left-md u-align-left-sm u-align-left-xs u-clearfix u-valign-middle u-section-4"
    id="carousel_0910"
  >
    {/*products*/}
    {/*products_options_json*/}
    {/*{"type":"Recent","source":"","tags":"","count":""}*/}
    {/*/products_options_json*/}
    <div className="u-expanded-width u-products u-products-1">
      <div className="u-repeater u-repeater-1">
        {/*product_item*/}
        {/*product_image*/}
        <div
          className="u-align-center u-container-style u-image u-product-control u-products-item u-repeater-item u-image-1"
          data-image-width={800}
          data-image-height={1066}
        >
          {/*/product_image*/}
          <div className="u-container-layout u-similar-container u-valign-bottom u-container-layout-1">
            {/*product_price*/}
            <div className="u-product-control u-product-price u-product-price-1">
              <div className="u-price-wrapper u-spacing-10">
                {/*product_old_price*/}
                <div className="u-hide-price u-old-price">
                  {/*product_old_price_content*/}$12
                  {/*/product_old_price_content*/}
                </div>
                {/*/product_old_price*/}
                {/*product_regular_price*/}
                <div
                  className="u-price u-text-body-alt-color"
                  style={{ fontSize: "1.25rem", fontWeight: 700 }}
                >
                  {/*product_regular_price_content*/}$9.95
                  {/*/product_regular_price_content*/}
                </div>
                {/*/product_regular_price*/}
              </div>
            </div>
            {/*/product_price*/}
            {/*product_button*/}
            {/*options_json*/}
            {/*{"clickType":"add-to-cart","content":""}*/}
            {/*/options_json*/}
            <a
              href="https://nicepage.com/k/loans-website-templates"
              className="u-border-2 u-border-white u-btn u-button-style u-none u-product-control u-text-body-alt-color u-text-hover-black u-btn-1"
            >
              {/*product_button_content*/}Add to Cart
              {/*/product_button_content*/}
            </a>
            {/*/product_button*/}
          </div>
        </div>
        {/*/product_item*/}
        {/*product_item*/}
        {/*product_image*/}
        <div
          className="u-align-center-lg u-align-center-md u-align-center-sm u-align-center-xs u-container-style u-image u-product-control u-products-item u-repeater-item u-image-2"
          data-image-width={700}
          data-image-height={933}
        >
          {/*/product_image*/}
          <div className="u-container-layout u-similar-container u-valign-bottom u-container-layout-2">
            {/*product_price*/}
            <div className="u-product-control u-product-price u-product-price-2">
              <div className="u-price-wrapper u-spacing-10">
                {/*product_old_price*/}
                <div className="u-hide-price u-old-price">
                  {/*product_old_price_content*/}$12
                  {/*/product_old_price_content*/}
                </div>
                {/*/product_old_price*/}
                {/*product_regular_price*/}
                <div
                  className="u-price u-text-body-alt-color"
                  style={{ fontSize: "1.25rem", fontWeight: 700 }}
                >
                  {/*product_regular_price_content*/}$9.95
                  {/*/product_regular_price_content*/}
                </div>
                {/*/product_regular_price*/}
              </div>
            </div>
            {/*/product_price*/}
            {/*product_button*/}
            {/*options_json*/}
            {/*{"clickType":"add-to-cart","content":""}*/}
            {/*/options_json*/}
            <a
              href="https://nicepage.com/static-site-generator"
              className="u-border-2 u-border-white u-btn u-button-style u-none u-product-control u-text-body-alt-color u-text-hover-black u-btn-2"
            >
              {/*product_button_content*/}Add to Cart
              {/*/product_button_content*/}
            </a>
            {/*/product_button*/}
          </div>
        </div>
        {/*/product_item*/}
        {/*product_item*/}
        {/*product_image*/}
        <div
          className="u-align-center u-container-style u-image u-product-control u-products-item u-repeater-item u-image-3"
          data-image-width={811}
          data-image-height={1080}
        >
          {/*/product_image*/}
          <div className="u-container-layout u-similar-container u-valign-bottom u-container-layout-3">
            {/*product_price*/}
            <div className="u-product-control u-product-price u-product-price-3">
              <div className="u-price-wrapper u-spacing-10">
                {/*product_old_price*/}
                <div className="u-hide-price u-old-price">
                  {/*product_old_price_content*/}$12
                  {/*/product_old_price_content*/}
                </div>
                {/*/product_old_price*/}
                {/*product_regular_price*/}
                <div
                  className="u-price u-text-body-alt-color"
                  style={{ fontSize: "1.25rem", fontWeight: 700 }}
                >
                  {/*product_regular_price_content*/}$9.95
                  {/*/product_regular_price_content*/}
                </div>
                {/*/product_regular_price*/}
              </div>
            </div>
            {/*/product_price*/}
            {/*product_button*/}
            {/*options_json*/}
            {/*{"clickType":"add-to-cart","content":""}*/}
            {/*/options_json*/}
            <a
              href="https://nicepage.com/web-design"
              className="u-border-2 u-border-white u-btn u-button-style u-none u-product-control u-text-body-alt-color u-text-hover-black u-btn-3"
            >
              {/*product_button_content*/}Add to Cart
              {/*/product_button_content*/}
            </a>
            {/*/product_button*/}
          </div>
        </div>
        {/*/product_item*/}
        {/*product_item*/}
        {/*product_image*/}
        <div
          className="u-align-center u-container-style u-image u-product-control u-products-item u-repeater-item u-image-4"
          data-image-width={700}
          data-image-height={933}
        >
          {/*/product_image*/}
          <div className="u-container-layout u-similar-container u-valign-bottom u-container-layout-4">
            {/*product_price*/}
            <div className="u-product-control u-product-price u-product-price-4">
              <div className="u-price-wrapper u-spacing-10">
                {/*product_old_price*/}
                <div className="u-hide-price u-old-price">
                  {/*product_old_price_content*/}$12
                  {/*/product_old_price_content*/}
                </div>
                {/*/product_old_price*/}
                {/*product_regular_price*/}
                <div
                  className="u-price u-text-body-alt-color"
                  style={{ fontSize: "1.25rem", fontWeight: 700 }}
                >
                  {/*product_regular_price_content*/}$9.95
                  {/*/product_regular_price_content*/}
                </div>
                {/*/product_regular_price*/}
              </div>
            </div>
            {/*/product_price*/}
            {/*product_button*/}
            {/*options_json*/}
            {/*{"clickType":"add-to-cart","content":""}*/}
            {/*/options_json*/}
            <a
              href="https://nicepage.com/c/real-estate-html-templates"
              className="u-border-2 u-border-white u-btn u-button-style u-none u-product-control u-text-body-alt-color u-text-hover-black u-btn-4"
            >
              {/*product_button_content*/}Add to Cart
              {/*/product_button_content*/}
            </a>
            {/*/product_button*/}
          </div>
        </div>
        {/*/product_item*/}
      </div>
    </div>
    {/*/products*/}
  </section>
  <section className="u-align-left u-clearfix u-section-5" id="carousel_b54f">
    <div className="u-clearfix container u-sheet u-sheet-1">
      <div className="u-container-style u-expanded-width-md u-expanded-width-sm u-expanded-width-xs u-group u-group-1">
        <div className="u-container-layout u-valign-middle-lg u-valign-middle-md u-valign-middle-xl u-valign-top-sm u-valign-top-xs u-container-layout-1">
          <h1 className="u-texting u-text-palette-5-dark-3 u-text-1">Summer</h1>
          <h5 className="u-custom-font u-font-pt-sans u-texting u-text-2">
            Outfit Inspiration
          </h5>
          <p className="u-custom-font u-heading-font u-texting u-text-3">
            New season, new wardrobe. As the temperatures continue to rise, have
            your style follow suit and revamp your racks with fresh, elevated
            summer looks.{" "}
          </p><br />
          <a
            href="https://nicepage.online"
            className="u-active-white u-black u-btn u-btn-rectangle u-button-style u-custom-font u-heading-font u-hover-white u-radius-0 u-btn-1"
          >
            read more
          </a>
        </div>
      </div>
      <div className="u-list u-list-1">
        <div className="u-repeater u-repeater-1">
          <div className="u-container-style u-list-item u-repeater-item">
            <div className="u-container-layout u-similar-container u-valign-bottom-xs u-container-layout-2">
              <img
                src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/1661325753603juZlGv.png"
                alt=""
                className="u-image u-image-default u-image-1"
                data-image-width={1900}
                data-image-height={2532}
              />
              <h6 className="u-custom-font u-font-ubuntu u-texting u-text-4">
                Sample Headline
              </h6>
              <p className="u-texting u-text-grey-40 u-text-5">
                Sample text. Click to select the text box. Click again or double
                click to start editing the text.
              </p>
              <h3 className="u-custom-font u-font-ubuntu u-texting u-text-6">
                $200
              </h3>
            </div>
          </div>
          <div className="u-container-style u-list-item u-repeater-item">
            <div className="u-container-layout u-similar-container u-valign-bottom-xs u-container-layout-3">
              <img
                src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/1661325753592PCgq1J.png"
                alt=""
                className="u-image u-image-default u-image-2"
                data-image-width={1900}
                data-image-height={2532}
              />
              <h6 className="u-custom-font u-font-ubuntu u-texting u-text-7">
                Sample Headline
              </h6>
              <p className="u-texting u-text-grey-40 u-text-8">
                Sample text. Click to select the text box. Click again or double
                click to start editing the text.
              </p>
              <h3 className="u-custom-font u-font-ubuntu u-texting u-text-9">
                $300
              </h3>
            </div>
          </div>
          <div className="u-container-style u-list-item u-repeater-item">
            <div className="u-container-layout u-similar-container u-valign-bottom-xs u-container-layout-4">
              <img
                src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/166132575359617YXsa.png"
                alt=""
                className="u-image u-image-default u-image-3"
              />
              <h6 className="u-custom-font u-font-ubuntu u-texting u-text-10">
                Sample Headline
              </h6>
              <p className="u-texting u-text-grey-40 u-text-11">
                Sample text. Click to select the text box. Click again or double
                click to start editing the text.
              </p>
              <h3 className="u-custom-font u-font-ubuntu u-texting u-text-12">
                $250
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="u-align-center u-clearfix u-section-6" id="carousel_cb5c">
    <div className="u-expanded-width u-gallery u-layout-grid u-lightbox u-show-text-on-hover u-gallery-1">
      <div className="u-gallery-inner u-gallery-inner-1">
        <div className="u-effect-fade u-gallery-item">
          <div className="u-back-slide">
            <img
              className="u-back-image u-expanded"
              src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/1661325753620rbpyzp.png"
            />
          </div>
          <div className="u-over-slide u-shading u-over-slide-1">
            <h3 className="u-gallery-heading" />
            <p className="u-gallery-text" />
          </div>
        </div>
        <div className="u-effect-fade u-gallery-item">
          <div className="u-back-slide">
            <img
              className="u-back-image u-expanded"
              src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/1661325753622SHjbGB.png"
            />
          </div>
          <div className="u-over-slide u-shading u-over-slide-2">
            <h3 className="u-gallery-heading" />
            <p className="u-gallery-text" />
          </div>
        </div>
        <div className="u-effect-fade u-gallery-item">
          <div className="u-back-slide">
            <img
              className="u-back-image u-expanded"
              src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/1661325753619v5hL8A.png"
            />
          </div>
          <div className="u-over-slide u-shading u-over-slide-3">
            <h3 className="u-gallery-heading" />
            <p className="u-gallery-text" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    className="u-clearfix u-valign-middle u-section-7"
    id="carousel_6e61"
  >
    <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
      <div className="u-layout">
        <div className="u-layout-row">
          <div className=" u-align-demo u-align-right u-container-style u-image u-layout-cell u-left-cell u-size-43-md u-size-43-sm u-size-43-xs u-size-45-lg u-size-45-xl u-image-1">
            <div className="u-container-layout u-valign-top-md u-valign-top-sm u-valign-top-xs u-container-layout-1">
              <h2 className="u-texting u-text-1">Men's Style &amp; Fashion</h2>
              <p className="u-texting u-text-2">
                News, Tips, Trends &amp; Celebrity Style
              </p>
              <p className="u-texting u-text-black u-text-3">
                Image by{" "}
                <a
                  href="https://www.freepik.com/photos/fashion"
                  className="u-border-1 u-border-grey-75 u-btn u-button-style u-none u-text-body-color u-btn-1"
                >
                  Freepik
                </a>
              </p>
              <a
                href="https://go.lnkam.com/link/r?u=https%3A%2F%2Fwww.apple.com&campaign_id=3gAeRuCpz6kHngLps2LHKQ&source=zhwvpm6yrdvacl"
                className="u-btn u-button-style u-hover-palette-4-dark-2 u-palette-4-base u-btn-2"
                target="_blank"
              >
                Learn more
              </a>
            </div>
          </div>
          <div className=" u-align-demos u-black u-container-style u-layout-cell u-right-cell u-size-15-lg u-size-15-xl u-size-17-md u-size-17-sm u-size-17-xs u-layout-cell-2">
            <div className="u-container-layout u-valign-bottom u-container-layout-2">
              <p className="u-custom-font u-heading-font u-text u-text-palette-4-base u-text-4">
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="u-clearfix u-section-8" id="carousel_62f3">
    <div className="u-clearfix container u-sheet u-valign-middle u-sheet-1">
      <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
        <div className="u-layout">
          <div className="u-layout-row">
            <div className="u-container-style u-layout-cell u-left-cell u-size-30 u-layout-cell-1">
              <div className="u-container-layout u-container-layout-1">
                <h1 className="u-texting u-text-default u-title u-text-1">
                  Hello,
                  <br />
                  I'm Linda
                </h1>
                <h6 className="u-texting u-text-default u-text-2">
                  We help to capitalize on low hanging fruit to identify a
                  ballpark value added activity to beta test. Nanotechnology
                  immersion along the information highway will close the loop on
                  focusing on the line.
                </h6>
                <div className="u-palette-4-base u-shape u-shape-circle u-shape-1" />
              </div>
            </div>
            <div className="u-container-style u-image u-layout-cell u-right-cell u-size-30 u-image-1">
              <div className="u-container-layout u-container-layout-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    className="u-clearfix u-valign-middle-lg u-valign-middle-md u-valign-middle-sm u-valign-middle-xs u-section-9"
    id="carousel_46dd"
  >
    <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
      <div className="u-layout">
        <div className="u-layout-row">
          <div className="u-align-center-sm u-align-center-xs u-container-style u-layout-cell u-left-cell u-palette-4-base u-shape-rectangle u-size-30 u-layout-cell-1">
            <div className="u-container-layout u-valign-middle-sm u-valign-middle-xl u-valign-middle-xs u-container-layout-1">
              <h2 className="u-text u-text-default u-text-1">Contact Us</h2>
              <p className="u-custom-font u-heading-font u-text u-text-body-alt-color u-text-2">
                Vitae congue mauris rhoncus aenean vel elit scelerisque.
                Consequat nisl vel pretium lectus quam id leo in vitae.
              </p>
              <div className="u-form u-form-1">
                <form
                  action="//publish.nicepage.com/Form/Process"
                  method="POST"
                  className="u-clearfix u-form-spacing-10 u-form-vertical u-inner-form"
                  style={{ padding: 0 }}
                  source="email"
                  name="form"
                >
                  <div className="u-form-group u-form-name">
                    <label
                      htmlFor="name-ac24"
                      className="u-form-control-hidden u-label"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      placeholder="Enter your Name"
                      id="name-ac24"
                      name="name"
                      className="u-border-1 u-border-white u-input u-input-rectangle"
                      required=""
                    />
                  </div>
                  <div className="u-form-group u-form-message">
                    <label
                      htmlFor="message-ac24"
                      className="u-form-control-hidden u-label"
                    >
                      Message
                    </label>
                    <textarea
                      placeholder="Enter your message"
                      rows={4}
                      cols={50}
                      id="message-ac24"
                      name="message"
                      className="u-border-1 u-border-white u-input u-input-rectangle"
                      required=""
                      defaultValue={""}
                    />
                  </div>
                  <div className="u-align-left u-form-group u-form-submit">
                    <a
                      href="#"
                      className="u-btn u-btn-submit u-button-style u-white u-btn-1"
                    >
                      Submit
                    </a>
                    <input
                      type="submit"
                      defaultValue="submit"
                      className="u-form-control-hidden"
                    />
                  </div>
                  <div className="u-form-send-message u-form-send-success">
                    {" "}
                    Thank you! Your message has been sent.{" "}
                  </div>
                  <div className="u-form-send-error u-form-send-message">
                    {" "}
                    Unable to send your message. Please fix errors then try
                    again.{" "}
                  </div>
                  <input
                    type="hidden"
                    defaultValue=""
                    name="recaptchaResponse"
                  />
                </form>
              </div>
            </div>
          </div>
          <div className="u-container-style u-image u-layout-cell u-right-cell u-size-30 u-image-1">
            <div className="u-container-layout u-container-layout-2">
              <h2 className="u-custom-font u-font-playfair-display u-text u-text-body-alt-color u-text-3">
                tender style
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
    </>
  )
}
