import React, { Component } from "react"
import logo from '../../img/shopperr_logo.png';
import Categories from '../../global/Categories';
import CommonFunctions from '../../global/CommonFunctions';
class HeaderSearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keyword: props.search.keyword ?? "",
            category: props.search.category ?? "search",
        }
        let results = Categories.getCategories().then((data) => {
            this.setState({ categories: data.results });
            return data;
        });

    }
    searchProducts(e) {
        this.setState({ keyword: CommonFunctions.getValidURL(e.target.value) });
        if (e.key === 'Enter') {
            this.onSearch(e);
        }
    }
    onSearch(e,category) {
        e.preventDefault();
        window.location.href = `/${category ?? this.state.category}/${this.state.keyword}`;
    }
    render() {
        return (
            <div className="header-container container-fluid">
                <div className="header-row py-2">
                    <div className="header-column w-100">
                        <div className="header-row">
                            <div className="header-logo z-index-2 col-lg-4 px-0">
                                <a href="/">
                                    <img alt="Shopperr" className="ShopperrLogo" data-sticky-width="82" data-sticky-height="40" data-sticky-top="84" src={logo} />
                                </a>
                            </div>
                            <div className="header-nav-features header-nav-features-no-border col-lg-5 col-xl-6 px-0 ms-0">
                                <div className="header-nav-feature">
                                    <form role="search">
                                        <div className="search-with-select">
                                            <a href="#" className="mobile-search-toggle-btn me-2" data-porto-toggle-class="open">
                                                <i className="icons icon-magnifier text-color-dark text-color-hover-primary"></i>
                                            </a>
                                            <div className="search-form-wrapper input-group">
                                                <input className="form-control text-1" name="q" type="text" placeholder="Search..." onInput={this.searchProducts.bind(this)} />
                                                <div className="search-form-select-wrapper">
                                                    <div className="custom-select-1">
                                                        <select name="category" className="form-control form-select" onChange={(e) => { this.setState({ category: CommonFunctions.getValidURL(e.target.value) }); this.onSearch(e,CommonFunctions.getValidURL(e.target.value)); }} value={this.state.category}>
                                                            <option value="search">All Categories</option>
                                                            {
                                                                this.state.categories ?
                                                                    this.state.categories.map((item, key) => {
                                                                        return (
                                                                            <option value={CommonFunctions.getValidURL(item.category)} key={key}>{item.category}</option>
                                                                        );
                                                                    })
                                                                    : ""
                                                            }
                                                        </select>
                                                    </div>
                                                    <button className="btn" type="submit" onClick={this.onSearch.bind(this)}>
                                                        <i className="icons icon-magnifier header-nav-top-icon"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {/* <ul className="header-extra-info col-lg-3 col-xl-2 ps-2 ps-xl-0 ms-lg-3 d-none d-lg-block">
                                <li className="d-none d-sm-inline-flex ms-0">
                                    <div className="header-extra-info-icon ms-lg-4">
                                        <i className="icons icon-phone text-3 text-color-dark position-relative top-1"></i>
                                    </div>
                                    <div className="header-extra-info-text">
                                        <label className="text-1 font-weight-semibold text-color-default">CALL US NOW</label>
                                        <strong className="text-4"><a href="tel:+1234567890" className="text-color-hover-primary text-decoration-none">+123 4567 890</a></strong>
                                    </div>
                                </li>
                            </ul> */}
                            {/*---
                              !User.getUser() ? 
                              <Login />
                              :
                              <HeaderCart />                              
                            ---*/}
                        </div>
                    </div>
                   {/*---  <div className="header-column justify-content-end">
                        <div className="header-row">


                        </div>
                    </div>
                    ---*/}
                </div>
            </div>
        )
    }
}


export default HeaderSearchBar;