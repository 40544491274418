import React from 'react'
import Computers_Accessories1 from './Computers_Accessories1'
import Computers_Accessories2 from './Computers_Accessories2'
import Computers_Accessories3 from './Computers_Accessories3'
import Computers_Accessories4 from './Computers_Accessories4'
export default function Computers_Accessories() {
  return (
    <>
        <Computers_Accessories1/>
        <Computers_Accessories2/>
        <Computers_Accessories3/>
        <Computers_Accessories4/>
    </>
  )
}
