import React, { Component } from "react"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import placeHolderSrc from '../../img/placeholder-medium.png'
import User from '../../global/User';
import axios from "axios";
import { NavLink } from "react-router-dom";
import cf from '../../global/CommonFunctions';
class HeaderCart extends Component {
  constructor() {
    super();
    this.state = {
      products: [],
      cartDetail:{
        grossAmount:0
      }
    }
    this.removeFromCart = this.removeFromCart.bind(this);
    this.apiUrl = process.env.REACT_APP_NODE_API_URL.trim() + "/"+ process.env.REACT_APP_NODE_API_PATH.trim().replace(/\/+/g,"\/");
  }
  logoutUser() {
    User.logoutUser();
    window.location.reload()
  }
  componentDidMount() {
    this.orderDetails()
    document.addEventListener("updateCart",this.updateCartHander.bind(this));
  }
  componentWillUnmount(){
    document.removeEventListener("updateCart",this.updateCartHander.bind(this));
  } 
  updateCartHander(e){
    if(e.detail){
      this.setState({products:e.detail.products});
      delete e.detail.products;
      this.setState({cartDetail:e.detail});
      return false;
    } 
    this.orderDetails()
  }

  toggleClass(e){
    e.preventDefault();
    let el = document.getElementById("headerTopCartDropdown");
    if(this.state.products.length < 1){
      el.style.display = "none"
      return false
    }
    el.classList.toggle("show")
  }
  orderDetails(){
    let user = User.getUser()
    axios
    .post(this.apiUrl+"/addtocart", {
        key: process.env.REACT_APP_MOBILE_API_KEY.trim(),
        token: process.env.REACT_APP_MOBILE_API_TOKEN.trim(),
        merchantId: user.id,
        source: "web",
        accessStatus: 0,
    })
    .then((res) => {
        let cartDetail = res.data.Response.data;
        this.setState({products:cartDetail.products});
        delete cartDetail.products;
        this.setState({cartDetail});
        
    })
    .catch((err) => {
        console.log("Error in Single Product APi", err);
    });
  }
  removeFromCart = (_id,sku,e) => {
    e.preventDefault();

    this.addToCartApi([{
        "productId": _id,
        "sku": sku,
        "quantity": 0

    }],{accessStatus:1,add:0},this.orderDetails.bind(this))
        
  };
  addToCartApi = (cart,params = {accessStatus:1,add:0},callback)=>{
    let user = User.getUser()
    axios
      .post(this.apiUrl+"/addtocart", {
        key: process.env.REACT_APP_MOBILE_API_KEY.trim(),
        token: process.env.REACT_APP_MOBILE_API_TOKEN.trim(),
        merchantId: user.id,
        source: "web",
        ...params,
        cartData: cart
      })
      .then((res) => {
        callback()
      })
      .catch((err) => {
        console.log("Error in Single Product APi", err);
      });
  }

  render() {
    return (
      <div className="d-flex" id="header">
        <ul className="header-extra-info" style={{ position: 'fixed' }}>
          {/* <li className="mr-2 ml-3" style={{ marginRight: '0px!important' }}>
            <div className="header-extra-info-icon">
              <a href="https://sellercentral.shopperr.in/merchant/dashboard" className="text-decoration-none text-color-dark text-color-hover-primary text-2">
                <i className="icons icon-user" style={{ fontSize: '24px' }}></i>
              </a>
            </div>
          </li>
          <li className="mr-2 ml-3" style={{ marginRight: '0px!important' }}>
            <div className="header-extra-info-icon">
              <a href="https://sellercentral.shopperr.in/merchant/subscribeproduct" className="text-decoration-none text-color-dark text-color-hover-primary text-2">
                <i className="icons icon-heart" style={{ fontSize: '24px' }}></i>
              </a>
            </div>
          </li> */}

           <li style={{ marginLeft: '0' }}>

            <a href="" className="text-decoration-none text-color-dark text-color-hover-primary text-2"  onClick={this.toggleClass.bind(this)}>
              <i className="icon-bag icons" style={{ fontSize: '16px' }}></i>
              <span className="cart-info">
                <span className="cart-qty"></span>
              </span>
            </a>

          </li> 
          {/* {User.getUser() ?
            <li className="ml-0 ml-xl-4" style={{ marginLeft: '10px!important', marginRight: '0.5rem!important' }}>
              <div className="header-extra-info-icon">
                <a href="#" onClick={this.logoutUser.bind(this)} className="text-decoration-none text-color-dark text-color-hover-primary text-2">
                  <i className="icon-logout icons" style={{ fontSize: '24px' }}></i>
                  <span className="name" style={{ fontSize: '16px', marginLeft: '3px' }}>logout</span>
                </a>
              </div>
            </li> :
            ""
          } */}

        </ul>
        <div className="header-nav-features pl-0">
          <div className="header-nav-feature header-nav-features-cart header-nav-features-cart-big d-inline-flex">
             <a href="" className="header-nav-features-toggle" onClick={this.toggleClass.bind(this)}>
              <img src="img/icons/icon-cart-big.svg" height="0" alt="" className="header-nav-top-icon-img" />
              <span className="cart-info">
                <span className="cart-qty">{this.state.products?.length || 0}</span>
              </span>
            </a> 
            <div className="header-nav-features-dropdown" id="headerTopCartDropdown">
              <ol className="mini-products-list">
                {
                  this.state.products && this.state.products.map((el,i)=>{
                    return (<li className="item" key={i}>
                         <NavLink
                            className="product-image"
                            to={'/product/'+el.handle}
                            exact
                        >
                            <LazyLoadImage
                            //  alt={item.title}
                                src={el.imgUrl && el.imgUrl.length ? el.imgUrl[0].src : ""} // use normal <img> attributes as props
                                onError={(e)=>{e.target.src = placeHolderSrc}}
                                placeholderSrc={placeHolderSrc}
                            />
                        </NavLink>

                        <div className="product-details">
                            <p className="product-name">
                            <NavLink
                              to={'/product/'+el.handle}
                              exact
                            >
                              {el.name.substr(0,20)+"..."} 
                            </NavLink>
                          </p>
                          <p className="qty-price">
                            {el.cartQuantity}X <span className="price">{cf.getPriceWithCurrency(el.price)}</span>
                          </p>
                          <a href="" title="Remove This Item" className="btn-remove" onClick={(e)=>{this.removeFromCart(el._id,el.code,e)}} ><i className="fas fa-times"></i></a>
                        </div>
                      </li>)
                  })
                }

              </ol>
              <div className="totals">
                <span className="label">Total:</span>
                <span className="price-total"><span className="price">{cf.getPriceWithCurrency(this.state.cartDetail.grossAmount)}</span></span>
              </div>
              <div className="actions">
                <NavLink
                  className="btn btn-primary"
                  to={'/cart'}
                  exact
                >
                  View Cart
                </NavLink>
                <NavLink
                  className="btn btn-primary"
                  to={'/checkout'}
                  exact
                >
                  Checkout
                </NavLink>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    )

  }
}
export default HeaderCart;













































