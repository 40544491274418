import React, { Component } from "react"

export default class Thankyou extends Component {

    render() {
        return (

            <div className="card border-width-3 border-radius-0 border-color-success">
                <div className="card-body text-center">
                    <p className="text-color-dark font-weight-bold text-4-5 mb-0">
                        <i className="fas fa-check text-color-success mr-1" /> Thank You. Your
                        Order has been received.
                    </p>
                </div>
            </div>
        )
    }
}