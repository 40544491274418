import React, { Component } from "react"
import Header from '../Header.js';
import Footer from '../Footer.js';
import Branding from './Branding';
import Branded from './Branded';
import Brand from './Brand'
import Brandings from './Brandings'
class Brands extends Component {
    constructor(props) {
        super(props)

    }

    render() {
        return (

            <div>
                <div className="body" style={{ marginTop: '45.1875px', transition: 'margin 300ms ease 0s' }}>
                    <Header />
                    {(() => {



                        switch (this.props.match.params.brandsname) {

                            case 'branding':

                                return (

                                    <Branding />

                                )


                            case 'branded':

                                return (

                                    <Branded />

                                ) 


                            case 'brand':

                                return (

                                    <Brand />

                                )        
                                case 'brandings':

                                return (

                                    <Brandings />

                                )   
                            default:

                                return (

                                    <div></div>

                                )

                        }
                    })()}
                    <Footer />
                </div>

            </div>

        )
    }
}


export default Brands;