import React from 'react'
import JewelleryAccessories1 from './JewelleryAccessories1'
import Jewellery_Accessories2 from './Jewellery_Accessories2'
import Jewellery_Accessories3 from './Jewellery_Accessories3'
import Jewellery_Accessories4 from './Jewellery_Accessories4'
export default function Jewellery() {
  return (
    <>
        <JewelleryAccessories1/>
        <Jewellery_Accessories2/>
        <Jewellery_Accessories3/>
        <Jewellery_Accessories4/>
    </>
  )
}
