import React, { Component } from "react"
import { NavLink } from "react-router-dom";

class TopSelling extends Component {
    render() {
        return (
            <section className="container-fluid top-selling">
                <h3 className="text-center">Top Categories</h3>
                <div className="row">
                    <div className="col-md-2 text-center">
                        <NavLink className="" to={'/search/Hotel Linen'}>
                            <img style = {{height:"195px", width:"195px"}} src="https://cdn.shopify.com/s/files/1/0790/4713/1410/products/H3e31e8ed5e1b4567b6a7d199059e3a69C.jpg?v=1691473778"/>
                            <p>Hotel Linen</p>
                        </NavLink>
                    </div>
                    <div className="col-md-2 text-center">
                        <NavLink className="" to={'/search/Guestroom Customized'}>
                            <img style={{height:"195px", width:"195px"}} src="https://cdn.shopify.com/s/files/1/0790/4713/1410/products/H8cf98006d6fb4ee685709e1936271f0aP.jpg?v=1691509223"/>
                            <p> Guestroom Customized</p>
                        </NavLink>
                    </div>
                    <div className="col-md-2 text-center">
                        <NavLink className="" to={'/search/Cleaning Equipment'}>
                            <img style={{height:"195px", width:"195px"}}  src="https://cdn.shopify.com/s/files/1/0790/4713/1410/products/H55733ca1a85b455cab12827f770ae2c3j.png?v=1691491854"/>
                            <p> Cleaning Equipment</p>
                        </NavLink>
                    </div>
                    <div className="col-md-2 text-center">
                        <NavLink className="" to={'/search/Dinnerware'}>
                            <img style={{height:"195px", width:"185px"}} src="https://cdn.shopify.com/s/files/1/0790/4713/1410/products/H2cc1202c7c854b9aa7b86b14919c764da.png?v=1691503896https://cdn.shopify.com/s/files/1/0790/4713/1410/products/H2cc1202c7c854b9aa7b86b14919c764da.png?v=1691503896" />
                            <p>Dinnerware</p>
                        </NavLink>
                    </div>
                    <div className="col-md-2 text-center">
                        <NavLink className="" to={'/search/Buffet'}>
                            <img style={{height:"195px", width:"195px"}}  src="https://cdn.shopify.com/s/files/1/0790/4713/1410/products/Hb19053dc03794a6a83ed5430c087b69ao.png?v=1691507866" />
                            <p>Buffet</p>
                        </NavLink>
                    </div>
                    <div className="col-md-2 text-center">
                        <NavLink className="" to={'/search/Furniture'}>
                            <img style={{height:"195px", width:"195px"}}  src="https://cdn.shopify.com/s/files/1/0790/4713/1410/products/H14941f3a12c54905b14beeada29bce21p.jpg?v=1691508955" />
                            <p>Furniture</p>
                        </NavLink>
                    </div>
                </div>
            </section>
        )

    }
}

export default TopSelling;       