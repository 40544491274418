import React from 'react'



export default function Branded() {
  return (
    <>

<section className="u-clearfix u-gradient u-section-10" id="carousel_cf28">
    <img
      src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/16619274787517wUdDf.png"
      alt=""
      className="u-image_1 u-image-contain u-image-default u-image-1"
    />
    <div className="u-clearfix u-sheet u-sheet-1">
      <div className="u-align-center-xs u-container-style u-group u-group-1">
        <div className="u-container-layout u-valign-middle-md u-container-layout-1">
          <img
            src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/1661927478776if9s3t.png"
            alt=""
            className="u-image_1 u-image-contain u-image-default u-image-2"
            data-image-width={1280}
            data-image-height={704}
          />
          <h2 className="u-subtitle u-text u-text-grey-40 u-text-1">
            New Collection
          </h2>
          <h1 className="u-text u-title u-text-2" style={{color: '#000'}}>New Balance</h1>
          <p className="u-text u-text-grey-50 u-text-3" style={{color: '#000'}}>
            From sports to running to work shoes and even sandals, browse our
            women’s active shoes below to find your ideal fit.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section className="u-clearfix u-section-20" id="carousel_cb6b">
    <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
      <div className="u-layout">
        <div className="u-layout-row">
          <div className="u-container-style u-image u-layout-cell u-left-cell u-shading u-size-12 u-size-20-md u-image-1">
            <div className="u-container-layout u-container-layout-1">
              <h4 className="u-align-center u-text u-text-1">running</h4>
            </div>
          </div>
          <div className="u-container-style u-image u-layout-cell u-size-12 u-size-20-md u-image-2">
            <div className="u-container-layout u-container-layout-2">
              <h4 className="u-align-center u-text u-text-body-alt-color u-text-2">
                studio
              </h4>
            </div>
          </div>
          <div className="u-container-style u-image u-layout-cell u-shading u-size-12 u-size-20-md u-image-3">
            <div className="u-container-layout u-container-layout-3">
              <h4 className="u-align-center u-text u-text-3">lifestyle</h4>
            </div>
          </div>
          <div className="u-container-style u-image u-layout-cell u-size-12 u-size-30-md u-image-4">
            <div className="u-container-layout u-container-layout-4">
              <h4 className="u-align-center u-text u-text-white u-text-4">
                training
              </h4>
            </div>
          </div>
          <div className="u-container-style u-image u-layout-cell u-right-cell u-size-12 u-size-30-md u-image-5">
            <div className="u-container-layout u-container-layout-5">
              <h4 className="u-align-center u-text u-text-white u-text-5">
                cycling
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="u-clearfix u-image u-section-30" id="carousel_f12a">
    <div className="u-clearfix u-sheet u-sheet-1">
      <div className="u-align-right u-container-style u-expanded-width-sm u-expanded-width-xs u-group u-group-1">
        <div className="u-container-layout u-valign-middle u-container-layout-1">
          <h4 className="u-text u-text-white u-text-1">new collection</h4>
          <h2 className="u-text u-text-white u-text-2">
            New Balance 247 Classic
          </h2>
          <p className="u-text u-text-white u-text-3">
            The shoe features a mesh toe with synthetic nubuck on the midfoot, a
            heel panelling, and a stretchy neoprene collar and tongue that
            provide a foot-hugging comfort and breathability.
          </p>
          <a href="https://nicepage.com/templates" className="u-link u-link-1">
            read more
          </a>
        </div>
      </div>
    </div>
  </section>
  <section className="u-clearfix u-gradient u-section-40" id="carousel_500c">
    <div className="u-clearfix u-sheet u-sheet-1">
      <div className="u-container-style u-group u-group-1">
        <div className="u-container-layout u-valign-middle u-container-layout-1">
          <h2 className="u-align-center u-text u-text-1">About Brand</h2>
          <p className="u-align-center u-text u-text-grey-50 u-text-2">
            One of the coolest sneaker&nbsp;cult-brands ever, for which retro
            models there are people willing to camp outside stores. The brand
            was founded in 1905 and began to manufacture sneakers that were
            originally designed to improve shoe fit - a special design with
            three support points to provide greater balance and comfort in the
            shoe. Their model Tracster, from 1960, was the first professional
            running shoe and the model W320 was a running shoe designed
            especially for women only.
          </p>
          <a href="https://nicepage.me" className="u-link u-link-1">
            read more
          </a>
        </div>
      </div>
    </div>
  </section>
  <section className="u-clearfix u-section-50" id="carousel_9e3c">
    <div className="u-clearfix u-expanded-width u-layout-wrap">
      <div className="u-layout">
        <div className="u-layout-col">
          <div className="u-size-30">
            <div className="u-layout-row">
              <div className="u-container-style u-image u-layout-cell u-left-cell u-size-15 u-size-30-md u-image-1">
                <div className="u-container-layout u-container-layout-1">
                  <h5 className="u-align-center u-text u-text-grey-50 u-text-1">
                    new balance 520
                  </h5>
                  <p className="u-align-center u-text u-text-2">$ 80</p>
                </div>
              </div>
              <div className="u-container-style u-image u-layout-cell u-size-15 u-size-30-md u-image-2">
                <div className="u-container-layout u-container-layout-2">
                  <h5 className="u-align-center u-text u-text-grey-50 u-text-3">
                    new balance 247
                  </h5>
                  <p className="u-align-center u-text u-text-4">$ 70</p>
                </div>
              </div>
              <div className="u-container-style u-image u-layout-cell u-size-15 u-size-30-md u-image-3">
                <div className="u-container-layout u-container-layout-3">
                  <h5 className="u-align-center u-text u-text-grey-50 u-text-5">
                    new balance 373
                  </h5>
                  <p className="u-align-center u-text u-text-6">$ 67</p>
                </div>
              </div>
              <div className="u-container-style u-image u-layout-cell u-right-cell u-size-15 u-size-30-md u-image-4">
                <div className="u-container-layout u-container-layout-4">
                  <h5 className="u-align-center u-text u-text-grey-50 u-text-7">
                    new balance 996
                  </h5>
                  <p className="u-align-center u-text u-text-8">$ 89</p>
                </div>
              </div>
            </div>
          </div>
          <div className="u-size-30">
            <div className="u-layout-row">
              <div className="u-container-style u-image u-layout-cell u-left-cell u-size-15 u-size-30-md u-image-5">
                <div className="u-container-layout u-container-layout-5">
                  <h5 className="u-align-center u-text u-text-grey-50 u-text-9">
                    new balance 247
                  </h5>
                  <p className="u-align-center u-text u-text-10">$ 67</p>
                </div>
              </div>
              <div className="u-container-style u-image u-layout-cell u-size-15 u-size-30-md u-image-6">
                <div className="u-container-layout u-container-layout-6">
                  <h5 className="u-align-center u-text u-text-grey-50 u-text-11">
                    new balance 574
                  </h5>
                  <p className="u-align-center u-text u-text-12">$ 89</p>
                </div>
              </div>
              <div className="u-container-style u-image u-layout-cell u-size-15 u-size-30-md u-image-7">
                <div className="u-container-layout u-container-layout-7">
                  <h5 className="u-align-center u-text u-text-grey-50 u-text-13">
                    new balance 996
                  </h5>
                  <p className="u-align-center u-text u-text-14">$ 90</p>
                </div>
              </div>
              <div className="u-container-style u-image u-layout-cell u-right-cell u-size-15 u-size-30-md u-image-8">
                <div className="u-container-layout u-container-layout-8">
                  <h5 className="u-align-center u-text u-text-grey-50 u-text-15">
                    new balance 997
                  </h5>
                  <p className="u-align-center u-text u-text-16">$ 90</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="u-clearfix u-image u-section-60" id="carousel_ba87">
    <div className="u-clearfix u-sheet u-sheet-1" />
  </section>
  <section className="u-clearfix u-section-70" id="carousel_aabe">
    <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
      <div className="u-layout">
        <div className="u-layout-row">
          <div className="u-size-30 u-size-60-md">
            <div className="u-layout-row">
              <div className="u-container-style u-image u-layout-cell u-left-cell u-size-60 u-image-1">
                <div className="u-container-layout" />
              </div>
            </div>
          </div>
          <div className="u-size-30 u-size-60-md">
            <div className="u-layout-col">
              <div className="u-align-center u-container-style u-gradient u-layout-cell u-right-cell u-size-20 u-layout-cell-2">
                <div className="u-container-layout u-valign-middle">
                  <h4 className="u-text u-text-grey-50 u-text-1" style={{color:'#808080'}}>
                    New Balance
                  </h4>
                  <h2 className="u-text u-text-2" style={{color:'#000'}}>
                    Women's 574 'State Fair' Pack Available Now
                  </h2>
                  <p className="u-text u-text-grey-50 u-text-3" style={{color:'#808080'}}>
                    The pack consists of three vibrant renditions ($80)
                    constructed from a supple nubuck and mesh combination.
                    Contrasting details highlight the N logo as well as the
                    semi-translucent outsole. The 'State Fair' Pack is finished
                    with custom woven tongue labels, as well as an all-over
                    frozen popsicle design on the inner liner. Purchase your
                    favorite pair online, as well as in-store.{" "}
                  </p>
                  <a
                    href="https://nicepage.com/joomla-page-builder"
                    className="u-link u-link-1"
                  >
                    read more
                  </a>
                </div>
              </div>
              <div className="u-container-style u-image u-layout-cell u-right-cell u-size-40 u-image-2">
                <div className="u-container-layout" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="u-clearfix u-gradient u-section-80" id="carousel_7ffa">
    <div className="u-clearfix u-sheet u-sheet-1">
      <div className="u-container-style u-group u-group-1">
        <div className="u-container-layout u-valign-middle u-container-layout-1">
          <h2 className="u-align-center u-text u-text-1">Run It All</h2>
          <p className="u-align-center u-text u-text-grey-50 u-text-2">
            Introducing the Fresh Foam Lazr V2 for Kids
          </p>
          <a href="https://nicepage.review" className="u-link u-link-1">
            shop now
          </a>
        </div>
      </div>
    </div>
  </section>
  <section className="u-clearfix u-gradient u-section-90" id="carousel_d8cd">
    <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
      <div className="u-gutter-0 u-layout">
        <div className="u-layout-row">
          <div className="u-align-center u-container-style u-layout-cell u-left-cell u-size-20 u-layout-cell-1">
            <div className="u-container-layout u-valign-middle u-container-layout-1">
              <h2 className="u-text u-text-1" style={{color:'#000'}}>New Balance 574 “Pastel” Pack</h2>
              <p className="u-text u-text-grey-50 u-text-2" style={{color:'#808080', fontSize:'16px', lineHeight:'1.5'}}>
                Push past your limits in New Balance shoes for women. Whether
                you’re searching for casual sneakers or athletic shoes for
                women, our collections have something for every activity or
                occasion. From sports to running to work shoes and even sandals,
                browse our women’s active shoes below to find your ideal fit.
              </p>
              <a
                href="https://nicepage.com/website-design"
                className="u-link u-link-1"
              >
                read more
              </a>
            </div>
          </div>
          <div className="u-align-center u-container-style u-image u-layout-cell u-size-20 u-image-1">
            <div className="u-container-layout u-container-layout-2" />
          </div>
          <div className="u-container-style u-image u-layout-cell u-right-cell u-size-20 u-image-2">
            <div className="u-container-layout" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="u-clearfix u-image u-section-100" id="carousel_2280">
    <div className="u-clearfix u-sheet u-sheet-1">
      <div className="u-align-right u-container-style u-group u-group-1">
        <div className="u-container-layout u-valign-middle u-container-layout-1">
          <p className="u-text u-text-1" style={{color:'#000'}}>
            New Balance releases a collection of Women's 574s inspired by the
            sights and sounds of carnival attractions.&nbsp;
          </p>
        </div>
      </div>
    </div>
  </section>
  
    </>
  )
}
