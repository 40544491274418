import React, { Component } from "react"
import HeaderAdvertisement from './Header/HeaderAdvertisement';
import HeaderNavigation from './Header/HeaderNavigation';
import HeaderSearchBar from './Header/HeaderSearchBar';
import HeaderNavbar from './Header/HeaderNavbar';
import { ToastContainer} from 'react-toastify';
class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    componentDidMount() {

    }
    render() {
        return (
            <div>
                {/* <HeaderAdvertisement style={{display:"none;"}} /> */}
                <header id="header" data-plugin-options="{'stickyEnabled': true, 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true, 'stickyStartAt': 135, 'stickySetTop': '-135px', 'stickyChangeLogo': true}" style={{ height: "182px" }}>
                    <div className="header-body header-body-bottom-border-fixed box-shadow-none border-top-0">
                        <HeaderNavigation />
                        <HeaderSearchBar search={{...this.props.search}} />
                        <HeaderNavbar parentCallback={this.props.parentCallback} />
                        <ToastContainer />
                    </div>
                </header>
            </div>
        )

    }
}

export default Header;