import axios from "axios";
import jwt_decoder from 'jwt-decode';
class User {
  tokenkey = Symbol(process.env.REACT_APP_USER_TOKEN_KEY.trim());
  constructor() {
      
  }
  setUser(data){
	 //console.log('setUser',data);
    return window.localStorage.setItem(this.tokenkey.description,data);
  }
  getUser(){
    try {
      let decoder = jwt_decoder(window.localStorage.getItem(this.tokenkey.description) || "");
      return (decoder?.email && decoder?.id ) && decoder || null;
    } catch(err){
      return null
    }
  }
  logoutUser(){
    window.localStorage.removeItem(this.tokenkey.description);
    window.location.href = `${process.env.REACT_APP_NODE_API_URL}/logout-merchant?redirecturl=${window.origin}`;
  }
  loginUser(){
    localStorage.setItem("lref",window.location.href);
    window.location.href = `${process.env.REACT_APP_NODE_API_URL}/login-merchant?redirecturl=${window.origin}/verify-login-merchant`;
  }
}

const userObj = new User();
export default userObj;     
