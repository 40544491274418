import React, { Component } from "react"
import FooterFirst from './Footer/FooterFirst';
import FooterTwo from './Footer/FooterTwo';





class Footer extends Component {
    constructor() {
        super()
        this.state = {
        }
    }
    componentDidMount() {

    }
    render() {

        return (
            <div>
                <footer id="footer" className="footer-texts-more-lighten">
                    <FooterFirst />
                    <FooterTwo />

                </footer>






            </div>
        )

    }
}

export default Footer;