import axios from "axios";
export default (() => {
class CommonFunctions {
    dC = "USD";
    cC = 'USD' || this.dC;
    symbols = {
         countrycode:{"US":"USD","IN":"INR","CN":"CNY","JP":"JPY","GB":"GBP","EU":"EUR","AE":"AED","AU":"AUD","BD":"BDT","CA":"CAD","CH":"CHF","HK":"HKD","ID":"IDR","KR":"KRW","LK":"LKR","MX":"MXN","MY":"MYR","PH":"PHP","QA":"QAR","SA":"SAR","TH":"THB","TR":"TRY","ZA":"ZAR"},
         "moneyFormats":{
           "USD":{
              "money_format":"${{amount}}",
              "money_format_no_decimals":"${{amount}}",
              "money_with_currency_format":"${{amount}} USD",
              "money_with_currency_format_no_decimals":"${{amount}} USD"
           },
           "EUR":{
              "money_format":"€{{amount}}",
              "money_format_no_decimals":"€{{amount}}",     
              "money_with_currency_format":"€{{amount}} EUR",
              "money_with_currency_format_no_decimals":"€{{amount}} EUR"
           },
           "GBP":{
              "money_format":"£{{amount}}",
              "money_format_no_decimals":"£{{amount}}",
              "money_with_currency_format":"£{{amount}} GBP",
              "money_with_currency_format_no_decimals":"£{{amount}} GBP"
           },
           "CAD":{
              "money_format":"${{amount}}",
              "money_format_no_decimals":"${{amount}}",
              "money_with_currency_format":"${{amount}} CAD",
              "money_with_currency_format_no_decimals":"${{amount}} CAD"
           },
           "ALL":{
              "money_format":"Lek {{amount}}",
              "money_format_no_decimals":"Lek {{amount}}",
              "money_with_currency_format":"Lek {{amount}} ALL",
              "money_with_currency_format_no_decimals":"Lek {{amount}} ALL"
           },
           "DZD":{
              "money_format":"DA {{amount}}",
              "money_format_no_decimals":"DA {{amount}}",
              "money_with_currency_format":"DA {{amount}} DZD",
              "money_with_currency_format_no_decimals":"DA {{amount}} DZD"
           },
           "AOA":{
              "money_format":"Kz{{amount}}",
              "money_format_no_decimals":"Kz{{amount}}",
              "money_with_currency_format":"Kz{{amount}} AOA",
              "money_with_currency_format_no_decimals":"Kz{{amount}} AOA"
           },
           "ARS":{
              "money_format":"${{amount}}",
              "money_format_no_decimals":"${{amount}}",
              "money_with_currency_format":"${{amount}} ARS",
              "money_with_currency_format_no_decimals":"${{amount}} ARS"
           },
           "AMD":{
              "money_format":"{{amount}} AMD",
              "money_format_no_decimals":"{{amount}} AMD",
              "money_with_currency_format":"{{amount}} AMD",
              "money_with_currency_format_no_decimals":"{{amount}} AMD"
           },
           "AWG":{
              "money_format":"Afl{{amount}}",
              "money_format_no_decimals":"Afl{{amount}}",
              "money_with_currency_format":"Afl{{amount}} AWG",
              "money_with_currency_format_no_decimals":"Afl{{amount}} AWG"
           },
           "AUD":{
              "money_format":"${{amount}}",
              "money_format_no_decimals":"${{amount}}",
              "money_with_currency_format":"${{amount}} AUD",
              "money_with_currency_format_no_decimals":"${{amount}} AUD"
           },
           "BBD":{
              "money_format":"${{amount}}",
              "money_format_no_decimals":"${{amount}}",
              "money_with_currency_format":"${{amount}} Bds",
              "money_with_currency_format_no_decimals":"${{amount}} Bds"
           },
           "AZN":{
              "money_format":"m.{{amount}}",
              "money_format_no_decimals":"m.{{amount}}",
              "money_with_currency_format":"m.{{amount}} AZN",
              "money_with_currency_format_no_decimals":"m.{{amount}} AZN"
           },
           "BDT":{
              "money_format":"Tk {{amount}}",
              "money_format_no_decimals":"Tk {{amount}}",
              "money_with_currency_format":"Tk {{amount}} BDT",
              "money_with_currency_format_no_decimals":"Tk {{amount}} BDT"
           },
           "BSD":{
              "money_format":"BS${{amount}}",
              "money_format_no_decimals":"BS${{amount}}",
              "money_with_currency_format":"BS${{amount}} BSD",
              "money_with_currency_format_no_decimals":"BS${{amount}} BSD"
           },
           "BHD":{
              "money_format":"{{amount}}0 BD",
              "money_format_no_decimals":"{{amount}}0 BD",
              "money_with_currency_format":"{{amount}}0 BHD",
              "money_with_currency_format_no_decimals":"{{amount}}0 BHD"
           },
           "BYR":{
              "money_format":"Br {{amount}}",
              "money_format_no_decimals":"Br {{amount}}",
              "money_with_currency_format":"Br {{amount}} BYR",
              "money_with_currency_format_no_decimals":"Br {{amount}} BYR"
           },
           "BZD":{
              "money_format":"BZ${{amount}}",
              "money_format_no_decimals":"BZ${{amount}}",
              "money_with_currency_format":"BZ${{amount}} BZD",
              "money_with_currency_format_no_decimals":"BZ${{amount}} BZD"
           },
           "BTN":{
              "money_format":"Nu {{amount}}",
              "money_format_no_decimals":"Nu {{amount}}",
              "money_with_currency_format":"Nu {{amount}} BTN",
              "money_with_currency_format_no_decimals":"Nu {{amount}} BTN"
           },
           "BAM":{
              "money_format":"KM {{amount}}",
              "money_format_no_decimals":"KM {{amount}}",
              "money_with_currency_format":"KM {{amount}} BAM",
              "money_with_currency_format_no_decimals":"KM {{amount}} BAM"
           },
           "BRL":{
              "money_format":"R$ {{amount}}",
              "money_format_no_decimals":"R$ {{amount}}",
              "money_with_currency_format":"R$ {{amount}} BRL",
              "money_with_currency_format_no_decimals":"R$ {{amount}} BRL"
           },
           "BOB":{
              "money_format":"Bs{{amount}}",
              "money_format_no_decimals":"Bs{{amount}}",
              "money_with_currency_format":"Bs{{amount}} BOB",
              "money_with_currency_format_no_decimals":"Bs{{amount}} BOB"
           },
           "BWP":{
              "money_format":"P{{amount}}",
              "money_format_no_decimals":"P{{amount}}",
              "money_with_currency_format":"P{{amount}} BWP",
              "money_with_currency_format_no_decimals":"P{{amount}} BWP"
           },
           "BND":{
              "money_format":"${{amount}}",
              "money_format_no_decimals":"${{amount}}",
              "money_with_currency_format":"${{amount}} BND",
              "money_with_currency_format_no_decimals":"${{amount}} BND"
           },
           "BGN":{
              "money_format":"{{amount}} лв",
              "money_format_no_decimals":"{{amount}} лв",
              "money_with_currency_format":"{{amount}} лв BGN",
              "money_with_currency_format_no_decimals":"{{amount}} лв BGN"
           },
           "MMK":{
              "money_format":"K{{amount}}",
              "money_format_no_decimals":"K{{amount}}",
              "money_with_currency_format":"K{{amount}} MMK",
              "money_with_currency_format_no_decimals":"K{{amount}} MMK"
           },
           "KHR":{
              "money_format":"KHR{{amount}}",
              "money_format_no_decimals":"KHR{{amount}}",
              "money_with_currency_format":"KHR{{amount}}",
              "money_with_currency_format_no_decimals":"KHR{{amount}}"
           },
           "KYD":{
              "money_format":"${{amount}}",
              "money_format_no_decimals":"${{amount}}",
              "money_with_currency_format":"${{amount}} KYD",
              "money_with_currency_format_no_decimals":"${{amount}} KYD"
           },
           "XAF":{
              "money_format":"FCFA{{amount}}",
              "money_format_no_decimals":"FCFA{{amount}}",
              "money_with_currency_format":"FCFA{{amount}} XAF",
              "money_with_currency_format_no_decimals":"FCFA{{amount}} XAF"
           },
           "CLP":{
              "money_format":"${{amount}}",
              "money_format_no_decimals":"${{amount}}",
              "money_with_currency_format":"${{amount}} CLP",
              "money_with_currency_format_no_decimals":"${{amount}} CLP"
           },
           "CNY":{
              "money_format":"¥{{amount}}",
              "money_format_no_decimals":"¥{{amount}}",
              "money_with_currency_format":"¥{{amount}} CNY",
              "money_with_currency_format_no_decimals":"¥{{amount}} CNY"
           },
           "COP":{
              "money_format":"${{amount}}",
              "money_format_no_decimals":"${{amount}}",
              "money_with_currency_format":"${{amount}} COP",
              "money_with_currency_format_no_decimals":"${{amount}} COP"
           },
           "CRC":{
              "money_format":"&#8353; {{amount}}",
              "money_format_no_decimals":"&#8353; {{amount}}",
              "money_with_currency_format":"&#8353; {{amount}} CRC",
              "money_with_currency_format_no_decimals":"&#8353; {{amount}} CRC"
           },
           "HRK":{
              "money_format":"{{amount}} kn",
              "money_format_no_decimals":"{{amount}} kn",
              "money_with_currency_format":"{{amount}} kn HRK",
              "money_with_currency_format_no_decimals":"{{amount}} kn HRK"
           },
           "CZK":{
              "money_format":"{{amount}} Kč",
              "money_format_no_decimals":"{{amount}} Kč",
              "money_with_currency_format":"{{amount}} Kč",
              "money_with_currency_format_no_decimals":"{{amount}} Kč"
           },
           "DKK":{
              "money_format":"{{amount}}",
              "money_format_no_decimals":"{{amount}}",
              "money_with_currency_format":"kr.{{amount}}",
              "money_with_currency_format_no_decimals":"kr.{{amount}}"
           },
           "DOP":{
              "money_format":"RD$ {{amount}}",
              "money_format_no_decimals":"RD$ {{amount}}",
              "money_with_currency_format":"RD$ {{amount}}",
              "money_with_currency_format_no_decimals":"RD$ {{amount}}"
           },
           "XCD":{
              "money_format":"${{amount}}",
              "money_format_no_decimals":"${{amount}}",
              "money_with_currency_format":"EC${{amount}}",
              "money_with_currency_format_no_decimals":"EC${{amount}}"
           },
           "EGP":{
              "money_format":"LE {{amount}}",
              "money_format_no_decimals":"LE {{amount}}",
              "money_with_currency_format":"LE {{amount}} EGP",
              "money_with_currency_format_no_decimals":"LE {{amount}} EGP"
           },
           "ETB":{
              "money_format":"Br{{amount}}",
              "money_format_no_decimals":"Br{{amount}}",
              "money_with_currency_format":"Br{{amount}} ETB",
              "money_with_currency_format_no_decimals":"Br{{amount}} ETB"
           },
           "XPF":{
              "money_format":"{{amount}} XPF",
              "money_format_no_decimals":"{{amount}} XPF",
              "money_with_currency_format":"{{amount}} XPF",
              "money_with_currency_format_no_decimals":"{{amount}} XPF"
           },
           "FJD":{
              "money_format":"${{amount}}",
              "money_format_no_decimals":"${{amount}}",
              "money_with_currency_format":"FJ${{amount}}",
              "money_with_currency_format_no_decimals":"FJ${{amount}}"
           },
           "GMD":{
              "money_format":"D {{amount}}",
              "money_format_no_decimals":"D {{amount}}",
              "money_with_currency_format":"D {{amount}} GMD",
              "money_with_currency_format_no_decimals":"D {{amount}} GMD"
           },
           "GHS":{
              "money_format":"GH₵{{amount}}",
              "money_format_no_decimals":"GH₵{{amount}}",
              "money_with_currency_format":"GH₵{{amount}}",
              "money_with_currency_format_no_decimals":"GH₵{{amount}}"
           },
           "GTQ":{
              "money_format":"Q{{amount}}",
              "money_format_no_decimals":"Q{{amount}}",
              "money_with_currency_format":"{{amount}} GTQ",
              "money_with_currency_format_no_decimals":"{{amount}} GTQ"
           },
           "GYD":{
              "money_format":"G${{amount}}",
              "money_format_no_decimals":"G${{amount}}",
              "money_with_currency_format":"${{amount}} GYD",
              "money_with_currency_format_no_decimals":"${{amount}} GYD"
           },
           "GEL":{
              "money_format":"{{amount}} GEL",
              "money_format_no_decimals":"{{amount}} GEL",
              "money_with_currency_format":"{{amount}} GEL",
              "money_with_currency_format_no_decimals":"{{amount}} GEL"
           },
           "HNL":{
              "money_format":"L {{amount}}",
              "money_format_no_decimals":"L {{amount}}",
              "money_with_currency_format":"L {{amount}} HNL",
              "money_with_currency_format_no_decimals":"L {{amount}} HNL"
           },
           "HKD":{
              "money_format":"${{amount}}",
              "money_with_currency_format":"HK${{amount}}",
              "money_with_currency_format_no_decimals":"HK${{amount}}"
           },
           "HUF":{
              "money_format":"{{amount}}",
              "money_format_no_decimals":"{{amount}}",
              "money_with_currency_format":"{{amount}} Ft",
              "money_with_currency_format_no_decimals":"{{amount}} Ft"
           },
           "ISK":{
              "money_format":"{{amount}} kr",
              "money_format_no_decimals":"{{amount}} kr",
              "money_with_currency_format":"{{amount}} kr ISK",
              "money_with_currency_format_no_decimals":"{{amount}} kr ISK"
           },
           "INR":{
              "money_format":"₹ {{amount}}",
              "money_format_no_decimals":"Rs. {{amount}}",
              "money_with_currency_format":"Rs. {{amount}}",
              "money_with_currency_format_no_decimals":"Rs. {{amount}}"
           },
           "IDR":{
              "money_format":"{{amount}}",
              "money_format_no_decimals":"{{amount}}",
              "money_with_currency_format":"Rp {{amount}}",
              "money_with_currency_format_no_decimals":"Rp {{amount}}"
           },
           "ILS":{
              "money_format":"{{amount}} NIS",
              "money_format_no_decimals":"{{amount}} NIS",
              "money_with_currency_format":"{{amount}} NIS",
              "money_with_currency_format_no_decimals":"{{amount}} NIS"
           },
           "JMD":{
              "money_format":"${{amount}}",
              "money_format_no_decimals":"${{amount}}",
              "money_with_currency_format":"${{amount}} JMD",
              "money_with_currency_format_no_decimals":"${{amount}} JMD"
           },
           "JPY":{
              "money_format":"¥{{amount}}",
              "money_format_no_decimals":"¥{{amount}}",
              "money_with_currency_format":"¥{{amount}} JPY",
              "money_with_currency_format_no_decimals":"¥{{amount}} JPY"
           },
           "JEP":{
              "money_format":"£{{amount}}",
              "money_format_no_decimals":"£{{amount}}",
              "money_with_currency_format":"£{{amount}} JEP",
              "money_with_currency_format_no_decimals":"£{{amount}} JEP"
           },
           "JOD":{
              "money_format":"{{amount}}0 JD",
              "money_format_no_decimals":"{{amount}}0 JD",
              "money_with_currency_format":"{{amount}}0 JOD",
              "money_with_currency_format_no_decimals":"{{amount}}0 JOD"
           },
           "KZT":{
              "money_format":"{{amount}} KZT",
              "money_format_no_decimals":"{{amount}} KZT",
              "money_with_currency_format":"{{amount}} KZT",
              "money_with_currency_format_no_decimals":"{{amount}} KZT"
           },
           "KES":{
              "money_format":"KSh{{amount}}",
              "money_format_no_decimals":"KSh{{amount}}",
              "money_with_currency_format":"KSh{{amount}}",
              "money_with_currency_format_no_decimals":"KSh{{amount}}"
           },
           "KWD":{
              "money_format":"{{amount}}0 KD",
              "money_format_no_decimals":"{{amount}}0 KD",
              "money_with_currency_format":"{{amount}}0 KWD",
              "money_with_currency_format_no_decimals":"{{amount}}0 KWD"
           },
           "KGS":{
              "money_format":"лв{{amount}}",
              "money_format_no_decimals":"лв{{amount}}",
              "money_with_currency_format":"лв{{amount}}",
              "money_with_currency_format_no_decimals":"лв{{amount}}"
           },
           "LVL":{
              "money_format":"Ls {{amount}}",
              "money_format_no_decimals":"Ls {{amount}}",
              "money_with_currency_format":"Ls {{amount}} LVL",
              "money_with_currency_format_no_decimals":"Ls {{amount}} LVL"
           },
           "LBP":{
              "money_format":"L£ {{amount}}",
              "money_format_no_decimals":"L£ {{amount}}",
              "money_with_currency_format":"L£ {{amount}} LBP",
              "money_with_currency_format_no_decimals":"L£ {{amount}} LBP"
           },
           "LTL":{
              "money_format":"{{amount}} Lt",
              "money_format_no_decimals":"{{amount}} Lt",
              "money_with_currency_format":"{{amount}} Lt",
              "money_with_currency_format_no_decimals":"{{amount}} Lt"
           },
           "MGA":{
              "money_format":"Ar {{amount}}",
              "money_format_no_decimals":"Ar {{amount}}",
              "money_with_currency_format":"Ar {{amount}} MGA",
              "money_with_currency_format_no_decimals":"Ar {{amount}} MGA"
           },
           "MKD":{
              "money_format":"ден {{amount}}",
              "money_format_no_decimals":"ден {{amount}}",
              "money_with_currency_format":"ден {{amount}} MKD",
              "money_with_currency_format_no_decimals":"ден {{amount}} MKD"
           },
           "MOP":{
              "money_format":"MOP${{amount}}",
              "money_format_no_decimals":"MOP${{amount}}",
              "money_with_currency_format":"MOP${{amount}}",
              "money_with_currency_format_no_decimals":"MOP${{amount}}"
           },
           "MVR":{
              "money_format":"Rf{{amount}}",
              "money_format_no_decimals":"Rf{{amount}}",
              "money_with_currency_format":"Rf{{amount}} MRf",
              "money_with_currency_format_no_decimals":"Rf{{amount}} MRf"
           },
           "MXN":{
              "money_format":"$ {{amount}}",
              "money_format_no_decimals":"$ {{amount}}",
              "money_with_currency_format":"$ {{amount}} MXN",
              "money_with_currency_format_no_decimals":"$ {{amount}} MXN"
           },
           "MYR":{
              "money_format":"RM{{amount}} MYR",
              "money_format_no_decimals":"RM{{amount}} MYR",
              "money_with_currency_format":"RM{{amount}} MYR",
              "money_with_currency_format_no_decimals":"RM{{amount}} MYR"
           },
           "MUR":{
              "money_format":"Rs {{amount}}",
              "money_format_no_decimals":"Rs {{amount}}",
              "money_with_currency_format":"Rs {{amount}} MUR",
              "money_with_currency_format_no_decimals":"Rs {{amount}} MUR"
           },
           "MDL":{
              "money_format":"{{amount}} MDL",
              "money_format_no_decimals":"{{amount}} MDL",
              "money_with_currency_format":"{{amount}} MDL",
              "money_with_currency_format_no_decimals":"{{amount}} MDL"
           },
           "MAD":{
              "money_format":"{{amount}} dh",
              "money_format_no_decimals":"{{amount}} dh",
              "money_with_currency_format":"Dh {{amount}} MAD",
              "money_with_currency_format_no_decimals":"Dh {{amount}} MAD"
           },
           "MNT":{
              "money_format":"{{amount}} ₮",
              "money_format_no_decimals":"{{amount}} ₮",
              "money_with_currency_format":"{{amount}} MNT",
              "money_with_currency_format_no_decimals":"{{amount}} MNT"
           },
           "MZN":{
              "money_format":"{{amount}} Mt",
              "money_format_no_decimals":"{{amount}} Mt",
              "money_with_currency_format":"Mt {{amount}} MZN",
              "money_with_currency_format_no_decimals":"Mt {{amount_decimals}} MZN"
           },
           "NAD":{
              "money_format":"N${{amount}}",
              "money_format_no_decimals":"N${{amount}}",
              "money_with_currency_format":"N${{amount}} NAD",
              "money_with_currency_format_no_decimals":"N${{amount}} NAD"
           },
           "NPR":{
              "money_format":"Rs{{amount}}",
              "money_format_no_decimals":"Rs{{amount}}",
              "money_with_currency_format":"Rs{{amount}} NPR",
              "money_with_currency_format_no_decimals":"Rs{{amount}} NPR"
           },
           "ANG":{
              "money_format":"&fnof;{{amount}}",
              "money_format_no_decimals":"&fnof;{{amount}}",
              "money_with_currency_format":"{{amount}} NA&fnof;",
              "money_with_currency_format_no_decimals":"{{amount}} NA&fnof;"
           },
           "NZD":{
              "money_format":"${{amount}}",
              "money_format_no_decimals":"${{amount}}",
              "money_with_currency_format":"${{amount}} NZD",
              "money_with_currency_format_no_decimals":"${{amount}} NZD"
           },
           "NIO":{
              "money_format":"C${{amount}}",
              "money_format_no_decimals":"C${{amount}}",
              "money_with_currency_format":"C${{amount}} NIO",
              "money_with_currency_format_no_decimals":"C${{amount}} NIO"
           },
           "NOK":{
              "money_format":"kr {{amount}}",
              "money_format_no_decimals":"kr {{amount}}",
              "money_with_currency_format":"kr {{amount}} NOK",
              "money_with_currency_format_no_decimals":"kr {{amount}} NOK"
           },
           "OMR":{
              "money_format":"{{amount}} OMR",
              "money_format_no_decimals":"{{amount}} OMR",
              "money_with_currency_format":"{{amount}} OMR",
              "money_with_currency_format_no_decimals":"{{amount}} OMR"
           },
           "PKR":{
              "money_format":"Rs.{{amount}}",
              "money_format_no_decimals":"Rs.{{amount}}",
              "money_with_currency_format":"Rs.{{amount}} PKR",
              "money_with_currency_format_no_decimals":"Rs.{{amount}} PKR"
           },
           "PGK":{
              "money_format":"K {{amount}}",
              "money_format_no_decimals":"K {{amount}}",
              "money_with_currency_format":"K {{amount}} PGK",
              "money_with_currency_format_no_decimals":"K {{amount}} PGK"
           },
           "PYG":{
              "money_format":"Gs. {{amount}}",
              "money_format_no_decimals":"Gs. {{amount}}",
              "money_with_currency_format":"Gs. {{amount}} PYG",
              "money_with_currency_format_no_decimals":"Gs. {{amount}} PYG"
           },
           "PEN":{
              "money_format":"S/. {{amount}}",
              "money_format_no_decimals":"S/. {{amount}}",
              "money_with_currency_format":"S/. {{amount}} PEN",
              "money_with_currency_format_no_decimals":"S/. {{amount}} PEN"
           },
           "PHP":{
              "money_format":"₱{{amount}}",
              "money_format_no_decimals":"₱{{amount}}",
              "money_with_currency_format":"₱{{amount}} PHP",
              "money_with_currency_format_no_decimals":"₱{{amount}} PHP"
           },
           "PLN":{
              "money_format":"{{amount}} zl",
              "money_format_no_decimals":"{{amount}} zl",
              "money_with_currency_format":"{{amount}} zl PLN",
              "money_with_currency_format_no_decimals":"{{amount}} zl PLN"
           },
           "QAR":{
              "money_format":"QAR {{amount}}",
              "money_format_no_decimals":"QAR {{amount}}",
              "money_with_currency_format":"QAR {{amount}}",
              "money_with_currency_format_no_decimals":"QAR {{amount}}"
           },
           "RON":{
              "money_format":"{{amount}} lei",
              "money_format_no_decimals":"{{amount}} lei",
              "money_with_currency_format":"{{amount}} lei RON",
              "money_with_currency_format_no_decimals":"{{amount}} lei RON"
           },
           "RUB":{
              "money_format":"руб{{amount}}",
              "money_format_no_decimals":"руб{{amount}}",
              "money_with_currency_format":"руб{{amount}} RUB",
              "money_with_currency_format_no_decimals":"руб{{amount}} RUB"
           },
           "RWF":{
              "money_format":"{{amount}} RF",
              "money_with_currency_format":"{{amount}} RWF"
           },
           "WST":{
              "money_format":"WS$ {{amount}}",
              "money_format_no_decimals":"WS$ {{amount}}",
              "money_with_currency_format":"WS$ {{amount}} WST",
              "money_with_currency_format_no_decimals":"WS$ {{amount}} WST"
           },
           "SAR":{
              "money_format":"{{amount}} SR",
              "money_format_no_decimals":"{{amount}} SR",
              "money_with_currency_format":"{{amount}} SAR",
              "money_with_currency_format_no_decimals":"{{amount}} SAR"
           },
           "STD":{
              "money_format":"Db {{amount}}",
              "money_format_no_decimals":"Db {{amount}}",
              "money_with_currency_format":"Db {{amount}} STD",
              "money_with_currency_format_no_decimals":"Db {{amount}} STD"
           },
           "RSD":{
              "money_format":"{{amount}} RSD",
              "money_format_no_decimals":"{{amount}} RSD",
              "money_with_currency_format":"{{amount}} RSD",
              "money_with_currency_format_no_decimals":"{{amount}} RSD"
           },
           "SCR":{
              "money_format":"Rs {{amount}}",
              "money_format_no_decimals":"Rs {{amount}}",
              "money_with_currency_format":"Rs {{amount}} SCR",
              "money_with_currency_format_no_decimals":"Rs {{amount}} SCR"
           },
           "SGD":{
              "money_format":"${{amount}}",
              "money_format_no_decimals":"${{amount}}",
              "money_with_currency_format":"${{amount}} SGD",
              "money_with_currency_format_no_decimals":"${{amount}} SGD"
           },
           "SYP":{
              "money_format":"S£{{amount}}",
              "money_format_no_decimals":"S£{{amount}}",
              "money_with_currency_format":"S£{{amount}} SYP",
              "money_with_currency_format_no_decimals":"S£{{amount}} SYP"
           },
           "ZAR":{
              "money_format":"R {{amount}}",
              "money_format_no_decimals":"R {{amount}}",
              "money_with_currency_format":"R {{amount}} ZAR",
              "money_with_currency_format_no_decimals":"R {{amount}} ZAR"
           },
           "KRW":{
              "money_format":"₩{{amount}}",
              "money_format_no_decimals":"₩{{amount}}",
              "money_with_currency_format":"₩{{amount}} KRW",
              "money_with_currency_format_no_decimals":"₩{{amount}} KRW"
           },
           "LKR":{
              "money_format":"Rs {{amount}}",
              "money_format_no_decimals":"Rs {{amount}}",
              "money_with_currency_format":"Rs {{amount}} LKR",
              "money_with_currency_format_no_decimals":"Rs {{amount}} LKR"
           },
           "SEK":{
              "money_format":"{{amount}} kr",
              "money_format_no_decimals":"{{amount}} kr",
              "money_with_currency_format":"{{amount}} kr SEK",
              "money_with_currency_format_no_decimals":"{{amount}} kr SEK"
           },
           "CHF":{
              "money_format":"SFr. {{amount}}",
              "money_format_no_decimals":"SFr. {{amount}}",
              "money_with_currency_format":"SFr. {{amount}} CHF",
              "money_with_currency_format_no_decimals":"SFr. {{amount}} CHF"
           },
           "TWD":{
              "money_format":"${{amount}}",
              "money_format_no_decimals":"${{amount}}",
              "money_with_currency_format":"${{amount}} TWD",
              "money_with_currency_format_no_decimals":"${{amount}} TWD"
           },
           "THB":{
              "money_format":"{{amount}} ฿",
              "money_format_no_decimals":"{{amount}} ฿",
              "money_with_currency_format":"{{amount}} ฿ THB",
              "money_with_currency_format_no_decimals":"{{amount}} ฿ THB"
           },
           "TZS":{
              "money_format":"{{amount}} TZS",
              "money_format_no_decimals":"{{amount}} TZS",
              "money_with_currency_format":"{{amount}} TZS",
              "money_with_currency_format_no_decimals":"{{amount}} TZS"
           },
           "TTD":{
              "money_format":"${{amount}}",
              "money_format_no_decimals":"${{amount}}",
              "money_with_currency_format":"${{amount}} TTD",
              "money_with_currency_format_no_decimals":"${{amount}} TTD"
           },
           "TND":{
              "money_format":"{{amount}}",
              "money_format_no_decimals":"{{amount}}",
              "money_with_currency_format":"{{amount}} DT",
              "money_with_currency_format_no_decimals":"{{amount}} DT"
           },
           "TRY":{
              "money_format":"{{amount}}TL",
              "money_format_no_decimals":"{{amount}}TL",
              "money_with_currency_format":"{{amount}}TL",
              "money_with_currency_format_no_decimals":"{{amount}}TL"
           },
           "UGX":{
              "money_format":"Ush {{amount}}",
              "money_format_no_decimals":"Ush {{amount}}",
              "money_with_currency_format":"Ush {{amount}} UGX",
              "money_with_currency_format_no_decimals":"Ush {{amount}} UGX"
           },
           "UAH":{
              "money_format":"₴{{amount}}",
              "money_with_currency_format":"₴{{amount}} UAH"
           },
           "AED":{
              "money_format":"Dhs. {{amount}}",
              "money_format_no_decimals":"Dhs. {{amount}}",
              "money_with_currency_format":"Dhs. {{amount}} AED",
              "money_with_currency_format_no_decimals":"Dhs. {{amount}} AED"
           },
           "UYU":{
              "money_format":"${{amount}}",
              "money_format_no_decimals":"${{amount}}",
              "money_with_currency_format":"${{amount}} UYU",
              "money_with_currency_format_no_decimals":"${{amount}} UYU"
           },
           "VUV":{
              "money_format":"${{amount}}",
              "money_format_no_decimals":"${{amount}}",
              "money_with_currency_format":"${{amount}}VT",
              "money_with_currency_format_no_decimals":"${{amount}}VT"
           },
           "VEF":{
              "money_format":"Bs. {{amount}}",
              "money_format_no_decimals":"Bs. {{amount}}",
              "money_with_currency_format":"Bs. {{amount}} VEF",
              "money_with_currency_format_no_decimals":"Bs. {{amount}} VEF"
           },
           "VND":{
              "money_format":"{{amount}}&#8363;",
              "money_format_no_decimals":"{{amount}}&#8363;",
              "money_with_currency_format":"{{amount}} VND",
              "money_with_currency_format_no_decimals":"{{amount}} VND"
           },
           "XBT":{
              "money_format":"{{amount}} BTC",
              "money_format_no_decimals":"{{amount}} BTC",
              "money_with_currency_format":"{{amount}} BTC",
              "money_with_currency_format_no_decimals":"{{amount}} BTC"
           },
           "XOF":{
              "money_format":"CFA{{amount}}",
              "money_format_no_decimals":"CFA{{amount}}",
              "money_with_currency_format":"CFA{{amount}} XOF",
              "money_with_currency_format_no_decimals":"CFA{{amount}} XOF"
           },
           "ZMW":{
              "money_format":"K{{amount}}",
              "money_format_no_decimals":"K{{amount}}",
              "money_with_currency_format":"ZMW{{amount}}",
              "money_with_currency_format_no_decimals":"ZMW{{amount}}"
           },
           "SPL":{
              "money_format":"{{amount}}",
              "money_format_no_decimals":"{{amount}}",
              "money_with_currency_format":"{{amount}} SPL",
              "money_with_currency_format_no_decimals":"{{amount}} SPL"
           }
        },
        "currentCurrency":"INR",
        "format":"money_format",
        "shopCurrency":"INR",
        "defaultCurrency":"INR"
     }
    rate = 1;
    constructor(rates) {
        this.specialCharRegex = /[^a-z0-9]/;
        this.rate = JSON.parse(localStorage.getItem("currencyRate"))?.rates?.[this.cC] || 1;
    }
    getValidURL(keyword = ""){
        return keyword.toLowerCase().replace(new RegExp(this.specialCharRegex, "gi"), "-").replace(/-{2,}/g, '-').trim("-");
    }
    getTags(){
        return ["collectibles", "motor-part", "car-emplifier", "dongguan-langzu", "guangzhou-kuer", "all-electronics", "lelisten", "all-mobile-accessories", "electronic-appliances", "headphones", "mobiles-computers", "shantou-lexi", "wearable-devices", "yongkang-tuyi", "bluetooth-digital-scale", "magic", "xxuzhou-rich", "all-electronics", "car-mobile-holder", "car-stereo", "computer-accessories", "esunway-shenzhen", "guangzhou-princess", "hdmi-series", "health-personal-care", "le-listen", "motor-parts", "newproducts", "shenzhen-casdte", "shenzhen-cregift", "shenzhen-lexin", "shenzhen-magelei", "shenzhen-qiwan", "shenzhen-vanedove", "tws-earphone", "weighing-scale", "woyaqi-shenzhen", "xiamen-koderay", "yuantu-culture", "all-computers-&-accessories", "new", "aogfy", "bathroommirror", "carseatcushion", "cartoonearphone", "casun", "chestcompartments", "clothing", "cutlerytray", "cuttingboard", "drawerorganizer", "eva-mat", "earphone", "extralargebamboocuttingboard", "eyelashcase", "eyelashholder", "fastcharging", "floorboughpot", "foldable-mirror", "footwearexclusive", "gdyayu", "galova", "gifts&toysexclusive", "glass", "greenfilm", "greenport", "home&kitchen", "jacotta", "kms", "kids", "knifeblock", "led", "ledlamp", "ledmakeupmirror", "ledmirror"];
    }
    getPriceWithCurrency(price){
        return this.symbols.moneyFormats[this.cC].money_format.replace("{{amount}}",(parseFloat(price)*this.rate).toFixed(1));
    }
    setCallBack(callback){
        this.callback = callback
    }
    getCallBack(){
        return this.callback;
    }
    setCurrentCurrency(curreny){
        if(Object.keys(this.symbols.rates).indexOf(curreny) === -1){
            return false;
        }
        this.cC = curreny;
    }
    get shippingDTime(){
      return {
        "ex-china":{
          "min":1,
          "max":3
        },
        "sea":{
          "min":30,
          "max":45,
        },
        "air":{
          "min":5,
          "max":7
        }
      }
    }
}
return  new CommonFunctions();
})();
