import React, { Component } from "react"
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import Checkouts from '../components/Checkouts.js';



export default class checkout extends Component {

    render() {
        return (
            <div>
                <div className="body" style={{ 'marginTop': '45.1875px', transition: 'margin 300ms ease 0s' }}>
                    <Header search={{ ...this.props.match.params }} />

                </div>
                <Checkouts />
                <Footer />
            </div>
        )

    }
}