import React, { Component } from "react"
import cf from '../../global/CommonFunctions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import placeHolderSrc from '../../img/placeholder-medium.png'
import User from '../../global/User';
import axios from "axios";
import { NavLink } from "react-router-dom";
import {toast } from 'react-toastify';

export default class ShippingInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            products: [],
            cart:{},
            cartDetail:{
                grossAmount:0,
                totalDuty:0,
                GST:0,
                shipping:"ex-china"
            },
            mQua:10
        }
        this.apiUrl = process.env.REACT_APP_NODE_API_URL.trim() + "/"+ process.env.REACT_APP_NODE_API_PATH.trim().replace(/\/+/g,"\/");
    }
    componentDidMount() {
        this.orderDetails()
    }

    // componentWillReceiveProps(nextProps){
    //     if (nextProps.price && nextProps.price !== this.state.price) {
    //       this.setState({ price: nextProps.price});
    //       this.setState({ currentPage: 1});
    //       this.getProductsData(1, this.state.showPerPage,1)
    //     }
    //   }

    componentDidUpdate(prevProps){
        if (this.props.shipping !== prevProps.shipping) {
            this.orderDetails();
        }
    }
    orderDetails(){
        let user = User.getUser()
        if (!user)
            window.location.href = '/';
        axios
        .post(this.apiUrl+"/addtocart", {
            key: process.env.REACT_APP_MOBILE_API_KEY.trim(),
            token: process.env.REACT_APP_MOBILE_API_TOKEN.trim(),
            merchantId: user.id,
            source: "web",
            accessStatus: 0,
        })
        .then((res) => {
            let cartDetail = res.data.Response.data;
            let cart = {};
            cartDetail.products.forEach(el=>{
                cart[el.code] = {
                    productId:el._id,
                    sku:el.code,
                    quantity:el.cartQuantity
                }
            })
            this.setState({cart})
            this.setState({products:cartDetail.products});
            delete cartDetail.products;
            this.setState({cartDetail})
            this.props.parentCallback({cartDetail});
        })
        .catch((err) => {
            console.log("Error in Single Product APi", err);
        });
    }

    addToCartApi = (cart,params = {accessStatus:1,add:0},callback)=>{
        let user = User.getUser()
		if (!user)
			window.location.href = '/';
		axios
			.post(this.apiUrl+"/addtocart", {
				key: process.env.REACT_APP_MOBILE_API_KEY.trim(),
				token: process.env.REACT_APP_MOBILE_API_TOKEN.trim(),
				merchantId: user.id,
				source: "web",
				...params,
				cartData: cart
			})
			.then((res) => {
                callback();
                toast.success("Your Cart Updated Successfully", {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			})
			.catch((error) => {

                let errObj = error.response.data.Response?.data?.find(el=>el.error);
                let mes = errObj?.msg || error.response.data.Response.msg;
                return toast.error(mes, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
			});
    }

    render() {
        let min = cf.shippingDTime?.[this.state.cartDetail?.shipping].min || 30;
        let max = cf.shippingDTime?.[this.state.cartDetail?.shipping].max || 45;
        let minDate = new Date(new Date().getTime() + 24*60*60*1000*min).toISOString().split("T")[0].split("-");
        let maxDate = new Date(new Date().getTime() + 24*60*60*1000*max).toISOString().split("T")[0].split("-");
        return (
            <div className="col-lg-8 mb-5 mb-lg-0">
                <div className="col-lg-12">
                    <div className="row mb-2">
                        <div className="col-sm-2">Shipping methods</div>
                        <div className="col-sm-10">EXPRESS</div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-sm-2">Incoterms</div>
                        <div className="col-sm-10">DAP(Delivered at Place)</div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-sm-2">Estimated duty</div>
                        <div className="col-sm-10">The estimated import duty is {cf.getPriceWithCurrency(this.state.cartDetail?.totalDuty || 0)}. Please note that the import fees shall be paid by the buyer and determined by the local customs.</div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-sm-2">Estimated VAT</div>
                        <div className="col-sm-10">The estimated integrated Goods and Services Tax (IGST) is {cf.getPriceWithCurrency(this.state.cartDetail?.GST || 0)}. Please note that the import fees shall be paid by the buyer and determined by the local customs.</div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-sm-2">Delivery timeline</div>
                        <div className="col-sm-10">Estimated delivery by { minDate[1] }/{ minDate[2] }-{ maxDate[1] }/{ maxDate[2] }</div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-10">
                            <ul className="shippingSteps">
                                <li>Payment</li>
                                <li>Dispatch<span>CN</span></li>
                                <li>Received<span>IN</span></li>
                                <p className="inbetweenTxt">Lead time 1 days</p>
                                <p className="inbetweenTxt" style={{right: "0", left: "inherit"}}>Shipping time {cf.shippingDTime?.[this.state.cartDetail?.shipping].min || 30}-{cf.shippingDTime?.[this.state.cartDetail?.shipping].max || 45} working days</p>
                            </ul>
                        </div>
                    </div>
                    <div className="row my-5"></div>
                    <div className="row">
                        <div className="col-sm-10 mx-auto alert alert-danger alert-dismissible"><i className="fas fa-exclamation-triangle"></i> Shipment to India may delay due to uncertainties associated with custom clearance and logisitics services. Please check with the sellers before placing orders.</div>
                    </div>
                </div>
            </div>
        )
    }
}