import React from 'react'
import Car_Motorbike1 from './Car_Motorbike1'
import Car_Motorbike2 from './Car_Motorbike2'
import Car_Motorbike3 from './Car_Motorbike3'
import Car_Motorbike4 from './Car_Motorbike4'
export default function Car_Motorbike() {
  return (
    <>
        <Car_Motorbike1/>
        <Car_Motorbike2/>
        <Car_Motorbike3/>
        <Car_Motorbike4/>
    </>
  )
}
