import React, { Component } from "react"


class FooterTwo extends Component {

    render() {

        return (

            <div className="container">
                <div className="footer-copyright footer-copyright-style-2 pt-4 pb-5">
                    <div className="row align-items-center justify-content-md-between">
                        <div className="col-12 col-md-auto text-center text-md-start mb-2 mb-md-0">
                            <p className="mb-0">©2021 Shopperr B2B. All rights reserved</p>
                        </div>
                        <div className="col-12 col-md-auto">
                            <div className="payment-cc justify-content-center justify-content-md-end">
                                <i className="fab fa-cc-visa" style={{ color: 'white' }}></i>
                                <i className="fab fa-cc-paypal" style={{ color: 'white' }}></i>
                                <i className="fab fa-cc-stripe" style={{ color: 'white' }}></i>
                                <i className="fab fa-cc-mastercard" style={{ color: 'white' }}></i>
                                <i className="fab fa-cc-apple-pay" style={{ color: 'white' }}></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

export default FooterTwo;
