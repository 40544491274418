import React, { Component } from "react"
import axios from 'axios';
import electronics from '../img/electronics.jpg';
import footwear from '../img/footwear.jpg';
import beauty from '../img/beauty.jpg';
import toys from '../img/toys.jpg';
import imgHomeKitchen from '../img/home_kitchen.jpg';
class MultiCategoryCard extends Component {
    render() {
        return (
            <div className="col-md-6 col-lg-4 mb-5 mb-lg-0 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay={400} style={{ animationDelay: '400ms' }}>

                <div className="card border-radius-0 bg-color-light border-0 box-shadow-1" style={{ height: '480px', marginBottom: '20px' }}>
                    <div className="card-body">
                        <h4 className="card-title mb-1 text-4 font-weight-bold" style={{ textAlign: 'left' }}>Up to 70% off | Electronics</h4>
                        <div className="row" style={{ marginTop: '25px' }}>
                            <div className="col-lg-6 mb-2">
                                <a href="/mobilephone-accessories">
                                    <img className="img-fluid rounded mb-2" src={electronics} alt="Electronics" />
                                    <p>Mobile Accessories</p>
                                </a>

                            </div>
                            <div className="col-lg-6 mb-2">
                                <a href="/footwear">
                                    <img className="img-fluid rounded mb-2" src={footwear} alt="Footwear" />
                                    <p>Footwear</p>
                                </a>
                            </div>
                            <div className="col-lg-6 mb-2">
                                <a href="/beauty-grooming">
                                    <img className="img-fluid rounded mb-2" src={beauty} alt="Beauty" />
                                    <p>Beauty & Grooming</p></a>
                            </div>
                            <div className="col-lg-6 mb-2">
                                <a href="/gifts-toys">
                                    <img className="img-fluid rounded mb-2" src={toys} alt="Toys" />
                                    <p>Gifts & Toys</p></a>
                            </div>
                        </div>
                        <div style={{ position: 'absolute', bottom: '0', width: '100%', left: '10px' }}>
                            <a href="/mobilephone-accessories" style={{ color: '#ffa000' }}>View More</a></div>
                    </div>
                </div>
            </div>




        )
    }
}


export default MultiCategoryCard;