import React, { Component } from "react"
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import OrderCompletes from '../components/OrderCompletes.js';

export default class OrderComplete extends Component {
    render() {
        return (
            <div>
                <div className="body" style={{ 'marginTop': '45.1875px', transition: 'margin 300ms ease 0s' }}>
                    <Header search={{ ...this.props.match.params }} />
                </div>
                <OrderCompletes id={this.props.match.params.id} />
                <Footer />
            </div>
        )

    }
}