import React, { Component } from "react"
import axios from "axios";
import { NavLink } from "react-router-dom";
import cf from '../global/CommonFunctions';
import User from '../global/User';

class ProductGrid extends Component {
    constructor(props) {
        super(props)
        this.state = {
          productsList: [],
          keyword: props.keyword ?? "footwear",
          headingText: props.headingText ?? "New Products",
          lastElement: props.lastElement || 0
        }
      }
      componentDidMount() {
        this.productListHomeWidget();
      }
    
    
    productListHomeWidget() {
        let params = {
            "keyword": this.state.keyword,
            "type": 1,
            "limit": 5,
            "pageNumber": 1,
            "source": "web"
        }
        axios.post(process.env.REACT_APP_NODE_API_URL.trim() + "/"+ process.env.REACT_APP_NODE_API_PATH.trim().replace(/\/+/g,"\/")+'/top-rated', params)
            .then(res => {
            this.setState({ productsList: res.data.Response.data.products })
        })
    }
    
  render() {
    let showingbetween = 0;
    showingbetween = this.state.currentPage*this.state.showPerPage;
    showingbetween = showingbetween > this.state.productTotalCount ? this.state.productTotalCount :  showingbetween;
    return (
        <section className={`container product-listing ${ this.state.lastElement ? "mb-5" : ""}`}>
            <h3>{this.state.headingText}</h3>
            <div className="row">
            {
                this.state.productsList &&
                this.state.productsList.map((item, index) => {
                    return (
                        <div className="col text-center" key={index.toString()}>
                            <div className="collection-box">
                                <div className="grid__product-image text-center">        
                                    <NavLink to={'/product/' + item.handle} exact className="grid__image"> 
                                        <img src={item?.imgUrl?.[0]?.src || ""}  data-size="auto" alt={item.title} className="fade-in first--image lazyloaded" style={{left: '0'}} />
                                        <img src={item?.imgUrl?.[1]?.src || ""}  data-size="auto" className="second--image lazyloaded" alt={item.title} />
                                    </NavLink>
                                </div>
                                <div className="grid__product__content">
                                    <h4>
                                        <NavLink to={'/product/' + item.handle} exact>
                                        {item.title.substring(0, 35)}...
                                        </NavLink>
                                    </h4>
                                    <h5>{item.displayPrice.toFixed(2)} / per piece</h5>
                                </div>
                            </div>
                        </div>
                    // <div className="col text-center">
                    //     <div className="collection-box">hello</div>
                    // </div>
                    // <div className="col text-center">
                    //     <div className="collection-box">hello</div>
                    // </div>
                    // <div className="col text-center">
                    //     <div className="collection-box">hello</div>
                    // </div>
                    // <div className="col text-center">
                    //     <div className="collection-box">hello</div>
                    // </div>
                    )
                })
            }
            </div>
        </section>
    )
  }
}

export default ProductGrid;
