import React, { Component } from "react"
import cf from '../../global/CommonFunctions';
import { NavLink } from "react-router-dom";
import axios from "axios";
import {toast } from 'react-toastify';
import User from '../../global/User';
export default class CartTotal extends Component {
    constructor(props) {
        super(props)
        const {cartDetail} = this.props.cartDetail;
        console.log(cartDetail,"cartDetail");
        this.state = {
            cartDetail:cartDetail || {
                netAmount:0,
                shipping:"air"
            },
            nextURL:this.props.nextURL || "checkout",
        }
        this.changeShippingMethod = this.changeShippingMethod.bind(this)
        this.apiUrl = process.env.REACT_APP_NODE_API_URL.trim() + "/"+ process.env.REACT_APP_NODE_API_PATH.trim().replace(/\/+/g,"\/");
    }
    componentWillReceiveProps(nextProps){
        this.setState({cartDetail: nextProps.cartDetail});
    }
    changeShippingMethod(val){
        this.updateShipping(val);
    }
    updateShipping = (shipping)=>{
        let user = User.getUser()
		axios
			.post(this.apiUrl+"/updateShipping", {
				key: process.env.REACT_APP_MOBILE_API_KEY.trim(),
				token: process.env.REACT_APP_MOBILE_API_TOKEN.trim(),
				merchantId: user.id,
				source: "web",
				shipping
			})
			.then((res) => {
                toast.success("Shipping Updated", {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
                this.props.parentCallback({shipping:shipping});
			})
			.catch((error) => {
                let errObj = error.response.data.Response?.data?.find(el=>el.error);
                let mes = errObj?.msg || error.response.data.Response.msg;
                return toast.error(mes, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
			});
    }

    render() {
        return (
            <div className="col-lg-4 position-relative">
                <div className="pin-wrapper" style={{ height: 462 }}>
                    <div
                        className="card border-width-3 border-radius-0 border-color-hover-dark"
                        data-plugin-sticky
                        data-plugin-options="{'minWidth': 991, 'containerSelector': '.row', 'padding': {'top': 85}}"
                        style={{ width: 356 }}
                    >
                        <div className="card-body">
                            <h4 className="font-weight-bold text-uppercase text-4 mb-3">
                                Cart Totals
                            </h4>
                            <table className="shop_table cart-totals mb-4">
                                <tbody>
                                    <tr className="cart-subtotal">
                                        <td className="border-top-0">
                                            <strong className="text-color-dark">Subtotal</strong>
                                        </td>
                                        <td className="border-top-0 text-end text-right">
                                            <strong>
                                                <span className="amount font-weight-medium">{cf.getPriceWithCurrency(this.state.cartDetail.netAmount)}</span>
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr className="shipping" style={{display: this.state.cartDetail?.shipping === "ex-china" ? "none" : ""}}>
                                        <td colSpan={2}>
                                            <strong className="d-block text-color-dark mb-2">
                                                Tax
                                            </strong>
                                            <div className="d-flex flex-column">
                                                <label
                                                    className="d-flex align-items-center text-color-grey mb-0 justify-content-between"
                                                    htmlFor="shipping_method1"
                                                >
                                                   
                                                    <strong className="d-block text-color-dark">Estimated VAT</strong>
                                                    <strong className="amount font-weight-medium">
                                                        {cf.getPriceWithCurrency(this.state.cartDetail.GST)}
                                                    </strong> 
                                                </label>
                                            </div>

                                            <div className="d-flex flex-column">
                                                <label
                                                    className="d-flex align-items-center text-color-grey mb-0 justify-content-between"
                                                    htmlFor="shipping_method1"
                                                >
                                                    <strong className="d-block text-color-dark">Estimated duty</strong>
                                                    <strong className="amount font-weight-medium">
                                                        {cf.getPriceWithCurrency(this.state.cartDetail?.totalDuty || 0)}
                                                    </strong> 
                                                </label>
                                            </div>

                                            <div className="d-flex flex-column">
                                                <label
                                                    className="d-flex align-items-center text-color-grey mb-0 justify-content-between"
                                                    htmlFor="shipping_method1"
                                                >
                                                    <strong className="d-block text-color-dark">Shipping Charge</strong>
                                                    <strong className="amount font-weight-medium">
                                                        {cf.getPriceWithCurrency(this.state.cartDetail?.shippingCharges || 0)}
                                                    </strong> 
                                                </label>
                                            </div>

                                        </td>
                                    </tr>
                                    { this.state.nextURL == "checkout" &&<tr>
                                        <td>
                                            <div style={{ textAlign: "left" }}>
                                                <strong className="d-block text-color-dark">Shipping Method</strong>
                                                <select
                                                    onChange={(e)=>this.changeShippingMethod(e.target.value)}
                                                    className="form-control text-1 h-auto py-2" value={this.state.cartDetail?.shipping || "air"}
                                                >
                                                    <option value="air">Air Shipping Method</option>
                                                    <option value="sea">Sea Shipping Method</option>
                                                    <option value="ex-china">Ex-China</option>
                                                </select>
                                            </div>
                                        </td>
                                    </tr>     
                                    }       
                                    <tr className="total">
                                        <td>
                                            <strong className="text-color-dark text-3-5">Total</strong>
                                        </td>
                                        <td className="text-end text-right">
                                            <strong className="text-color-dark">
                                                <span className="amount text-color-dark text-5">{cf.getPriceWithCurrency(this.state.cartDetail.grossAmount)}</span>
                                            </strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            {
                                parseInt(this.state.cartDetail.grossAmount) > 0 && 
                                <NavLink className="btn btn-dark btn-modern w-100 text-uppercase bg-color-hover-primary border-color-hover-primary border-radius-0 text-3 py-3"
                                            to={'/'+this.state.nextURL}
                                            exact
                                        >
                                    Proceed to Checkout <i className="fas fa-arrow-right ms-2" />
                                </NavLink>
                            }

                           
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}