import React, { Component, Suspense, lazy } from "react"
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import LeftSidebar from '../components/LeftSidebar';
import LeftSidebarTags from '../components/LeftSidebarTags';
import LeftSidebarProducts from '../components/LeftSidebarProducts';
import SearchFilter from '../components/SearchFilter';
import Products from '../components/Products';
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';

export default class SearchScreen extends Component {
    constructor() {
        super()
        this.state = {
            tags: {},
            cats: {},
            price: [],
            stats:{min:0,max:0},
            menu:[]
        }
    }
    callbackFunction(obj = {}) {
        this.setState(obj);
    }
    uriBuilder(menus){
        return "/"+menus.map((uri)=>uri.toLowerCase().replace(/[^a-z0-9_-]/g,"-").replace(/-+/g,"-")).join("/");
    }
     
    render() {
        console.log(this.state.cats);
        return (
            <div>
                <div className="body" style={{transition: 'margin 300ms ease 0s' }}>
                <MetaTags>
                        <title>{this.state.menu}: Buy {this.state.menu } Online in India @Best Prices - shopperrglobal</title>
                       
                        <description>{this.state.menu.toString()} Online  Shop wide range of {this.state.menu } at Best Prices at shopperrglobal</description>
                                        

                    </MetaTags>
                    <Header search={{ ...this.props.match.params }} menu={{ ...this.state.menu }} parentCallback={this.callbackFunction.bind(this)} />
                    <div role="main" className="main shop pt-4">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <ul className="breadcrumb breadcrumb-style-2 d-block">
                                        { 
                                            this.state.menu && this.state.menu.map((el,i)=>{
                                            return (<li key={i.toString()}>
                                                        { i !== this.state.menu.length-1 ? <Link
                                                            to={this.uriBuilder(this.state.menu.slice(0,i+1))}
                                                            className="text-decoration-none"
                                                        >
                                                            {el}
                                                        </Link> : el }
                                                    </li>)
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>

                            <div className="row">
                                {/*<div className="col-lg-3 order-2 order-lg-1">
                                     <div id="left-side-scroller">
                                        {/* <LeftSidebar cats={this.state.cats} search={{ ...this.props.match.params }} /><br /> 
                                        {this.state.stats.max > 0 && <SearchFilter stats={this.state.stats} parentCallback={this.callbackFunction.bind(this)} />}    
                                        {this.state.tags && Object.keys(this.state.tags).length ? <h5 className="font-weight-semi-bold pt-5">Tags</h5> : ""}
                                        <LeftSidebarTags tags={this.state.tags} /> 
                                         <LeftSidebarProducts headingText={'TOP RATED PRODUCTS'} search={{ ...this.props.match.params }} />
                                    </div> 
                                </div>*/}
                                <div className="col-lg-12 order-1 order-lg-2">
                                  <Products parentCallback={this.callbackFunction.bind(this)} price={this.state.price} search={{ ...this.props.match.params }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}