import React from 'react'
import BrandStep1 from './BrandStep1'
import BrandStep2 from './BrandStep2'
import BrandStep3 from './BrandStep3'
import BrandStep4 from './BrandStep4'

export default function Brand() {
  return (
    <>
    <BrandStep1 />
    <BrandStep2 />
    <BrandStep3 />
    <BrandStep4 />
    </>
  )
}
