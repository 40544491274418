import React, { Component } from "react"
import { NavLink } from "react-router-dom";
import Pdf from '../../img/ShopperrGuides.pdf';
import Pdfs from '../../img/ShopperrFaqs.pdf';


class FooterFirst extends Component {

    render() {

        return (

            <div className="container">
                <div className="row py-4 my-5">
                    <div className="col-md-6 col-lg-3 mb-5 mb-lg-0">
                        <h5 className="text-4 text-color-light mb-3">CONTACT INFO</h5>
                        <ul className="list list-unstyled">
                            <li className="pb-1 mb-2">
                                <span className="d-block font-weight-normal line-height-1 text-color-light">ADDRESS</span>
                                Creationist Technovations Pvt. Ltd.5th And 6th Floor, Plot No 436 Phase 4, Udyog Vihar, Gurugram 122001 Gurugram, HR, IN,India
                            </li>
                            <li className="pb-1 mb-2">
                                <span className="d-block font-weight-normal line-height-1 text-color-light">PHONE</span>
                                <a href="tel:+1234567890">Toll Free (123) 456-7890</a>
                            </li>
                            <li className="pb-1 mb-2">
                                <span className="d-block font-weight-normal line-height-1 text-color-light">EMAIL</span>
                                <a href="mailto:mail@example.com">support@shopperr.in</a>
                            </li>
                            <li className="pb-1 mb-2">
                                <span className="d-block font-weight-normal line-height-1 text-color-light">WORKING DAYS/HOURS </span>
                                Mon - Sun / 9:00AM - 8:00PM
                            </li>
                        </ul>
                        <ul className="social-icons social-icons-clean-with-border social-icons-medium">
                            <li className="social-icons-instagram">
                                <a href="https://www.instagram.com/" className="no-footer-css" target="_blank" title="Instagram"><i className="fab fa-instagram"></i></a>
                            </li>
                            <li className="social-icons-twitter mx-2">
                                <a href="https://www.twitter.com/" className="no-footer-css" target="_blank" title="Twitter"><i className="fab fa-twitter"></i></a>
                            </li>
                            <li className="social-icons-facebook">
                                <a href="https://www.facebook.com/" className="no-footer-css" target="_blank" title="Facebook"><i className="fab fa-facebook-f"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6 col-lg-3 mb-5 mb-lg-0">
                        <h5 className="text-4 text-color-light mb-3">CUSTOMER SERVICE</h5>
                        <ul className="list list-unstyled mb-0">
                            <li className="mb-0"><NavLink

                                to={'/pages/about-us'}
                                exact
                            >About Us </NavLink></li>
                            <li className="mb-0"><NavLink

                                to={'/pages/privacy-policy'}
                                exact
                            >Privacy Policy</NavLink></li>
                            <li className="mb-0"><NavLink

                                to={'/pages/terms-conditions'}
                                exact
                            >Terms And Conditions </NavLink></li>
                            <li className="mb-0"><NavLink

                                to={'/pages/delivery-shipping'}
                                exact
                            >Delivery And Shipping</NavLink></li>
                            <li className="mb-0"><NavLink

                                to={'/pages/return-policy'}
                                exact
                            >Return, Refund & Cancellation Policy</NavLink></li>
                            <li className="mb-0"><a href={Pdf} target="_blank">Shopperr Reseller Guide</a></li>
                            <li className="mb-0"><a href="https://shopperr.zendesk.com/hc/en-us" target="_blank">Shopperr FAQ</a></li>
                            {/* <li className="mb-0"><a href="#">About Us</a></li>
                            <li className="mb-0"><a href="#">Corporate Sales</a></li> */}
                        </ul>
                    </div>
                    <div className="col-md-6 col-lg-3 mb-5 mb-md-0">
                        <h5 className="text-4 text-color-light mb-3">POPULAR TAGS</h5>
                        <ul className="list list-unstyled list-inline mb-0">
                            <li className="list-inline-item"><a href="/search/all-electronics" className="badge badge-dark rounded border border-color-light-3 font-weight-normal text-2 p-2" style={{ backgroundColor: '#000' }}>all-electronics</a></li>

                            <li className="list-inline-item"><a href="/search/mobiles-computers" className="badge badge-dark rounded border border-color-light-3 font-weight-normal text-2 p-2" style={{ backgroundColor: '#000' }}>mobiles-computers</a></li>
                            <li className="list-inline-item"><a href="/search/all-mobile-accessories" className="badge badge-dark rounded border border-color-light-3 font-weight-normal text-2 p-2" style={{ backgroundColor: '#000' }}>all-mobile-accessories</a></li>
                            <li className="list-inline-item"><a href="/search/Magic" className="badge badge-dark rounded border border-color-light-3 font-weight-normal text-2 p-2" style={{ backgroundColor: '#000' }}>magic</a></li>
                            <li className="list-inline-item"><a href="/search/all-computers-&amp;-accessories" className="badge badge-dark rounded border border-color-light-3 font-weight-normal text-2 p-2" style={{ backgroundColor: '#000' }}>all-computers-accessories</a></li>
                            <li className="list-inline-item"><a href="/search/computer-accessories" className="badge badge-dark rounded border border-color-light-3 font-weight-normal text-2 p-2" style={{ backgroundColor: '#000' }}>computer-accessories</a></li>
                            <li className="list-inline-item"><a href="/search/electronic-appliances" className="badge badge-dark rounded border border-color-light-3 font-weight-normal text-2 p-2" style={{ backgroundColor: '#000' }}>electronic-appliances</a></li>
                            <li className="list-inline-item"><a href="/search/shenzhen-magelei" className="badge badge-dark rounded border border-color-light-3 font-weight-normal text-2 p-2" style={{ backgroundColor: '#000' }}>shenzhen-magelei</a></li>


                        </ul>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <h5 className="text-4 text-color-light mb-3">SUBSCRIBE NEWSLETTER</h5>
                        <p className="mb-2">Get all the latest information on events, sales and offers. Sign up for newsletter:</p>
                        <div className="alert alert-success d-none" id="newsletterSuccess">
                            <strong>Success!</strong> You've been added to our email list.
                        </div>
                        <div className="alert alert-danger d-none" id="newsletterError"></div>
                        <form id="newsletterForm" className="form-style-5 opacity-10" action="php/newsletter-subscribe.php" method="POST" noValidate="noValidate">
                            <div className="row">
                                <div className="form-group col">
                                    <input className="form-control" placeholder="Email Address" name="newsletterEmail" id="newsletterEmail" type="text" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col">
                                    <button className="btn btn-primary btn-rounded btn-px-4 btn-py-2 font-weight-bold" type="submit">SUBSCRIBE</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        )

    }
}

export default FooterFirst;
