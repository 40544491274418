import React, { Component } from "react"
import axios from "axios";
import { NavLink } from "react-router-dom";
import cf from '../global/CommonFunctions';
import User from '../global/User';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import InfiniteScroll from 'react-infinite-scroll-component';
import placeHolderSrc from '../img/placeholder-medium.png'
import {toast } from 'react-toastify';


class Products extends Component {
  constructor(props) {
    super(props)
    this.state = {
      productTotalCount: 0,
			productsData: [],
			isLoaded: false,
			productImageUrl: "",
			currentPage: 1,
			showPerPage: 30,
			pageCount: 0,
			searchName: "",
			searchSku: "",
			errors: {},
			loading: true,
			modalShow: false,
			deleteProductId: "",
			deleteProductName: "",
      getSubscribed:false,
      subscribedProducts:{},
      price:[],
      so:[],
      row:this.props.row ?? 3,
      sortBy:{
        price:0
      },
      refreshData:false,
      search:this.props.search || {}
    }
    this.tags = cf.getTags();
    this.user = User.getUser();
    this.sortTabCss = {
      padding:"8px 0 4px",
      fontWeight:"500",
      cursor:"pointer",
      margin:"0 10px"
    }
    this.apiUrl = process.env.REACT_APP_NODE_API_URL.trim() + "/"+ process.env.REACT_APP_NODE_API_PATH.trim().replace(/\/+/g,"\/");
  }
  componentDidMount() {
    this.getProductsData(this.state.currentPage, this.state.showPerPage)
  }
  componentWillReceiveProps(nextProps){
    if ((nextProps.price && nextProps.price !== this.state.price) || (nextProps.search && JSON.stringify(nextProps.search) !== JSON.stringify(this.state.search))) {
      this.setState({
        price:nextProps.price,
        currentPage: 1,
        search:nextProps.search
      });
      this.getProductsData(1, this.state.showPerPage,1,{search:nextProps.search,price:nextProps.price})
    }
  }
  getProductsData(page, perPage,propsUp = 0,params={search:null,price:null}){
    let search = {...(params.search || this.state.search),type:1,source:"web",pageNumber:page,limit:perPage};
    if(this.props.price){
      search.price = params.price || this.props.price;
    }
    search.keyword = (search.keyword || "").toString();
    if(search.category == "search"){
      delete search.category;
    }
    search.sortBy = this.state.sortBy;
    axios.post(this.apiUrl+'/search-products', search)
      .then(result => {
        let response = result.data.Response;
        this.setState({
          isLoaded: true, productsData: propsUp ? response.data.products : !this.state.refreshData ? this.state.productsData.concat(response.data.products) : response.data.products, productTotalCount: response.data.totalRecords,
          pageCount: Math.ceil(response.data.totalRecords / this.state.showPerPage),
          currentPage: page, searchName: "", searchSku: "", loading: false
        });
        this.setState({refreshData:false});
        let tags = {};
        if (this.user) {
          let productIds = response.data.products.map((item) => {
            return item._id;
          });
          this.getSubscribedProducts(productIds);
        }
        tags = Object.entries(response.data.facets.tags).filter(([tag, count]) => {
          return !this.tags.includes(tag.toLocaleLowerCase());
        });
        tags = tags.filter((ele, index, arr) => {
          return Object.keys(Object.fromEntries(tags)).indexOf(ele[0]) === index;
        })
        this.props.parentCallback({tags:Object.fromEntries(tags)})
        this.props.parentCallback({cats:response.data.facets.cats})
        if(response.data.stats && response.data.stats.stats_fields && response.data.stats.stats_fields.price){
          this.props.parentCallback({stats:response.data.stats.stats_fields.price})
        }

    })
    .catch(err => {
        console.log(err)
        this.setState({ loading: false })
      })
  }
  getSubscribedProducts = (ids) => {
    let search = {
      merchantId: this.user.id,
      ids: ids,
      getDetail: 1
    }
    axios.post(process.env.REACT_APP_NODE_API_URL.trim() + '/web/subscribeProduct', search)
      .then(result => {
        let skus = {};
        this.setState({ getSubscribed: true });
        if (!result.data.data) return;
        result.data.data.forEach((sku) => {
          skus[sku] = 1;
        })
        this.setState({ subscribedProducts: skus })
      });
  }
  handlePageClick = e => {
    this.getProductsData((e.selected + 1), this.state.showPerPage)
  }
  subscribeProduct = (id, issubscribe,obj) => {
    // if(issubscribe == 1){
    //   return toast.success("Product already subscribed!", {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
    if (!this.user) return;
    let search = {
      merchantId: this.user.id,
      ids: [id],
      unsubscribe: issubscribe ? 1 : 0,
      margin: 75,
      is_draft: 1,
      channel:"ds"
    }
    this.addSpinner(obj.target,1)
    axios.post(process.env.REACT_APP_NODE_API_URL.trim() + '/web/subscribeProduct', search)
      .then(result => {
        this.addSpinner(obj.target,0)
        if (result.data.error == 1) {
            return toast.error(result.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        toast.success(result.data.message, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

        let ids = this.state.subscribedProducts;
        ids[id] = (issubscribe ? 0 : 1);
        this.setState({ subscribedProducts: ids });
      });
  }

  sortBy = (type) => {
    let sortBy = Object.assign({},this.state.sortBy,type);
    if(JSON.stringify(sortBy) === JSON.stringify(this.state.sortBy))
      return false;
    this.setState({sortBy});
    this.setState({refreshData:true});
    setTimeout(()=>{
      this.getProductsData(1, this.state.showPerPage)
    })
  }
  addSpinner(el,remove){
		el.style.color = "";
		if(remove){
			el.classList.remove("far","fa-heart");
			el.classList.add("fas","fa-spinner","fa-spin");
		} else {
			el.classList.add("far","fa-heart");
			el.classList.remove("fas","fa-spinner","fa-spin");
			
		}	
	}

  render() {
    let showingbetween = 0;
    showingbetween = this.state.currentPage*this.state.showPerPage;
    showingbetween = showingbetween > this.state.productTotalCount ? this.state.productTotalCount :  showingbetween;
    return (
         <div className={this.state.loading ? "masonry-loader masonry-loader-showing" : "masonry-loader masonry-loader-loaded"}>
           
           {/*adding new row*/}   
           <div className="row justify-content-between mb-4">
            <div className="align-self-center">Showing 1 – {showingbetween} of {this.state.productTotalCount} results</div>
              <div>Sort by: 
                <select className="form-select form-control d-inline w-auto" onChange={(e)=>{ this.sortBy.call(this,{price:e.target.value}); }}>
                  <option disabled="" value="">Popularity</option>
                  <option value="1">Price - Low to High</option>
                  <option value="-1">Price - High to Low</option>
                </select>
              </div>
          </div>
        {/*adding new row*/}

          {/*<div>Showing 1 – {showingbetween} of {this.state.productTotalCount} results</div><br />
          <div style={{display:"flex"}}>
            <span style={{padding:"8px 10px 4px 0","fontWeight":"500"}}>Sort By</span>
            <div style={this.sortTabCss} onClick={this.sortBy.bind(this,{price:1})}>Price -- Low to High</div>
            <div style={this.sortTabCss} onClick={this.sortBy.bind(this,{price:-1})}>Price -- High to Low</div>
          </div><br />*/}
          <InfiniteScroll 
            className="row products product-thumb-info-list" data-plugin-options="{'layoutMode': 'fitRows'}"
            dataLength={this.state.productsData.length} //This is important field to render the next data
            next={this.getProductsData.bind(this,this.state.currentPage+1, this.state.showPerPage)}
            hasMore={this.state.pageCount > this.state.currentPage && 1}
            style={{ display: 'flex', overflow: '' }}
            loader={ <div id="content-end" className="masonry-loader masonry-loader-showing">
                  Please wait. Loading...
                </div> }
          >
            {
              this.state.productsData && this.state.productsData.length ?
                this.state.productsData.map((item, index) => {
                  return (
                    <div className={`"col-sm-6 col-lg-${this.state.row}`} key={index}>
                      <div className="product mb-0">
                        <div className="product-thumb-info border-0 mb-3">
                          {/* <div className="product-thumb-info-badges-wrapper">
                            <span className="badge badge-ecommerce badge-success">exclusive</span>
                          </div> */}
                          {/* <div className="addtocart-btn-wrapper">
                                          <a href="shop-cart.html" className="text-decoration-none addtocart-btn" title="Add to Cart">
                                              <i className="icons icon-bag"></i>
                                          </a>
                                      </div> */}
                          {/* <a href="ajax/shop-product-quick-view.html" className="quick-view text-uppercase font-weight-semibold text-2">
                                          QUICK VIEW
                                      </a> */}
                          <NavLink className="product-desc text-color-dark text-color-hover-primary"
                            to={'/product/' + item.handle}
                            exact
                          >
                            <div className="product-thumb-info-image">
                              <LazyLoadImage
                              //  alt={item.title}
                                height={"250px"}
                                width={"250px"}
                                src={item.imgUrl && item.imgUrl.length ? item.imgUrl[0].src : ""} // use normal <img> attributes as props
                                onError={(e)=>{e.target.src = placeHolderSrc}}
                                placeholderSrc={placeHolderSrc}
                              />

                              {/* <img alt={item.title} className="img-fluid" src={item.imgUrl && item.imgUrl.length ? item.imgUrl[0].src : ""} /> */}
                            </div>
                          </NavLink>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <NavLink
                              className="d-block text-uppercase  text-decoration-none text-color-default text-color-hover-primary line-height-1 text-0 mb-1"
                              to={'/' + item.cat}
                              exact
                            >
                              {item.cat}
                            </NavLink>
                            <h3 className="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-0">
                              <NavLink
                                className="product-desc text-color-dark text-color-hover-primary"
                                to={'/product/' + item.handle}
                                exact
                              >
                                {item.title}
                              </NavLink>
                            </h3>
                          </div>
                          <a href="" onClick={(e)=>{ e.preventDefault(); this.subscribeProduct.call(this, item._id, this.state.subscribedProducts[item._id],e)} } style={{ "display": this.state.getSubscribed ? "" : "none" }} className="text-decoration-none text-color-default text-color-hover-dark text-4"><i className="far fa-heart" style={{ "color": this.state.subscribedProducts[item._id] ? "red" : "" }}></i></a>
                        </div>
                        <div title="Rated 5 out of 5">
                          <input type="text" className="d-none" title="" data-plugin-star-rating="" data-plugin-options="{'displayOnly': true, 'color': 'default', 'size':'xs'}" />
                        </div>
                        <p className="price text-5 mb-3">
                          <span className="sale text-color-dark font-weight-semi-bold">${item.displayPrice.toFixed(2)}</span>
                          <span className="amount">${(item.displayPrice * 3).toFixed(2)}</span>
                        </p>
                      </div>
                    </div>
                  )           
                })
                :
                <section className="call-to-action featured featured-secondary mb-5" style={{ width: "100%" }}>
                  <div className="col-sm-9 col-lg-12">
                    <div className="call-to-action-content" style={{ display: "auto" }}>
                      <h3>Search Result Not found.</h3>
                      {/* <p className="mb-0">The Best HTML Site Template on ThemeForest</p> */}
                    </div>
                  </div>
                </section>
            }
          </InfiniteScroll>
          {/* {this.state.productTotalCount > 1 ?
            <div className="row mt-4">
              <div className="col">
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick.bind(this)}
                  containerClassName={'pagination float-end'}
                  pageClassName={'page-item'}
                  pageLinkClassName={"page-link"}
                  previousClassName={'page-item'}
                  nextClassName={'page-item'}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                  breakClassName={'page-item'}
                  breakLinkClassName={'page-link'}
                  activeClassName={'active'}
                />
              </div>
            </div>
            :
            ""
          } */}
          <div className="bounce-loader"><div className="bounce1"></div><div className="bounce2"></div><div className="bounce3"></div></div>
        </div>
    )
  }
}

export default Products;
