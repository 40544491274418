import React from 'react'
import Beauty_Accessories1 from './Beauty_Accessories1'
import Beauty_Accessories2 from './Beauty_Accessories2'
import Beauty_Accessories3 from './Beauty_Accessories3'
import Beauty_Accessories4 from './Beauty_Accessories4'
export default function Beauty_Accessories() {
  return (
    <>
        <Beauty_Accessories1/>
        <Beauty_Accessories2/>
        <Beauty_Accessories3/>
        <Beauty_Accessories4/>
    </>
  )
}
