import React from 'react'

export default function BrandStep4() {
  return (
    <>
     <div className='container' style={{marginTop:'50px'}}>
        <h1 style={{textAlign:'center'}}>Shop By Collection</h1>
        <div className='row __pf'>
                        <div className="sc-pbYdQ jklmHY pf-c pf-c-xs--12 pf-c-sm--6 pf-c-md--6 pf-c-lg--6">
                <div className="sc-pkUyL fTyjzF pf-129f5e11" data-pf-type="Column">
                    <h3 data-pf-type="Heading" className="sc-oTLFK lcHmGL pf-da45952a">
                    <span data-pf-type="Text" className="sc-ptSuy fnmilh pf-544b371d">
                        RUBAB
                    </span>
                    </h3>
                    <a
                    href="/collections/lyla-rubab"
                    target="_self"
                    data-pf-type="Button"
                    className="sc-pZdvY kuwSuz pf-3b129f5e"
                    >
                    <span data-pf-type="Text" className="sc-ptSuy fnmilh pf-371d2955">
                        SHOP NOW
                    </span>
                    </a>
                </div>
                </div>
                <div className="sc-pbYdQ jklmHY pf-c pf-c-xs--12 pf-c-sm--6 pf-c-md--6 pf-c-lg--6">
                    <div className="sc-pkUyL fTyjzF pf-4d93b3bb" data-pf-type="Column">
                        <h3 data-pf-type="Heading" className="sc-oTLFK lcHmGL pf-fc13bd0e">
                        <span data-pf-type="Text" className="sc-ptSuy fnmilh pf-29554b2b">
                            KATLI
                        </span>
                        </h3>
                        <a
                        href="/collections/lyla-katli"
                        target="_self"
                        data-pf-type="Button"
                        className="sc-pZdvY kuwSuz pf-e54d93b3"
                        >
                        <span data-pf-type="Text" className="sc-ptSuy fnmilh pf-4b2be2cd">
                            SHOP NOW
                        </span>
                        </a>
                    </div>
                </div>
        </div>
     </div>
    </>
  )
}
