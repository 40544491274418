import React, { Component } from "react"

import CartBreadcrumb from './Cart/CartBreadcrumb.js';
import CartProduct from './Cart/CartProduct.js';
import CartTotal from './Cart/CartTotal.js';
import User from '../global/User';
import axios from "axios";

export default class Carts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cartDetail:{
                netAmount:0
            }
        }
    }
    callbackFunction(state = {}) {
        this.setState(state);
    }
    componentDidMount() {
        let el = document.getElementsByClassName("header-body")[0];
        if(el){
            el.style.top = "0px";
        }
    }
    render() {
        return (
            <div>
                <div role="main" className="main shop pb-4">
                    <div className="container">
                        <CartBreadcrumb />
                        <div className="row pb-4">
                            <CartProduct parentCallback={this.callbackFunction.bind(this)}/>
                            {/*<CartTotal cartDetail={this.state.cartDetail} nextURL={"shipping"}/>*/}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
