import React, { Component } from "react"

import CartBreadcrumb from './Cart/CartBreadcrumb.js';
import Thankyou from './OrderComplete/Thankyou.js';
import OrderNo from './OrderComplete/OrderNo.js';
import Order from './OrderComplete/Order.js';
import Billing from './OrderComplete/Billing.js';
import axios from "axios";
import User from '../global/User';

export default class OrderCompletes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            order:{
                customer:{}
            }
        }
        this.user = User.getUser();
    }
    componentDidMount() {
        this.getOrder();
        let el = document.getElementsByClassName("header-body")[0];
        if(el){
            el.style.top = "0px";
        }
    }
    getOrder(){
        axios.get(process.env.REACT_APP_NODE_API_URL.trim() + "/"+ process.env.REACT_APP_NODE_API_PATH.trim().replace(/\/+/g,"\/")+"/get-order/"+this.props.id, {
            key: process.env.REACT_APP_MOBILE_API_KEY.trim(),
            token: process.env.REACT_APP_MOBILE_API_TOKEN.trim(),
            merchantId: this.user.id,
            source: "web",
            save : 0,
        }).then(res=>{
            if(res.data.Response.status !== 200){
                return false;
            }
            this.setState({order:res.data.Response.order})
        })
    }
    render() {
        return (
            <div role="main" className="main shop pb-4">
                <div className="container">
                    <CartBreadcrumb step={3}/>
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <Thankyou />
                            <OrderNo order={this.state.order} />
                            <Order order={this.state.order} />
                            <Billing customer={this.state.order.customer} />
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}