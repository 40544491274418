import React from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeScreen from "./screens/Homescreen.js";
import HomePage from "./screens/HomePage.js";
import SearchScreen from "./screens/SearchScreen.js";
import Cart from "./screens/Cart.js";
import Checkout from "./screens/Checkout.js";
import OrderComplete from "./screens/OrderComplete.js";
import Pages from "./components/Footer/Pages.js";
import Categories from "./components/Footer/Categories.js";
import Brands from "./components/Footer/Brands.js";
import VerifyLoginToken from "./screens/VerifyLoginToken";
import SingleProductDetail from "./screens/SingleProductDetail";
import Shipping from "./screens/Shipping";
import SlickCarousel from "./components/SlickCarousel";
import "./App.css?v=11";

function App() {
	return (
		<Router>
			<Switch>
				<Route exact path="/home" component={HomeScreen} />
				<Route exact path="/" component={HomePage} />
				<Route
					exact
					path="/verify-login-merchant/:token"
					component={VerifyLoginToken}
				/>

				<Route
					exact
					path="/product/:handle"
					component={SingleProductDetail}
				/>
				<Route exact path="/cart" component={Cart} />
				<Route exact path="/checkout" component={Checkout} />
				<Route exact path="/shipping" component={Shipping} />
				<Route exact path="/order-complete/:id" component={OrderComplete} />

				<Route exact path="/pages/:pagename" component={Pages} /> 

				<Route exact path="/categories/:categoriesname" component={Categories} />
				<Route exact path="/brand/:brandsname" component={Brands} />
				<Route exact path="/:category/:keyword/:subcat" component={SearchScreen} />
				<Route exact path="/category/:suppliercategory" component={SearchScreen} />
				<Route exact path="/:category/:keyword" component={SearchScreen} />
				<Route exact path="/:category" component={SearchScreen} />
				<Route exact path="/slick" component={SlickCarousel} />
			</Switch>
		</Router>
	);
}

export default App;
