import React, { Component } from 'react'
import axios from "axios";
import { NavLink } from "react-router-dom";
import cf from '../../global/CommonFunctions';
export default class BrandingStep3 extends Component {

    constructor(props) {
       
        super(props)
        this.state = {
            ourProductCrouselList: []
            
    
    
    
        }
        this.tags = cf.getTags();
    }

    componentDidMount() {
    
        this.ourProductCrousel();
        
      
      }

      ourProductCrousel() {
        this.setState({loading: true})
        let params = {
          
            "category": "fashion",
            "facet": true,
            "keyword": "",
            "limit": 8,
            "pageNumber": 1,
            "price": [],
            "sortBy": {"price": 0},
            "source": "web",
            "type": 1
        }
          
        
        axios.post('https://sellercentral-staging.shopperr.in/sscapi/web/search-products', params)
            .then(res => {
                console.log(res.data.Response.data.products)
                this.setState({ ourProductCrouselList: res.data.Response.data.products })
                 
            })
      }
  render() {
    return (
      <>
      <div className='container' style={{marginTop:'50px'}}>
        <div className='row sc-gWXbKe hoZTIr __pf'>
            <div className='col-lg-6'>

            <div
                data-href="https://shopperr.in/collections/tom-tailor/products/tom-tailor-plain-cotton-jacket-for-women-white?variant=39419914584204"
                data-target="_self"
                data-pf-type="Column"
                className="sc-hAcGzb bAQARH pf-112_"
                >
                <h3 data-pf-type="Heading" className="sc-ctqQKy ioCblW pf-113_">
                    <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-115_">
                    Plain Cotton
                    <br /> Jacket for
                    <br /> Women
                    </span>
                </h3>
                </div>
            </div>
            <div className='col-lg-6'>
            <div
                data-href="https://shopperr.in/collections/tom-tailor/products/tom-tailor-winter-coat-with-lapel-collar-short-coat-for-womens-deep-ocean-sea"
                data-target="_self"
                data-pf-type="Column"
                className="sc-hAcGzb bAQARH pf-116_"
                >
                <h3 data-pf-type="Heading" className="sc-ctqQKy ioCblW pf-117_">
                    <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-119_">
                    Winter Coat with <br />
                    Lapel Collar Short
                    <br /> Coat
                    </span>
                </h3>
                </div>
            </div>
        </div>
      </div>

      <div className='container' style={{marginTop:'50px'}}>
        <h1 style={{textAlign:'center'}}>New Arrivals</h1>
        <div className='row'>
        {
               
                          
               this.state.ourProductCrouselList?.map((item, index) => {
                return (
        <div className="col-sm-6 col-lg-3">
                <div className="product mb-0">
                    <div className="product-thumb-info border-0 mb-3">
                    <NavLink className="product-desc text-color-dark text-color-hover-primary"
                            to={'/product/' + item.handle}
                            exact
                          >
                <div className="product-thumb-info-image">
                <span
                    className=" lazy-load-image-background  lazy-load-image-loaded"
                    style={{ display: "inline-block", height: 250, width: 250 }}
                >
                    <img
                    height="250px"
                    width="250px"
                    src={item.imgUrl && item.imgUrl.length ? item.imgUrl[0].src : ""}
                    />
                </span>
                </div>
                </NavLink>
                    </div>
                    <div className="d-flex justify-content-between">
                    <div>
                        <a
                        className="d-block text-uppercase  text-decoration-none text-color-default text-color-hover-primary line-height-1 text-0 mb-1"
                        href="/undefined"
                        />
                        <h3 className="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-0">
                        <NavLink
                                className="product-desc text-color-dark text-color-hover-primary"
                                to={'/product/' + item.handle}
                                exact
                              >
                                {item.title}
                              </NavLink>
                        </h3>
                    </div>
                    <a
                        href=""
                        className="text-decoration-none text-color-default text-color-hover-dark text-4"
                        style={{ display: "none" }}
                    >
                        <i className="far fa-heart" />
                    </a>
                    </div>
                    <p className="price text-5 mb-3">
                    <span className="sale text-color-dark font-weight-semi-bold">
                    {cf.getPriceWithCurrency(new Number(item.displayPrice).toFixed(2))}
                    </span>
                    <span className="amount">{cf.getPriceWithCurrency(new Number(item.recommendedRetailPrice).toFixed(2))}</span>
                    </p>
                </div>
                </div>
                )
                })
                
                }
        </div>
      </div>
      </>
    )
  }
}
