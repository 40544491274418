import React from 'react'
import Kitchen_Accessories1 from './Kitchen_Accessories1'
import Kitchen_Accessories2 from './Kitchen_Accessories2'
import Kitchen_Accessories3 from './Kitchen_Accessories3'
import Kitchen_Accessories4 from './Kitchen_Accessories4'
export default function Home_Kitchen() {
  return (
    <>
        <Kitchen_Accessories1/>
        <Kitchen_Accessories2/>
        <Kitchen_Accessories3/>
        <Kitchen_Accessories4/>
    </>
  )
}
