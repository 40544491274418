import React, { Component } from "react";
import { NavLink } from "react-router-dom";
class LeftMenu extends Component {
  render() {
    return (
      <ul className="lhs-menu">  
        <li>
          <NavLink to={"/search/Hotel Linen"} exact>
            <img src="https://static-sellercentral.shopperr.in/web/images/web-banner-shopper/pf-08cdbba7--1Mobile-Computer1_32x.png" />
            Hotel Linen
          </NavLink>
        </li>
        <li>
          <NavLink to={"/search/Hotel Amenities"} exact>
            <img src="https://static-sellercentral.shopperr.in/web/images/web-banner-shopper/pf-a7e8cac5--4Beauty-Health1_32x.png" />
            Hotel Amenities
          </NavLink>
        </li>

        <li>
          <NavLink to={"/search/Guestroom Customized"} exact>
            <img src="https://static-sellercentral.shopperr.in/web/images/web-banner-shopper/pf-bba7e8ca--3Home-Kitchen1_32x.png" />
            Guestroom Customized
          </NavLink>
        </li>

        <li>
          <NavLink to={"/search/Guestroom Appliances"} exact>
            <img src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/1659604388494jStJD8.png" />
            Guestroom Appliances
          </NavLink>
        </li>

        <li>
          <NavLink to={"/search/Cleaning Equipment"} exact>
            <img src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/1659605228641fyl7TZ.png" />
            Cleaning Equipment
          </NavLink>
        </li>

        <li>
          <NavLink to={"/search/Public Item"} exact>
            <img src="https://static-sellercentral.shopperr.in/web/images/web-banner-shopper/pf-2c1fedb8--bag_32x.png" />
            Public Item
          </NavLink>
        </li>

        <li>
          <NavLink to={"/search/Buffet"} exact>
            <img src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/16596083925207sO0OG.png" />
            Buffet
          </NavLink>
        </li>
        <li>
          <NavLink to={"/search/Dinnerware"} exact>
            <img src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/16596061095134fbGi7.png" />
            Dinnerware
          </NavLink>
        </li>
        <li>
          <NavLink to={"/search/Mock-up-Room"} exact>
            <img src="https://static-sellercentral.shopperr.in/web/images/web-banner-shopper/pf-edb82aaa--camera_32x.png" />
            Mock-up Room
          </NavLink>
        </li>

        <li>
          <NavLink to={"/search/Furniture"} exact>
            <img src="https://static-sellercentral.shopperr.in/web/images/web-banner-shopper/pf-edb82aaa--camera_32x.png" />
            Furniture
          </NavLink>
        </li>
      </ul>
    );
  }
}

export default LeftMenu;
