import React, { Component } from "react"
import '../../css/Theme.css';
import { NavLink } from "react-router-dom";
import Pdfs from '../../img/ShopperrFaqs.pdf';
import User from '../../global/User';
import CommonFunctions from '../../global/CommonFunctions';
import Login from './Login';
import HeaderCart from './HeaderCart';
import axios from "axios";
class HeaderNavigation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cC:CommonFunctions.cC
        }
        this.changeCurrency = this.changeCurrency.bind(this)
    }
    componentDidMount() {
        document.querySelectorAll("#dropdownLanguageSelector a").forEach((el)=>{
            el.addEventListener("click",this.changeCurrency)
        })
        try {
            let date = new Date().toISOString();
            if(!localStorage.getItem("currencyRate") || (localStorage.getItem("currencyRate") && new Date(JSON.parse(localStorage.getItem("currencyRate")).date).getTime() != new Date(date.split("T")[0]).getTime())) {
                this.getCurrentRate();
            }
        } catch(err){
            this.getCurrentRate();
        }
    }
    componentWillUnmount(){
        document.querySelectorAll("#dropdownLanguageSelector a").forEach((el)=>{
            el.removeEventListener("click",this.changeCurrency)
        })
    }
    changeCurrency = (e) =>{
        localStorage.setItem("currentCurrency",'USD');
        window.location.reload()
    }
    handleClick = (e) => {
        e.preventDefault();
        User.logoutUser()      
    }
    getCurrentRate = () =>{
        if(!localStorage.getItem("currentCurrency")){
            axios.get("https://ipinfo.io").then(response=>{
                response = response.data;
                if(CommonFunctions?.symbols?.countrycode?.[response.country]){
                    localStorage.setItem("currentCurrency",CommonFunctions?.symbols?.countrycode?.[response.country]);
                    window.location.reload()
                }
            });
        }

        axios.get(process.env.REACT_APP_NODE_API_URL.trim() +"/web/currency-rates").then(res=>{
            localStorage.setItem("currencyRate",JSON.stringify(res.data));
        }).catch((err)=>{
            
        })
    }
    render() {
        return (
            <div className="header-top header-top-small-minheight header-top-simple-border-bottom">
                <div className="container">
                    <div className="header-row justify-content-between">
                        <div className="header-column col-auto px-0">
                            <div className="header-row">
                                <p className="font-weight-semibold text-1 mb-0 d-none d-sm-block d-md-none">FREE SHIPPING ORDERS INR99+</p>
                                {/* <p className="font-weight-semibold text-1 mb-0 d-none d-md-block">FREE RETURNS, STANDARD SHIPPING ORDERS INR99+</p> */}
                            </div>
                        </div>
                        <div className="header-column justify-content-end col-auto px-0">
    <div className="header-row">
        <nav className="header-nav-top">
            <ul className="nav nav-pills font-weight-semibold text-2">
                <li className="nav-item ">
                    <span className="nav-link text-color-default text-color-hover-primary">
                        <img height={"16px"} alt={this.state.cC} src={`https://static-sellercentral.shopperr.in/web/images/currencies-icon/${this.state.cC.substr(0,2).toLocaleLowerCase()}.svg`} />
                        {this.state.cC}
                    </span>
                </li>
                            {/* <a className="dropdown-item" href="#" data-value="INR"> 
                                                    <img height={"25px"} alt="INR"  src="https://static-sellercentral.shopperr.in/web/images/currencies-icon/in.svg" />
                                                    INR
                                                </a>
                                                <a className="dropdown-item" href="#" data-value="CNY">
                                                    <img height={"25px"} alt="CNY"  src="https://static-sellercentral.shopperr.in/web/images/currencies-icon/cn.svg" />
                                                    CNY
                                                </a>
                                                <a className="dropdown-item" href="#" data-value="JPY"> 
                                                    <img height={"25px"} alt="JPY"  src="https://static-sellercentral.shopperr.in/web/images/currencies-icon/jp.svg" />
                                                    JPY
                                                </a>
                                                <a className="dropdown-item" href="#" data-value="GBP"> 
                                                    <img height={"25px"} alt="GBP"  src="https://static-sellercentral.shopperr.in/web/images/currencies-icon/gb.svg" />
                                                    GBP
                                                </a>
                                                <a className="dropdown-item" href="#" data-value="EUR"> 
                                                    <img height={"25px"} alt="EUR"  src="https://static-sellercentral.shopperr.in/web/images/currencies-icon/eu.svg" />
                                                    EUR
                                                </a>
                                                <a className="dropdown-item" href="#" data-value="AED"> 
                                                    <img height={"25px"} alt="AED"  src="https://static-sellercentral.shopperr.in/web/images/currencies-icon/ae.svg" />
                                                    AED
                                                </a>
                                                <a className="dropdown-item" href="#" data-value="AUD"> 
                                                    <img height={"25px"} alt="AUD"  src="https://static-sellercentral.shopperr.in/web/images/currencies-icon/au.svg" />
                                                    AUD
                                                </a>
                                                <a className="dropdown-item" href="#" data-value="BDT"> 
                                                    <img height={"25px"} alt="BDT"  src="https://static-sellercentral.shopperr.in/web/images/currencies-icon/bd.svg" />
                                                    BDT
                                                </a>
                                                <a className="dropdown-item" href="#" data-value="CAD"> 
                                                    <img height={"25px"} alt="CAD"  src="https://static-sellercentral.shopperr.in/web/images/currencies-icon/ca.svg" />
                                                    CAD
                                                </a>
                                                <a className="dropdown-item" href="#" data-value="CHF">
                                                    <img height={"25px"} alt="CHF"  src="https://static-sellercentral.shopperr.in/web/images/currencies-icon/ch.svg" />
                                                    CHF
                                                </a>
                                                <a className="dropdown-item" href="#" data-value="HKD">
                                                    <img height={"25px"} alt="HKD"  src="https://static-sellercentral.shopperr.in/web/images/currencies-icon/hk.svg" />
                                                    HKD
                                                </a>
                                                <a className="dropdown-item" href="#" data-value="IDR">
                                                    <img height={"25px"} alt="IDR"  src="https://static-sellercentral.shopperr.in/web/images/currencies-icon/id.svg" />
                                                    IDR
                                                </a>
                                                <a className="dropdown-item" href="#" data-value="KRW"> 
                                                    <img height={"25px"} alt="KRW"  src="https://static-sellercentral.shopperr.in/web/images/currencies-icon/kr.svg" />
                                                    KRW
                                                </a>
                                                <a className="dropdown-item" href="#" data-value="LKR"> 
                                                    <img height={"25px"} alt="LKR"  src="https://static-sellercentral.shopperr.in/web/images/currencies-icon/lk.svg" />
                                                    LKR
                                                </a>
                                                <a className="dropdown-item" href="#" data-value="MXN"> 
                                                    <img height={"25px"} alt="MXN"  src="https://static-sellercentral.shopperr.in/web/images/currencies-icon/mx.svg" />
                                                    MXN
                                                </a>
                                                <a className="dropdown-item" href="#" data-value="MYR"> 
                                                    <img height={"25px"} alt="MYR"  src="https://static-sellercentral.shopperr.in/web/images/currencies-icon/my.svg" />
                                                    MYR
                                                </a>
                                                <a className="dropdown-item" href="#" data-value="PHP">
                                                    <img height={"25px"} alt="PHP"  src="https://static-sellercentral.shopperr.in/web/images/currencies-icon/ph.svg" />
                                                    PHP
                                                </a>
                                                <a className="dropdown-item" href="#" data-value="QAR">
                                                    <img height={"25px"} alt="QAR"  src="https://static-sellercentral.shopperr.in/web/images/currencies-icon/qa.svg" />
                                                    QAR
                                                </a>
                                                <a className="dropdown-item" href="#" data-value="SAR"> 
                                                    <img height={"25px"} alt="SAR"  src="https://static-sellercentral.shopperr.in/web/images/currencies-icon/sa.svg" />
                                                    SAR
                                                </a>
                                                <a className="dropdown-item" href="#" data-value="THB">
                                                    <img height={"25px"} alt="THB"  src="https://static-sellercentral.shopperr.in/web/images/currencies-icon/th.svg" />
                                                    THB
                                                </a>
                                                <a className="dropdown-item" href="#" data-value="TRY"> 
                                                    <img height={"25px"} alt="TRY"  src="https://static-sellercentral.shopperr.in/web/images/currencies-icon/tr.svg" />
                                                    TRY
                                                </a>
                                                <a className="dropdown-item" href="#" data-value="ZAR">
                                                    <img height={"25px"} alt="ZAR"  src="https://static-sellercentral.shopperr.in/web/images/currencies-icon/za.svg" />
                                                    ZAR
                                                </a> */}
                                            {/* </div> 
                                        </li> */}

                                        
                                        <li className="nav-item dropdown nav-item-left-border d-lg-none">
                                            {/* <a className="nav-link text-color-default text-color-hover-primary" href="#" role="button" id="dropdownMobileMore" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                More
                                                <i className="fas fa-angle-down"></i>
                                            </a> */}
                                            <div className="dropdown-menu">
                                                <NavLink
                                                    className="dropdown-item"
                                                    to={'/pages/about-us'}
                                                    exact
                                                >About</NavLink>
                                                {/* <a className="dropdown-item" href="#">Our Stores</a>
                                                <a className="dropdown-item" href="#">Blog</a> */}
                                                <NavLink
                                                    className="dropdown-item"
                                                    to={'/pages/contact-us'}
                                                    exact
                                                >Contact</NavLink>
                                                <a href="https://shopperr.zendesk.com/hc/en-us" targeheight={"25px"} alt="_blank" className="dropdown-item">Help &amp; FAQs</a>
                                                <a className="dropdown-item" href="#">Track Order</a>
                                            </div>
                                        </li>
                                        <li className="nav-item d-none d-lg-inline-block">
                                            <NavLink
                                                className="text-decoration-none"
                                                to={'/pages/about-us'}
                                                exact
                                            >About</NavLink>
                                        </li>
                                        {/* <li className="nav-item d-none d-lg-inline-block">
                                            <a href="#" className="text-decoration-none text-color-default text-color-hover-primary">Our Stores</a>
                                        </li> */}
                                        {/* <li className="nav-item d-none d-lg-inline-block">
                                            <a href="#" className="text-decoration-none text-color-default text-color-hover-primary">Blog</a>
                                        </li> */}
                                        <li className="nav-item d-none d-lg-inline-block">
                                            <NavLink
                                                className="text-decoration-none"
                                                to={'/pages/contact-us'}
                                                exact
                                            >Contact</NavLink>
                                        </li>
                                        <li className="nav-item d-none d-xl-inline-block">
                                            <a href="https://shopperr.zendesk.com/hc/en-us" targeheight={"25px"} alt="_blank" className="text-decoration-none">Help &amp; FAQs</a>
                                        </li>
                                        <li className="nav-item d-none d-xl-inline-block">
                                            <NavLink
                                                className="text-decoration-none"
                                                to={'/pages/track-order'}
                                                exact
                                            >Track Order</NavLink>
                                        </li>
                                        <li className="nav-item d-none d-xl-inline-block">
                                            {                
                                                !User.getUser() ? 
                                                <Login />
                                                :   
                                                <div className='userLogin'>
                                                    <a
                                                        className="text-decoration-none account"
                                                        href = {`${process.env.REACT_APP_NODE_API_URL}/merchant/dashboard`}
                                                        target = '_blank'
                                                    >Manage Account</a> 
                                                    <a href ='' className='logout' onClick={(e)=>{ this.handleClick(e) }}>Log Out</a>  
                                                </div>
                                            }
                                        </li>
                                        { User.getUser() && <li className="nav-item d-none d-xl-inline-block"><HeaderCart /></li>}
                                        {/* <li className="nav-item dropdown nav-item-left-border">
                                            <a className="nav-link text-color-default text-color-hover-primary" href="#" role="button" id="dropdownCurrency" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                INR
                                                <i className="fas fa-angle-down"></i>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-arrow-centered min-width-0" aria-labelledby="dropdownCurrency">
                                                <a className="dropdown-item" href="#">EUR</a>
                                                <a className="dropdown-item" href="#">USD</a>
                                            </div>
                                        </li> */}
                                       
                                    </ul>
                               
                                    <ul className="header-social-icons social-icons social-icons-clean social-icons-icon-gray">
                                        <li className="social-icons-facebook">
                                            <a href="http://www.facebook.com/" targeheight={"25px"} alt="_blank" title="Facebook"><i className="fab fa-facebook-f"></i></a>
                                        </li>
                                        <li className="social-icons-twitter">
                                            <a href="http://www.twitter.com/" targeheight={"25px"} alt="_blank" title="Twitter"><i className="fab fa-twitter"></i></a>
                                        </li>
                                        <li className="social-icons-linkedin">
                                            <a href="http://www.linkedin.com/" targeheight={"25px"} alt="_blank" title="Linkedin"><i className="fab fa-linkedin-in"></i></a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

 
        )

    }
}

export default HeaderNavigation;
