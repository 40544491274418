import React, { cloneElement, Component } from "react"
import MetaTags from 'react-meta-tags';




class AboutUs extends Component {


  componentDidMount() {
    window.scrollTo(0, 0)

  }

  render() {
    return (

      <div className="wrapper">
        <MetaTags>
          <title>AboutUs</title>
          {/* <Description>Shopperr is one of the most evolved B2B platform that enables members to source products of international brands directly from manufacturers and suppliers.</Description> */}
          <meta id="description" name="mg-description" content="Shopperr is one of the most evolved B2B platform that enables members to source products of international brands directly from manufacturers and suppliers." />
          {/* <meta id="keywords" property="mg-keyword" content="head,body,html,tags" /> */}

        </MetaTags>
        <div className="container">                <div className="row">
          <div className="col-lg-12">
            <h2 className="font-weight-normal text-7 mb-2" style={{ textAlign: 'center', marginTop: '10px' }}>
              <strong className="font-weight-extra-bold">About Us</strong>
            </h2>

            <p className="lead">
              OUR MISSION
            </p>
            <p>
              In a society driven by technological solutions, the trading business has remained complex. It is still a challenge to source products from international suppliers and to manage the inventory. We realised that to drive business growth, the process must be simplified. The resellers, wholesalers and traders must be empowered.
            </p>
            <p>
              Shopperr was born with this idea to help them grow as an interconnected community within a robust ecosystem. The system that does everything while they trade and profit.
            </p>
            <p className="lead">
              WHAT IS SHOPPERR?
            </p>
            <p>
              Shopperr is one of the most evolved B2B platform that enables members to source products of international brands directly from manufacturers and suppliers. You enjoy wholesale rate without worrying about the minimum order quantity (MOQ) of products.
            </p>
            <div className="sticky-container">
              <div className="row">
                <div className="col-lg-8">
                  <p>
                    As a reseller or a wholesaler, you can use this one-click solution to source products for reselling. If you are a wholesaler, you get access to bulk pricing model.
                  </p>
                  <p>
                    Irrespective of subscription every member gets access to one of the largest assortment of carefully curated products and brands which they can resell.
                  </p>
                  <p className="lead">
                    DO I HAVE TO BUY THE PRODUCTS FIRST?
                  </p>
                  <p>
                    No. You pay after you make a sale. First, browse the products listed on the platform, select the ones which you would like to resell, and share them on your Shopify or Amazon store. You can even customize the products before you start marketing them to your customers. When someone buys a product on your store, only then you pay for that product on Shopperr.
                  </p>
                  <p>
                    You do not have to buy and store inventory. Just focus on growing your sales and increasing profits while we take care of everything else.
                  </p>
                  <p className="lead">
                    CAN I SOURCE PRODUCTS FOR MY OFFLINE SHOP?

                  </p>
                  <p>
                    Yes, you can. In fact, one of our objectives is to help the local retailers, wholesalers, and distributors like yourself to source international products through Shopperr. The products you order would be delivered to your doorstep, ready to be sold to your local customers through your shop.
                  </p>
                  <p className="lead">
                    WHAT ABOUT THE DELIVERY OF ITEMS TO MY CUSTOMERS?

                  </p>
                  <p>
                    We take care of that too. When you place an order, you also provide the delivery address to us so that we can deliver the items to your customer’s doorsteps. It would even mention your details as the sender.
                  </p>
                </div>
              </div>
            </div>
            <p className="lead">
              HOW DO YOU SOURCE THE PRODUCTS?

            </p>
            <p>
              We buy directly from suppliers, manufacturers, and brand owners. Our objective is to bargain hard to get the best price so that you earn a good profit when you resell these products. We can accomplish this as our volumes are high and intermediaries are less or non-existent. At the same time, it works for our suppliers who get larger sales. This creates a sweet spot for everyone - the suppliers, us and most importantly you.
            </p>
            <p>
              We work towards your success and that means getting you the right products at the right price. Our list of brands and products is growing, including those exclusively available for Shopperr members.
            </p>
            <p className="lead">
              IS THIS A SECURE PLATFORM?

            </p>
            <p>
              It is a robust platform with advanced systems in place. We follow industry best practices to protect your personal information and ensure that it is not inappropriately lost, misused, accessed, disclosed, altered, or destroyed. Please read our privacy policy to know more about our security standards.
            </p>
            <p className="lead">
              WHO OPERATES SHOPPERR?

            </p>
            <p>
              We are a company of human-centric technology and retail experts called Creationist Technovations Pvt. Ltd. based at 436, Jawala Mill Road, Electronic City, Phase-IV, Sec-18, Udyog Vihar, Gurugram.
            </p>
            <p>
              We are here to support every individual passionate about starting an enterprise by utilizing the reach our reach. Our work is based on our core values:
            </p>
            <div style={{ textAlign: 'center' }}>
              <b>EMPATHY</b>
              <p>
                We earnestly try to identify and understand your business needs
              </p>
              <b>AUTHENTICITY</b>
              <p>
                Genuine solutions
              </p>
              <b>PASSION</b>
              <p>
                Our enthusiasm to create solutions runs deep
              </p>
              <b>PERSEVERANCE</b>
              <p>
                We have a culture of adaptability and creativity
              </p>
              <b>SIMPLICITY</b>
              <p>
                We keep it meaningful and real
              </p>
              <b>TEAMWORK</b>
              <p>
                Consider us your extended team
              </p>
            </div>
            <p className="lead">
              WHAT DO I NEED TO GET STARTED?

            </p>
            <p>
              All you need is a computer connected to the internet, for that matter even a smartphone would work. Just get an account on Shopperr and start your journey.
            </p>
            <p>
              Once you are on board, these are the advantages you will have:
            </p>
            <ul style={{ color: '#252129' }}>
              <li>Ease of choosing the products with the most potential</li>
              <li>Tools to manage product pricing and mark-ups</li>
              <li>Sell first, pay later</li>
              <li>Order tracking</li>
              <li>Zero inventory management</li>
              <li>Advanced technology</li>
              <li>Direct sourcing from international manufacturers and suppliers</li>
              <li>Complete end to end support - from order placement to doorstep delivery</li>
            </ul>
            <p>
              If you have ever had the vision to start an enterprise, it is time to get started on Shopperr.
            </p>
          </div>
        </div>
        </div>
      </div>




    )
  }
}


export default AboutUs;