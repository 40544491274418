import React, { Component } from "react"
import axios from 'axios';
import { NavLink } from "react-router-dom";
class HomePageProducts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            productsHomePages: [],
            keyword: props.keyword ?? "footwear",
            headingText: props.headingText ?? "New Products"
        }
    }
    componentDidMount() {
        this.productHomePage();
    }
    productHomePage() {
        let params = {
            "keyword": this.state.keyword,
            "type": 1,
            "limit": 12,
            "pageNumber": 1,
            "source": "web"
        }
        axios.post(process.env.REACT_APP_NODE_API_URL.trim() + "/"+ process.env.REACT_APP_NODE_API_PATH.trim().replace(/\/+/g,"\/")+'/top-rated', params)
            .then(res => {
          this.setState({ productsHomePages: res.data.Response.data.products })
        })
    }
    render() {
        return (
            <div className="col-lg-12 order-1 order-lg-2" style={{ marginTop: '40px' }}>
                <h4 className="font-weight-semibold text-4 mb-3" style={{ textAlign: 'center' }}>{this.state.headingText}</h4>
                <br />
                <div className="masonry-loader masonry-loader-loaded">
                    <div
                        className="row products product-thumb-info-list"
                        data-plugin-options="{'layoutMode': 'fitRows'}"
                    >
                        {
                            this.state.productsHomePages ?
                                this.state.productsHomePages.map((item, index) => {
                                    let discount = item.sellingPrice * 18 / 100;
                                    discount = discount ? discount : 0;
                                    return (
                                        <div className="col-sm-6 col-lg-3" key={index}>
                                            <div className="product mb-0">
                                                <div className="product-thumb-info border-0 mb-3">
                                                    <div className="product-thumb-info-badges-wrapper">
                                                        <span className="badge badge-ecommerce badge-success">
                                                            exclusive
                                                        </span>
                                                    </div>
                                                    <NavLink
                                                        className="product-desc text-color-dark text-color-hover-primary"
                                                        to={'/product/' + item.handle}
                                                        exact
                                                    >
                                                        <div className="product-thumb-info-image fullImage">
                                                            <img alt="" className="img-fluid" src={item.imgUrl && item.imgUrl.length ? item.imgUrl[0].src : ""} />
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <a
                                                            href="#"
                                                            className="d-block text-uppercase  text-decoration-none text-color-default text-color-hover-primary line-height-1 text-0 mb-1"
                                                        />
                                                        <h3 className="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-0"><NavLink
                                                            className="product-desc text-color-dark text-color-hover-primary"
                                                            to={'/product/' + item.handle}
                                                            exact
                                                        >{item.title.substring(0, 35)}...</NavLink></h3>
                                                    </div>
                                                    <a
                                                        href="#"
                                                        className="text-decoration-none text-color-default text-color-hover-dark text-4"
                                                    >
                                                        <i className="far fa-heart" />
                                                    </a>
                                                </div>
                                                <div title="Rated 5 out of 5">
                                                    <input
                                                        type="text"
                                                        className="d-none"
                                                        title
                                                        data-plugin-star-rating
                                                        data-plugin-options="{'displayOnly': true, 'color': 'default', 'size':'xs'}"
                                                        defaultValue={5}
                                                    />
                                                </div>
                                                <p className="price text-5 mb-3">
                                                    <span className="sale text-color-dark font-weight-semi-bold">₹ {item.displayPrice}</span>
                                                    <span className="amount">₹ {item.recommendedRetailPrice}</span>
                                                </p>
                                            </div>
                                        </div>)
                                })
                                : ""
                        }
                    </div>
                </div>     
            </div>
        )
    }
}


export default HomePageProducts;