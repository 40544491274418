import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import InnerImageZoom from 'react-inner-image-zoom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

const SlickCarousel = ({ images }) => {
	const [nav1, setNav1] = useState(null);
	const [nav2, setNav2] = useState(null);
	const [slider1, setSlider1] = useState(null);
	const [slider2, setSlider2] = useState(null);

	useEffect(() => {
		setNav1(slider1);
		setNav2(slider2);
		
		let observer = new MutationObserver(mutationRecords => {
			document.querySelectorAll(".slick-track figure.slick-slide-image").forEach(el=>{
				let parent = el.parentElement.parentElement.parentElement;
				if(parent.classList.contains("slick-active")){
					parent.style.visibility = "";
				} else {
					parent.style.visibility = "hidden";
				}
			})
		});
		  
		// observe everything except attributes
		observer.observe(document.getElementsByClassName("slick-list")[0], {
			childList: true, // observe direct children
			subtree: true, // and lower descendants too
			characterDataOldValue: true // pass old data to callback
		});

	}, [nav1, nav2, slider1, slider2]);

	const settingsMain = {
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		fade: true,
		asNavFor: ".slider-nav",
	};

	const settingsThumbs = {
		slidesToShow: 3,
		slidesToScroll: 1,
		variableWidth: true, 
		mobileFirst: true,
		asNavFor: ".slider-for",
		dots: true,
		centerMode: true,
		swipeToSlide: true,
		focusOnSelect: true,
		centerPadding: "10px",
	};

	const slidesData = [
		{
			id: 1,
			title: "repellendus id ullam",
			label: "Dolorem officiis temporibus.",
		},
		{
			id: 2,
			title: "excepturi consequatur est",
			label: "Officia non provident dolor esse et neque.",
		},
		{
			id: 3,
			title: "eius doloribus blanditiis",
			label: "Ut recusandae vel vitae molestiae id soluta.",
		},
		{
			id: 4,
			title: "nihil voluptates delectus",
			label: "Qui vel consequatur recusandae illo repellendus.",
		},
		{
			id: 5,
			title: "nemo dolorem necessitatibus",
			label: "Placeat odit velit itaque voluptatem.",
		},
		{
			id: 6,
			title: "dolorem quibusdam quasi",
			label: "Adipisci officiis repudiandae.",
		},
	];

	return (
		<div>
			<div className="slider-wrapper">
				<Slider
					{...settingsMain}
					asNavFor={nav2}
					ref={(slider) => setSlider1(slider)}
				>
					{images.map((slide) => (
						<div className="slick-slide" style={{}} key={slide.src}>
							<InnerImageZoom
								className="slick-slide-image"
								src={slide.src}
								zoomSrc={slide.src}
								zoomType="hover"
								fullscreenOnMobile={false}
								zoomPreload={true}
								zoomScale={2}
								hasSpacer={true}
								alt="product image"
							/>
						</div>
					))}
				</Slider>
				<div className="thumbnail-slider-wrap">
					<Slider
						{...settingsThumbs}
						asNavFor={nav1}
						ref={(slider) => setSlider2(slider)}
					>
						{images.map((slide) => (
							<div className={`slick-slide ${slide?.varient_id || '' }`} key={slide.src}>
								<img
									className="slick-slide-image"
									src={slide.src}
									alt="product image"
								/>
							</div>
						))}
					</Slider>
				</div>
			</div>
		</div>
	);
};

export default SlickCarousel;
