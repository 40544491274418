import React, { cloneElement, Component } from "react"
import MetaTags from 'react-meta-tags';
import Estimate from '../../img/estimated-delivery-date-icon.png';
import Order from '../../img/order-received.png';
import Picked from '../../img/picked-icon.png';
import Multi from '../../img/in-transition.png';
import Reached from '../../img/reached.png';
import Delivery from '../../img/out-delivery.png';





class TrackOrder extends Component {
    constructor() {
        super()
        this.state = {
            showMe: false
        }
    }
    operation() {
        this.setState({
            showMe: 0
        })
    }
    operations() {
        this.setState({
            showMe: 1
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0)

    }

    render() {
        return (

            <div className="wrapper">
                <MetaTags>
                    <title>TractOrder</title>
                    {/* <Description>Shopperr is one of the most evolved B2B platform that enables members to source products of international brands directly from manufacturers and suppliers.</Description> */}
                    <meta id="description" name="mg-description" content="Shopperr is one of the most evolved B2B platform that enables members to source products of international brands directly from manufacturers and suppliers." />
                    {/* <meta id="keywords" property="mg-keyword" content="head,body,html,tags" /> */}

                </MetaTags>


                <div id="examples" className="container py-2">
                    <section className="call-to-action with-borders button-centered mb-5">
                        <div className="col-12">
                            <div className="call-to-action-content">
                                <h3>
                                    Track your  <strong className="font-weight-extra-bold">Order</strong>{" "}
                                    or Shipment{" "}
                                    <strong className="font-weight-extra-bold">website!</strong>
                                </h3>
                                <p className="mb-0">Enter your AWB number or Order ID to track the status of your order. You can find the AWB number and Order ID in the Email/SMS confirming that your order has been shipped</p>
                            </div>
                        </div>
                        <div className="col-lg-9" style={{ marginTop: '40px' }}>
                            <div className="tabs tabs-vertical tabs-left">
                                <h4 style={{ margin: '15px' }}>Search By:</h4>

                                <div
                                    className="btn-group"
                                    role="group"
                                    aria-label="Basic radio toggle button group"
                                >
                                    <input style={{ margin: '5px' }}
                                        type="radio"
                                        className="btn-check"
                                        name="btnradio"
                                        id="btnradio1"
                                        autoComplete="off"
                                        defaultChecked
                                        onClick={() => this.operation()}
                                    />
                                    <span>AWB</span>


                                    <input style={{ margin: '5px', marginLeft: '5px!important' }}
                                        type="radio"
                                        className="btn-check"
                                        name="btnradio"
                                        id="btnradio3"
                                        autoComplete="off"
                                        onClick={() => this.operations()}
                                    />
                                    <span>Order Id</span>

                                </div>

                                <div className="tab-content">
                                    <div id="popular11" className="tab-pane active" style={{ display: (this.state.showMe ? "none" : "") }}>
                                        <h4 style={{ textAlign: 'left' }}>Enter Details </h4>

                                        <div className="col-lg-9 form-group" style={{ paddingLeft: '0px' }} >

                                            <input type="text" placeholder="Enter your Airway Bill Number(AWB)" class="form-control" style={{ borderRadius: '30px' }} />


                                        </div>


                                    </div>
                                    <div id="recent11 " className="tab-pane active" style={{ display: (!this.state.showMe ? "none" : "") }}>
                                        <h4 style={{ textAlign: 'left' }}>Enter Details </h4>
                                        <div class="col-lg-9 form-group" style={{ display: '-webkit-box', paddingLeft: '0px' }}>
                                            <input type="text" placeholder="Order Id" class="form-control" style={{ borderRadius: '30px' }} />

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="call-to-action-btn" style={{ margin: '20px 25px' }}>
                                <a
                                    href="#"
                                    target="_blank"
                                    className="btn btn-modern text-2 btn-primary"
                                >
                                    SUBMIT                                </a>
                            </div>

                        </div>
                    </section>
                    <h2 style={{ textAlign: 'center' }}>What Do These Shipment Tracking Terms Mean?
                    </h2>
                    <div className="row mt-5">

                        <div className="col">

                            <div className="row process my-5">
                                <div
                                    className="process-step col-lg-4 mb-5 mb-lg-4 appear-animation animated fadeInUpShorter appear-animation-visible"
                                    data-appear-animation="fadeInUpShorter"
                                    data-appear-animation-delay={200}
                                    style={{ animationDelay: "200ms" }}
                                >
                                    <div className="process-step-circle">
                                        <img className="img-fluid border-radius-0" src={Estimate} alt="" />
                                    </div>
                                    <div className="process-step-content">
                                        <h4 className="mb-1 text-5 font-weight-bold" style={{ marginTop: '10px' }}>Estimated Delivery Date
                                        </h4>
                                        <p style={{ marginTop: '15px' }} className="mb-0">
                                            You can expect to receive your order by this date


                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="process-step col-lg-4 mb-5 mb-lg-4 appear-animation animated fadeInUpShorter appear-animation-visible"
                                    data-appear-animation="fadeInUpShorter"
                                    data-appear-animation-delay={400}
                                    style={{ animationDelay: "400ms" }}
                                >
                                    <div className="process-step-circle">
                                        <img className="img-fluid border-radius-0" src={Order} alt="" />
                                    </div>
                                    <div className="process-step-content">
                                        <h4 className="mb-1 text-5 font-weight-bold" style={{ marginTop: '10px' }}>Order Received
                                        </h4>
                                        <p style={{ marginTop: '15px' }} className="mb-0">
                                            Your order has been received by the courier company and courier tracking has been enabled


                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="process-step col-lg-4 mb-5 mb-lg-4 appear-animation animated fadeInUpShorter appear-animation-visible"
                                    data-appear-animation="fadeInUpShorter"
                                    data-appear-animation-delay={600}
                                    style={{ animationDelay: "600ms" }}
                                >
                                    <div className="process-step-circle">
                                        <img className="img-fluid border-radius-0" src={Picked} alt="" />
                                    </div>
                                    <div className="process-step-content">
                                        <h4 className="mb-1 text-5 font-weight-bold" style={{ marginTop: '10px' }}>Picked
                                        </h4>
                                        <p style={{ marginTop: '15px' }} className="mb-0">
                                            Your order has been picked up by the courier company mentioned in the Email.


                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="process-step col-lg-4 mb-5 mb-lg-4 appear-animation animated fadeInUpShorter appear-animation-visible"
                                    data-appear-animation="fadeInUpShorter"
                                    data-appear-animation-delay={600}
                                    style={{ animationDelay: "600ms" }}
                                >
                                    <div className="process-step-circle">
                                        <img className="img-fluid border-radius-0" src={Multi} alt="" />
                                    </div>
                                    <div className="process-step-content">
                                        <h4 className="mb-1 text-5 font-weight-bold" style={{ marginTop: '10px' }}>In Transit
                                        </h4>
                                        <p style={{ marginTop: '15px' }} className="mb-0">
                                            Your order is moving through cities and warehouses


                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="process-step col-lg-4 mb-5 mb-lg-4 appear-animation animated fadeInUpShorter appear-animation-visible"
                                    data-appear-animation="fadeInUpShorter"
                                    data-appear-animation-delay={600}
                                    style={{ animationDelay: "600ms" }}
                                >
                                    <div className="process-step-circle">
                                        <img className="img-fluid border-radius-0" src={Reached} alt="" />
                                    </div>
                                    <div className="process-step-content">
                                        <h4 className="mb-1 text-5 font-weight-bold" style={{ marginTop: '10px' }}>Reached Destination
                                        </h4>
                                        <p style={{ marginTop: '15px' }} className="mb-0">
                                            Your order has arrived at the destination city


                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="process-step col-lg-4 mb-5 mb-lg-4 appear-animation animated fadeInUpShorter appear-animation-visible"
                                    data-appear-animation="fadeInUpShorter"
                                    data-appear-animation-delay={600}
                                    style={{ animationDelay: "600ms" }}
                                >
                                    <div className="process-step-circle">
                                        <img className="img-fluid border-radius-0" src={Delivery} alt="" />
                                    </div>
                                    <div className="process-step-content">
                                        <h4 className="mb-1 text-5 font-weight-bold" style={{ marginTop: '10px' }}>Out For Delivery
                                        </h4>
                                        <p style={{ marginTop: '15px' }} className="mb-0">
                                            Courier executive is on its way to deliver your order at the address provided


                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>




        )
    }
}


export default TrackOrder;