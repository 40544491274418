import React, { Component } from "react"
import MetaTags from 'react-meta-tags';



class PrivacyPolicy extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)

    }

    render() {
        return (



            <div className="wrapper">
                <MetaTags>
                    <title>PrivacyPolicy</title>
                    <description></description>

                    {/* <meta id="description" name="mg-description" content="PrivacyPolicy In Shopperr." />
                    <meta id="keywords" property="mg-keyword" content="head,body,html,tags" /> */}

                </MetaTags>
                <div className="container">                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="font-weight-normal text-7 mb-2" style={{ textAlign: 'center', marginTop: '10px' }}>
                            <strong className="font-weight-extra-bold">Privacy Policy</strong>
                        </h2>

                        <p className="lead">
                            We Value Your Privacy
                        </p>
                        <p>
                            Shopperr.in (“Shopperr”, “Platform”) is operated and managed by Creationist Technovations Pvt Ltd. and its affiliates (“Company”, “we’, “us” or “ours”), a company incorporated under the laws of India, having its registered office at Electronic City, Gurugram. We deeply care about privacy, security and online safety of the members and users of our website.
                        </p>
                        <p>
                            The Company facilitates its members to source products through the Platform and resell them through their local offline business or Amazon and Shopify stores. The privacy policy applies to all the data collected during the use of the Platform. While we collect the data to continuously improve our services to you its security is one of our priorities. We are committed to safeguarding and ensuring the security of the information you provide on the Platform.
                        </p>
                        <p>
                            We aim to stay transparent about the data we collect about you and this privacy policy explains our information gathering and dissemination practices. This privacy policy applies when you use Shopperr.
                        </p>
                        <p className="lead">
                            CHANGES TO THE PRIVACY POLICY
                        </p>
                        <p>
                            We reserve the right to modify this privacy policy from time to time as per the applicable laws. You must check the most updated privacy policy. The current version of this privacy policy is posted here at Shopperr.
                        </p>
                        <div className="sticky-container">
                            <div className="row">
                                <div className="col-lg-8">

                                    <p className="lead">
                                        THE INFORMATION WE COLLECT
                                    </p>
                                    <p>
                                        We collect your information for our legitimate business interest and to provide our services. The rules regarding how we process your data, along with the purposes and scope of the processing vary depending on whether you are our registered member or visitor. We may collect and process the following information from you:
                                    </p>
                                    <ol style={{ color: '#252129' }}>
                                        <li>Personal data you directly provide us when you register as a member or purchase a subscription. The types of personal information you may provide include:</li>
                                        <ul>
                                            <li>Contact information (such as name, email address, phone number)</li>
                                            <li>Business information (such as retail site URL or marketplace name, GSTIN code, company name)</li>
                                            <li>Payment information (including credit/debit card information, bank account information, alternative payment information (Wire, PrePay, PayPal, etc.)</li>
                                            <li>Purchase information (purchased from Shopperr, delivery/shipping address)</li>
                                            <li>Account log-in credentials or unique identifiers (such as username and password)</li>
                                        </ul>
                                        <li>We log usage data when you visit or otherwise use Shopperr and process this information to improve our service and to ensure network and information security. This may include information like:</li>
                                        <ul>
                                            <li>Statistical data such as products searched, time spent</li>
                                            <li>Standard data collection methodologies like cookies. Cookies do not collect any personally identifiable information. These are very small files which only help our servers identify your device. This is required to provide you with customized information while you navigate the Platform and to improve your experience.</li>
                                            <li>We also use Cookies to analyse how Shopperr is used. Every time you visit the Platform, information is sent from your browser to our server log files. This includes time and date of your visit, pages you visited, IP addresses, browser information, URL of the site that referred you to the Platform. This information is used to impose additional security and site integrity. They also help us fight spam and phishing attacks.</li>
                                        </ul>
                                        <li>Third parties may provide us with information about you which can be processed in combination with the information we collect directly. This may be information that our logistics partners provide about your updated delivery address to correct our records or we may collect identity information which we use to help prevent and detect fraud.</li>
                                    </ol>

                                    <p className="lead">
                                        WHY AND HOW DO WE USE YOUR INFORMATION

                                    </p>
                                    <p>
                                        We use your information for legitimate business reasons and as described below:
                                    </p>
                                    <ul style={{ color: '#252129' }} >
                                        <li>Complete your registration, onboarding, account management on Shopperr</li>
                                        <li>Communication, service announcements, newsletter</li>
                                        <li>Perform our contract and fulfil the transaction</li>
                                        <li>We use it to provide support and assistance to you concerning our services</li>
                                        <li>We may also process it to send marketing and promotional material, special offers, related our services</li>
                                    </ul>
                                    <p className="lead">
                                        RETENTION PERIOD OF YOUR INFORMATION


                                    </p>
                                    <ul style={{ color: '#252129' }}>
                                        <li>Information about you we collect to engage in transactions and to process purchases on the Platform will be retained for the duration of the transaction or services period, or longer as necessary for record retention and legal compliance purposes.</li>
                                        <li>The information you provided during registration would be retained for as long as you maintain an active account on Shopperr.</li>
                                        <li>The information held by “session cookies” is temporary as they are automatically deleted when you close your browser. The “persistent cookies” can be manually deleted by you from your browser.</li>
                                        <li>The duration of holding your information also depends on the type of information we are holding and the purpose for which we need it like complying with our legal obligations.</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <p className="lead">
                            DISCLOSURE OF YOUR DATA

                        </p>
                        <p>
                            Please know that we do not sell, distribute, or give your personal information to any third party except with the ones we have a commercial relationship with or when it is required by law. We may be required to share your information to fulfil our legal obligations. In general, the third-party providers we use will only collect, use, and disclose your information to the extent necessary to allow them to perform the services they provide us. We may share personally identifiable information with our marketing partners so they may contact you about information and services that we feel you would benefit from. As we continue our efforts to enable more people with the opportunity to be a reseller, we may deem it necessary to share aggregated user statistics with prospective partners, advertisers and other third parties.
                        </p>

                        <p className="lead">
                            SECURITY

                        </p>
                        <p>
                            We take reasonable precautions and follow industry best practices to protect your personal information ensuring that it is not inappropriately lost, misused, accessed, disclosed, altered, or destroyed. All the credit card information stored in our systems is encrypted using a secure socket layer (SSL) in compliance with the (Indian) Information Technology Act, 2000, protecting it against unauthorized access.
                        </p>
                        <p className="lead">
                            YOUR RIGHTS

                        </p>
                        <p>
                            You have the right to stop using the platform at any time or to cancel your account. In case you do so we may continue to keep your information for reasons set out in this policy. You also have the right to get your information rectified in our records.
                        </p>


                        <p className="lead">
                            YOUR CONSENT

                        </p>
                        <p>
                            By using the site or by providing your information, you confirm that you have read and understood the privacy policy and voluntarily express consent to our data collection and its use in the manner as set out in the privacy policy.
                        </p>
                        <p className="lead">
                            GRIEVANCE OFFICER
                        </p>
                        <p>
                            If you have any questions, concerns or grievances please write to the grievance officer(# Email to be provided).
                        </p>
                    </div>
                </div>
                </div>
            </div>




        )
    }
}


export default PrivacyPolicy;