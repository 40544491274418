import React, { Component } from "react"
import Header from '../Header.js';
import Footer from '../Footer.js';
import AboutUs from './AboutUs.js';
import PrivacyPolicy from './PrivacyPolicy.js';
import TermsConditions from './TermsConditions.js';
import DeliveryAndShipping from './DeliveryAndShipping.js';
import ReturnPolicy from './ReturnPolicy.js';
import ContactUss from './ContactUss';
import TrackOrder from './TrackOrder';







class Pages extends Component {
    constructor(props) {
        super(props)

    }

    render() {
        return (

            <div>
                <div className="body" style={{ marginTop: '45.1875px', transition: 'margin 300ms ease 0s' }}>
                    <Header />
                    {(() => {



                        switch (this.props.match.params.pagename) {

                            case 'about-us':

                                return (

                                    <AboutUs />

                                )

                            case 'privacy-policy':

                                return (

                                    <PrivacyPolicy />

                                )

                            case 'terms-conditions':

                                return (

                                    <TermsConditions />

                                )

                            case 'delivery-shipping':

                                return (

                                    <DeliveryAndShipping />

                                )

                            case 'return-policy':

                                return (

                                    <ReturnPolicy />

                                )

                            case 'contact-us':

                                return (

                                    <ContactUss />

                                )
                            case 'track-order':

                                return (

                                    <TrackOrder />

                                )


                            default:

                                return (

                                    <div></div>

                                )

                        }



                    })()}


                    <Footer />
                </div>

            </div>




        )
    }
}


export default Pages;