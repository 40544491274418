import React, { Component } from "react"
import Header from '../Header.js';
import Footer from '../Footer.js';
import Computers_Accessories from './Computers_Accessories'
import Beauty_Accessories from './Beauty_Accessories'
import Home_Kitchen from './Home_Kitchen'
import Jewellery from './Jewellery'
import Home_Improvement from './Home_Improvement'
import Car_Motorbike from './Car_Motorbike'
import Clothing_Accessories from './Clothing_Accessories'
import Sports_Fitness from './Sports_Fitness'
class Categories extends Component {
    constructor(props) {
        super(props)

    }

    render() {
        return (

            <div>
                <div className="body" style={{ marginTop: '45.1875px', transition: 'margin 300ms ease 0s' }}>
                    <Header />
                    {(() => {



                        switch (this.props.match.params.categoriesname) {

                            case 'computers-accessories':

                                return (

                                    <Computers_Accessories />

                                )

                            case 'beauty-accessories':

                                return (
    
                                    <Beauty_Accessories />
    
                                )  
                                    
                                    
                            case 'home-kitchen':

                                return (

                                    <Home_Kitchen />

                                )

                            case 'jewellery':

                                return (

                                    <Jewellery />

                                )

                            case 'home-improvement':

                                return (

                                    <Home_Improvement />

                                )

                            case 'car-motorbike':

                                return (

                                    <Car_Motorbike />

                                )

                            case 'clothing-accessories':

                                return (

                                    <Clothing_Accessories />

                                )

                            case 'sports-fitness':

                                return (

                                    <Sports_Fitness />

                                )



                            default:

                                return (

                                    <div></div>

                                )

                        }
                    })()}
                    <Footer />
                </div>

            </div>

        )
    }
}


export default Categories;