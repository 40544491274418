import React from 'react'
import Clothing_Accessories1 from './Clothing_Accessories1'
import Clothing_Accessories2 from './Clothing_Accessories2'
import Clothing_Accessories3 from './Clothing_Accessories3'
import Clothing_Accessories4 from './Clothing_Accessories4'
export default function Clothing_Accessories() {
  return (
    <>
        <Clothing_Accessories1/>
        <Clothing_Accessories2/>
        <Clothing_Accessories3/>
        <Clothing_Accessories4/>
    </>
  )
}
