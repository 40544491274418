import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import SlickCarousel from "../components/SlickCarousel";
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import axios from "axios";
import { Link } from "react-router-dom";
import User from '../global/User';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import cf from '../global/CommonFunctions';
import Moment from 'react-moment';
import moment from 'moment';
import MetaTags from 'react-meta-tags';
// const initialState={
//   imgUrl:[],
//   name:"",
//   selliingPrice:"",
//   mrp:"",
//   sp:"",
//   vo : [],
//   description:"",
//   vd : [],
// }

let variantData = {};

const SingleProductDetail = ({ match }) => {
	const [product, setProduct] = useState({});
	const [variantOptions, setVariantOption] = useState([]);
	const [subscribed, setSubscribed] = useState(0);
	const [mQua,setMQua] = useState(1)
	const [price, setPrice] = useState({
		"airsC": 0,
		"factoryPrice": 0,
		"hundredPairsC": 0,
		"hundredPiece": 0,
		"hundredPseasC": 0,
		"mrp": 0,
		"price": 0,
		"priceWithoutGst": 0,
		"seasC": 0
	});
	const [isloaded, setLoaded] = useState(false);
	const [imgSize, setimgSize] = useState([]);
	const [cquantity, setQuantity] = useState(mQua);
	const [quantity, setVQuantity] = useState(0);
	const [csku, setCurrentSku] = useState(1);
	const [smath, setSMath] = useState("ex-china");
	const [slabPrices, setSlabPrices] = useState({
		"slabFour": 0,
		"slabOne": 0,
		"slabThree": 0,
		"slabTwo": 0
	});
	const [apiURL, setApiURL] = useState(process.env.REACT_APP_NODE_API_URL.trim() + "/"+ process.env.REACT_APP_NODE_API_PATH.trim().replace(/\/+/g,"\/"));
	const handleVariantOptionChange = (event) => {
		const updatedVariantOption = variantOptions.map((v, i) => {
			if (v.name === event.target.name) {
				const newObj = { ...v, defaultSelected: event.target.value };
				return { ...newObj };
			} else {
				return { ...v };
			}
		});
		setVariantOption(updatedVariantOption);
		let verientN = updatedVariantOption.reduce((t, c, i) => t + (i !== 0 ? '/' : '') + c.defaultSelected, "").replace(" ","")
		let variant = product.vd.find((x) => { 
			return x.varient.replace(/\s+/g,"") === verientN.replace(/\s+/g,"")
		});
		if(!variant) return;
		loadPrice(product._id, variant.sku);
		setVQuantity(variant.quantity);
		changeVariantImage(variant.varient_id);
	};

	//destruct state variables
	const {
		imgUrl = [],
		name,
		code,
		selliingPrice,
		mrp,
		sp,
		vo = [],
		description,
		vd = [],
	} = product;

	useEffect(() => {
		if (!isloaded)
			loadProducts();

		//orderDetails();

		window.scrollTo(0, 0)
	}, [match.params.handle]);

	const loadPrice = (pId, sku) => {
		setCurrentSku(sku)
		if (variantData[sku]) {
			setPrice(variantData[sku]);
			setSlabPrice(variantData[sku])
			return true;
		}
		axios
			.post(apiURL+"/getproductinfoByPId", { pId, sku, globalprice: 1 })
			.then((res) => {
				variantData[sku] = res.data.dropshipperPrice ;
				setPrice(res.data.dropshipperPrice);
				setSlabPrice(variantData[sku]);
				setLoaded(true)
			})
			.catch((err) => {
				console.log("error in fetching price", err);
			});
	};
	const setSlabPrice = (dataObj)=>{
		setSlabPrices({
			"slabFour": dataObj?.slabFour || 0,
			"slabOne": dataObj?.slabOne || 0 ,
			"slabThree": dataObj?.slabThree || 0,
			"slabTwo": dataObj?.slabTwo || 0
		})
	}
	const loadProducts = () => {
		axios
			.post(apiURL + "/getproduct", {
				key: process.env.REACT_APP_MOBILE_API_KEY.trim(),
				token: process.env.REACT_APP_MOBILE_API_TOKEN.trim(),
				handle: `${match.params.handle}`,
				merchantId: "607421f46b463720ea5bfea0",
			})
			.then((res) => {
				let size = res?.data?.data?.product?.imgUrl.length < 6 ? res?.data?.data?.product?.imgUrl.length : 6;
				let data = res?.data?.data?.product?.imgUrl.slice(0, size).map((item) => {
					return item
				});
				setimgSize(data);
				setVQuantity(res?.data?.data?.product?.quantity || 0);
				setProduct({ ...Object(res?.data?.data?.product) });
				setVariantOption(res?.data?.data?.product?.vo);
				loadPrice(res?.data?.data?.product?._id, res?.data?.data?.product?.code);
				getSubscribedProducts(res?.data?.data?.product?._id);
				setCurrentSku(res?.data?.data?.product?.vd.length > 0 ? res?.data?.data?.product?.vd?.[0]?.sku : res?.data?.data?.product?.code)
			})
			.catch((err) => {
				console.log("Error in Single Product APi", err);
			});
	};

	// const orderDetails = () => {
    //     let user = User.getUser()
    //     if (!user)
    //         return user.loginUser();;
    //     axios
    //     .post(apiURL+"/addtocart", {
    //         key: process.env.REACT_APP_MOBILE_API_KEY.trim(),
    //         token: process.env.REACT_APP_MOBILE_API_TOKEN.trim(),
    //         merchantId: user.id,
    //         source: "web",
    //         accessStatus: 0,
    //     })
    //     .then((res) => {
    //         let cartDetail = res.data.Response.data;

    //         //fire Event
    //         let event = new CustomEvent("updateCart", {detail:{...cartDetail} });
    //         document.dispatchEvent(event);

	// 		setSMath(cartDetail?.shipping);
    //     })
    //     .catch((err) => {
    //         console.log("Error in Single Product APi", err);
    //     });
    // }

	const addToCart = (e) => {
		e.preventDefault()
		let user = User.getUser()
		if (!user)
			return User.loginUser();
			
		let cartData = []
		axios
			.post(apiURL + "/addtocart", {
				key: process.env.REACT_APP_MOBILE_API_KEY.trim(),
				token: process.env.REACT_APP_MOBILE_API_TOKEN.trim(),
				merchantId: user.id,
				source: "web",
				accessStatus: 1,
				add:1,
				cartData: cartData.concat([{
					"productId": product._id,
					"sku": csku,
					"quantity": cquantity

				}])
			})
			.then((res) => {
				if(res.data.Response.status !== 200){
					return toast.error(res.data.Response.msg, {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}

				//fire Event
				let event = new CustomEvent("updateCart", {});
				document.dispatchEvent(event);

				toast.success(res.data.Response.msg, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				}); 
				if(e.target.classList.contains("buy-now")){
					console.log("hello  dfsdf ");
					updateShipping(smath);
					
					return false;
				}
			})
			.catch((err) => {
				console.log("Error in Single Product APi", err);
			});
	};
	const subscribeProduct = (id) => {
		let ele = document.getElementById("issubscribed")
		let issubscribed = ele ? ele.classList.contains("active") ? 1 : 0 : 0;
		if(subscribed){
			return;
		}
		setSubscribed(1)
		if (!User.getUser()) return;
		let search = {
			merchantId: User.getUser().id,
			ids: [id],
			unsubscribe: issubscribed,
			margin: 75,
			is_draft: 1,
			channel:"global"
		}
		axios.post(process.env.REACT_APP_NODE_API_URL.trim() + '/web/subscribeProduct', search)
			.then(result => {
				setSubscribed(0)
				addSpinner(document.getElementById("issubscribed"),0)
				if (result.data.error == 1) {
					return toast.error(result.data.message, {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}
				toast.success(result.data.message, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
				changeSubscribeState(!issubscribed)
			});
	}

	const getSubscribedProducts = (ids) => {
		if (!User.getUser()) return;
		let search = {
			merchantId: User.getUser().id,
			ids: [ids],
			getDetail: 1
		}
		axios.post(process.env.REACT_APP_NODE_API_URL.trim() + '/web/subscribeProduct', search)
			.then(result => {
				if (!result.data.data.length) return;
				changeSubscribeState(1)
				
			});
	}

	function addSpinner(el,remove){
		el.style.color = "";
		if(remove){
			el.classList.remove("far","fa-heart");
			el.classList.add("fas","fa-spinner","fa-spin");
		} else {
			el.classList.add("far","fa-heart");
			el.classList.remove("fas","fa-spinner","fa-spin");
			
		}	
	}
	function changeSubscribeState(issubscribed) {
		if (issubscribed) {
			document.getElementById("issubscribed").classList.add("active")
			document.getElementById("issubscribed").style.color = "red";
			document.getElementById("button-text").innerHTML = "UNSUBSCRIBE"
		} else {
			document.getElementById("issubscribed").classList.remove("active");
			document.getElementById("issubscribed").style.color = "";
			document.getElementById("button-text").innerHTML = "SUBSCRIBE"
		}
	}

	function updateQu(qu,sy){
        if(sy == "+"){
            qu = qu+mQua;
        }
        if(sy == "-"){
            qu = qu-mQua;
        }
        qu = qu > 0 ? qu : mQua;
        setQuantity(qu);
    }

	function changeVariantImage(id){
		let el = document.getElementsByClassName(id);
		if(el.length){
			el[0].click();
		}
	}
	const changeShippingMethod = (e) =>{
		setSMath(e.target.value)
	}

	const updateShipping = (shipping)=>{
        let user = User.getUser()
        if(!user){
        	return false;
        }
		axios
			.post(apiURL+"/updateShipping", {
				key: process.env.REACT_APP_MOBILE_API_KEY.trim(),
				token: process.env.REACT_APP_MOBILE_API_TOKEN.trim(),
				merchantId: user.id,
				source: "web",
				shipping
			})
			.then((res) => {
				window.location.href = "/shipping";
			})
			.catch((error) => {
				let errObj = error.response.data.Response?.data?.find(el=>el.error);
                let mes = errObj?.msg || error.response.data.Response.msg;
                return toast.error(mes, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
			});
    }
	const calculateShippingTime = (SM)=>{
		let min = cf.shippingDTime?.[SM].min || 30;
        let max = cf.shippingDTime?.[SM].max || 45;
        let minDate = new Date(new Date().getTime() + 24*60*60*1000*min).toISOString().split("T")[0].split("-");
        let maxDate = new Date(new Date().getTime() + 24*60*60*1000*max).toISOString().split("T")[0].split("-");
		return [minDate,maxDate];
	}
	return (
		<div >
			
			<MetaTags>
                        <title>Buy {name}  Online - shopperrglobal Product</title>
						 
						<description>Buy {name}  Online - shopperrglobal Product  Online: Shop from wide range of {name} Online in India at best prices</description>

                                        

                    </MetaTags>
			<div className="body" style={{ transition: 'margin 300ms ease 0s' }}>
				<Header />
			</div>
			<div role="main" style={!isloaded ? { height: "600px" } : {}} className={!isloaded ? "main shop  masonry-loader masonry-loader-showing" : "main shop masonry-loader masonry-loader-loaded"}>
				<div className="container">
					<div className="row">
						<div className="col">
							<ul className="breadcrumb breadcrumb-style-2 d-block">
								<li>
									<Link
										to="/"
										className="text-decoration-none"
									>
										Home
									</Link>
								</li>
								<li>
									<Link
										to="/"
										className="text-decoration-none"
									>
										Category
									</Link>
								</li>
								<li>{name}</li>
							</ul>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-12 clearfix">
							<div className="thumb-gallery-wrapper">
								<SlickCarousel images={imgUrl} />
								{/* <Carousel showArrows={true}  autoPlay infiniteLoop>
               {imgUrl.map(({src})=>( 
                 <div key={src}>
                <img src={src} /> 
            </div>
            ))}
               
            </Carousel> */}
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12">
							<div className="summary entry-summary position-relative">
								<h1 className="mb-0 font-weight-bold text-7 pb-15">
									{name}
								</h1>
								{/*  <div className="pb-0 clearfix d-flex align-items-center">
                    <div title="Rated 3 out of 5" className="float-left">
                      <input type="text" className="d-none" defaultValue={3} title data-plugin-star-rating data-plugin-options="{'displayOnly': true, 'color': 'primary', 'size':'xs'}" />
                    </div>
                   <div className="review-num">
                      <a href="#description" className="text-decoration-none text-color-default text-color-hover-primary" data-hash data-hash-offset={75} data-hash-trigger-click=".nav-link-reviews" data-hash-trigger-click-delay={1000}>
                        <span className="count text-color-inherit" itemProp="ratingCount">(2</span> reviews)
                      </a>
               </div> 
                  </div> */}
								<p className="text-light1 mb-0">
									<span>Your Price:</span>
								</p>

								{/* <p className="price mb-1">
									<span className="sale text-color-dark1">
										{cf.getPriceWithCurrency(new Number(
											price.priceWithoutGst
										).toFixed(2))}
									</span> */}
									{/* <span className="sale text-color-dark1">-</span>
									<span className="sale text-color-dark1">₹ 300</span>
									<span className="actualPrice amount">
										{" "}
										₹
										{Math.ceil(
											new Number(
												new Number(price.price) * 1.55
											)
										).toFixed(2)}
									</span>
									<img src="https://cdn.shopify.com/s/files/1/0317/5999/0924/files/Verified_256x256_crop_center.png?v=1613570156" style={{ height: "36px", paddingLeft: "10px", marginTop: "-5px", order: "3" }} /> */}
								{/* </p> */}

								<div id="module_price" style={{marginBottom: "12px", marginTop: "12px"}}>
									<div className="widget-main-action">
										<div className="ma-price-wrap">
										<ul id="ladderPrice" className="ma-ladder-price ma-ladder-price-count-4 util-clearfix">
											<li data-role="ladder-price-item" className="ma-ladder-price-item" style={{backgroundColor: "rgb(255, 255, 255)"}}>
												<span className="ma-quantity-range" title="1 - 5 PIECES" data-spm-anchor-id="a2700.details.0.i24.2fc44e12HPmGAH">1 - 5 PIECES</span>
												<div className="ma-spec-price ma-price-promotion" title={new Number(slabPrices?.slabOne).toFixed(2)}><span className="pre-inquiry-price">${new Number(slabPrices?.slabOne).toFixed(2)}</span></div>
												<div className="ma-spec-price ma-price-original"></div>
											</li>
											<li data-role="ladder-price-item" className="ma-ladder-price-item" style={{backgroundColor: "rgb(255, 255, 255)"}}>
												<span className="ma-quantity-range" title="6 - 20 PIECES">6 - 20 PIECES</span>
												<div className="ma-spec-price" title={cf.getPriceWithCurrency(new Number(slabPrices?.slabTwo).toFixed(2))}><span className="pre-inquiry-price">${new Number(slabPrices?.slabTwo).toFixed(2)}</span></div>
												<div className="ma-spec-price ma-price-original"></div>
											</li>
											<li data-role="ladder-price-item" className="ma-ladder-price-item" style={{backgroundColor: "rgb(255, 255, 255)"}}>
												<span className="ma-quantity-range" title="21 - 100 PIECES">21 - 100 PIECES</span>
												<div className="ma-spec-price" title={cf.getPriceWithCurrency(new Number(slabPrices?.slabThree).toFixed(2))}><span className="pre-inquiry-price">${new Number(slabPrices?.slabThree).toFixed(2)}</span></div>
												<div className="ma-spec-price ma-price-original"></div>
											</li>
											<li data-role="ladder-price-item" className="ma-ladder-price-item" style={{backgroundColor: "rgb(255, 255, 255)"}}>
												<span className="ma-quantity-range" title=">=100 PIECES"> &gt;=100 PIECES</span>
												<div className="ma-spec-price" title={cf.getPriceWithCurrency(new Number(slabPrices?.slabFour).toFixed(2))}><span className="pre-inquiry-price">${new Number(slabPrices?.slabFour).toFixed(2)}</span></div>
												<div className="ma-spec-price ma-price-original"></div>
											</li>
										</ul>
										</div>
									</div>
								</div>
								
								{/* <p
									className="price"
									style={{ fontSize: "20px" }}
								>
									Your Profit Margin : &nbsp;
									<b>
										₹
										{new Number(
											Math.ceil(
												new Number(price.price) * 1.55
											) - price.price
										).toFixed(2)}
									</b>{" "}
									<b>
										{" "}
										&nbsp;(
										{Math.floor(
											((new Number(price.price) * 1.55 -
												price.price) *
												100) /
											(new Number(price.price) * 1.55)
										)}
										%)
									</b>
								</p> */}
								{/* <p className="text-light1 mb-0">
									<span>MOQ: 100 Pieces</span>
								</p> */}
								<div>
									<table className="table table-bordered w-100 lt-blue fnt-18">
										<tbody>
											{/* <tr>
												<td scope="col">GST</td>
												<td scope="col">
													₹ {price.gstOnProduct} (
													{price.gstPer}%)
												</td>
											</tr>
											<tr>
												<td scope="col">
													Price Including GST
												</td>
												<td scope="col">
													<b>
														₹{" "}
														{new Number(
															price.price
														).toFixed(2)}
													</b>
												</td>
											</tr> */}
											{/*<tr>
												<td scope="col">MRP</td>
												<td scope="col">
													<b>
														{cf.getPriceWithCurrency(new Number(
															price.mrp
														).toFixed(2))}
													</b>
												</td>
											</tr>*/}
											{/* <tr>
												<td scope="col">
													Shipping Time
												</td>
												<td scope="col">{cf.shippingDTime[smath].min || 30}~{cf.shippingDTime[smath].max || 45} days</td>
											</tr> */}
										</tbody>
									</table>
								</div>


								{/* <div className="row my-3">
									<div className="col">
										<div className="pricing-block">
											<div className="row">
												<div className="col-lg-7" style={{ padding: "4px", color: "#414141", fontSize: "16px" }}>
													<div className="row">
														<div className="col-lg-6">100 Pieces</div> <div className="col-lg-6">{cf.getPriceWithCurrency(price.hundredPiece)}</div>
														<div className="col-lg-6">Shipping Charges</div> <div className="col-lg-6"><b>{cf.getPriceWithCurrency(price[smath]?.hundredP || 0)}</b></div>
														<div className="col-lg-6">Total Amount</div> <div className="col-lg-6"><b>{cf.getPriceWithCurrency(Math.ceil(parseInt(price.hundredPiece)+parseInt(price[smath]?.hundredP || 0)).toFixed(2))}</b></div>
														<div className="col-lg-6">Processing Time</div> <div className="col-lg-6">1 day</div>
													</div>
												</div>
												<div className="col-lg-5" style={{ textAlign: "left" }}>
													{/* <a href="#" onClick={changeShippingMethod}>&raquo; Change Shipping Method</a> 
													<select
														onChange={changeShippingMethod}
														className="form-control text-1 h-auto py-2"
														defaultValue={smath}
													>
														<option value="sea">Sea Shipping Method</option>
														<option value="air">Air Shipping Method</option>
														<option value="ex-china">Ex-China</option>
													</select>
													<button className="btn btn-dark bg-color-hover-primary border-color-hover-primary mt-2 buy-now" onClick={addToCart}>Start Order</button>
												</div>
											</div>
										</div>
									</div>
								</div>  */}
								<ul className="list list-unstyled fnt-16 mt-2">
									{/* <li className="mb-0">
										AVAILABILITY:{" "}
										{quantity > 0 ? (
											<strong style={{ color: "green" }}>
												AVAILABLE
											</strong>
										) : (
											<strong style={{ color: "red" }}>
												NOT AVAILABLE
											</strong>
										)}
									</li> */}
									<li className="mb-0">
										SKU:{" "}
										<strong className="text-color-dark">
											{csku}
										</strong>
									</li>
									{/* <li className="mb-0">Samples: {cf.getPriceWithCurrency(new Number(
											price.priceWithoutGst
										).toFixed(2))}/Piece | 1 Piece (Min. Order) | <a href="#" className="buy-now" onClick={addToCart}>Buy Samples</a></li> */}
								</ul>
								<form
									encType="multipart/form-data"
									method="post"
									className="cart"
								>
									<table
										className="table table-borderless"
										style={{ maxWidth: "300px" }}
									>
										<tbody>
											{variantOptions.map(
												({
													name,
													values,
													defaultSelected,
												}) => (
													<tr key={name}>
														<td className="align-middle fnt-16  px-0 py-2">
															{name}
														</td>
														<td className="px-0 py-2 fnt-16">
															<div className="custom-select-1">
																<select
																	name={name}
																	defaultValue={
																		defaultSelected
																	}
																	onChange={
																		handleVariantOptionChange
																	}
																	className="form-control text-1 h-auto py-2"
																>
																	{values.map(
																		(v) => (
																			<option
																				key={
																					v
																				}
																				value={
																					v
																				}
																			>
																				{
																					v
																				}
																			</option>
																		)
																	)}
																</select>
															</div>
														</td>
													</tr>
												)
											)}
										</tbody>
									</table>
									<hr />
									{ (cquantity <= quantity || 1) && 
										<>
										<div className="quantity quantity-lg" >
											<input
												type="button"
												className="minus text-color-hover-light bg-color-hover-primary border-color-hover-primary"
												defaultValue="-"
												onClick={(e)=>{updateQu(cquantity,"-"); }}
											/>
											<input
												type="text"
												className="input-text qty text"
												title="Qty"
												defaultValue={cquantity}
												name="quantity"
												min={mQua}
												step={mQua}
												onInput={(e)=>{setQuantity(e.target.value); }}
											/>
											<input
												type="button"
												className="plus text-color-hover-light bg-color-hover-primary border-color-hover-primary"
												defaultValue="+"
												onClick={(e)=>{updateQu(cquantity,"+"); }}
											/>
										</div>
										
										<button
											className="btn btn-dark btn-modern text-uppercase bg-color-hover-primary border-color-hover-primary"
											onClick={addToCart}
										>
											+ Add to cart
										</button>
									</>
									}
									<hr />
								</form>
								<div style={{ maxWidth: "400px", minWidth: "275px" }}><img style={{ width: "100%" }} alt="Checkout Secure" src="//cdn.shopify.com/s/files/1/0317/5999/0924/t/38/assets/checkout_icon.png?v=17253768708377827341" className="no-border checkout-img checkout-img" /></div>
								<div className="d-flex align-items-center">
									<ul className="social-icons social-icons-medium social-icons-clean-with-border social-icons-clean-with-border-border-grey social-icons-clean-with-border-icon-dark mr-3 mb-0">
										{/* Facebook */}
										<li className="social-icons-facebook">
											<a
												href={`http://www.facebook.com/sharer.php?u=${window.location.href}`}
												target="_blank"
												data-toggle="tooltip"
												data-placement="top"
												title={name}
											>
												<i className="fab fa-facebook-f" />
											</a>
										</li>
										{/* Google+ */}
										<li className="social-icons-googleplus">
											<a
												href={`https://plus.google.com/share?url=${window.location.href}`}
												target="_blank"
												data-toggle="tooltip"
												data-placement="top"
												title={name}
											>
												<i className="fab fa-google-plus-g" />
											</a>
										</li>
										{/* Twitter */}
										<li className="social-icons-twitter">
											<a
												href={`https://twitter.com/share?url=${window.location.href}`}
												target="_blank"
												data-toggle="tooltip"
												data-placement="top"
												title={name}
											>
												<i className="fab fa-twitter" />
											</a>
										</li>
										{/* Email */}
										{/* <li className="social-icons-email">
											<a
												href="mailto:?Subject=Share This Page&Body=I%20saw%20this%20and%20thought%20of%20you!%20 https://www.okler.net"
												data-toggle="tooltip"
												data-placement="top"
												title="Share By Email"
											>
												<i className="far fa-envelope" />
											</a>
										</li> */}
									</ul>
									{
										User.getUser() ?
											<a
												href="" onClick={(e)=>{ e.preventDefault(); subscribeProduct(product._id)}}
												className="d-flex align-items-center text-decoration-none text-color-dark text-color-hover-primary font-weight-semibold text-2"
											>
												<i className="far fa-heart mr-1 " id="issubscribed" /><span id="button-text"> SUBSCRIBE </span>
											</a> : ""
									}
								</div>
							</div>
						</div>
					</div>
					<div className="row mb-4 mt-5">
						<div className="col-lg-12 mobile-hide">
							<ul className="nav nav-tabs" id="myTab" role="tablist">
								<li className="nav-item">
									<a className="nav-link active" id="desc-tab" data-toggle="tab" href="#desc" role="tab" aria-controls="desc" aria-selected="true">Description</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" id="shipping-tab" data-toggle="tab" href="#shipping" role="tab" aria-controls="shipping" aria-selected="false">Shipping</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" id="" data-toggle="tab" href="#delivery" role="tab" aria-controls="delivery" aria-selected="false">Delivery & Returns</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" id="whyus-tab" data-toggle="tab" href="#whyus" role="tab" aria-controls="whyus" aria-selected="false">Why buy from us</a>
								</li>
							</ul>
							<div className="tab-content" id="myTabContent">
								<div className="tab-pane fade show active" id="desc" role="tabpanel" aria-labelledby="desc-tab">
									<p dangerouslySetInnerHTML={{ __html: description }}></p>
									<div className="row">
										<div className="col">
											<div className="blog-posts">
												<div className="row">
													{
														imgSize.map(({ src }, i) => (
															<div className="col-md-6" key={i}>
																<article className="post post-medium border-0 pb-0 mb-5">
																	<div className="post-image">
																		<a href="#!">
																			<img src={src} style={{ width: '100%', height: 'auto' }} />
																		</a>
																	</div>

																</article>
															</div>
														))}
												</div>
											</div>
										</div>
									</div>
								</div>
								
								<div className="tab-pane fade" id="shipping" role="tabpanel" aria-labelledby="shipping-tab">
									<div className="grid">
										<div className="grid__item large--one-fifth small--one-whole margin--bottom-15">
											<b>Shipping</b>
										</div>
										<div className="grid__item large--four-fifths small--one-whole margin--bottom-15">
											This item Ships to&nbsp;<span className="countryName">India</span> <span className="flagImg f32"><i className="flag-icon flag-icon-in"></i></span>&nbsp;
										</div>

										<div className="grid__item large--one-fifth small--one-whole margin--bottom-15">
											<b>Delivery</b>
										</div>
										<div className="grid__item large--four-fifths small--one-whole margin--bottom-15">
											<p style={{ margin: "0" }}>Estimated between&nbsp;
												<span className="date_one_ship" data-date="30"> 
													<Moment format="MMM Do YY">
														{moment().add(cf.shippingDTime[smath].min, 'd')}
													</Moment>
												</span> and &nbsp;
												<span className="date_two_ship" data-date="50">
													<Moment format="MMM Do YY">
														{moment().add(cf.shippingDTime[smath].max, 'd')}
													</Moment>
												</span> 
											</p>
											<em style={{ fontSize: "15px" }}>Will usually ship within {cf.shippingDTime[smath].min || 30}~{cf.shippingDTime[smath].max || 45} business day.</em>
										</div>
										<div className="grid__item large--four-fifths small--one-whole margin--bottom-15">
										</div>
									</div>
								</div>
								<div className="tab-pane fade" id="delivery" role="tabpanel" aria-labelledby="delivery-tab">
									<table width="100%">
										<tbody>
											<tr>
												<td><img src="https://static-sellercentral.shopperr.in/images/supplier/607e8d9394c4c6938baa94cd/2021/81/1631177349277HMgtkc.png" /></td>
												<td> &nbsp; &nbsp;</td>
												<td>
													<div><meta charSet="utf-8" /></div>
													<p><b>Please allow 30-50 days for your order to arrive. </b>We source products from all over the world to bring you epic offers and the lowest prices. This means sometimes you have to wait a little longer to get your order but it's always worth it!</p>
												</td>
											</tr>
											<tr>
												<td><img src="https://static-sellercentral.shopperr.in/images/supplier/607e8d9394c4c6938baa94cd/2021/81/1631177464741F2XPoF.png" /></td>
												<td />
												<td>
													<div><meta charSet="utf-8" /></div>
													<p><strong>Returns are easy</strong>, simply <a contact-us="true" target="_blank" title="Contact Us">contact us</a> for a returns number and send your item to our returns centre for fast processing. We'll get you a replacement or refund in a snap!</p>
												</td>
											</tr>
										</tbody>
									</table>
								</div>

								<div className="tab-pane fade" id="whyus" role="tabpanel" aria-labelledby="whyus-tab">
									<strong>Here are 5 more great reasons to buy from us:</strong><p />
									<table width="100%">
										<tbody>
											<tr>
												<td><img src="https://static-sellercentral.shopperr.in/images/supplier/607e8d9394c4c6938baa94cd/2021/81/1631177417921xoZB5x.png" alt="" /></td>
												<td>&nbsp; &nbsp; </td>
												<td>
													<div><meta charSet="utf-8" /></div>
													<p><strong>We Put Our Customers First</strong> to us. If it doesn't fit, it breaks, you've changed your mind or for no reason whatsoever simply send it back to us and we'll cheerfully refund you every cent.</p>
												</td>
											</tr>
											<tr>
												<td><img src="https://static-sellercentral.shopperr.in/images/supplier/607e8d9394c4c6938baa94cd/2021/81/16311775238310Q0SzM.png" alt="" /></td>
												<td />
												<td>
													<div><meta charSet="utf-8" /></div>
													<p><strong>Returns are easy</strong>, simply <a href="/contact-us%22" target="_blank" title="Contact Us">contact us</a> for a returns number and send your item to our returns centre for fast processing. We'll get you a replacement or refund in a snap!</p>
												</td>
											</tr>
											<tr>
												<td><img src="https://static-sellercentral.shopperr.in/images/supplier/607e8d9394c4c6938baa94cd/2021/81/1631177570596sQ2eIo.png" alt="" /></td>
												<td />
												<td>
													<div><meta charSet="utf-8" /></div>
													<p><strong>In the unlikely event that you find your item cheaper at another online store</strong>, just let us know and we'll beat the competitor's pricing hands-down.</p>
												</td>
											</tr>
											<tr>
												<td><img src="https://static-sellercentral.shopperr.in/images/supplier/607e8d9394c4c6938baa94cd/2021/81/1631177601690bXximr.png" alt="" /></td>
												<td />
												<td>
													<div><meta charSet="utf-8" /></div>
													<p><strong>We insist that you love everything you buy from us</strong>. If you're unhappy for any reason whatsoever, just let us know and we'll bend over backwards to make things right again.</p>
												</td>
											</tr>
											<tr>
												<td><img src="https://static-sellercentral.shopperr.in/images/supplier/607e8d9394c4c6938baa94cd/2021/81/1631177623902FFcybA.png" alt="" /></td>
												<td />
												<td>
													<div><meta charSet="utf-8" /></div>
													<strong>Ordering from Shopperr is 100% safe and secure</strong> so you can rest easy. Your personal details are never shared, sold or rented to anyone either.</td>
											</tr>
										</tbody>
									</table>
									<p />
									<p style={{ paddingLeft: '30px' }} />
								</div>
							</div>
						</div>

						{/* mobile tabs starts */}
						<div className="col-lg-12 desktop-hide">
							<div className="bs-example">
								<div className="accordion" id="accordionExample">
									<div className="card tabIcon">
										<div className="card-header" id="headingOne">
											<a href="#" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne">Description</a>
										</div>
										<div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
											<div className="card-body">
												<p dangerouslySetInnerHTML={{ __html: description }}></p>
												<div className="row">
													<div className="col">
														<div className="blog-posts">
															<div className="row">
																{
																	imgSize.map(({ src }, i) => (

																		<div className="col-md-6" key={i}>
																			<article className="post post-medium border-0 pb-0 mb-5">
																				<div className="post-image">
																					<a href="#!">
																						<img src={src} style={{ width: '100%', height: 'auto' }} />
																					</a>
																				</div>

																			</article>
																		</div>
																	))}
															</div>
														</div>
													</div>
												</div>

											</div>
										</div>
									</div>

									<div className="card tabIcon">
										<div className="card-header" id="headingThree">
											<a href="#" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree">Shipping</a>
										</div>
										<div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
											<div className="card-body">
												<div className="grid">
													<div className="grid__item large--one-fifth small--one-whole margin--bottom-15">
														<b>Shipping</b>
													</div>
													<div className="grid__item large--four-fifths small--one-whole margin--bottom-15">
														<span className="flagImg f32"><i className="flag-icon flag-icon-in" /></span>&nbsp;This item Ships to&nbsp;<span className="countryName">India</span>
													</div>
													<div className="grid__item large--one-fifth small--one-whole margin--bottom-15">
														<b>Delivery</b>
													</div>
													<div className="grid__item large--four-fifths small--one-whole margin--bottom-15">
														<p style={{ margin: 0 }}>Estimated between&nbsp;<span className="date_one_ship" data-date={30}>WED Oct 13</span> and <span className="date_two_ship" data-date={50}>FRI Nov 05</span></p>
														<em style={{ fontSize: '15px' }}>Will usually ship within 2 business day.</em>
													</div>
													<div className="grid__item large--four-fifths small--one-whole margin--bottom-15">
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="card tabIcon">
										<div className="card-header" id="headingFour">
											<a href="#" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour">Delivery & Returns</a>
										</div>
										<div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
											<div className="card-body">
												<table width="100%">
													<tbody>
														<tr>
															<td><img src="https://static-sellercentral.shopperr.in/images/supplier/607e8d9394c4c6938baa94cd/2021/81/1631177349277HMgtkc.png" /></td>
															<td> &nbsp; &nbsp;</td>
															<td>
																<div><meta charSet="utf-8" /></div>
																<p><b>Please allow 30-50 days for your order to arrive. </b>We source products from all over the world to bring you epic offers and the lowest prices. This means sometimes you have to wait a little longer to get your order but it's always worth it!</p>
															</td>
														</tr>
														<tr>
															<td><img src="https://static-sellercentral.shopperr.in/images/supplier/607e8d9394c4c6938baa94cd/2021/81/1631177464741F2XPoF.png" /></td>
															<td />
															<td>
																<div><meta charSet="utf-8" /></div>
																<p><strong>Returns are easy</strong>, simply <a contact-us="true" target="_blank" title="Contact Us">contact us</a> for a returns number and send your item to our returns centre for fast processing. We'll get you a replacement or refund in a snap!</p>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<div className="card tabIcon">
										<div className="card-header" id="headingFive">
											<a href="#" className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive">
												Why buy from us</a>
										</div>
										<div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
											<div className="card-body">
												<strong>Here are 5 more great reasons to buy from us:</strong><p />
												<table width="100%">
													<tbody>
														<tr>
															<td><img src="https://static-sellercentral.shopperr.in/images/supplier/607e8d9394c4c6938baa94cd/2021/81/1631177417921xoZB5x.png" alt="" /></td>
															<td>&nbsp; &nbsp; </td>
															<td>
																<div><meta charSet="utf-8" /></div>
																<p><strong>We Put Our Customers First</strong> to us. If it doesn't fit, it breaks, you've changed your mind or for no reason whatsoever simply send it back to us and we'll cheerfully refund you every cent.</p>
															</td>
														</tr>
														<tr>
															<td><img src="https://static-sellercentral.shopperr.in/images/supplier/607e8d9394c4c6938baa94cd/2021/81/16311775238310Q0SzM.png" alt="" /></td>
															<td />
															<td>
																<div><meta charSet="utf-8" /></div>
																<p><strong>Returns are easy</strong>, simply <a href="/contact-us%22" target="_blank" title="Contact Us">contact us</a> for a returns number and send your item to our returns centre for fast processing. We'll get you a replacement or refund in a snap!</p>
															</td>
														</tr>
														<tr>
															<td><img src="https://static-sellercentral.shopperr.in/images/supplier/607e8d9394c4c6938baa94cd/2021/81/1631177570596sQ2eIo.png" alt="" /></td>
															<td />
															<td>
																<div><meta charSet="utf-8" /></div>
																<p><strong>In the unlikely event that you find your item cheaper at another online store</strong>, just let us know and we'll beat the competitor's pricing hands-down.</p>
															</td>
														</tr>
														<tr>
															<td><img src="https://static-sellercentral.shopperr.in/images/supplier/607e8d9394c4c6938baa94cd/2021/81/1631177601690bXximr.png" alt="" /></td>
															<td />
															<td>
																<div><meta charSet="utf-8" /></div>
																<p><strong>We insist that you love everything you buy from us</strong>. If you're unhappy for any reason whatsoever, just let us know and we'll bend over backwards to make things right again.</p>
															</td>
														</tr>
														<tr>
															<td><img src="https://static-sellercentral.shopperr.in/images/supplier/607e8d9394c4c6938baa94cd/2021/81/1631177623902FFcybA.png" alt="" /></td>
															<td />
															<td>
																<div><meta charSet="utf-8" /></div>
																<strong>Ordering from Shopperr is 100% safe and secure</strong> so you can rest easy. Your personal details are never shared, sold or rented to anyone either.</td>
														</tr>
													</tbody>
												</table>
												<p />
												<p style={{ paddingLeft: '30px' }} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* mobile tabs ends */}

          </div>
		  		<div className="row">
					<div className="col-lg-12">
							<div className="card mb-4" style={{border: "1px solid #dee2e6"}}>
								<div className="card-body">
									<div className="row">
										<div className="col">
											<form className="contact-form" action="php/contact-form.php" method="POST" noValidate="noValidate">
												<div className="row form-group">
													<label className="col-sm-2 col-form-label text-right">To:</label>
													<div className="col-sm-10">
														<input type="text" readOnly className="form-control-plaintext" id="staticEmail" defaultValue="Mr. Ravi Chandran" />
													</div>
												</div>
												<div className="row form-group">
													<label className="col-sm-2 col-form-label text-right">*Message:</label>
													<div className="col-sm-10">
														<textarea maxLength="5000" data-msg-required="Please enter your message." rows="8" className="form-control text-3 h-auto py-2" name="message" required=""></textarea>
													</div>
												</div>
												<div className="row form-group">
													<label className="col-sm-2 col-form-label text-right">Quantity:</label>
													<div className="col-sm-5">
														<input type="text" defaultValue="" data-msg-required="Please enter your name." maxLength="100" className="form-control text-3 h-auto py-2" name="name" required="" />
													</div>
													<div className="col-sm-5 custom-select-1">
														<select className="form-select form-control h-auto py-2" data-msg-required="Please select a city." name="city" required="">
															<option value="">Choose...</option>
															<option value="1">1</option>
															<option value="2">2</option>
															<option value="3">3</option>
														</select>
													</div>
												</div>												
												<div className="row form-group">
													<div className="col-sm-2"></div>
													<div className="col-sm-10">
														<div className="form-check">
															<input className="form-check-input" type="checkbox" defaultValue="" name="agree" id="tabContent9Checkbox" data-msg-required="You must agree before submiting." required="" />
															<label className="form-check-label" htmlFor="tabContent9Checkbox">
																Recommend matching sippliers if this supplier doesn't contact me on Message Center within 24 hours. <a href="#">Request for Quotation</a>
															</label>
														</div>
													</div>
												</div>
												<div className="row form-group">
													<div className="col-sm-2"></div>
													<div className="col-sm-10">
														<div className="form-check">
															<input className="form-check-input" type="checkbox" defaultValue="" name="agree" id="tabContent9Checkbox" data-msg-required="You must agree before submiting." required="" />
															<label className="form-check-label" htmlFor="tabContent9Checkbox">
																I agree to share my <b>Business Card</b> to the supplier
															</label>
														</div>
													</div>
												</div>
												<div className="row form-group">
												<div className="col-sm-2"></div>
													<div className="col-sm-10">
														<input type="submit" value="Send" className="btn btn-primary" data-loading-text="Loading..." />
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
						</div>
				</div>
				<div className="bounce-loader"><div className="bounce1"></div><div className="bounce2"></div><div className="bounce3"></div></div>
			</div>
			<Footer />
		</div>
	);
};

export default SingleProductDetail;
      