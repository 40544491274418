import React from 'react'
import Sports_Fitness1 from './Sports_Fitness1'
import Sports_Fitness2 from './Sports_Fitness2'
import Sports_Fitness3 from './Sports_Fitness3'
import Sports_Fitness4 from './Sports_Fitness4'
export default function Sports_Fitness() {
  return (
    <>
        <Sports_Fitness1/>
        <Sports_Fitness2/>
        <Sports_Fitness3/>
        <Sports_Fitness4/>
    </>
  )
}
