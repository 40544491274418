import React, { Component } from "react"
import OrderDetails from './OrderDetails.js';
import User from '../../global/User';
import axios from "axios";

export default class CheckoutDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cartDetail: null,
            addresses:{},
            address:{

            },
            sa:{

            },
            addressId:"",
            states:[]
        }
        this.user = User.getUser()
        if (!this.user)
            window.location.href = '/';
        this.processedToCheckout = this.processedToCheckout.bind(this);
        this.selectAddress = this.selectAddress.bind(this)
        this.requiredFields = ['firstName','lastName','company','address1','city','zip','contact_number','email','s-firstName','s-lastName','s-address1','s-city','s-zip','s-contact_number'];
        this.apiUrl = process.env.REACT_APP_NODE_API_URL.trim() + "/"+ process.env.REACT_APP_NODE_API_PATH.trim().replace(/\/+/g,"\/");
    }
    componentDidMount() {
        this.getAllAddress()
        this.getState();
    }

    selectAddress(id){
        this.setState({addressId:id});
        this.setState({address:this.state.addresses[id]})
        this.setState({sa:this.state.addresses[id]?.sa ?? this.state.addresses[id] })
    }
    async processedToCheckout(e){
        e.preventDefault();
        let fromData = Array.from(new FormData(e.target));
        document.querySelectorAll("input:required.error").forEach(el=>el.classList.remove("error"))
        let invalidField = fromData.filter(el=>{
            if(el[0].match(/^s-/g) && !document.querySelector("[name=shipAddress]").checked){
                return false;
            }
            if((!el[1] || (el[1] && el[1].length < 2)) && this.requiredFields.includes(el[0])){
                let ele = document.querySelector("[name="+el[0]+"]");
                if(ele){
                    ele.classList.add("error");
                }
                return true;
            }
            return false;
        })
        let address = {};
        let sAddress = {};
        let orderNote = "";
        if(invalidField.length){
            return false;
        }
        fromData.forEach(el=>{
            if(el[0] === "o-note"){
                orderNote = el[1];
                return;
            }
            if(el[0].match(/^s-/g)){
                sAddress[el[0].replace(/^s-/g,"")] = el[1];
                return;
            }
            address[el[0]] = el[1];
        })
        address['is_default'] = 1;
        address['business_type'] = "Retail";
        address['address_title'] = address['company'];
        address['is_default'] = 1;
        if(!document.querySelector("[name=shipAddress]").checked){
            sAddress = {};
        }
        let aRes = await this.saveAddress({...address,sa:sAddress});
        let cRes = await this.checkoutOrder(aRes.data.Response.data.addressId,1,{
            orderNote,
            returnUrl:window.location.origin+'/order-complete',
            discountCode:this.props.couponCode
        })
        let url = cRes.data.Response.data.paymentObj.checkoutAction;
        delete cRes.data.Response.data.paymentObj.checkoutAction;
        this.postDataRedirect(url,Object.entries(cRes.data.Response.data.paymentObj))
    }

    saveAddress(address={}){
        return axios.post(this.apiUrl + "/user-addresses", {
            key: process.env.REACT_APP_MOBILE_API_KEY.trim(),
            token: process.env.REACT_APP_MOBILE_API_TOKEN.trim(),
            merchantId: this.user.id,
            source: "web",
            save : 1,
            addressId:this.state.addressId,
            address:address
        })
    }

    getAllAddress(){
        axios.post(this.apiUrl + "/user-addresses", {
            key: process.env.REACT_APP_MOBILE_API_KEY.trim(),
            token: process.env.REACT_APP_MOBILE_API_TOKEN.trim(),
            merchantId: this.user.id,
            source: "web",
            save : 0,
        }).then(res=>{
            if(res.data.Response.status !== 200){
                return false;
            }
            let addresses = {};
            res.data.Response.data.forEach(el=>{
                addresses[el._id] = el;
            })
            this.setState({addresses})
        })
    }

    checkoutOrder(addressId,isconfirm=0,params={}){
        return axios.post(this.apiUrl + "/checkout", {
            key: process.env.REACT_APP_MOBILE_API_KEY.trim(),
            token: process.env.REACT_APP_MOBILE_API_TOKEN.trim(),
            merchantId: this.user.id,
            source: "web",
            addressId,
            isconfirm,
            ...params
        })
    }
    postDataRedirect(url, data) {
        let form = document.createElement("form");
        form.method = "POST";
        form.action = url;
        data.forEach(el=>{
            var element = document.createElement("input");
            element.type = "hidden";
            element.value = el[1];
            element.name = el[0];
            form.appendChild(element);
        });
        document.body.appendChild(form);
        form.submit();  
    }

    getState(){
        axios.get(this.apiUrl+"/get-states", {
            key: process.env.REACT_APP_MOBILE_API_KEY.trim(),
            token: process.env.REACT_APP_MOBILE_API_TOKEN.trim(),
            merchantId: this.user.id,
            source: "web",
            save : 0,
        }).then(res=>{
            if(res.data.Response.status !== 200){
                return false;
            }            
            this.setState({states:res.data.Response.data.states})
        })
    }
    render() {
        return (
            <form
                role="form"
                className="needs-validation"
                noValidate="novalidate"
                onSubmit={(e)=>{this.processedToCheckout(e); }}
            >
                <div className="row">
                    <div className="col-lg-7 mb-4 mb-lg-0">
                        <h2 className="text-color-dark font-weight-bold text-5-6 mb-3">
                            Billing Details
                        </h2>

                        <h4 className="text-color-dark font-weight-bold text-5-6 mb-3">
                            <span></span>
                        </h4>
                        <div className="form-row">
                            <div className="form-group col">
                                <label>
                                    Saved Addresses
                                </label>
                                <div className="custom-select-1">
                                    <select
                                        className="form-control border-radius-0 h-auto py-2"
                                        name="addressId"
                                        onChange={(e)=>{this.selectAddress(e.target.value);}}
                                    >
                                        {
                                            Object.values(this.state.addresses).map((ad,i)=>{
                                                return (<option value={ad._id} key={i}>{ad.address1 || ad.address2}</option>)
                                            })
                                        }
                                        <option value="">Use a new address</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        
                        <div className="form-row">
                            
                            <div className="form-group col-md-6">
                                <label>
                                    First Name <span className="text-color-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-0 h-auto py-2"
                                    name="firstName"
                                    defaultValue={this.state.address?.firstName ?? ""}
                                    required
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label>
                                    Last Name <span className="text-color-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-0 h-auto py-2"
                                    name="lastName"
                                    defaultValue={this.state.address?.lastName ?? ""}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <label>Company Name</label>
                                <input
                                    type="text"
                                    className="form-control border-radius-0 h-auto py-2"
                                    name="company"
                                    defaultValue={this.state.address?.company ?? ""}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <label>
                                    Country/Region <span className="text-color-danger">*</span>
                                </label>
                                <div className="custom-select-1">
                                    <select
                                        className="form-control border-radius-0 h-auto py-2"
                                        name="country"
                                        required
                                    >
                                        <option value="" />
                                        <option value="India" selected>India</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <label>
                                    Street Address <span className="text-color-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-0 h-auto py-2"
                                    name="address1"
                                    defaultValue={this.state.address?.address1 ?? ""}
                                    placeholder="House number and street name"
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <input
                                    type="text"
                                    className="form-control border-radius-0 h-auto py-2"
                                    name="address2"
                                    defaultValue={this.state.address?.address2 ?? ""}
                                    placeholder="Apartment, suite, unit, etc..."
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <label>
                                    Town/City <span className="text-color-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-0 h-auto py-2"
                                    name="city"
                                    defaultValue={this.state.address?.city ?? ""}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <label>
                                    State <span className="text-color-danger">*</span>
                                </label>
                                <div className="custom-select-1">
                                    <select
                                        className="form-control border-radius-0 h-auto py-2"
                                        name="province"
                                        required
                                    >
                                        <option value="" selected />
                                        {
                                            this.state.states.map((el,i)=>{
                                                return <option value={el} key={i}>{el}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <label>
                                    ZIP <span className="text-color-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control border-radius-0 h-auto py-2"
                                    name="zip"
                                    defaultValue={this.state.address?.zip ?? ""}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <label>
                                    Phone <span className="text-color-danger">*</span>
                                </label>
                                <input
                                    type="number"
                                    className="form-control border-radius-0 h-auto py-2"
                                    name="contact_number"
                                    defaultValue={this.state.address?.contact_number ?? ""}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <label>
                                    Email Address <span className="text-color-danger">*</span>
                                </label>
                                <input
                                    type="email"
                                    className="form-control border-radius-0 h-auto py-2"
                                    name="email"
                                    defaultValue={this.state.address?.email ?? ""}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <div
                                    className="custom-checkbox-1"
                                    data-toggle="collapse"
                                    data-target=".shipping-field-wrapper"
                                >
                                    <input
                                        id="shipAddress"
                                        type="checkbox"
                                        name="shipAddress"
                                        />
                                    <label htmlFor="shipAddress">Shop to a different address ?</label>
                                </div>
                            </div>
                        </div>
                        {/* Ship to a differente address fields */}
                        <div className="shipping-field-wrapper collapse">
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label>
                                        First Name <span className="text-color-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control border-radius-0 h-auto py-2"
                                        name="s-firstName"
                                        defaultValue={this.state.sa?.firstName ?? ""}
                                        required
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>
                                        Last Name <span className="text-color-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control border-radius-0 h-auto py-2"
                                        name="s-lastName"
                                        defaultValue={this.state.sa?.lastName ?? ""}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col">
                                    <label>Company Name</label>
                                    <input
                                        type="text"
                                        className="form-control border-radius-0 h-auto py-2"
                                        name="s-company"
                                        ddefaultValue={this.state.sa?.company ?? ""}
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col">
                                    <label>
                                        Country/Region <span className="text-color-danger">*</span>
                                    </label>
                                    <div className="custom-select-1">
                                        <select
                                            className="form-control border-radius-0 h-auto py-2"
                                            name="s-country"
                                            required
                                        >
                                            <option value="" />
                                            <option value="India" selected>India</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col">
                                    <label>
                                        Street Address <span className="text-color-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control border-radius-0 h-auto py-2"
                                        name="s-address1"
                                        defaultValue={this.state.sa?.address1 ?? ""}
                                        placeholder="House number and street name"
                                        required
                                    />
                                </div>
                                <div className="form-group col">
                                    <input
                                        type="text"
                                        className="form-control border-radius-0 h-auto py-2"
                                        name="s-address2"
                                        defaultValue={this.state.sa?.address2 ?? ""}
                                        placeholder="Apartment, suite, unit, etc..."
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col">
                                    <label>
                                        Town/City <span className="text-color-danger">*</span>
                                    </label> 
                                    <input
                                        type="text"
                                        className="form-control border-radius-0 h-auto py-2"
                                        name="s-city"
                                        defaultValue={this.state.sa?.city ?? ""}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col">
                                    <label>
                                        State <span className="text-color-danger">*</span>
                                    </label>
                                    <div className="custom-select-1">
                                        <select
                                            className="form-control border-radius-0 h-auto py-2"
                                            name="s-province"
                                            required
                                        >
                                            <option value="" selected />
                                            {
                                                this.state.states.map((el,i)=>{
                                                    return <option value={el} key={i}>{el}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col">
                                    <label>
                                        ZIP <span className="text-color-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control border-radius-0 h-auto py-2"
                                        name="s-zip"
                                        defaultValue={this.state.sa?.zip ?? ""}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col">
                                    <label>
                                        Phone <span className="text-color-danger">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control border-radius-0 h-auto py-2"
                                        name="s-contact_number"
                                        defaultValue={this.state.sa?.contact_number ?? ""}
                                        required
                                    />
                                </div>
                            </div>
                            {/* End of Ship to a differente address fields */}
                        </div>
                        <div className="form-row">
                            <div className="form-group col">
                                <label>Order Notes</label>
                                <textarea
                                    className="form-control border-radius-0 h-auto py-2"
                                    name="o-note"
                                    rows={5}
                                    placeholder="Notes about you orderm e.g. special notes for delivery"
                                    defaultValue={this.state.sa?.note ?? ""}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="pin-wrapper" style={{ height: 908 }}>
                            <OrderDetails onCheckoutCallback={this.processedToCheckout} couponCode={this.props.couponCode}/>
                        </div>
                    </div>
                </div>
            </form>

        )

    }
}