import React, { Component } from "react"

class TopCarousel extends Component {
    render() {
        return (
            <div className="owl-carousel owl-theme nav-inside owl-loaded owl-drag owl-carousel-init topCarousel" data-plugin-options="{'items': 1, 'margin': 10, 'loop': false, 'nav': true, 'dots': false}" style={{ height: 'auto', maxHeight: '426px', marginBottom: '0' }}>
                <div className="owl-stage-outer">
                    <div className="owl-stage" style={{ transform: 'translate3d(-1126px, 0px, 0px)', transition: 'all 0.25s ease 0s', width: '2252px' }}>
                        <div className="owl-item" style={{ width: '1116px', marginRight: '10px' }}>
                            <div>
                                <div className="img-thumbnail border-0 p-0 d-block" style={{height: 'auto'}}><img className="img-fluid border-radius-0" src="https://static-sellercentral.shopperr.in/web/images/web-banner-shopper/pf-f161c9bc--web-banner-shopper1.jpg" alt="" /></div>
                            </div>
                        </div>
                        <div className="owl-item active" style={{ width: '1116px', marginRight: '10px' }}>
                            <div>
                                <div className="img-thumbnail border-0 p-0 d-block" style={{height: 'auto'}}><img className="img-fluid border-radius-0" src="https://static-sellercentral.shopperr.in/web/images/web-banner-shopper/pf-14aae7cb--web-banner-4.jpg" alt="" /></div>
                            </div>
                        </div>
                        <div className="owl-item active" style={{ width: '1116px', marginRight: '10px' }}>
                            <div>
                                <div className="img-thumbnail border-0 p-0 d-block" style={{height: 'auto'}}><img className="img-fluid border-radius-0" src="https://static-sellercentral.shopperr.in/web/images/web-banner-shopper/pf-aae7cbf1--web-banner-5.jpg" alt="" /></div>
                            </div>
                        </div>
                        <div className="owl-item active" style={{ width: '1116px', marginRight: '10px' }}>
                            <div>
                                <div className="img-thumbnail border-0 p-0 d-block" style={{height: 'auto'}}><img className="img-fluid border-radius-0" src="https://static-sellercentral.shopperr.in/web/images/web-banner-shopper/pf-e7cbf161--web-banner-3.jpg" alt="" /></div>
                            </div>
                        </div>
                        <div className="owl-item active" style={{ width: '1116px', marginRight: '10px' }}>
                            <div>
                                <div className="img-thumbnail border-0 p-0 d-block" style={{height: 'auto'}}><img className="img-fluid border-radius-0" src="https://static-sellercentral.shopperr.in/web/images/web-banner-shopper/pf-cbf161c9--web-banner-2.jpg" alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="owl-nav">
                    <button type="button" role="presentation" className="owl-prev"></button><button type="button" role="presentation" className="owl-next disabled"></button>
                </div>
                <div className="owl-dots disabled"></div>
            </div>
        )

    }
}

export default TopCarousel;