import React, { Component } from "react"
import Header from '../components/Header.js';
import LeftMenu from '../components/LeftMenu.js';
import TopCarousel from '../components/TopCarousel.js';
// import AdBanner from '../components/AdBanner.js';
import TopSelling from '../components/TopSelling.js';
// import ImageCrousel from '../components/ImageCrousel.js';
import MultiCategoryCard from '../components/MultiCategoryCard.js';
// import SingleImageCard from '../components/SingleImageCard.js';
// import MultiProductCard from '../components/MultiProductCard.js';
import HomePageProducts from '../components/HomePageProducts.js';
import ProductCrousel from '../components/ProductCrousel.js';
import FeaturedProducts from '../components/FeaturedProducts.js';
import ProductGrid from '../components/ProductGrid.js';
import Footer from '../components/Footer.js';
import MetaTags from 'react-meta-tags';
import Products from '../components/Products';

export default class HomePage extends Component {
    constructor() {
        super()
        this.state = {
          tags: {},
          cats: {},
          price: []
      }
    }
    componentDidMount() {
    }
    callbackFunction(state = {}) {
      this.setState(state);
    }  
    render() {
        return (
            <div id="homepage__template">
                <div className="wrapper">
                    <MetaTags>
                        <title> Wholesale Suppliers | b2b ecommerce dropshipping platform - Shopperr – Shopperr B2B </title>
                       
                        
                        <meta name="description" content="Shopperr is one of the most evolved B2B ecommerce platform that enables members to source products of international brands directly from manufacturers and wholesale suppliers." />
                       <meta property="og:title" content= "Wholesale Suppliers | b2b ecommerce dropshipping platform - Shopperr – Shopperr B2B "/>
                        

                    </MetaTags>
                    <div className="body wrapping" style={{transition: 'margin 300ms ease 0s' }}>
                        <Header />



                        {/* LeftMenu + TopCarousel ends */}
                        <div className="container-fluid">
                            <div className="row top-mod-space">
                                <div className="col-md-3" style={{height:"370px"}}>
                                    <LeftMenu />
                                </div>

                                <div className="col-md-9">
                                    <TopCarousel />
                                </div>
                            </div>
                        </div>
                        {/* LeftMenu + TopCarousel ends */}


                        {/* Ad banner starts */}
                        {/* <AdBanner /> */}
                        {/* Ad banner ends */}

       
                        {/* top selling module starts */}
                        <TopSelling />
                        {/* top selling module ends */}

                        {/* product listing starts */}
                        {/* <ProductGrid headingText={'FEATURED PRODUCTS'} keyword={'footwear'} /> */}
                        {/* <ProductGrid headingText={'Top Picks'} keyword={'mobile-accessories'} /> */}
                        {/* <ProductGrid headingText={'New Arrival'} keyword={'home-kitchen'} /> */}
                        {/* <ProductGrid headingText={'Electronics'} keyword={'mobile-accessories'} /> */}
                        {/* <ProductGrid headingText={'Accessories'} keyword={'Gifts-Toys'} /> */}
                        {/* <ProductGrid headingText={'Kitchen & Dining'} keyword={'kitchen-dining'} /> */}
                        {/* <ProductGrid headingText={'Beauty & Grooming'} keyword={'beauty-grooming'} lastElement={1}/> */}
                        {/* <ProductGrid headingText={'Recently viewed products'} keyword={'recently-viewed-products'} /> */}
                        
                        {/* <section className="container product-listing mb-4">
                            <h3>Featured Products</h3>
                            <div className="row">
                                <div className="col text-center">
                                    <div className="collection-box">
                                        <div className="grid__product-image text-center">        
                                            <a href="/collections/casun-mobile-accessories/products/true-wireless-in-ear-bluetooth-5-1-headphones-with-free-5w-wireless-charger-deep-bass-ipx-4-water-sweat-resistant-passive-noise-cancelling-earphone-with-built-in-hd-mic" target="_blank" className="grid__image">
                                                <img src="//cdn.shopify.com/s/files/1/0595/4273/2973/products/16140019421272j8v3Y_275x.jpg?v=1629364896" data-src="//cdn.shopify.com/s/files/1/0595/4273/2973/products/16140019421272j8v3Y_275x.jpg?v=1629364896" data-size="auto" alt="True Wireless In-Ear Bluetooth 5.1 Headphones with Free 5W Wireless Charger, Deep Bass,  IPX-4 Water/Sweat Resistant, Passive Noise Cancelling Earphone with Built-in HD Mic" className="fade-in first--image lazyloaded" style={{left: '0'}} />
                                                <img src="//cdn.shopify.com/s/files/1/0595/4273/2973/products/1614001942127jNzieE_275x.jpg?v=1629364896" data-src="//cdn.shopify.com/s/files/1/0595/4273/2973/products/1614001942127jNzieE_275x.jpg?v=1629364896" data-size="auto" className="second--image lazyloaded" alt="True Wireless In-Ear Bluetooth 5.1 Headphones with Free 5W Wireless Charger, Deep Bass,  IPX-4 Water/Sweat Resistant, Passive Noise Cancelling Earphone with Built-in HD Mic" />
                                            </a>
                                        </div>
                                        <div className="grid__product__content">
                                            <h4><a href="#">True Wireless In-Ear Bluetooth 5.1...</a></h4>
                                            <h5>Rs. 453.00 / per piece</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col text-center">
                                    <div className="collection-box">hello</div>
                                </div>
                                <div className="col text-center">
                                    <div className="collection-box">hello</div>
                                </div>
                                <div className="col text-center">
                                    <div className="collection-box">hello</div>
                                </div>
                                <div className="col text-center">
                                    <div className="collection-box">hello</div>
                                </div>
                            </div>
                        </section> */}
                        {/* product listing ends */}


                        {/* <ImageCrousel /> */}
                        <div className="container">
                            {/* <div className="row mb-5 pb-3" style={{ position: 'relative', zIndex: '1', padding: '20px' }}> */}
                                {/* <MultiCategoryCard /> */}
                                {/* <SingleImageCard /> */}
                                {/* <MultiProductCard headingText={'Trending Products'} keyword={'Footwear'} urlText={'footwear'} />  */}

                                {/* <MultiProductCard headingText={'Top Picks'} keyword={'Mobile Accessories'} urlText={'mobile-accessories'} />
                                <MultiProductCard headingText={'Electronics'} keyword={'electronics'} urlText={'computer-peripherals'} />
                                <MultiProductCard headingText={'Featured Product'} keyword={'Beauty'} urlText={'beauty'} /> */}
                                {/* <MultiProductCard headingText={'Accessories'} keyword={'Gifts & Toys'} urlText={'gifts-toys'} />
                                <MultiProductCard headingText={'Luggage & Travel'} keyword={'Luggage & Travel'} urlText={'luggage-travel'} /> */}
                                {/* <MultiProductCard headingText={'Home & Kitchen'} keyword={'Home & Kitchen'} urlText={'home-kitchen'} /> */}
                                {/* <MultiProductCard headingText={'Photography Accessories'} keyword={'Photography Accessories'} urlText={'photography-accessories'} />
                                <MultiProductCard headingText={'Automobile Accessories'} keyword={'Automobile Accessories'} urlText={'automobile-accessories'} />
                                <MultiProductCard headingText={'New Arrival'} keyword={'Home & Kitchen'} urlText={'home-kitchen'} /> */}
                            {/* </div> */}
                            <div style={{ marginTop: '50px' }}>
                                <div className="products row">
                                    <FeaturedProducts headingText={'FEATURED PRODUCTS'} keyword={'Public Item'} />
                                    <FeaturedProducts headingText={'BEST SELLING PRODUCTS'} keyword={'Mobile Accessories'} />
                                    <FeaturedProducts headingText={'LATEST PRODUCTS'} keyword={'Furniture'} />
                                    <FeaturedProducts headingText={'TOP RATED PRODUCTS'} keyword={'Dinnerware'} />
                                    {/* <div className="col-lg-12 order-1 order-lg-2" style={{ marginTop: '40px' }}> */}
                                      {/* <h4 className="font-weight-semibold text-4 mb-3" style={{ textAlign: 'center' }}>TOP RATED PRODUCTS</h4> */}
                                        {/* <br /> */}
                                        {/* <Products parentCallback={this.callbackFunction.bind(this)} price={this.state.price} search={{keyword:'Beauty',width:"350px" }} row={3} /> */}
                                    {/* </div> */}
                                </div>
                            </div>

                            {/* <ProductCrousel /> */}
                        </div>
                        <Footer />    
                    </div>
                </div>
            </div>
        )
    }
}