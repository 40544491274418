import React, { useState, useEffect } from "react";
const Login = () => {
	const [state, setState] = useState({
	});

	return ( 
		<div className="header-nav-feature header-nav-features-cart header-nav-features-cart-big d-inline-flex top-2 ms-2">
		<a href="https://www.seller.globalshopperr.com/login-merchant?redirecturl=https://www.globalshopperr.com/verify-login-merchant" className="header-nav-features-toggle">
			My Account</a>
			{/*
			<a href={`${process.env.REACT_APP_NODE_API_URL}/login-merchant?redirecturl=${window.origin}/verify-login-merchant`} className="header-nav-features-toggle">My Account</a>
			*/}
			</div>
			
	);
};

export default Login;
