import React, { Component } from "react"
import CommonFunctions from '../global/CommonFunctions';
class LeftSidebarTags extends Component {
    constructor(props){
        super(props);
        this.state = {
          tags: this.props.tags || {},
        }
    }
    componentDidUpdate(){
      
    }
    componentWillReceiveProps(nextProps){
      if (nextProps.tags) {
        this.setState({ tags: nextProps.tags});
      }
    }
    render() {
        return (
          this.props.tags ?
            <div className="mb-3 pb-1">
              {
                this.state.tags ? 
                Object.entries(this.state.tags).map(([tag,count],key)=>{
                  return (<a href={'/search/'+CommonFunctions.getValidURL(tag)} key={key}><span className="badge badge-dark badge-sm rounded-pill text-uppercase px-2 py-1 me-1">{tag.replace("-"," ").replace("-&-","&")}</span></a>)
                })
                : 
                ""
              }
            </div>
          : ""
        )

    }
}
export default LeftSidebarTags;
