import React, { useState, useEffect } from "react";
import Slider from "react-slick";

const hpMainSlider = {
    dots: false,
    autoplay: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };


const BrandingStep1 = () => {
    return (
        
        <div id="maincarousel">
        <div className="container">
          <div className="row">
                              <div className="col-lg-3">
                              <div class="sc-gWXbKe hoZTIr __pf" id="__pf">
                              <div data-pf-type="Block" className="sc-bilyIR cCHonK pf-16_">
                    <h3 data-pf-type="Heading" className="sc-ctqQKy ioCblW pf-17_">
                      <i className="sc-gIDmLj huzWMp pf-18_ pfa pfa-bars" data-pf-type="Icon" />
                      <a
                        data-link="inherit"
                        href="https://shopperr.in/pages/electronic-appliances"
                        target="_self"
                        style={{color:'#fff'}}
                      >
                        <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-19_">
                        Home
                        </span>
                      </a>
                    </h3>
                    <div
                      data-href="https://shopperr.in/collections/televisions"
                      data-target="_blank"
                      data-pf-type="Block"
                      className="sc-bilyIR cCHonK pf-20_"
                    >
                      <div data-pf-type="Image" className="sc-evcjhq RvYYJ pf-21_">
                        <img
                          src="https://cdn.shopify.com/s/files/1/0317/5999/0924/t/11/assets/pf-71b092d8--Products.png?v=1607335094"
                          srcSet="https://cdn.shopify.com/s/files/1/0317/5999/0924/t/11/assets/pf-71b092d8--Products.png?v=1607335094 1536w"
                        />
                      </div>
                      <h3 data-pf-type="Heading" className="sc-ctqQKy ioCblW pf-22_">
                        <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-24_">
                        All Products
                        </span>
                      </h3>
                    </div>
                    <div
                      data-href="https://shopperr.in/collections/headphones"
                      data-target="_blank"
                      data-pf-type="Block"
                      className="sc-bilyIR cCHonK pf-25_"
                    >
                      <div data-pf-type="Image" className="sc-evcjhq RvYYJ pf-26_">
                        <img
                          src="https://cdn.shopify.com/s/files/1/0317/5999/0924/t/11/assets/pf-f3a1be77--Featured-Products.png?v=1607337187"
                          srcSet="https://cdn.shopify.com/s/files/1/0317/5999/0924/t/11/assets/pf-f3a1be77--Featured-Products.png?v=1607337187 1536w"
                        />
                      </div>
                      <h3 data-pf-type="Heading" className="sc-ctqQKy ioCblW pf-27_">
                        <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-29_">
                        Featured Products
                        </span>
                      </h3>
                    </div>
                    <div
                      data-href="https://shopperr.in/collections/speakers"
                      data-target="_blank"
                      data-pf-type="Block"
                      className="sc-bilyIR cCHonK pf-30_"
                    >
                      <div data-pf-type="Image" className="sc-evcjhq RvYYJ pf-31_">
                        <img
                          src="https://cdn.shopify.com/s/files/1/0317/5999/0924/t/11/assets/pf-f2f3a1be--Best-Seller.png?v=1607336768"
                          srcSet="https://cdn.shopify.com/s/files/1/0317/5999/0924/t/11/assets/pf-f2f3a1be--Best-Seller.png?v=1607336768 1536w"
                        />
                      </div>
                      <h3 data-pf-type="Heading" className="sc-ctqQKy ioCblW pf-32_">
                        <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-34_">
                        Men's Wears
                        </span>
                      </h3>
                    </div>
                    <div
                      data-href="https://shopperr.in/collections/dslr-cameras"
                      data-target="_blank"
                      data-pf-type="Block"
                      className="sc-bilyIR cCHonK pf-35_"
                    >
                      <div data-pf-type="Image" className="sc-evcjhq RvYYJ pf-36_">
                        <img
                          src="https://cdn.shopify.com/s/files/1/0317/5999/0924/t/11/assets/pf-6af2f3a1--New-Arivals.png?v=1607336412"
                          srcSet="https://cdn.shopify.com/s/files/1/0317/5999/0924/t/11/assets/pf-6af2f3a1--New-Arivals.png?v=1607336412 1536w"
                        />
                      </div>
                      <h3 data-pf-type="Heading" className="sc-ctqQKy ioCblW pf-37_">
                        <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-39_">
                        Women's Wear
                        </span>
                      </h3>
                    </div>
                    <div
                      data-href="https://shopperr.in/collections/security-cameras"
                      data-target="_blank"
                      data-pf-type="Block"
                      className="sc-bilyIR cCHonK pf-40_"
                    >
                      <div data-pf-type="Image" className="sc-evcjhq RvYYJ pf-41_">
                        <img
                          src="https://cdn.shopify.com/s/files/1/0317/5999/0924/t/11/assets/pf-5e6af2f3--Company-Profile.png?v=1607336201"
                          srcSet="https://cdn.shopify.com/s/files/1/0317/5999/0924/t/11/assets/pf-5e6af2f3--Company-Profile.png?v=1607336201 1536w"
                        />
                      </div>
                      <h3 data-pf-type="Heading" className="sc-ctqQKy ioCblW pf-42_">
                        <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-44_">
                        Kid's Wear
                        </span>
                      </h3>
                    </div>
                    <div
                      data-href="https://shopperr.in/collections/gaming-consoles"
                      data-target="_blank"
                      data-pf-type="Block"
                      className="sc-bilyIR cCHonK pf-45_"
                    >
                      <div data-pf-type="Image" className="sc-evcjhq RvYYJ pf-46_">
                        <img
                          src="https://cdn.shopify.com/s/files/1/0317/5999/0924/t/11/assets/pf-495e6af2--History.png?v=1607335811"
                          srcSet="https://cdn.shopify.com/s/files/1/0317/5999/0924/t/11/assets/pf-495e6af2--History.png?v=1607335811 1536w"
                        />
                      </div>
                      <h3 data-pf-type="Heading" className="sc-ctqQKy ioCblW pf-47_">
                        <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-49_">
                        Brand History
                        </span>
                      </h3>
                    </div>
                    <div
                      data-href="https://shopperr.in/collections/gaming-consoles"
                      data-target="_blank"
                      data-pf-type="Block"
                      className="sc-bilyIR cCHonK pf-45_"
                    >
                      <div data-pf-type="Image" className="sc-evcjhq RvYYJ pf-46_">
                        <img
                          src="https://cdn.shopify.com/s/files/1/0317/5999/0924/t/11/assets/pf-92d8495e--Certificates.png?v=1607335510"
                          srcSet="https://cdn.shopify.com/s/files/1/0317/5999/0924/t/11/assets/pf-92d8495e--Certificates.png?v=1607335510 1536w"
                        />
                      </div>
                      <h3 data-pf-type="Heading" className="sc-ctqQKy ioCblW pf-47_">
                        <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-49_">
                        Certificates

                        </span>
                      </h3>
                    </div>
                  </div>
                  </div>
                              </div>
            <div className="col-lg-9"> 
            
             <div className="row sc-gWXbKe hoZTIr __pf">

                 <div data-pf-type="Column" className="sc-hAcGzb bAQARH pf-50_">
                <h1 data-pf-type="Heading" className="sc-ctqQKy ioCblW pf-51_">
                    <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-53_">
                    Tom Tailor
                    </span>
                </h1>
                <h3 data-pf-type="Heading" className="sc-ctqQKy ioCblW pf-54_">
                    <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-56_">
                    <span style={{ fontWeight: "normal" }}>
                        Discover the wide range of Tom Tailor on Shopperr's
                    </span>
                    </span>
                </h3>
                <a
                    href="/collections/tom-tailor"
                    target="_self"
                    data-pf-type="Button"
                    className="sc-dtMgUX gJMWBM pf-570_"
                >
                    <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-59_">
                    Buy Now
                    </span>
                </a>
                </div>
             </div>
            
            </div>
          </div>
        </div>
       
       
        </div>

    )
}

export default BrandingStep1;
