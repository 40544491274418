import React, { useState, useEffect } from "react";
import Slider from "react-slick";

const hpMainSlider = {
    dots: false,
    autoplay: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };


const BrandStep1 = () => {
    return (
        
        <div id="maincarousel">
        <Slider {...hpMainSlider}>
            <div className="item">
                <a href="/franchisee_d4" className="banner"><img src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/61/1658294588093AoyUtP.png" style={{width: '100%'}} alt="首页面（新）" /></a>
            </div>
            <div className="item">
                <a href="/products/sofa" className="banner"><img src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/61/1658294588089whGiWz.png" style={{width: '100%'}} alt="Modern L-Shaped Sofa Price" /></a>
            </div>
            <div className="item">
                <a href="/products/sofa" className="banner"><img src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/61/1658294588075KcxZVx.png" style={{width: '100%'}} alt="Sofa S1015" /></a>
            </div>
            <div className="item">
                <a href="/products/sofa" className="banner"><img src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/61/16582945881003Ryy3r.png" style={{width: '100%'}} alt="Sofa S1015" /></a>
            </div>
            
            
            
        </Slider>
        </div>

    )
}

export default BrandStep1;

