import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";

const hpMainSlider = {
    dots: false,
    autoplay: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };


const Clothing_Accessories1 = () => {
    return (
        <>
        <div id="maincarousel">
        <div className="container">
          <div className="row">
                              <div className="col-lg-3">
                              <div class="sc-gWXbKe hoZTIr __pf" id="__pf">
                              <div data-pf-type="Block" className="sc-bilyIR cCHonK pf-16_">
                    <h3 data-pf-type="Heading" className="sc-ctqQKy ioCblW pf-17_">
                      <i className="sc-gIDmLj huzWMp pf-18_ pfa pfa-bars" data-pf-type="Icon" />
                     
                        <NavLink 
                            to={'/clothing-accessories'}
                            exact
                            style={{color:'#fff'}}
                          >
                        <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-19_">
                        Clothing Accessories
                        </span>
                        </NavLink>
                    </h3>
                    <div className="sc-bilyIR cCHonK pf-20_">
                    <NavLink 
                            to={'/baby'}
                            exact
                          >
                    
                      <div data-pf-type="Image" className="sc-evcjhq RvYYJ pf-21_">
                        <img
                          src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/16596070961624iGbPk.png"
                          srcSet="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/16596070961624iGbPk.png 1536w"
                        />
                      </div>
                      <h3 data-pf-type="Heading" className="sc-ctqQKy ioCblW pf-22_">
                        <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-24_">
                        Baby
                        </span>
                      </h3>
                      </NavLink>
                      </div>
                    <div className="sc-bilyIR cCHonK pf-25_"
                    >
                      <NavLink 
                            to={'/women'}
                            exact
                          >
                      <div data-pf-type="Image" className="sc-evcjhq RvYYJ pf-26_">
                        <img
                          src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/1659607168686UXzM6M.png"
                          srcSet="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/1659607168686UXzM6M.png 1536w"
                        />
                      </div>
                      <h3 data-pf-type="Heading" className="sc-ctqQKy ioCblW pf-27_">
                        <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-29_">
                        Women
                        </span>
                      </h3>
                      </NavLink>
                    </div>
                    
                    <div
                      
                      className="sc-bilyIR cCHonK pf-30_"
                    >
                      <NavLink 
                            to={'/men'}
                            exact
                          >
                      <div data-pf-type="Image" className="sc-evcjhq RvYYJ pf-31_">
                        <img
                          src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/1659607220514Zsb4JZ.png"
                          srcSet="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/1659607220514Zsb4JZ.png 1536w"
                        />
                      </div>
                      <h3 data-pf-type="Heading" className="sc-ctqQKy ioCblW pf-32_">
                        <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-34_">
                        Men
                        </span>
                      </h3>
                      </NavLink>
                    </div>
                    <div
                      
                      className="sc-bilyIR cCHonK pf-35_"
                    >
                      <NavLink 
                            to={'/accessories'}
                            exact
                          >
                      <div data-pf-type="Image" className="sc-evcjhq RvYYJ pf-36_">
                        <img
                          src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/1659607283284IJkH9b.png"
                          srcSet="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/1659607283284IJkH9b.png 1536w"
                        />
                      </div>
                      <h3 data-pf-type="Heading" className="sc-ctqQKy ioCblW pf-37_">
                        <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-39_">
                        Accessories
                        </span>
                      </h3>
                      </NavLink>
                    </div>
                    <div
                      
                      className="sc-bilyIR cCHonK pf-40_"
                    >
                      <NavLink 
                            to={'/sunglasses'}
                            exact
                          >
                      <div data-pf-type="Image" className="sc-evcjhq RvYYJ pf-41_">
                        <img
                          src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/16596073480458NI4fZ.png"
                          srcSet="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/16596073480458NI4fZ.png 1536w"
                        />
                      </div>
                      <h3 data-pf-type="Heading" className="sc-ctqQKy ioCblW pf-42_">
                        <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-44_">
                        Sunglasses
                        </span>
                      </h3>
                      </NavLink>
                    </div>
                    <div
                      
                      className="sc-bilyIR cCHonK pf-45_"
                    >
                      <NavLink 
                            to={'/belts'}
                            exact
                          >
                      <div data-pf-type="Image" className="sc-evcjhq RvYYJ pf-46_">
                        <img
                          src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/16596075305987se6Aq.png"
                          srcSet="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/16596075305987se6Aq.png 1536w"
                        />
                      </div>
                      <h3 data-pf-type="Heading" className="sc-ctqQKy ioCblW pf-47_">
                        <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-49_">
                        Belts
                        </span>
                      </h3>
                      </NavLink>
                    </div>
                  </div>
                  </div>
                              </div>
            <div className="col-lg-9"> <Slider {...hpMainSlider}>
            <div className="item">
                <a href="/dresses" className="banner"><img src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/1659936021934vpPUAc.png" style={{width: '100%'}} className='image_banner' alt="首页面（新）" /></a>
            </div>
            <div className="item">
                <a href="/clothing-sets" className="banner"><img src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/71/1659936021939b4vgOi.png" style={{width: '100%' }} className='image_banner' alt="Modern L-Shaped Sofa Price" /></a>
            </div>
            {/* <div className="item">
                <a href="/products/sofa" className="banner"><img src="https://static-sellercentral.shopperr.in/images/supplier/6049a93d223709632c7962b2/2022/61/1657711628171PZ80nC.png" style={{width: '100%'}} alt="Sofa S1015" /></a>
            </div> */}
            
            
            
        </Slider></div>
          </div>
        </div>
        <div className='container' style={{marginTop:'30px'}}>
            <div className='row sc-gWXbKe hoZTIr __pf' id="__pf">
                <div className='col-lg-6'>
                <a href="/caps-hats">
                <div data-pf-type="Column" className="sc-hAcGzb bAQARH pf-57_clot">
                <h3 data-pf-type="Heading" className="sc-ctqQKy ioCblW pf-58_">
                <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-60_"><br />
                <span style={{'font-weight': 'bold'}}></span>
                <br />

                </span>
                </h3>
                </div> 
                </a>
                </div>
                <div className='col-lg-6 ' >
                <a href="/baby">
                <div data-pf-type="Column" className="sc-hAcGzb bAQARH pf-61_clot">
                    <h3 data-pf-type="Heading" className="sc-ctqQKy ioCblW pf-62_">
                        <span data-pf-type="Text" className="sc-jtXEFf kGfXVs pf-64_">
                        
                        <br />
                        <span style={{ fontWeight: 700 }}></span>
                        <br />
                        </span>
                    </h3>
                    </div>
</a>
                </div>
            </div>
        </div>
       
        </div>
        </>

    )
}

export default Clothing_Accessories1;
