import React, { useState, useEffect } from "react";
import axios from 'axios';


const HomeScreen = () => {
	const userInfoLocal = JSON.parse(window.localStorage.getItem("userInfo"));
	// <div>
	// 	Hello {userInfoLocal && userInfoLocal.email}
	// 	<a
	// 		href={`http://10.80.11.60:3077/login-merchant?redirecturl=${window.location.href}verify-login-merchant`}
	// 	>
	// 		{" "}
	// 		login
	// 	</a>
	// </div>
	const [state, setState] = useState({
			productTotalCount: 0,
			productsData: [],
			isLoaded: false,
			productImageUrl: "",
			currentPage: 1,
			showPerPage: 50,
			pageCount: 0,
			searchName: "",
			searchSku: "",
			errors: {},
			loading: true,
			modalShow: false,
			deleteProductId: "",
			deleteProductName: ""
	});
    // const [page, setpage] = useState(1) 
    // const [loading, setLoading] = useState(false) 
    // const [loading, setProductsData] = useState(false) 

    useEffect(() => {
    getProductsData();
    
  });

	function getProductsData(page, perPage){
		setState({loading:true})
		axios.get(`/add/getProducts?page=${page}&limit=${perPage}`)
		.then(result => {
			console.log(result);
			setState({isLoaded: true, productsData: result.data.items, productTotalCount: result.data.totalCount,
						productImageUrl: result.data.url, pageCount: Math.ceil(result.data.totalCount / this.state.showPerPage), 
						currentPage: page, searchName: "", searchSku: "", loading: false})
		})
		.catch(err => {
			console.log(err)
			setState({loading: false})
		})
  }

	return (
		<div>
			<div className="loading-overlay">
				<div className="bounce-loader">
					<div className="bounce1" />
					<div className="bounce2" />
					<div className="bounce3" />
				</div>
			</div>

			<div className="body">
				<header
					id="header"
					className="header-transparent header-effect-shrink"
				>
					<div className="header-body border-top-0 bg-dark box-shadow-none" style={{ position:"fixed", top:"0px"}}>
						<div className="header-container container">
							<div className="header-row">
								<div className="header-column">
									<div className="header-row">
										<div className="header-logo">
											<a href="#" className="goto-top">
												<img
													alt="Porto"
													
													data-sticky-width={82}
													data-sticky-height={36}
													data-sticky-top={0}
													src="img/lazy.png"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500}"
													data-original="https://cdn.shopify.com/s/files/1/0317/5999/0924/files/Shopperr_Logo_White_Without_Tagline_256x256_crop_center.png?v=1626949305"
												/>
											</a>
										</div>
									</div>
								</div>
								<div className="header-column justify-content-end">
									<div className="header-row">
										<div className="header-nav header-nav-links header-nav-light-text header-nav-dropdowns-dark">
											<div className="header-nav-main header-nav-main-square header-nav-main-dropdown-no-borders header-nav-main-text-capitalize header-nav-main-text-size-2 header-nav-main-mobile-dark header-nav-main-effect-1 header-nav-main-sub-effect-1">
												<nav className="collapse">
													<ul
														className="nav nav-pills"
														id="mainNav"
													>
														<li className="dropdown">
															<a
																className="dropdown-item dropdown-toggle"
																href="index.html"
															>
																Home
															</a>
															<ul className="dropdown-menu">
																<li>
																	<a
																		className="dropdown-item"
																		href="index.html"
																	>
																		Landing
																		Page
																	</a>
																</li>
																<li>
																	<a
																		className="dropdown-item"
																		href="index.html#demos"
																	>
																		Demos
																	</a>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Classic
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-classic.html"
																			>
																				Classic
																				-
																				Original
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-classic-color.html"
																			>
																				Classic
																				-
																				Color
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-classic-light.html"
																			>
																				Classic
																				-
																				Light
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-classic-video.html"
																			>
																				Classic
																				-
																				Video
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-classic-video-light.html"
																			>
																				Classic
																				-
																				Video
																				-
																				Light
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Corporate{" "}
																		<span className="tip tip-dark">
																			hot
																		</span>
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-corporate.html"
																			>
																				Corporate
																				-
																				Version
																				1
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-corporate-2.html"
																			>
																				Corporate
																				-
																				Version
																				2
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-corporate-3.html"
																			>
																				Corporate
																				-
																				Version
																				3
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-corporate-4.html"
																			>
																				Corporate
																				-
																				Version
																				4
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-corporate-5.html"
																			>
																				Corporate
																				-
																				Version
																				5
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-corporate-6.html"
																			>
																				Corporate
																				-
																				Version
																				6
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-corporate-7.html"
																			>
																				Corporate
																				-
																				Version
																				7
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-corporate-8.html"
																			>
																				Corporate
																				-
																				Version
																				8
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-corporate-9.html"
																			>
																				Corporate
																				-
																				Version
																				9
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-corporate-10.html"
																			>
																				Corporate
																				-
																				Version
																				10
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-corporate-11.html"
																			>
																				Corporate
																				-
																				Version
																				11
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-corporate-12.html"
																			>
																				Corporate
																				-
																				Version
																				12
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-corporate-13.html"
																			>
																				Corporate
																				-
																				Version
																				13
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-corporate-14.html"
																			>
																				Corporate
																				-
																				Version
																				14
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-corporate-15.html"
																			>
																				Corporate
																				-
																				Version
																				15
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-corporate-16.html"
																			>
																				Corporate
																				-
																				Version
																				16
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-corporate-17.html"
																			>
																				Corporate
																				-
																				Version
																				17
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-corporate-18.html"
																			>
																				Corporate
																				-
																				Version
																				18
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-corporate-19.html"
																			>
																				Corporate
																				-
																				Version
																				19
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-corporate-20.html"
																			>
																				Corporate
																				-
																				Version
																				20
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Portfolio
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-portfolio.html"
																			>
																				Portfolio
																				-
																				Version
																				1
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-portfolio-2.html"
																			>
																				Portfolio
																				-
																				Version
																				2
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-portfolio-3.html"
																			>
																				Portfolio
																				-
																				Version
																				3
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-portfolio-4.html"
																			>
																				Portfolio
																				-
																				Version
																				4
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-portfolio-5.html"
																			>
																				Portfolio
																				-
																				Version
																				5
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Blog
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-blog.html"
																			>
																				Blog
																				-
																				Version
																				1
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-blog-2.html"
																			>
																				Blog
																				-
																				Version
																				2
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-blog-3.html"
																			>
																				Blog
																				-
																				Version
																				3
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-blog-4.html"
																			>
																				Blog
																				-
																				Version
																				4
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-blog-5.html"
																			>
																				Blog
																				-
																				Version
																				5
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		One Page
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-one-page.html"
																			>
																				One
																				Page
																				Original
																			</a>
																		</li>
																	</ul>
																</li>
															</ul>
														</li>
														<li className="dropdown">
															<a
																className="dropdown-item"
																data-hash
																data-hash-offset={
																	130
																}
																href="#demos"
															>
																Demos
															</a>
														</li>
														<li className="dropdown dropdown-mega">
															<a
																className="dropdown-item dropdown-toggle"
																href="elements.html"
															>
																Elements
															</a>
															<ul className="dropdown-menu">
																<li>
																	<div className="dropdown-mega-content">
																		<div className="row">
																			<div className="col-lg-3">
																				<span className="dropdown-mega-sub-title">
																					Elements
																					1
																				</span>
																				<ul className="dropdown-mega-sub-nav">
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-accordions.html"
																						>
																							Accordions
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-toggles.html"
																						>
																							Toggles
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-tabs.html"
																						>
																							Tabs
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-icons.html"
																						>
																							Icons
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-icon-boxes.html"
																						>
																							Icon
																							Boxes
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-carousels.html"
																						>
																							Carousels
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-modals.html"
																						>
																							Modals
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-lightboxes.html"
																						>
																							Lightboxes
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-word-rotator.html"
																						>
																							Word
																							Rotator
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-before-after.html"
																						>
																							Before
																							/
																							After
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-360-image-viewer.html"
																						>
																							360º
																							Image
																							Viewer
																						</a>
																					</li>
																				</ul>
																			</div>
																			<div className="col-lg-3">
																				<span className="dropdown-mega-sub-title">
																					Elements
																					2
																				</span>
																				<ul className="dropdown-mega-sub-nav">
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-buttons.html"
																						>
																							Buttons
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-badges.html"
																						>
																							Badges
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-lists.html"
																						>
																							Lists
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-cards.html"
																						>
																							Cards
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-image-gallery.html"
																						>
																							Image
																							Gallery
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-image-frames.html"
																						>
																							Image
																							Frames
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-image-hotspots.html"
																						>
																							Image
																							Hotspots
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-testimonials.html"
																						>
																							Testimonials
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-blockquotes.html"
																						>
																							Blockquotes
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-sticky-elements.html"
																						>
																							Sticky
																							Elements
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-shape-dividers.html"
																						>
																							Shape
																							Dividers
																						</a>
																					</li>
																				</ul>
																			</div>
																			<div className="col-lg-3">
																				<span className="dropdown-mega-sub-title">
																					Elements
																					3
																				</span>
																				<ul className="dropdown-mega-sub-nav">
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-typography.html"
																						>
																							Typography
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-call-to-action.html"
																						>
																							Call
																							to
																							Action
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-pricing-tables.html"
																						>
																							Pricing
																							Tables
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-tables.html"
																						>
																							Tables
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-progressbars.html"
																						>
																							Progress
																							Bars
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-process.html"
																						>
																							Process
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-counters.html"
																						>
																							Counters
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-countdowns.html"
																						>
																							Countdowns
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-sections-parallax.html"
																						>
																							Sections
																							&amp;
																							Parallax
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-tooltips-popovers.html"
																						>
																							Tooltips
																							&amp;
																							Popovers
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-read-more.html"
																						>
																							Read
																							More
																						</a>
																					</li>
																				</ul>
																			</div>
																			<div className="col-lg-3">
																				<span className="dropdown-mega-sub-title">
																					Elements
																					4
																				</span>
																				<ul className="dropdown-mega-sub-nav">
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-headings.html"
																						>
																							Headings
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-dividers.html"
																						>
																							Dividers
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-animations.html"
																						>
																							Animations
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-medias.html"
																						>
																							Medias
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-maps.html"
																						>
																							Maps
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-arrows.html"
																						>
																							Arrows
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-star-ratings.html"
																						>
																							Star
																							Ratings
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-alerts.html"
																						>
																							Alerts
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-posts.html"
																						>
																							Posts
																						</a>
																					</li>
																					<li>
																						<a
																							className="dropdown-item"
																							href="elements-forms-basic-contact.html"
																						>
																							Forms
																						</a>
																					</li>
																				</ul>
																			</div>
																		</div>
																	</div>
																</li>
															</ul>
														</li>
														<li className="dropdown">
															<a
																className="dropdown-item dropdown-toggle"
																href="#"
															>
																Features
															</a>
															<ul className="dropdown-menu">
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Headers
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-headers-overview.html"
																			>
																				Overview
																			</a>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Classic
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-classic.html"
																					>
																						Classic
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-classic-language-dropdown.html"
																					>
																						Classic
																						+
																						Language
																						Dropdown
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-classic-big-logo.html"
																					>
																						Classic
																						+
																						Big
																						Logo
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Flat
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-flat.html"
																					>
																						Flat
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-flat-top-bar.html"
																					>
																						Flat
																						+
																						Top
																						Bar
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-flat-top-bar-top-borders.html"
																					>
																						Flat
																						+
																						Top
																						Bar
																						+
																						Top
																						Border
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-flat-colored-top-bar.html"
																					>
																						Flat
																						+
																						Colored
																						Top
																						Bar
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-flat-borders.html"
																					>
																						Flat
																						+
																						Borders
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Center
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-center.html"
																					>
																						Center
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-center-double-navs.html"
																					>
																						Center
																						+
																						Double
																						Navs
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-center-nav-buttons.html"
																					>
																						Center
																						+
																						Nav
																						+
																						Buttons
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-center-below-slider.html"
																					>
																						Center
																						Below
																						Slider
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Floating
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-floating-bar.html"
																					>
																						Floating
																						Bar
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-floating-icons.html"
																					>
																						Floating
																						Icons
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-headers-below-slider.html"
																			>
																				Below
																				Slider
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-headers-full-video.html"
																			>
																				Full
																				Video
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-headers-narrow.html"
																			>
																				Narrow
																			</a>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Sticky
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-sticky-shrink.html"
																					>
																						Sticky
																						Shrink
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-sticky-static.html"
																					>
																						Sticky
																						Static
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-sticky-change-logo.html"
																					>
																						Sticky
																						Change
																						Logo
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-sticky-reveal.html"
																					>
																						Sticky
																						Reveal
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Transparent
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-transparent-light.html"
																					>
																						Transparent
																						Light
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-transparent-dark.html"
																					>
																						Transparent
																						Dark
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-transparent-light-bottom-border.html"
																					>
																						Transparent
																						Light
																						+
																						Bottom
																						Border
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-transparent-dark-bottom-border.html"
																					>
																						Transparent
																						Dark
																						+
																						Bottom
																						Border
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-transparent-bottom-slider.html"
																					>
																						Transparent
																						Bottom
																						Slider
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-semi-transparent-light.html"
																					>
																						Semi
																						Transparent
																						Light
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-semi-transparent-dark.html"
																					>
																						Semi
																						Transparent
																						Dark
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-semi-transparent-bottom-slider.html"
																					>
																						Semi
																						Transparent
																						Bottom
																						Slider
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-semi-transparent-top-bar-borders.html"
																					>
																						Semi
																						Transparent
																						+
																						Top
																						Bar
																						+
																						Borders
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Full
																				Width
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-full-width.html"
																					>
																						Full
																						Width
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-full-width-borders.html"
																					>
																						Full
																						Width
																						+
																						Borders
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-full-width-transparent-light.html"
																					>
																						Full
																						Width
																						Transparent
																						Light
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-full-width-transparent-dark.html"
																					>
																						Full
																						Width
																						Transparent
																						Dark
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-full-width-semi-transparent-light.html"
																					>
																						Full
																						Width
																						Semi
																						Transparent
																						Light
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-full-width-semi-transparent-dark.html"
																					>
																						Full
																						Width
																						Semi
																						Transparent
																						Dark
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Navbar
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-navbar.html"
																					>
																						Navbar
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-navbar-full.html"
																					>
																						Navbar
																						Full
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-navbar-pills.html"
																					>
																						Navbar
																						Pills
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-navbar-divisors.html"
																					>
																						Navbar
																						Divisors
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-navbar-icons-search.html"
																					>
																						Nav
																						Bar
																						+
																						Icons
																						+
																						Search
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Side
																				Header
																			</a>
																			<ul className="dropdown-menu">
																				<li className="dropdown-submenu">
																					<a
																						className="dropdown-item"
																						href="#"
																					>
																						Side
																						Header
																						Left
																					</a>
																					<ul className="dropdown-menu">
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-headers-side-header-left-dropdown.html"
																							>
																								Dropdown
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-headers-side-header-left-expand.html"
																							>
																								Expand
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-headers-side-header-left-columns.html"
																							>
																								Columns
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-headers-side-header-left-slide.html"
																							>
																								Slide
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-headers-side-header-left-semi-transparent.html"
																							>
																								Semi
																								Transparent
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-headers-side-header-left-dark.html"
																							>
																								Dark
																							</a>
																						</li>
																					</ul>
																				</li>
																				<li className="dropdown-submenu">
																					<a
																						className="dropdown-item"
																						href="#"
																					>
																						Side
																						Header
																						Right
																					</a>
																					<ul className="dropdown-menu">
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-headers-side-header-right-dropdown.html"
																							>
																								Dropdown
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-headers-side-header-right-expand.html"
																							>
																								Expand
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-headers-side-header-right-columns.html"
																							>
																								Columns
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-headers-side-header-right-slide.html"
																							>
																								Slide
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-headers-side-header-right-semi-transparent.html"
																							>
																								Semi
																								Transparent
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-headers-side-header-right-dark.html"
																							>
																								Dark
																							</a>
																						</li>
																					</ul>
																				</li>
																				<li className="dropdown-submenu">
																					<a
																						className="dropdown-item"
																						href="#"
																					>
																						Side
																						Header
																						Offcanvas
																					</a>
																					<ul className="dropdown-menu">
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-headers-side-header-offcanvas-push.html"
																							>
																								Push
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-headers-side-header-offcanvas-slide.html"
																							>
																								Slide
																							</a>
																						</li>
																					</ul>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-side-header-narrow-bar.html"
																					>
																						Side
																						Header
																						Narrow
																						Bar
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-headers-sign-in-sign-up.html"
																			>
																				Sign
																				In
																				/
																				Sign
																				Up
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-headers-logged.html"
																			>
																				Logged
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-headers-mini-cart.html"
																			>
																				Mini
																				Cart
																			</a>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Search
																				Styles
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-search-simple-input.html"
																					>
																						Simple
																						Input
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-search-simple-input-reveal.html"
																					>
																						Simple
																						Input
																						Reveal
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-search-dropdown.html"
																					>
																						Dropdown
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-search-big-input-hidden.html"
																					>
																						Big
																						Input
																						Hidden
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-search-full-screen.html"
																					>
																						Full
																						Screen
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Extra
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-extra-big-icon.html"
																					>
																						Big
																						Icon
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-extra-big-icons-top.html"
																					>
																						Big
																						Icons
																						Top
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-extra-button.html"
																					>
																						Button
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-headers-extra-background-color.html"
																					>
																						Background
																						Color
																					</a>
																				</li>
																			</ul>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Navigations
																	</a>
																	<ul className="dropdown-menu">
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Pills
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-pills.html"
																					>
																						Pills
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-pills-arrows.html"
																					>
																						Pills
																						+
																						Arrows
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-pills-dark-text.html"
																					>
																						Pills
																						Dark
																						Text
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-pills-color-dropdown.html"
																					>
																						Pills
																						Color
																						Dropdown
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-pills-square.html"
																					>
																						Pills
																						Square
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-pills-rounded.html"
																					>
																						Pills
																						Rounded
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Stripes
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-stripe.html"
																					>
																						Stripe
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-stripe-dark-text.html"
																					>
																						Stripe
																						Dark
																						Text
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-stripe-color-dropdown.html"
																					>
																						Stripe
																						Color
																						Dropdown
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Hover
																				Effects
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-hover-top-line.html"
																					>
																						Top
																						Line
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-hover-top-line-animated.html"
																					>
																						Top
																						Line
																						Animated
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-hover-top-line-color-dropdown.html"
																					>
																						Top
																						Line
																						Color
																						Dropdown
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-hover-bottom-line.html"
																					>
																						Bottom
																						Line
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-hover-bottom-line-animated.html"
																					>
																						Bottom
																						Line
																						Animated
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-hover-slide.html"
																					>
																						Slide
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-hover-sub-title.html"
																					>
																						Sub
																						Title
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-hover-line-under-text.html"
																					>
																						Line
																						Under
																						Text
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Vertical
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-vertical-dropdown.html"
																					>
																						Dropdown
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-vertical-expand.html"
																					>
																						Expand
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-vertical-columns.html"
																					>
																						Columns
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-vertical-slide.html"
																					>
																						Slide
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Hamburguer
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-hamburguer-sidebar.html"
																					>
																						Sidebar
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-hamburguer-overlay.html"
																					>
																						Overlay
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Dropdown
																				Styles
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-dropdowns-dark.html"
																					>
																						Dark
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-dropdowns-light.html"
																					>
																						Light
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-dropdowns-colors.html"
																					>
																						Colors
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-dropdowns-top-line.html"
																					>
																						Top
																						Line
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-dropdowns-square.html"
																					>
																						Square
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-dropdowns-arrow.html"
																					>
																						Arrow
																						Dropdown
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-dropdowns-arrow-center.html"
																					>
																						Arrow
																						Center
																						Dropdown
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-dropdowns-modern-light.html"
																					>
																						Modern
																						Light
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-dropdowns-modern-dark.html"
																					>
																						Modern
																						Dark
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Dropdown
																				Effects
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-dropdowns-effect-no-effect.html"
																					>
																						No
																						Effect
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-dropdowns-effect-opacity.html"
																					>
																						Opacity
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-dropdowns-effect-move-to-top.html"
																					>
																						Move
																						To
																						Top
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-dropdowns-effect-move-to-bottom.html"
																					>
																						Move
																						To
																						Bottom
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-dropdowns-effect-move-to-right.html"
																					>
																						Move
																						To
																						Right
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-dropdowns-effect-move-to-left.html"
																					>
																						Move
																						To
																						Left
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Font
																				Styles
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-font-small.html"
																					>
																						Small
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-font-medium.html"
																					>
																						Medium
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-font-large.html"
																					>
																						Large
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-font-alternative.html"
																					>
																						Alternative
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Icons
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-icons.html"
																					>
																						Icons
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-navigations-icons-float-icons.html"
																					>
																						Float
																						Icons
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-navigations-sub-title.html"
																			>
																				Sub
																				Title
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-navigations-divisors.html"
																			>
																				Divisors
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-navigations-logo-between.html"
																			>
																				Logo
																				Between
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-navigations-one-page.html"
																			>
																				One
																				Page
																				Nav
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-navigations-click-to-open.html"
																			>
																				Click
																				To
																				Open
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Page
																		Headers
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-page-headers-overview.html"
																			>
																				Overview
																			</a>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Classic
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-page-headers-classic-small.html"
																					>
																						Small
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-page-headers-classic-medium.html"
																					>
																						Medium
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-page-headers-classic-large.html"
																					>
																						Large
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Modern
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-page-headers-modern-small.html"
																					>
																						Small
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-page-headers-modern-medium.html"
																					>
																						Medium
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-page-headers-modern-large.html"
																					>
																						Large
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Colors
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-page-headers-colors-primary.html"
																					>
																						Primary
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-page-headers-colors-secondary.html"
																					>
																						Secondary
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-page-headers-colors-tertiary.html"
																					>
																						Tertiary
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-page-headers-colors-quaternary.html"
																					>
																						Quaternary
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-page-headers-colors-light.html"
																					>
																						Light
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-page-headers-colors-dark.html"
																					>
																						Dark
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Title
																				Position
																			</a>
																			<ul className="dropdown-menu">
																				<li className="dropdown-submenu">
																					<a
																						className="dropdown-item"
																						href="#"
																					>
																						Left
																					</a>
																					<ul className="dropdown-menu">
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-page-headers-title-position-left-small.html"
																							>
																								Small
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-page-headers-title-position-left-medium.html"
																							>
																								Medium
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-page-headers-title-position-left-large.html"
																							>
																								Large
																							</a>
																						</li>
																					</ul>
																				</li>
																				<li className="dropdown-submenu">
																					<a
																						className="dropdown-item"
																						href="#"
																					>
																						Right
																					</a>
																					<ul className="dropdown-menu">
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-page-headers-title-position-right-small.html"
																							>
																								Small
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-page-headers-title-position-right-medium.html"
																							>
																								Medium
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-page-headers-title-position-right-large.html"
																							>
																								Large
																							</a>
																						</li>
																					</ul>
																				</li>
																				<li className="dropdown-submenu">
																					<a
																						className="dropdown-item"
																						href="#"
																					>
																						Center
																					</a>
																					<ul className="dropdown-menu">
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-page-headers-title-position-center-small.html"
																							>
																								Small
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-page-headers-title-position-center-medium.html"
																							>
																								Medium
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-page-headers-title-position-center-large.html"
																							>
																								Large
																							</a>
																						</li>
																					</ul>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Background
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-page-headers-background-fixed.html"
																					>
																						Fixed
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-page-headers-background-parallax.html"
																					>
																						Parallax
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-page-headers-background-video.html"
																					>
																						Video
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-page-headers-background-transparent-header.html"
																					>
																						Transparent
																						Header
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-page-headers-background-pattern.html"
																					>
																						Pattern
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-page-headers-background-overlay.html"
																					>
																						Overlay
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-page-headers-background-clean.html"
																					>
																						Clean
																						(No
																						Background)
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Extra
																			</a>
																			<ul className="dropdown-menu">
																				<li className="dropdown-submenu">
																					<a
																						className="dropdown-item"
																						href="#"
																					>
																						Breadcrumb
																					</a>
																					<ul className="dropdown-menu">
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-page-headers-extra-breadcrumb-outside.html"
																							>
																								Outside
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-page-headers-extra-breadcrumb-dark.html"
																							>
																								Dark
																							</a>
																						</li>
																					</ul>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-page-headers-extra-scroll-to-content.html"
																					>
																						Scroll
																						to
																						Content
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-page-headers-extra-full-width.html"
																					>
																						Full
																						Width
																					</a>
																				</li>
																			</ul>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Footers
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-footers-overview.html"
																			>
																				Overview
																			</a>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Classic
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-footers-classic.html#footer"
																					>
																						Classic
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-footers-classic-advanced.html#footer"
																					>
																						Advanced
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-footers-classic-compact.html#footer"
																					>
																						Compact
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-footers-classic-simple.html#footer"
																					>
																						Simple
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-footers-classic-locations.html#footer"
																					>
																						Locations
																					</a>
																				</li>
																				<li className="dropdown-submenu">
																					<a
																						className="dropdown-item"
																						href="#"
																					>
																						Copyright
																					</a>
																					<ul className="dropdown-menu">
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-footers-classic-copyright-light.html#footer"
																							>
																								Light
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-footers-classic-copyright-dark.html#footer"
																							>
																								Dark
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-footers-classic-copyright-social-icons.html#footer"
																							>
																								Social
																								Icons
																							</a>
																						</li>
																					</ul>
																				</li>
																				<li className="dropdown-submenu">
																					<a
																						className="dropdown-item"
																						href="#"
																					>
																						Colors
																					</a>
																					<ul className="dropdown-menu">
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-footers-classic-colors-primary.html#footer"
																							>
																								Primary
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-footers-classic-colors-secondary.html#footer"
																							>
																								Secondary
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-footers-classic-colors-tertiary.html#footer"
																							>
																								Tertiary
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-footers-classic-colors-quaternary.html#footer			"
																							>
																								Quaternary
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-footers-classic-colors-light.html#footer"
																							>
																								Light
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-footers-classic-colors-light-simple.html#footer"
																							>
																								Light
																								Simple
																							</a>
																						</li>
																					</ul>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Modern
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-footers-modern.html#footer"
																					>
																						Modern
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-footers-modern-font-style-alternative.html#footer"
																					>
																						Font
																						Style
																						Alternative
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-footers-modern-clean.html#footer"
																					>
																						Clean
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-footers-modern-useful-links.html#footer"
																					>
																						Useful
																						Links
																					</a>
																				</li>
																				<li className="dropdown-submenu">
																					<a
																						className="dropdown-item"
																						href="#"
																					>
																						Background
																					</a>
																					<ul className="dropdown-menu">
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-footers-modern-background-image-simple.html#footer"
																							>
																								Image
																								Simple
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-footers-modern-background-image-advanced.html#footer"
																							>
																								Image
																								Advanced
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-footers-modern-background-video-simple.html#footer"
																							>
																								Video
																								Simple
																							</a>
																						</li>
																					</ul>
																				</li>
																				<li className="dropdown-submenu">
																					<a
																						className="dropdown-item"
																						href="#"
																					>
																						Call
																						to
																						Action
																					</a>
																					<ul className="dropdown-menu">
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-footers-modern-call-to-action-button.html#footer"
																							>
																								Button
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-footers-modern-call-to-action-simple.html#footer"
																							>
																								Simple
																							</a>
																						</li>
																					</ul>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Blog
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-footers-blog-classic.html#footer"
																					>
																						Blog
																						Classic
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				eCommerce
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-footers-ecommerce-classic.html#footer"
																					>
																						eCommerce
																						Classic
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Contact
																				Form
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-footers-contact-form-classic.html#footer"
																					>
																						Classic
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-footers-contact-form-above-the-map.html#footer"
																					>
																						Above
																						the
																						Map
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-footers-contact-form-center.html#footer"
																					>
																						Center
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-footers-contact-form-columns.html#footer"
																					>
																						Columns
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Map
																			</a>
																			<ul className="dropdown-menu">
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-footers-map-hidden.html#footer"
																					>
																						Hidden
																						Map
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-footers-map-full-width.html#footer"
																					>
																						Full
																						Width
																					</a>
																				</li>
																			</ul>
																		</li>
																		<li className="dropdown-submenu">
																			<a
																				className="dropdown-item"
																				href="#"
																			>
																				Extra
																			</a>
																			<ul className="dropdown-menu">
																				<li className="dropdown-submenu">
																					<a
																						className="dropdown-item"
																						href="#"
																					>
																						Simple
																					</a>
																					<ul className="dropdown-menu">
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-footers-extra-top-social-icons.html#footer"
																							>
																								Top
																								Social
																								Icons
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-footers-extra-big-icons.html#footer"
																							>
																								Big
																								Icons
																							</a>
																						</li>
																					</ul>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-footers-extra-recent-work.html#footer"
																					>
																						Recent
																						Work
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-footers-extra-reveal.html#footer"
																					>
																						Reveal
																					</a>
																				</li>
																				<li>
																					<a
																						className="dropdown-item"
																						href="feature-footers-extra-instagram.html#footer"
																					>
																						Instagram
																					</a>
																				</li>
																				<li className="dropdown-submenu">
																					<a
																						className="dropdown-item"
																						href="#"
																					>
																						Full
																						Width
																					</a>
																					<ul className="dropdown-menu">
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-footers-extra-full-width-light.html#footer"
																							>
																								Simple
																								Light
																							</a>
																						</li>
																						<li>
																							<a
																								className="dropdown-item"
																								href="feature-footers-extra-full-width-dark.html#footer"
																							>
																								Simple
																								Dark
																							</a>
																						</li>
																					</ul>
																				</li>
																			</ul>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Admin
																		Extension{" "}
																		<span className="tip tip-dark">
																			hot
																		</span>
																		<em className="not-included">
																			(Not
																			Included)
																		</em>
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-admin-forms-basic.html"
																			>
																				Forms
																				Basic
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-admin-forms-advanced.html"
																			>
																				Forms
																				Advanced
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-admin-forms-wizard.html"
																			>
																				Forms
																				Wizard
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-admin-forms-code-editor.html"
																			>
																				Code
																				Editor
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-admin-tables-advanced.html"
																			>
																				Tables
																				Advanced
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-admin-tables-responsive.html"
																			>
																				Tables
																				Responsive
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-admin-tables-editable.html"
																			>
																				Tables
																				Editable
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-admin-tables-ajax.html"
																			>
																				Tables
																				Ajax
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-admin-charts.html"
																			>
																				Charts
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Sliders
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-slider-revolution.html"
																			>
																				Revolution
																				Slider
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="index-slider-owl.html"
																			>
																				Owl
																				Slider
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Layout
																		Options
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-layout-boxed.html"
																			>
																				Boxed
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-layout-dark.html"
																			>
																				Dark
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-layout-rtl.html"
																			>
																				RTL
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Extra
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-grid-system.html"
																			>
																				Grid
																				System
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-page-loading.html"
																			>
																				Page
																				Loading
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-page-transition.html"
																			>
																				Page
																				Transition
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-lazy-load.html"
																			>
																				Lazy
																				Load
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="feature-side-panel.html"
																			>
																				Side
																				Panel
																			</a>
																		</li>
																	</ul>
																</li>
															</ul>
														</li>
														<li className="dropdown">
															<a
																className="dropdown-item dropdown-toggle"
																href="#"
															>
																Pages
															</a>
															<ul className="dropdown-menu">
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Contact
																		Us
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="contact-us-advanced.php"
																			>
																				Contact
																				Us
																				-
																				Advanced
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="contact-us.html"
																			>
																				Contact
																				Us
																				-
																				Basic
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="contact-us-recaptcha.html"
																			>
																				Contact
																				Us
																				-
																				Recaptcha
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		About Us
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="about-us-advanced.html"
																			>
																				About
																				Us
																				-
																				Advanced
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="about-us.html"
																			>
																				About
																				Us
																				-
																				Basic
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="about-me.html"
																			>
																				About
																				Me
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Layouts
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="page-full-width.html"
																			>
																				Full
																				Width
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="page-left-sidebar.html"
																			>
																				Left
																				Sidebar
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="page-right-sidebar.html"
																			>
																				Right
																				Sidebar
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="page-left-and-right-sidebars.html"
																			>
																				Left
																				and
																				Right
																				Sidebars
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="page-sticky-sidebar.html"
																			>
																				Sticky
																				Sidebar
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="page-secondary-navbar.html"
																			>
																				Secondary
																				Navbar
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Extra
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="page-404.html"
																			>
																				404
																				Error
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="page-500.html"
																			>
																				500
																				Error
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="page-coming-soon.html"
																			>
																				Coming
																				Soon
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="page-maintenance-mode.html"
																			>
																				Maintenance
																				Mode
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="page-search-results.html"
																			>
																				Search
																				Results
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="sitemap.html"
																			>
																				Sitemap
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Team
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="page-team-advanced.html"
																			>
																				Team
																				-
																				Advanced
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="page-team.html"
																			>
																				Team
																				-
																				Basic
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Services
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="page-services.html"
																			>
																				Services
																				-
																				Version
																				1
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="page-services-2.html"
																			>
																				Services
																				-
																				Version
																				2
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="page-services-3.html"
																			>
																				Services
																				-
																				Version
																				3
																			</a>
																		</li>
																	</ul>
																</li>
																<li>
																	<a
																		className="dropdown-item"
																		href="page-custom-header.html"
																	>
																		Custom
																		Header
																	</a>
																</li>
																<li>
																	<a
																		className="dropdown-item"
																		href="page-careers.html"
																	>
																		Careers
																	</a>
																</li>
																<li>
																	<a
																		className="dropdown-item"
																		href="page-faq.html"
																	>
																		FAQ
																	</a>
																</li>
																<li>
																	<a
																		className="dropdown-item"
																		href="page-login.html"
																	>
																		Login /
																		Register
																	</a>
																</li>
																<li>
																	<a
																		className="dropdown-item"
																		href="page-user-profile.html"
																	>
																		User
																		Profile
																	</a>
																</li>
															</ul>
														</li>
														<li className="dropdown">
															<a
																className="dropdown-item dropdown-toggle"
																href="#"
															>
																Portfolio
															</a>
															<ul className="dropdown-menu">
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Single
																		Project
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-single-wide-slider.html"
																			>
																				Wide
																				Slider
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-single-small-slider.html"
																			>
																				Small
																				Slider
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-single-full-width-slider.html"
																			>
																				Full
																				Width
																				Slider
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-single-gallery.html"
																			>
																				Gallery
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-single-carousel.html"
																			>
																				Carousel
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-single-medias.html"
																			>
																				Medias
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-single-full-width-video.html"
																			>
																				Full
																				Width
																				Video
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-single-masonry-images.html"
																			>
																				Masonry
																				Images
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-single-left-sidebar.html"
																			>
																				Left
																				Sidebar
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-single-right-sidebar.html"
																			>
																				Right
																				Sidebar
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-single-left-and-right-sidebars.html"
																			>
																				Left
																				and
																				Right
																				Sidebars
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-single-sticky-sidebar.html"
																			>
																				Sticky
																				Sidebar
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-single-extended.html"
																			>
																				Extended
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Grid
																		Layouts
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-grid-1-column.html"
																			>
																				1
																				Column
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-grid-2-columns.html"
																			>
																				2
																				Columns
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-grid-3-columns.html"
																			>
																				3
																				Columns
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-grid-4-columns.html"
																			>
																				4
																				Columns
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-grid-5-columns.html"
																			>
																				5
																				Columns
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-grid-6-columns.html"
																			>
																				6
																				Columns
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-grid-no-margins.html"
																			>
																				No
																				Margins
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-grid-full-width.html"
																			>
																				Full
																				Width
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-grid-full-width-no-margins.html"
																			>
																				Full
																				Width
																				No
																				Margins
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-grid-1-column-title-and-description.html"
																			>
																				Title
																				and
																				Description
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Masonry
																		Layouts
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-masonry-2-columns.html"
																			>
																				2
																				Columns
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-masonry-3-columns.html"
																			>
																				3
																				Columns
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-masonry-4-columns.html"
																			>
																				4
																				Columns
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-masonry-5-columns.html"
																			>
																				5
																				Columns
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-masonry-6-columns.html"
																			>
																				6
																				Columns
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-masonry-no-margins.html"
																			>
																				No
																				Margins
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-masonry-full-width.html"
																			>
																				Full
																				Width
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Sidebar
																		Layouts
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-sidebar-left.html"
																			>
																				Left
																				Sidebar
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-sidebar-right.html"
																			>
																				Right
																				Sidebar
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-sidebar-left-and-right.html"
																			>
																				Left
																				and
																				Right
																				Sidebars
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-sidebar-sticky.html"
																			>
																				Sticky
																				Sidebar
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Ajax
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-ajax-page.html"
																			>
																				Ajax
																				on
																				Page
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-ajax-modal.html"
																			>
																				Ajax
																				on
																				Modal
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Extra
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-extra-timeline.html"
																			>
																				Timeline
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-extra-lightbox.html"
																			>
																				Lightbox
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-extra-load-more.html"
																			>
																				Load
																				More
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-extra-infinite-scroll.html"
																			>
																				Infinite
																				Scroll
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-extra-pagination.html"
																			>
																				Pagination
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="portfolio-extra-combination-filters.html"
																			>
																				Combination
																				Filters
																			</a>
																		</li>
																	</ul>
																</li>
															</ul>
														</li>
														<li className="dropdown">
															<a
																className="dropdown-item dropdown-toggle"
																href="#"
															>
																Blog
															</a>
															<ul className="dropdown-menu">
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Large
																		Image
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-large-image-full-width.html"
																			>
																				Full
																				Width
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-large-image-sidebar-left.html"
																			>
																				Left
																				Sidebar
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-large-image-sidebar-right.html"
																			>
																				Right
																				Sidebar{" "}
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-large-image-sidebar-left-and-right.html"
																			>
																				Left
																				and
																				Right
																				Sidebar
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Medium
																		Image
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-medium-image-sidebar-left.html"
																			>
																				Left
																				Sidebar
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-medium-image-sidebar-right.html"
																			>
																				Right
																				Sidebar{" "}
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Grid
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-grid-4-columns.html"
																			>
																				4
																				Columns
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-grid-3-columns.html"
																			>
																				3
																				Columns
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-grid-full-width.html"
																			>
																				Full
																				Width
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-grid-no-margins.html"
																			>
																				No
																				Margins
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-grid-no-margins-full-width.html"
																			>
																				No
																				Margins
																				Full
																				Width
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-grid-sidebar-left.html"
																			>
																				Left
																				Sidebar
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-grid-sidebar-right.html"
																			>
																				Right
																				Sidebar{" "}
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-grid-sidebar-left-and-right.html"
																			>
																				Left
																				and
																				Right
																				Sidebar
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Masonry
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-masonry-4-columns.html"
																			>
																				4
																				Columns
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-masonry-3-columns.html"
																			>
																				3
																				Columns
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-masonry-full-width.html"
																			>
																				Full
																				Width
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-masonry-no-margins.html"
																			>
																				No
																				Margins
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-masonry-no-margins-full-width.html"
																			>
																				No
																				Margins
																				Full
																				Width
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-masonry-sidebar-left.html"
																			>
																				Left
																				Sidebar
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-masonry-sidebar-right.html"
																			>
																				Right
																				Sidebar{" "}
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Timeline
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-timeline.html"
																			>
																				Full
																				Width
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-timeline-sidebar-left.html"
																			>
																				Left
																				Sidebar
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-timeline-sidebar-right.html"
																			>
																				Right
																				Sidebar{" "}
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Single
																		Post
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-post.html"
																			>
																				Full
																				Width
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-post-slider-gallery.html"
																			>
																				Slider
																				Gallery
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-post-image-gallery.html"
																			>
																				Image
																				Gallery
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-post-embedded-video.html"
																			>
																				Embedded
																				Video
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-post-html5-video.html"
																			>
																				HTML5
																				Video
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-post-blockquote.html"
																			>
																				Blockquote
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-post-link.html"
																			>
																				Link
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-post-embedded-audio.html"
																			>
																				Embedded
																				Audio
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-post-small-image.html"
																			>
																				Small
																				Image
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-post-sidebar-left.html"
																			>
																				Left
																				Sidebar
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-post-sidebar-right.html"
																			>
																				Right
																				Sidebar{" "}
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-post-sidebar-left-and-right.html"
																			>
																				Left
																				and
																				Right
																				Sidebar
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Post
																		Comments
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-post.html#comments"
																			>
																				Default
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-post-comments-facebook.html#comments"
																			>
																				Facebook
																				Comments
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="blog-post-comments-disqus.html#comments"
																			>
																				Disqus
																				Comments
																			</a>
																		</li>
																	</ul>
																</li>
															</ul>
														</li>
														<li className="dropdown">
															<a
																className="dropdown-item dropdown-toggle"
																href="#"
															>
																Shop
															</a>
															<ul className="dropdown-menu">
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		Single
																		Product
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="shop-product-full-width.html"
																			>
																				Full
																				Width
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="shop-product-sidebar-left.html"
																			>
																				Left
																				Sidebar
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="shop-product-sidebar-right.html"
																			>
																				Right
																				Sidebar
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="shop-product-sidebar-left-and-right.html"
																			>
																				Left
																				and
																				Right
																				Sidebar
																			</a>
																		</li>
																	</ul>
																</li>
																<li>
																	<a
																		className="dropdown-item"
																		href="shop-4-columns.html"
																	>
																		4
																		Columns
																	</a>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		3
																		Columns
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="shop-3-columns-full-width.html"
																			>
																				Full
																				Width
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="shop-3-columns-sidebar-left.html"
																			>
																				Left
																				Sidebar
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="shop-3-columns-sidebar-right.html"
																			>
																				Right
																				Sidebar{" "}
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		2
																		Columns
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="shop-2-columns-full-width.html"
																			>
																				Full
																				Width
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="shop-2-columns-sidebar-left.html"
																			>
																				Left
																				Sidebar
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="shop-2-columns-sidebar-right.html"
																			>
																				Right
																				Sidebar{" "}
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="shop-2-columns-sidebar-left-and-right.html"
																			>
																				Left
																				and
																				Right
																				Sidebar
																			</a>
																		</li>
																	</ul>
																</li>
																<li className="dropdown-submenu">
																	<a
																		className="dropdown-item"
																		href="#"
																	>
																		1 Column
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				className="dropdown-item"
																				href="shop-1-column-full-width.html"
																			>
																				Full
																				Width
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="shop-1-column-sidebar-left.html"
																			>
																				Left
																				Sidebar
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="shop-1-column-sidebar-right.html"
																			>
																				Right
																				Sidebar{" "}
																			</a>
																		</li>
																		<li>
																			<a
																				className="dropdown-item"
																				href="shop-1-column-sidebar-left-and-right.html"
																			>
																				Left
																				and
																				Right
																				Sidebar
																			</a>
																		</li>
																	</ul>
																</li>
																<li>
																	<a
																		className="dropdown-item"
																		href="shop-cart.html"
																	>
																		Cart
																	</a>
																</li>
																<li>
																	<a
																		className="dropdown-item"
																		href="shop-login.html"
																	>
																		Login
																	</a>
																</li>
																<li>
																	<a
																		className="dropdown-item"
																		href="shop-checkout.html"
																	>
																		Checkout
																	</a>
																</li>
																<li>
																	<a
																		className="dropdown-item"
																		href="shop-order-complete.html"
																	>
																		Order
																		Complete
																	</a>
																</li>
															</ul>
														</li>
													</ul>
												</nav>
											</div>
											<a
												className="btn btn-gradient btn-rounded font-weight-semibold px-4 ml-3"
												href="https://themeforest.net/item/porto-responsive-html5-template/4106987"
												target="_blank"
											>
												Start Shop
											</a>
											<button
												className="btn header-btn-collapse-nav"
												data-toggle="collapse"
												data-target=".header-nav-main nav"
											>
												<i className="fa fa-bars" />
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
				<div role="main" className="main">
					<section
						className="section section-concept section-no-border section-dark section-angled section-angled-reverse pt-5 m-0"
						id="section-concept"
						style={{
							backgroundSize: "cover",
							backgroundPosition: "center",
							animationDuration: "750ms",
							animationDelay: "300ms",
							animationFillMode: "forwards",
						}}
						data-plugin-lazyload
						data-plugin-options="{'threshold': 500}"
						data-original="img/header_bg.jpg"
					>
						<div
							className="section-angled-layer-bottom bg-light"
							style={{ padding: "8rem 0" }}
						/>
						<div className="container pt-5 mt-5">
							<div className="row align-items-center pt-3">
								<div className="col-lg-5 mb-5">
									<h5
										className="text-primary font-weight-bold mb-1 appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-duration={750}
									>
										Start selling
									</h5>
									<h1
										className="font-weight-bold text-12 line-height-2 mb-3 appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={400}
										data-appear-animation-duration={750}
									>
										You Sell We take care of the
										<span
											className="appear-animation"
											data-appear-animation="fadeInRightShorter"
											data-appear-animation-delay={600}
											data-appear-animation-duration={800}
										>
											<span className="d-inline-block text-primary highlighted-word highlighted-word-rotate highlighted-word-animation-1 alternative-font-3 font-weight-bold text-1 ml-2">
												rest
											</span>
										</span>
									</h1>
									<p
										className="custom-font-size-1 appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={900}
										data-appear-animation-duration={750}
									>
										Shopperr is a platform offering 100,000+ products for you to resell.
										 You can easily sync the selected products to your store.
										  Shopperr will ship them directly to your customers..{" "}
										<a
											href="#intro"
											data-hash
											data-hash-offset={120}
											className="text-color-light font-weight-semibold text-1 d-block"
										>
											VIEW MORE{" "}
											<i className="fa fa-long-arrow-alt-right ml-1" />
										</a>
									</p>
									<a
										className="video-open lightbox d-block text-color-light appear-animation"
										href="#popup-content-1"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={1100}
										data-appear-animation-duration={750}
										data-plugin-options="{'type':'inline'}"
									>
										<div className="video-open-icon" />
										Shop Now
									</a>
									<div
										id="popup-content-1"
										className="dialog dialog-lg zoom-anim-dialog rounded p-3 mfp-hide mfp-close-out"
									>
										<div className="embed-responsive embed-responsive-4by3">
											<video
												width="100%"
												height="100%"
												autoPlay
												muted
												loop
												controls
												preload="none"
											>
												<source
													src="img/Shopperr.mp4"
													type="video/mp4"
												/>
											</video>
										</div>
									</div>
								</div>
								<div
									className="col-lg-6 offset-lg-1 mb-5 appear-animation"
									data-appear-animation="fadeIn"
									data-appear-animation-delay={1200}
									data-appear-animation-duration={750}
								>
									<div className="border-width-10 border-color-light clearfix border border-radius">
										<video
											className="float-left"
											width="100%"
											height="100%"
											autoPlay
											muted
											loop
											preload="metadata"
										>
											<source
												src="img/Shopperr.mp4"
												type="video/mp4"
											/>
											
										</video>
									</div>
								</div>
								<div
									className="col-md-8 col-lg-6 col-xl-5 custom-header-bar py-4 pr-5 appear-animation z-index-2"
									data-appear-animation="maskRight"
									data-appear-animation-delay={1200}
									data-appear-animation-duration={750}
								>
									<div
										className="appear-animation"
										data-appear-animation="fadeInRightShorter"
										data-appear-animation-delay={1500}
									>
										<div className="star-rating-wrap d-flex align-items-center justify-content-center position-relative text-dark text-5 py-2 mb-2">
											<i className="fas fa-star" />
											<i className="fas fa-star ml-1" />
											<i className="fas fa-star ml-1" />
											<i className="fas fa-star ml-1" />
											<i className="fas fa-star ml-1" />
										</div>
										<h4 className="position-relative text-center font-weight-bold text-7 line-height-2 mb-0">
											Popular HTML5 Site Template
										</h4>
										<p className="position-relative text-center font-weight-normal mb-1">
											* Aproved by 35k buyers since 2013
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						id="intro"
						className="section section-no-border section-angled bg-light pt-0 pb-5 m-0"
					>
						<div
							className="section-angled-layer-bottom section-angled-layer-increase-angle bg-color-light-scale-1"
							style={{ padding: "21rem 0" }}
						/>
						<div
							className="container pb-5"
							style={{ minHeight: "1000px" }}
						>
							<div className="row mb-5 pb-lg-3 counters">
								<div className="col-lg-10 text-center offset-lg-1">
									<h2 className="font-weight-bold text-9 mb-0 ">
										Why Resell with us
										<br />
										Shopperr gives you access to thousands of awesome products.
									</h2>
									<p
										className="sub-title text-primary text-4 font-weight-semibold positive-ls-2 mt-2 mb-4 appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={400}
										data-appear-animation-duration={750}
									>
										Looking for one to sell in {" "}
										<span className="highlighted-word highlighted-word-animation-1 highlighted-word-animation-1-2 highlighted-word-animation-1 highlighted-word-animation-1-no-rotate alternative-font-4 font-weight-semibold line-height-2 pb-2">
											your store?
										</span>
									</p>
									<p
										className="text-1rem text-color-default negative-ls-05 pt-3 pb-4 mb-5 appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={500}
										data-appear-animation-duration={750}
									>
										We source for high-demand products from reputable manufacturers in China.
										 We buy cheap and keep them in stock.
										 We perform inbound QC in our warehouses. Unlike most other dropship models,
										 you are dealing directly with us only, instead of with multiple suppliers.
										 You do not need to buy goods or invest in them in advance. You only pay for products that you sell to paying customers.
										 We sell to you at wholesale price, with no minimum order quantity requirement.
										 With an extensive range of over 100,000 SKU's, you can list some or all our products as if they belong to you.
										 You decide the price markup and difference is your profit!
										 We provide product information via Excel spreadsheet which you can modify accordingly 
										 and then re-upload to marketplace or your Webstore. API integration is also possible.
										 Listing can be done with just a few clicks. We take care of order fulfillment, prepare
										 all necessary paperwork for dispatch and dropship directly to your end-customer.
									</p>
								</div>
								<div className="col-sm-6 col-lg-4 offset-lg-2 counter mb-5 mb-md-0">
									<div
										className="appear-animation"
										data-appear-animation="fadeInLeftShorter"
										data-appear-animation-delay={750}
										data-appear-animation-duration={750}
									>
										<h3
											className="font-weight-extra-bold text-14 line-height-1 mb-2"
											data-to={80}
											data-append="+"
										>
											0
										</h3>
										<label className="font-weight-semibold negative-ls-1 text-color-dark mb-0">
											Large Assortment
										</label>
										<p className="text-color-grey font-weight-semibold pb-1 mb-2">
											Over 100,000 products
										    and weekly renewal.
										</p>
										<p className="mb-0">
											<a
												href="#demos"
												data-hash
												data-hash-offset={120}
												className="text-color-primary d-flex align-items-center justify-content-center text-4 font-weight-semibold text-decoration-none"
											>
												VIEW PRODUCTS{" "}
												<i className="fas fa-long-arrow-alt-right ml-2 text-4 mb-0" />
											</a>
										</p>
									</div>
								</div>
								<div className="col-sm-6 col-lg-4 counter divider-left-border">
									<div
										className="appear-animation"
										data-appear-animation="fadeInRightShorter"
										data-appear-animation-delay={750}
										data-appear-animation-duration={750}
									>
										<h3
											className="font-weight-extra-bold text-14 line-height-1 mb-2"
											data-to={35}
											data-append="K+"
										>
											0
										</h3>
										<label className="font-weight-semibold negative-ls-1 text-color-dark mb-0">
											Low Product Price
										</label>
										<p className="text-color-grey font-weight-semibold pb-1 mb-2">
											 Wholesale price with no MOQ requirement.
										</p>
										<p className="mb-0">
											<a
												href="https://themeforest.net/item/porto-responsive-html5-template/4106987"
												className="text-color-primary d-flex align-items-center justify-content-center text-4 font-weight-semibold text-decoration-none"
												target="_blank"
											>
												VIEW PRODUCTS{" "}
												<i className="fas fa-long-arrow-alt-right ml-2 text-4 mb-0" />
											</a>
										</p>
									</div>
								</div>
							</div>
							<div className="intro row align-items-center justify-content-center justify-content-md-start">
								<div className="col-3 pr-0 pl-3 z-index-2">
									<img
										src="https://cdn.shopify.com/s/files/1/0499/4347/8430/files/226x538_1_226x538_crop_center.jpg?v=1627034741"
										data-plugin-lazyload
										data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
										data-original="img/landing/intro2.jpg"
										className="img-fluid border border-width-10 border-color-light box-shadow-3 rounded d-none d-md-block appear-animation"
										alt="Screenshot 2"
										data-appear-animation="fadeInUp"
										data-appear-animation-delay={600}
									/>
									<div
										className="position-absolute d-none d-md-flex align-items-end appear-animation"
										data-appear-animation="fadeInLeftShorter"
										data-appear-animation-delay={900}
										style={{
											bottom: "-60px",
											right: "-90px",
										}}
									>
										<span
											className="arrow hlt"
											style={{ marginRight: "-70px" }}
										/>
										<strong className="text-color-dark mb-4 pb-3">
											modern designs!
										</strong>
									</div>
								</div>
								<div className="col-11 col-md-9 pl-0 pr-5 pb-5 pb-md-0 mb-5 mb-md-0">
									<div
										className="intro2 pt-5 pt-md-0 mt-5 mt-lg-0 appear-animation pr-5"
										data-appear-animation="fadeInUp"
										data-appear-animation-delay={400}
									>
										<img
											src="https://cdn.shopify.com/s/files/1/0499/4347/8430/files/831x476_831x476_crop_center.jpg?v=1627033267"
											data-plugin-lazyload
											data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
											data-original="img/landing/intro1.jpg"
											className="img-fluid box-shadow-3 position-relative z-index-1 rounded"
											alt="Screenshot 1"
											style={{ left: "-110px" }}
										/>
									</div>
									<div
										className="intro3 z-index-3 position-absolute appear-animation"
										data-appear-animation="fadeInUp"
										data-appear-animation-delay={800}
										style={{ top: "20%", right: "4%" }}
									>
										<img
											src="https://cdn.shopify.com/s/files/1/0499/4347/8430/files/411x256_411x256_crop_center.jpg?v=1627033267"
											data-plugin-lazyload
											data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
											data-original="img/landing/intro3.jpg"
											className="img-fluid border border-width-10 border-color-light box-shadow-3 rounded"
											alt="Screenshot 3"
										/>
										<div
											className="position-absolute d-none d-md-flex align-items-end appear-animation"
											data-appear-animation="fadeInUpShorter"
											data-appear-animation-delay={1100}
											style={{
												bottom: "-135px",
												right: "-20px",
											}}
										>
											<strong className="text-color-dark mb-3">
												a lot of demos!
											</strong>
											<span className="arrow hru" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="section section-no-border pb-0 m-0">
						<div className="container">
							<div className="row align-items-center justify-content-between">
								<div className="col-lg-4">
									<h2 className="text-7 font-weight-semibold line-height-2 mb-2">
										Large Assortment .
									</h2>
									<p>
										Over 100,000 products and weekly renewal.
									</p>
								</div>
								<div className="col-sm-4 col-lg-auto icon-box text-center mb-4">
									<i class="fas fa-tags" style={{ fontSize: "80px"}}></i>
									<h4 className="font-weight-bold custom-font-size-2 line-height-3 mb-0">
										Low Product Price
										<br />
										
									</h4>
								</div>
								<div className="col-sm-4 col-lg-auto icon-box text-center mb-4">
									<i class="fas fa-list" style={{ fontSize: "80px"}}></i>
									<h4 className="font-weight-bold custom-font-size-2 line-height-3 mb-0">
										Easy Listing
										<br />

									</h4>
								</div>
								<div className="col-sm-4 col-lg-auto icon-box text-center mb-4">
									<i class="fas fa-crosshairs" style={{ fontSize: "80px"}}></i>
									<h4 className="font-weight-bold custom-font-size-2 line-height-3 mb-0">
										Shipment Transparency
										<br />
										
									</h4>
								</div>
								<div className="col-sm-12">
									<hr className="my-5" />
									<h2 className="font-weight-bold text-center text-10 pt-3 mb-4">
										What Shopperr Offers You!
									</h2>
								</div>
								<div className="col-lg-8 offset-lg-2 px-lg-0 text-center">
									<p className="text-4">
										Shopperr is a dropshipping platform offering 100,000+ products for you to resell.
										You can easily sync the selected products to your store.
										Shopperr will ship them directly to your customers.
									</p>
								</div>
							</div>
							<div
								className="image-wrapper position-relative z-index-3 appear-animation"
								data-appear-animation="fadeInUpShorter"
								data-appear-animation-delay={500}
								data-appear-animation-duration={750}
								style={{ height: 0, paddingBottom: "16%" }}
							>
								<img
									src="https://cdn.shopify.com/s/files/1/0499/4347/8430/files/1206x501_1206x501_crop_center.png?v=1627283708"
									data-plugin-lazyload
									data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
									data-original="img/landing/theme_options.png"
									className="img-fluid"
									alt="The Most Customizable Template"
								/>
							</div>
						</div>
					</section>
					<section
						className="section section-no-border section-angled section-dark pb-0 m-0"
						style={{
							backgroundRepeat: "no-repeat",
							backgroundColor: "#0169fe !important",
						}}
						data-plugin-lazyload
						data-plugin-options="{'threshold': 500}"
						data-original="img/landing/reason_bg.png"
					>
						<div
							className="section-angled-layer-top section-angled-layer-increase-angle bg-color-light-scale-1"
							style={{ padding: "4rem 0" }}
						/>
						<div className="spacer py-md-4 my-md-5" />
						<div className="container pt-5 mt-5">
							<div className="row align-items-center pt-md-5 mt-5">
								<div className="col-lg-6 offset-lg-1 mb-5">
									<div
										className="appear-animation"
										data-appear-animation="fadeIn"
										data-appear-animation-delay={500}
									>
										<img
											src="img/lazy.png"
											data-plugin-lazyload
											data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
											data-original="img/landing/porto_dots.png"
											width={154}
											height={146}
											className="position-absolute top-auto"
											alt="6 reasons"
											data-plugin-float-element
											style={{
												bottom: "184px",
												left: "70px",
											}}
										/>
									</div>
									<div className="text-reasons">
										<label
											className="text-color-light appear-animation z-index-1"
											data-appear-animation="blurIn"
											data-appear-animation-delay={250}
											data-appear-animation-duration={750}
										>
											6
										</label>
										<h3
											className="text-4 text-md-6 appear-animation"
											data-appear-animation="fadeInRightShorter"
											data-appear-animation-delay={450}
											data-appear-animation-duration={750}
										>
											Reasons
										</h3>
										<h3
											className="text-4 text-md-6 appear-animation"
											data-appear-animation="fadeInRightShorter"
											data-appear-animation-delay={750}
											data-appear-animation-duration={750}
										>
											How does Shopperr
										</h3>
										<h3
											className="text-4 text-md-6 appear-animation"
											data-appear-animation="fadeInRightShorter"
											data-appear-animation-delay={950}
											data-appear-animation-duration={750}
										>
											<span className="highlight-word light">
												work
											</span>
										</h3>
									</div>
								</div>
								<div className="col-lg-5 col-xl-4 pl-lg-4 pl-xl-5 mb-5">
									<h2
										className="text-6 font-weight-semibold line-height-2 mb-2 appear-animation"
										data-appear-animation="fadeInRightShorter"
										data-appear-animation-delay={1150}
										data-appear-animation-duration={750}
									>
										Shopperr is a dropshipping 
										platform.
									</h2>
									<p
										className="custom-text-color-1 line-height-5 appear-animation"
										data-appear-animation="fadeInRightShorter"
										data-appear-animation-delay={1350}
										data-appear-animation-duration={750}
									>
										Shopperr will ship them directly
										 to your customers.
									</p>
								</div>
							</div>
							<div className="row justify-content-center mt-md-5 mb-4 pt-lg-4">
								<div className="col-lg-11">
									<div className="row justify-content-center">
										<div
											className="col-10 col-sm-6 col-lg-4 image-box appear-animation"
											data-appear-animation="fadeInUpShorter"
											data-appear-animation-delay={600}
											data-appear-animation-duration={750}
										>
											<img
												className="img-fluid"
												alt="Speed Performance"
												src="img/Select-products.png"
												data-plugin-lazyload
												data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
												data-original="img/landing/reason1.png"
											/>
											<div className="d-flex align-items-center mb-2">
												<span className="text-color-dark font-weight-extra-bold text-12 mr-2 line-height-1">
													1
												</span>
												<h4 className="d-flex flex-column font-weight-bold text-5 mb-0">
													<small className="font-weight-semibold positive-ls-2 line-height-1">
														Select products
													</small>
													{/*Speed Performance*/}
												</h4>
											</div>
											<p className="pr-5 custom-text-color-1">
												Select products on Shopperr and
											    publish them to your store.
											</p>
										</div>
										<div
											className="col-10 col-sm-6 col-lg-4 image-box appear-animation"
											data-appear-animation="fadeInUpShorter"
											data-appear-animation-delay={800}
											data-appear-animation-duration={750}
										>
											<img
												className="img-fluid"
												alt="Speed Performance"
												src="https://cdn.shopify.com/s/files/1/0499/4347/8430/files/Start-selling_256x256_crop_center.png?v=1626957688"
												data-plugin-lazyload
												data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
												data-original="img/landing/reason2.png"
											/>
											<div className="d-flex align-items-center mb-2">
												<span className="text-color-dark font-weight-extra-bold text-12 mr-2 line-height-1">
													2
												</span>
												<h4 className="d-flex flex-column font-weight-bold text-5 mb-0">
													<small className="font-weight-semibold positive-ls-2 line-height-1">
														Start selling
													</small>
													{/*Solid and Tested Base */}
												</h4>
											</div>
											<p className="pr-5 custom-text-color-1">
												Your customers place the orders
												 on your online store.
											</p>
										</div>
										<div
											className="col-10 col-sm-6 col-lg-4 image-box appear-animation"
											data-appear-animation="fadeInUpShorter"
											data-appear-animation-delay={1000}
											data-appear-animation-duration={750}
										>
											<img
												className="img-fluid"
												alt="Speed Performance"
												src="https://cdn.shopify.com/s/files/1/0499/4347/8430/files/Purchase-from-Shopperr_256x256_crop_center.png?v=1626957688"
												data-plugin-lazyload
												data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
												data-original="img/landing/reason3.png"
											/>
											<div className="d-flex align-items-center mb-2">
												<span className="text-color-dark font-weight-extra-bold text-12 mr-2 line-height-1">
													3
												</span>
												<h4 className="d-flex flex-column font-weight-bold text-5 mb-0">
													<small className="font-weight-semibold positive-ls-2 line-height-1">
														Purchase from Shopperr
													</small>
													{/*Usability Specialists*/}
												</h4>
											</div>
											<p className="pr-5 custom-text-color-1">
												Place an order for the
												 product you just sold.
											</p>
										</div>
										<div
											className="col-10 col-sm-6 col-lg-4 image-box appear-animation"
											data-appear-animation="fadeInUpShorter"
											data-appear-animation-delay={300}
											data-appear-animation-duration={750}
										>
											<img
												className="img-fluid"
												alt="Speed Performance"
												src="https://cdn.shopify.com/s/files/1/0499/4347/8430/files/Shopperr-ships-the-products_256x256_crop_center.png?v=1626957688"
												data-plugin-lazyload
												data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
												data-original="img/landing/reason4.png"
											/>
											<div className="d-flex align-items-center mb-2">
												<span className="text-color-dark font-weight-extra-bold text-12 mr-2 line-height-1">
													4
												</span>
												<h4 className="d-flex flex-column font-weight-bold text-5 mb-0">
													<small className="font-weight-semibold positive-ls-2 line-height-1">
														Shopperr ships the products

													</small>
													{/*Customer Experience*/}
												</h4>
											</div>
											<p className="pr-5 custom-text-color-1">
												Shopperr will ship the product
												directly from its warehouse
												to your customer's doorstep.
											</p>
										</div>
										<div
											className="col-10 col-sm-6 col-lg-4 image-box appear-animation"
											data-appear-animation="fadeInUpShorter"
											data-appear-animation-delay={500}
											data-appear-animation-duration={750}
										>
											<img
												className="img-fluid"
												alt="Speed Performance"
												src="https://cdn.shopify.com/s/files/1/0499/4347/8430/files/Large-Assortment_2_256x256_crop_center.png?v=1627283435"
												data-plugin-lazyload
												data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
												data-original="img/landing/reason5.png"
											/>
											<div className="d-flex align-items-center mb-2">
												<span className="text-color-dark font-weight-extra-bold text-12 mr-2 line-height-1">
													5
												</span>
												<h4 className="d-flex flex-column font-weight-bold text-5 mb-0">
													<small className="font-weight-semibold positive-ls-2 line-height-1">
														Large Assortment
													</small>
													{/*Porto Admin Template*/}
												</h4>
											</div>
											<p className="pr-5 custom-text-color-1">
												Over 100,000 products and weekly renewal.
											</p>
										</div>
										<div
											className="col-10 col-sm-6 col-lg-4 image-box appear-animation"
											data-appear-animation="fadeInUpShorter"
											data-appear-animation-delay={700}
											data-appear-animation-duration={750}
										>
											<img
												className="img-fluid"
												alt="Speed Performance"
												src="https://cdn.shopify.com/s/files/1/0499/4347/8430/files/Low-Product-Price_2_256x256_crop_center.png?v=1627283581"
												data-plugin-lazyload
												data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
												data-original="img/landing/reason6.png"
											/>
											<div className="d-flex align-items-center mb-2">
												<span className="text-color-dark font-weight-extra-bold text-12 mr-2 line-height-1">
													6
												</span>
												<h4 className="d-flex flex-column font-weight-bold text-5 mb-0">
													<small className="font-weight-semibold positive-ls-2 line-height-1">
														Low Product Price
													</small>
													{/*Template Updates*/}
												</h4>
											</div>
											<p className="pr-5 custom-text-color-1">
												Wholesale price with no MOQ requirement.
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="text-center">
								<a
									href="https://themeforest.net/item/porto-responsive-html5-template/4106987"
									className="btn btn-dark btn-rounded btn-modern btn-px-5 text-3 appear-animation"
									data-appear-animation="fadeIn"
									data-appear-animation-delay={300}
									target="_blank"
								>
									Start Shop
								</a>
							</div>
						</div>
					</section>
					<section className="section section-no-border section-angled bg-color-light-scale-1 m-0">
						<div
							className="section-angled-layer-top section-angled-layer-increase-angle"
							style={{
								padding: "5rem 0",
								backgroundColor: "#0169fe",
							}}
						/>
						<div className="container py-5 my-5">
							<div className="row align-items-center text-center my-5">
								<div className="col-md-6">
									<h2
										className="font-weight-bold text-9 mb-0 appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										Features
									</h2>
									<p
										className="font-weight-semibold text-primary text-4 fonts-weight-semibold positive-ls-2 mb-3 appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={600}
										data-appear-animation-duration={750}
									>
										Price Monitor
									</p>
									<p
										className="pb-2 mb-4 appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={800}
										data-appear-animation-duration={750}
									>
										With our experience in India, Shopperr has calculated the MSRP for you.
										You can expect a decent profit (around 5% after deduction of all the expenses/tax).
										You can also mark up based on your marketing strategy.
									</p>
									<a
										href="https://themeforest.net/item/porto-admin-responsive-html5-template/8539472"
										className="btn btn-modern btn-gradient btn-rounded btn-px-5 py-3 text-3 mb-4 appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={1000}
										data-appear-animation-duration={750}
										target="_blank"
									>
										SHOP NOW!
									</a>
									<p
										className="appear-animation text-3"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={1200}
									>
										* You can {" "}
										<strong className="text-dark">
											also mark up
										</strong>{" "}
										 based on your marketing strategy.
									</p>
								</div>
								<div className="col-md-6 py-5">
									<div
										className="appear-animation"
										data-appear-animation="fadeIn"
										data-appear-animation-delay={500}
									>
										<img
											className="porto-lz"
											src="https://cdn.shopify.com/s/files/1/0499/4347/8430/files/porto_dots2_256x256_crop_center.png?v=1627290716"
											data-plugin-lazyload
											data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
											data-original="img/landing/porto_dots2.png"
											alt=""
											width={149}
											height={142}
											style={{
												position: "absolute",
												top: "-60px",
												right: "-8%",
											}}
										/>
									</div>
									<div
										className="appear-animation"
										data-appear-animation="fadeInLeftShorter"
										data-appear-animation-delay={0}
										data-appear-animation-duration={750}
									>
										<div className="strong-shadow rounded strong-shadow-top-right">
											<img
												src="https://cdn.shopify.com/s/files/1/0499/4347/8430/files/495x357_495x357_crop_center.png?v=1627288382"
												data-plugin-lazyload
												data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
												data-original="img/landing/porto_admin.jpg"
												className="img-fluid border border-width-10 border-color-light rounded box-shadow-3"
												alt="Porto Admin"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						className="section border-0 section-dark section-angled section-angled-reverse section-funnel m-0 position-relative overflow-visible"
						style={{
							backgroundImage: "url(img/lazy.png)",
							backgroundSize: "100%",
							backgroundPosition: "top",
							backgroundRepeat: "no-repeat",
						}}
						data-plugin-lazyload
						data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
						data-original="img/landing/porto_performance_bg.png"
					>
						<div
							className="section-angled-layer-top section-angled-layer-increase-angle"
							style={{
								padding: "5rem 0",
								backgroundColor: "#22252a",
							}}
						/>
						<svg
							version="1.1"
							viewBox="500 200 600 900"
							width={1920}
							height="100%"
							xmlns="http://www.w3.org/2000/svg"
							className="background-svg-style-1"
							style={{ top: "120px" }}
						>
							<defs>
								<filter
									id="shadow"
									x="-300%"
									y="-300%"
									width="600%"
									height="600%"
								>
									<feDropShadow
										dx={0}
										dy={0}
										stdDeviation="10 10"
										floodColor="#08c"
										radius={10}
										floodOpacity={1}
									/>
								</filter>
							</defs>
							<path
								id="svg_17"
								d="m1644.875212,897.875106l-1684.875221,-0.374889l1.25,-446.250108c-1.25,0.372765 496.124925,24.124892 496.124925,24.124892c0,0 255.000064,-106.250026 253.875257,-106.624912c1.124807,0.374885 129.874839,-2.125116 128.750031,-2.500001c1.124808,0.374885 112.374836,-32.125123 111.250027,-32.500008c1.124809,0.374885 144.874844,21.62489 144.874844,21.62489c0,0 128.750032,-73.750018 127.625222,-74.124903c1.124811,0.374884 133.624844,9.124887 133.624844,9.124887c0,0 108.750027,35.000009 108.750027,35.000009c0,0 178.750045,-125.000031 177.625231,-125.374915"
								opacity="0.5"
								strokeOpacity="null"
								strokeWidth={0}
								stroke="#191b1e"
								fill="#191b1e"
								fillOpacity="0.4"
							/>
							<path
								id="svg_6"
								d="m1663.83437,909.61168l-1704.94553,-0.72172l1.11111,-486.66724l648.88966,30.00004l105.55568,-41.11116l126.66682,1.11111l122.22236,-34.44449l126.66682,14.44447c0.49906,0.72171 126.05477,-64.83392 126.05477,-64.83392c0,0 128.88904,4.44445 128.38998,3.72273c0.49906,0.72172 118.27698,28.49953 118.27698,28.49953c0,0 173.33353,-108.88902 172.83447,-109.61073"
								strokeOpacity="null"
								strokeWidth={0}
								stroke="#1a1b1f"
								fill="#1a1b1f"
								fillOpacity="0.4"
							/>
							<ellipse
								className="dots appear-animation"
								data-appear-animation="fadeIn"
								data-appear-animation-delay={250}
								stroke="#000"
								ry="3.5"
								rx="3.5"
								id="svg_9"
								cy="453.023736"
								cx="609.150561"
								strokeOpacity="null"
								strokeWidth={0}
								fill="#fff"
							/>
							<circle
								className="appear-animation"
								data-appear-animation="dotPulse"
								data-appear-animation-delay={2000}
								stroke="#FFF"
								r={20}
								id="svg_9"
								cy="453.023736"
								cx="609.150561"
								strokeOpacity="null"
								strokeWidth="0.2"
								fill="none"
								style={{ transformOrigin: "101.5% 50.4%" }}
							/>
							<ellipse
								className="dots appear-animation"
								data-appear-animation="fadeIn"
								data-appear-animation-delay={500}
								stroke="#000"
								ry="3.5"
								rx="3.5"
								id="svg_10"
								cy="411.595173"
								cx="715.341014"
								strokeOpacity="null"
								strokeWidth={0}
								fill="#fff"
							/>
							<circle
								className="appear-animation"
								data-appear-animation="dotPulse"
								data-appear-animation-delay={250}
								stroke="#FFF"
								r={20}
								id="svg_9"
								cy="411.595173"
								cx="715.341014"
								strokeOpacity="null"
								strokeWidth="0.2"
								fill="none"
								style={{ transformOrigin: "119.2% 45.7%" }}
							/>
							<ellipse
								className="dots appear-animation"
								data-appear-animation="fadeIn"
								data-appear-animation-delay={750}
								stroke="#000"
								ry="3.5"
								rx="3.5"
								id="svg_11"
								cy="412.071364"
								cx="841.05527"
								strokeOpacity="null"
								strokeWidth={0}
								fill="#fff"
							/>
							<circle
								className="appear-animation"
								data-appear-animation="dotPulse"
								data-appear-animation-delay={2000}
								stroke="#FFF"
								r={20}
								id="svg_9"
								cy="412.071364"
								cx="841.05527"
								strokeOpacity="null"
								strokeWidth="0.2"
								fill="none"
								style={{ transformOrigin: "140.1% 45.7%" }}
							/>
							<ellipse
								className="dots appear-animation"
								data-appear-animation="fadeIn"
								data-appear-animation-delay={1000}
								stroke="#000"
								ry="3.5"
								rx="3.5"
								id="svg_12"
								cy="378.261847"
								cx="964.388575"
								strokeOpacity="null"
								strokeWidth={0}
								fill="#fff"
							/>
							<circle
								className="appear-animation"
								data-appear-animation="dotPulse"
								data-appear-animation-delay={250}
								stroke="#FFF"
								r={20}
								id="svg_9"
								cy="378.261847"
								cx="964.388575"
								strokeOpacity="null"
								strokeWidth="0.2"
								fill="none"
								style={{ transformOrigin: "160.7% 42%" }}
							/>
							<ellipse
								className="dots appear-animation"
								data-appear-animation="fadeIn"
								data-appear-animation-delay={1250}
								stroke="#000"
								ry="3.5"
								rx="3.5"
								id="svg_13"
								cy="391.595177"
								cx="1090.102832"
								strokeOpacity="null"
								strokeWidth={0}
								fill="#fff"
							/>
							<circle
								className="appear-animation"
								data-appear-animation="dotPulse"
								data-appear-animation-delay={2000}
								stroke="#FFF"
								r={20}
								id="svg_9"
								cy="391.595177"
								cx="1090.102832"
								strokeOpacity="null"
								strokeWidth="0.2"
								fill="none"
								style={{ transformOrigin: "181.6% 43.5%" }}
							/>
							<ellipse
								className="dots appear-animation"
								data-appear-animation="fadeIn"
								data-appear-animation-delay={1500}
								stroke="#000"
								ry="3.5"
								rx="3.5"
								id="svg_14"
								cy="327.706436"
								cx="1216.769206"
								strokeOpacity="null"
								strokeWidth={0}
								fill="#fff"
							/>
							<circle
								className="appear-animation"
								data-appear-animation="dotPulse"
								data-appear-animation-delay={250}
								stroke="#FFF"
								r={20}
								id="svg_9"
								cy="327.706436"
								cx="1216.769206"
								strokeOpacity="null"
								strokeWidth="0.2"
								fill="none"
								style={{ transformOrigin: "202.8% 36.4%" }}
							/>
							<ellipse
								className="dots appear-animation"
								data-appear-animation="fadeIn"
								data-appear-animation-delay={1750}
								stroke="#000"
								ry="3.5"
								rx="3.5"
								id="svg_15"
								cy="332.150871"
								cx="1346.213343"
								strokeOpacity="null"
								strokeWidth={0}
								fill="#fff"
							/>
							<circle
								className="appear-animation"
								data-appear-animation="dotPulse"
								data-appear-animation-delay={2000}
								stroke="#FFF"
								r={20}
								id="svg_9"
								cy="332.150871"
								cx="1346.213343"
								strokeOpacity="null"
								strokeWidth="0.2"
								fill="none"
								style={{ transformOrigin: "224.3% 36.9%" }}
							/>
							<ellipse
								className="dots appear-animation"
								data-appear-animation="fadeIn"
								data-appear-animation-delay={2000}
								stroke="#000"
								ry="3.5"
								rx="3.5"
								id="svg_16"
								cy="358.26192"
								cx="1463.43529"
								strokeOpacity="null"
								strokeWidth={0}
								fill="#fff"
							/>
							<circle
								className="appear-animation"
								data-appear-animation="dotPulse"
								data-appear-animation-delay={250}
								stroke="#FFF"
								r={20}
								id="svg_9"
								cy="358.26192"
								cx="1463.43529"
								strokeOpacity="null"
								strokeWidth="0.2"
								fill="none"
								style={{ transformOrigin: "243.8% 39.8%" }}
							/>
							<ellipse
								className="dots appear-animation"
								data-appear-animation="fadeIn"
								data-appear-animation-delay={2250}
								stroke="#000"
								ry="3.5"
								rx="3.5"
								id="svg_7"
								cy="278.817661"
								cx="1589.546107"
								strokeOpacity="null"
								strokeWidth={0}
								fill="#fff"
							/>
							<circle
								className="appear-animation"
								data-appear-animation="dotPulse"
								data-appear-animation-delay={2000}
								stroke="#FFF"
								r={20}
								id="svg_9"
								cy="278.817661"
								cx="1589.546107"
								strokeOpacity="null"
								strokeWidth="0.2"
								fill="none"
								style={{ transformOrigin: "264.6% 30.9%" }}
							/>
						</svg>
						<img
							className="img-fluid position-absolute d-none d-lg-block appear-animation"
							data-appear-animation="fadeInUpShorter"
							data-appear-animation-delay={300}
							data-appear-animation-duration={750}
							src="https://cdn.shopify.com/s/files/1/0499/4347/8430/files/529x637_529x637_crop_center.png?v=1627290092"
							data-plugin-lazyload
							data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
							data-original="img/landing/porto_notebook.png"
							alt="Performance on Laptop"
							style={{
								display: "block",
								top: "-170px",
								left: "90px",
							}}
						/>
						<div className="container text-center py-5 mb-5">
							<div className="row justify-content-center pb-md-5 mb-md-5">
								<div className="col-md-7 offset-lg-5 pb-md-5 mb-md-5">
									<h2
										className="font-weight-bold text-9 appear-animation mb-3"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										Data Integration
									</h2>
									<p
										className="custom-text-color-1 color-inherit mb-4 pb-lg-2 appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={400}
										data-appear-animation-duration={750}
									>
										Reduce your challenges of managing different systems — Sync your subscription products'data from Shopperr to ecommerce marketplace e.g.
										Amazon. Shopperr updates your product prices and stock levels according to suppliers' adjustment.
										No manual work needed.
									</p>
								</div>
							</div>
							<div className="row align-items-center pb-md-5 mb-md-5">
								<div className="col-12 col-md-7 text-center mt-5">
									<h2
										className="font-weight-bold text-7 text-md-6 text-lg-9 pt-5 pt-md-4 mt-5 mb-lg-3 mb-2 appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										Price {" "}
										<span className="highlighted-word highlighted-word-animation-1 highlighted-word-animation-1-no-rotate alternative-font-4 font-weight-bold">
											{" "}
											Any{" "}
										</span>{" "}
										Monitor
									</h2>
									<p
										className="custom-text-color-1 color-inherit appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={400}
										data-appear-animation-duration={750}
									>
										All suppliers in Shopperr are screened with good rating in fulfillment.
										They will ship the products to your end customers directly. With Shopperr,
										you can seamlessly connect to the shipment suppliers'
										tracking system and ensure all your orders are under contro.
									</p>
								</div>
								<div className="col-5 d-none d-md-block">
									<div
										className="text-right mr-3 mr-lg-5 ml-auto mb-4 appear-animation"
										data-appear-animation="fadeInLeftShorter"
										data-appear-animation-delay={600}
										data-appear-animation-duration={750}
										style={{ maxWidth: "244px" }}
										data-plugin-options="{'accY': -100}"
									>
										<img
											className="img-fluid"
											src="https://cdn.shopify.com/s/files/1/0499/4347/8430/files/244x228_244x228_crop_center.png?v=1627289014"
											data-plugin-lazyload
											data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
											data-original="img/landing/porto_iphone.png"
											width={244}
											height={228}
											alt="Performance on Mobile"
										/>
									</div>
									<img
										className="img-fluid appear-animation z-index-1 position-relative"
										data-appear-animation="fadeInRightShorter"
										data-appear-animation-delay={800}
										data-appear-animation-duration={750}
										src="https://cdn.shopify.com/s/files/1/0499/4347/8430/files/437x241_437x241_crop_center.png?v=1627289980"
										data-plugin-lazyload
										data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
										data-original="img/landing/porto_ipad.png"
										width={437}
										height={241}
										alt="Performance on Tablet"
										style={{ marginBottom: "-10%" }}
									/>
								</div>
							</div>
						</div>
						<div className="section-funnel-layer-bottom">
							<div className="section-funnel-layer bg-light" />
							<div className="section-funnel-layer bg-light" />
						</div>
					</section>
					<section
						id="demos"
						className="section section-no-border section-light position-relative z-index-3 pt-0 m-0"
					>
						<div className="container">
							<div
								className="row align-items-center appear-animation"
								data-appear-animation="fadeInUpShorter"
								data-appear-animation-delay={250}
								data-appear-animation-duration={750}
							>
								<div className="col-lg-8 offset-lg-2 text-center">
									<ul className="list list-unstyled d-flex justify-content-center text-primary font-weight-semibold positive-ls-2 flex-column flex-md-row text-4 mb-4">
										<li>1. SELECT DEMO</li>
										<li className="mx-5">2. CUSTOMIZE</li>
										<li>3. DONE</li>
									</ul>
									<p
										className="text-4 mb-5 appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={400}
										data-appear-animation-duration={750}
									>
										Porto is a truely complete template,
										with over 80 demos that make it suitable
										for any type of website. We believe you
										will like Porto.
									</p>
									<p
										className="d-flex align-items-center justify-content-center font-weight-bold text-color-dark text-8 negative-ls-1 pb-2 mb-5 appear-animation"
										data-appear-animation="blurIn"
										data-appear-animation-delay={600}
									>
										<span className="highlighted-word highlighted-word-animation-1 highlighted-word-animation-1-2 highlighted-word-animation-1-2-dark highlighted-word-animation-1 highlighted-word-animation-1-no-rotate alternative-font-4 font-weight-extra-bold line-height-1 text-9 mr-3">
											80+
										</span>{" "}
										Demos Ready to Use
									</p>
								</div>
							</div>
						</div>
						<div className="container-fluid" id="demos">
							<div
								className="text-center mb-5 appear-animation"
								data-appear-animation="fadeIn"
								data-appear-animation-delay={400}
								data-appear-animation-duration={750}
							>
								<ul
									className="nav nav-pills sort-source sort-source-style-3 justify-content-center mb-0"
									data-sort-id="portfolio"
									data-option-key="filter"
									data-plugin-options="{'layoutMode': 'fitRows', 'filter': '*', 'useHash': false}"
								>
									<li
										className="nav-item active"
										data-option-value="*"
									>
										<a
											className="nav-link custom-nav-link active"
											href="#"
										>
											Show All
										</a>
									</li>
									<li
										className="nav-item"
										data-option-value=".new"
									>
										<a
											className="nav-link custom-nav-link"
											href="#"
										>
											NEW
										</a>
									</li>
									<li
										className="nav-item"
										data-option-value=".classic"
									>
										<a
											className="nav-link custom-nav-link"
											href="#"
										>
											Classic
										</a>
									</li>
									<li
										className="nav-item"
										data-option-value=".shop"
									>
										<a
											className="nav-link custom-nav-link"
											href="#"
										>
											Shop
										</a>
									</li>
									<li
										className="nav-item"
										data-option-value=".onepage"
									>
										<a
											className="nav-link custom-nav-link"
											href="#"
										>
											One Page
										</a>
									</li>
									<li
										className="nav-item"
										data-option-value=".business"
									>
										<a
											className="nav-link custom-nav-link"
											href="#"
										>
											Business
										</a>
									</li>
									<li
										className="nav-item"
										data-option-value=".blog"
									>
										<a
											className="nav-link custom-nav-link"
											href="#"
										>
											Blog
										</a>
									</li>
									<li
										className="nav-item"
										data-option-value=".portfolio"
									>
										<a
											className="nav-link custom-nav-link"
											href="#"
										>
											Portfolio
										</a>
									</li>
								</ul>
							</div>
							<div
								className="row portfolio-list sort-destination sort-destination-margin sort-destination-items-hardware-acc overflow-visible"
								data-sort-id="portfolio"
							>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item classic">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-classic.jpg"
												>
													<a href="index-classic.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Classic
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item onepage">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-one-page.jpg"
												>
													<a href="index-one-page.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												One Page
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-corporate.jpg"
												>
													<a href="index-corporate.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Corporate 1
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-corporate-2.jpg"
												>
													<a href="index-corporate-2.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Corporate 2
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-corporate-3.jpg"
												>
													<a href="index-corporate-3.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Corporate 3
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-corporate-4.jpg"
												>
													<a href="index-corporate-4.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Corporate 4
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-corporate-5.jpg"
												>
													<a href="index-corporate-5.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Corporate 5
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-corporate-6.jpg"
												>
													<a href="index-corporate-6.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Corporate 6
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-corporate-7.jpg"
												>
													<a href="index-corporate-7.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Corporate 7
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-corporate-8.jpg"
												>
													<a href="index-corporate-8.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Corporate 8
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-corporate-9.jpg"
												>
													<a href="index-corporate-9.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Corporate 9
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-corporate-10.jpg"
												>
													<a href="index-corporate-10.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Corporate 10
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-corporate-11.jpg"
												>
													<a href="index-corporate-11.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Corporate 11
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-corporate-12.jpg"
												>
													<a href="index-corporate-12.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Corporate 12
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-corporate-13.jpg"
												>
													<a href="index-corporate-13.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Corporate 13
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-corporate-14.jpg"
												>
													<a href="index-corporate-14.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Corporate 14
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-corporate-15.jpg"
												>
													<a href="index-corporate-15.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Corporate 15
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-corporate-16.jpg"
												>
													<a href="index-corporate-16.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Corporate 16
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-corporate-17.jpg"
												>
													<a href="index-corporate-17.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Corporate 17
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-corporate-18.jpg"
												>
													<a href="index-corporate-18.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Corporate 18
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-corporate-19.jpg"
												>
													<a href="index-corporate-19.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Corporate 19
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-corporate-20.jpg"
												>
													<a href="index-corporate-20.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Corporate 20
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item portfolio">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-portfolio.jpg"
												>
													<a href="index-portfolio.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Portfolio 1
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item portfolio">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-portfolio-2.jpg"
												>
													<a href="index-portfolio-2.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Portfolio 2
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item portfolio">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-portfolio-3.jpg"
												>
													<a href="index-portfolio-3.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Portfolio 3
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item portfolio">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-portfolio-4.jpg"
												>
													<a href="index-portfolio-4.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Portfolio 4
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item portfolio">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-portfolio-5.jpg"
												>
													<a href="index-portfolio-5.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Portfolio 5
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item blog">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-blog.jpg"
												>
													<a href="index-blog.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Blog 1
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item blog">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-blog-2.jpg"
												>
													<a href="index-blog-2.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Blog 2
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item blog">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-blog-3.jpg"
												>
													<a href="index-blog-3.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Blog 3
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item blog">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-blog-4.jpg"
												>
													<a href="index-blog-4.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Blog 4
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item blog">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-blog-5.jpg"
												>
													<a href="index-blog-5.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Blog 5
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-corporate-hosting.jpg"
												>
													<a href="index-corporate-hosting.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Hosting
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item classic">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-classic-color.jpg"
												>
													<a href="index-classic-color.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Classic Color
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item classic">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-classic-light.jpg"
												>
													<a href="index-classic-light.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Classic Light
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item classic">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-classic-video.jpg"
												>
													<a href="index-classic-video.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Classic Video
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item classic">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-classic-video-light.jpg"
												>
													<a href="index-classic-video-light.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Classic Video Light
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-construction.jpg"
												>
													<a href="demo-construction.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Construction
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-construction-2.jpg"
												>
													<a href="demo-construction-2.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Construction 2
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-business-consulting.jpg"
												>
													<a href="demo-business-consulting.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Business Consulting
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-business-consulting-2.jpg"
												>
													<a href="demo-business-consulting-2.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Business Consulting 2
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-medical.jpg"
												>
													<a href="demo-medical.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Medical
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-medical-2.jpg"
												>
													<a href="demo-medical-2.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Medical 2
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business new">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center portfolio-item-new">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-auto-services.jpg"
												>
													<a href="demo-auto-services.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Auto Services
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-finance.jpg"
												>
													<a href="demo-finance.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Finance
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-industry-factory.jpg"
												>
													<a href="demo-industry-factory.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Industry Factory
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-insurance.jpg"
												>
													<a href="demo-insurance.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Insurance
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-seo.jpg"
												>
													<a href="demo-seo.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												SEO
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-seo-2.jpg"
												>
													<a href="demo-seo-2.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												SEO 2
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-it-services.jpg"
												>
													<a href="demo-it-services.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												IT Services
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-cleaning-services.jpg"
												>
													<a href="demo-cleaning-services.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Cleaning Services
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item shop">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-product-landing.jpg"
												>
													<a href="demo-product-landing.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Product Landing
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-coffee.jpg"
												>
													<a href="demo-coffee.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Coffee Shop
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-sass.jpg"
												>
													<a href="demo-sass.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												SASS
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-architecture-interior.jpg"
												>
													<a href="demo-architecture-interior.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Architecture &amp; Interior
												Design
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business new">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center portfolio-item-new">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-architecture-2.jpg"
												>
													<a href="demo-architecture-2.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Architecture 2
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-barber.jpg"
												>
													<a href="demo-barber.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Barber Shop
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-education.jpg"
												>
													<a href="demo-education.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Education
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-startup-agency.jpg"
												>
													<a href="demo-startup-agency.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Startup Agency
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-digital-agency.jpg"
												>
													<a href="demo-digital-agency.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Digital Agency
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-digital-agency-2.jpg"
												>
													<a href="demo-digital-agency-2.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Digital Agency 2 (Light)
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-digital-agency-2-dark.jpg"
												>
													<a href="demo-digital-agency-2-dark.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Digital Agency 2 (Dark)
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-law-firm.jpg"
												>
													<a href="demo-law-firm.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Law Firm
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business new">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center portfolio-item-new">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-law-firm-2.jpg"
												>
													<a href="demo-law-firm-2.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Law Firm 2
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item onepage">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-app-landing.jpg"
												>
													<a href="demo-app-landing.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												App Landing
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item portfolio creative">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<div
												className="owl-carousel nav-dark show-nav-hover"
												data-plugin-options="{'items': 1, 'loop': true, 'nav': true, 'dots': false, 'autoplay': true, 'autoplayTimeout': 3000, 'autoplayHoverPause': true}"
											>
												<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
													<span
														className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
														data-plugin-lazyload
														data-plugin-options="{'threshold': 1000, 'effect':'fadeIn'}"
														data-original="img/previews/preview-resume.jpg"
													>
														<a href="demo-resume.html" />
													</span>
												</span>
												<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
													<span
														className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
														data-plugin-lazyload
														data-plugin-options="{'threshold': 1000, 'effect':'fadeIn'}"
														data-original="img/previews/preview-resume-blue.jpg"
													>
														<a href="demo-resume-blue.html" />
													</span>
												</span>
												<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
													<span
														className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
														data-plugin-lazyload
														data-plugin-options="{'threshold': 1000, 'effect':'fadeIn'}"
														data-original="img/previews/preview-resume-red.jpg"
													>
														<a href="demo-resume-red.html" />
													</span>
												</span>
												<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
													<span
														className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
														data-plugin-lazyload
														data-plugin-options="{'threshold': 1000, 'effect':'fadeIn'}"
														data-original="img/previews/preview-resume-green.jpg"
													>
														<a href="demo-resume-green.html" />
													</span>
												</span>
												<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
													<span
														className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
														data-plugin-lazyload
														data-plugin-options="{'threshold': 1000, 'effect':'fadeIn'}"
														data-original="img/previews/preview-resume-dark.jpg"
													>
														<a href="demo-resume-dark.html" />
													</span>
												</span>
											</div>
											<h5 className="text-color-dark text-capitalize mt-3">
												CV - Portfolio - Resume
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-hotel.jpg"
												>
													<a href="demo-hotel.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Hotel
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-real-estate.jpg"
												>
													<a href="demo-real-estate.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Real Estate
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item creative business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-event.jpg"
												>
													<a href="demo-event.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Event
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-restaurant.jpg"
												>
													<a href="demo-restaurant.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Restaurant
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-gym.jpg"
												>
													<a href="demo-gym.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Gym
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item onepage creative business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-band.jpg"
												>
													<a href="demo-band.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Band
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item onepage creative">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-one-page-agency.jpg"
												>
													<a href="demo-one-page-agency.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												One Page Agency
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item portfolio">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-photography.jpg"
												>
													<a href="demo-photography.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Photography
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item portfolio">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-photography-2.jpg"
												>
													<a href="demo-photography-2.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Photography 2
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item portfolio">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-photography-3.jpg"
												>
													<a href="demo-photography-3.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Photography 3
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-church.jpg"
												>
													<a href="demo-church.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Church
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item business">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-wedding.jpg"
												>
													<a href="demo-wedding.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Wedding
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item classic">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-boxed.jpg"
												>
													<a href="feature-layout-boxed.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												Boxed
											</h5>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3 col-xl-1-5 isotope-item classic">
									<div
										className="appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={200}
										data-appear-animation-duration={750}
									>
										<div className="portfolio-item hover-effect-1 text-center">
											<span className="thumb-info thumb-info-no-zoom thumb-info-no-overlay thumb-info-no-bg">
												<span
													className="thumb-info-wrapper thumb-info-wrapper-demos thumb-info-wrapper-link m-0"
													data-plugin-lazyload
													data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
													data-original="img/previews/preview-rtl.jpg"
												>
													<a href="feature-layout-rtl.html" />
												</span>
											</span>
											<h5 className="text-color-dark text-capitalize mt-3">
												RTL
											</h5>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						className="section section-angled section-angled-reverse border-0 m-0 bg-dark section-dark"
						style={{
							backgroundSize: "100%",
							backgroundPosition: "top",
						}}
						data-plugin-lazyload
						data-plugin-options="{'threshold': 500}"
						data-original="img/landing/header_builder_bg.jpg"
					>
						<div
							className="section-angled-layer-top section-angled-layer-increase-angle bg-light"
							style={{ padding: "4rem 0" }}
						/>
						<div className="container py-5 mt-5">
							<div className="row align-items-center mt-4 pt-2">
								<div className="col-lg-6 pr-lg-5 position-relative text-center mb-5 mb-lg-0">
									<img
										alt="Porto Headers"
										src="img/lazy.png"
										data-plugin-lazyload
										data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
										data-original="img/landing/porto_headers.png"
										className="img-fluid appear-animation"
										data-appear-animation="fadeInRightShorter"
										data-appear-animation-delay={300}
									/>
								</div>
								<div className="col-lg-5 text-center px-lg-0">
									<h5
										className="text-primary font-weight-semibold positive-ls-2 text-4 mb-2 appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={250}
										data-appear-animation-duration={750}
									>
										ADVANCED USABILITY-FOCUSED{" "}
									</h5>
									<h2
										className="font-weight-bold text-9 mb-2 appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={600}
										data-appear-animation-duration={750}
									>
										Headers and Menus
									</h2>
									<p
										className="custom-text-color-1 color-inherit appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={800}
										data-appear-animation-duration={750}
									>
										Porto comes with several headers and
										menus options for you to use on your
										website. We have created several options
										always focused on the best user
										experience to improve your business.
									</p>
									<p
										className="custom-text-color-1 color-inherit pb-2 mb-4 appear-animation"
										data-appear-animation="fadeInUpShorter"
										data-appear-animation-delay={1000}
										data-appear-animation-duration={750}
									>
										Select any of the options we have giver
										you or create your own.
									</p>
									<div className="d-flex align-items-center justify-content-center">
										<i
											className="fa fa-check text-color-primary bg-light rounded-circle p-2 mr-3 appear-animation"
											data-appear-animation="fadeInRightShorter"
											data-appear-animation-delay={1600}
											data-appear-animation-duration={750}
										/>
										<p
											className="mb-0 line-height-5 ls-0 text-color-light font-weight-semibold appear-animation"
											data-appear-animation="fadeInRightShorter"
											data-appear-animation-delay={1300}
											data-appear-animation-duration={750}
										>
											Menus, Nav Icons, Search Icons, Mini
											Cart,
											<br />
											Account Items, Search and much
											more...
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						className="section section-funnel border-0 bg-light m-0"
						style={{
							backgroundSize: "100%",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "top",
						}}
						data-plugin-lazyload
						data-plugin-options="{'threshold': 500}"
						data-original="img/landing/half_circle.png"
					>
						<div className="container text-center pb-5 mt-3 mb-5">
							<h2
								className="font-weight-bold text-9 mb-2 appear-animation"
								data-appear-animation="fadeInUpShorter"
								data-appear-animation-delay={250}
							>
								Layouts
							</h2>
							<h5
								className="text-primary font-weight-semibold positive-ls-2 text-4 mb-3 appear-animation"
								data-appear-animation="fadeInUpShorter"
								data-appear-animation-delay={400}
							>
								GIANT LIBRARY OF VARIATIONS
							</h5>
							<p
								className="text-4 mb-0 appear-animation"
								data-appear-animation="fadeInUpShorter"
								data-appear-animation-delay={550}
							>
								Giant variety of layouts to create your site
								with unlimited possibilities.
							</p>
							<div className="row flex-column flex-lg-row justify-content-center align-items-center my-5">
								<div
									className="col-8 col-md-5 col-lg-3 image-box mb-4 mb-lg-0 appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={600}
									data-appear-animation-duration={750}
								>
									<div className="image mb-3">
										<img
											alt="Layouts"
											width={195}
											height={161}
											src="img/lazy.png"
											data-plugin-lazyload
											data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
											data-original="img/landing/porto_layouts1.png"
											className="position-relative z-index-3 mr-auto"
											style={{
												width: "67.47%",
												left: "-51px",
											}}
										/>
										<img
											alt="Layouts"
											width={174}
											height={161}
											src="img/lazy.png"
											data-plugin-lazyload
											data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
											data-original="img/landing/porto_layouts3.png"
											className="position-relative z-index-1 ml-auto"
											style={{
												width: "60.2%",
												marginTop: "-32%",
												right: "-50px",
											}}
										/>
										<img
											alt="Layouts"
											width={174}
											height={161}
											src="img/lazy.png"
											data-plugin-lazyload
											data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
											data-original="img/landing/porto_layouts3.png"
											className="position-relative z-index-2 mx-auto"
											style={{
												width: "60.2%",
												marginTop: "-13%",
											}}
										/>
									</div>
									<h4 className="text-dark font-weight-bold pt-2 mb-1">
										Layouts
									</h4>
									<p className="fs-md text-3 px-lg-4">
										Max Width 1200px, 1170px, 1024px,
										<br /> full width, etc...
									</p>
								</div>
								<div
									className="col-8 col-md-5 col-lg-3 image-box mb-4 mb-lg-0 appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={800}
									data-appear-animation-duration={750}
								>
									<div className="image mb-3">
										<img
											alt="Sidebars"
											width={172}
											height={161}
											src="img/lazy.png"
											data-plugin-lazyload
											data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
											data-original="img/landing/porto_sidebars1.png"
											className="position-relative z-index-1 ml-auto"
											style={{ right: "-33px" }}
										/>
										<img
											alt="Sidebars"
											width={172}
											height={161}
											src="img/lazy.png"
											data-plugin-lazyload
											data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
											data-original="img/landing/porto_sidebars2.png"
											className="position-relative z-index-2"
											style={{
												left: "-19px",
												marginTop: "-32%",
											}}
										/>
										<img
											alt="Sidebars"
											width={172}
											height={161}
											src="img/lazy.png"
											data-plugin-lazyload
											data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
											data-original="img/landing/porto_sidebars3.png"
											className="position-relative z-index-3 ml-auto"
											style={{
												right: "-20px",
												marginTop: "-13%",
											}}
										/>
									</div>
									<h4 className="text-dark font-weight-bold pt-2 mb-1">
										Sidebars
									</h4>
									<p className="fs-md text-3 px-lg-4">
										Sidebar can be set to be on left, right,
										both or even disabled.
									</p>
								</div>
								<div
									className="col-8 col-md-5 col-lg-3 image-box appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={1000}
									data-appear-animation-duration={750}
								>
									<div className="image mb-3">
										<img
											alt="Sliders"
											width={142}
											height={161}
											src="img/lazy.png"
											data-plugin-lazyload
											data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
											data-original="img/landing/porto_sliders1.png"
											className="position-relative z-index-1"
											style={{ left: "-31px" }}
										/>
										<img
											alt="Sliders"
											width={142}
											height={161}
											src="img/lazy.png"
											data-plugin-lazyload
											data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
											data-original="img/landing/porto_sliders2.png"
											className="position-relative z-index-2 ml-auto"
											style={{
												right: "-60px",
												marginTop: "-31%",
											}}
										/>
										<img
											alt="Sliders"
											width={142}
											height={161}
											src="img/lazy.png"
											data-plugin-lazyload
											data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
											data-original="img/landing/porto_sliders3.png"
											className="position-relative z-index-3 ml-auto"
											style={{
												marginRight: "15%",
												marginTop: "-41%",
											}}
										/>
										<img
											alt="Sliders"
											width={142}
											height={161}
											src="img/lazy.png"
											data-plugin-lazyload
											data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
											data-original="img/landing/porto_sliders4.png"
											className="position-relative z-index-4"
											style={{
												left: "-50px",
												marginTop: "-35.67%",
											}}
										/>
									</div>
									<h4 className="text-dark font-weight-bold pt-2 mb-1">
										Sliders
									</h4>
									<p className="fs-md text-3 px-lg-4">
										You can set several different types of
										sliders, boxed, full, grid, etc..
									</p>
								</div>
							</div>
						</div>
						<div className="section-funnel-layer-bottom">
							<div className="section-funnel-layer bg-color-light-scale-1" />
							<div className="section-funnel-layer bg-color-light-scale-1" />
						</div>
					</section>
					<section className="section section-funnel position-relative z-index-3 border-0 pt-0 m-0">
						<div className="container pb-5 mb-5">
							<h2 className="fotn-weight-extra-bold mb-1 text-center">
								<b
									className="text-13 d-block line-height-1 font-weight-extra-bold appear-animation"
									data-appear-animation="blurIn"
									data-appear-animation-delay={250}
									data-appear-animation-duration={750}
								>
									35K+
								</b>
								<span
									className="font-weight-bold text-5 appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={600}
									data-appear-animation-duration={750}
								>
									People Already Using Porto
								</span>
							</h2>
							<p
								className="font-weight-bold text-4 text-center appear-animation"
								data-appear-animation="fadeInUpShorter"
								data-appear-animation-delay={750}
							>
								100K+ IN ALL PORTO VERSIONS
							</p>
							<div
								className="appear-animation"
								data-appear-animation="fadeInUpShorter"
								data-appear-animation-delay={850}
								data-appear-animation-duration={850}
							>
								<h5 className="font-weight-semibold positive-ls-2 text-4 text-primary text-center mb-0">
									TOP 5 STAR RATING
								</h5>
								<p className="text-default text-center mb-4">
									Real people, real stories. Hear from our
									community.
								</p>
								<div
									className="owl-carousel carousel-center-active-item-2 nav-style-4 mb-4 pb-3"
									data-plugin-options="{'items': 1, 'loop': true, 'nav': true, 'dots': false}"
								>
									<div>
										<div className="d-flex flex-column flex-md-row justify-content-between mb-4">
											<div className="author">
												<h4 className="text-5 mb-0">
													onealbs
												</h4>
												<span className="opacity-7">
													Themeforest User
												</span>
											</div>
											<span className="star-rating">
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
											</span>
										</div>
										<p className="mb-0">
											"I have purchased this template four
											times for different projects and
											will soon be purchasing my fifth.
											This options for this template are
											limitless and customer service is
											amazing!"
										</p>
									</div>
									<div>
										<div className="d-flex flex-column flex-md-row justify-content-between mb-4">
											<div className="author">
												<h4 className="text-5 mb-0">
													mrmelton
												</h4>
												<span className="opacity-7">
													Themeforest User
												</span>
											</div>
											<span className="star-rating">
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
											</span>
										</div>
										<p className="mb-0">
											"This template is pure joy to work
											with and customize. Everything is
											designed so clearly and it just
											makes your life easier to design a
											site. Highly recommend."
										</p>
									</div>
									<div>
										<div className="d-flex flex-column flex-md-row justify-content-between mb-4">
											<div className="author">
												<h4 className="text-5 mb-0">
													daniyal1997
												</h4>
												<span className="opacity-7">
													Themeforest User
												</span>
											</div>
											<span className="star-rating">
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
											</span>
										</div>
										<p className="mb-0">
											"This theme continues to blow my
											mind! I can't believe how many
											features and layouts that are
											included and yet how elegantly it's
											all executed underneath."
										</p>
									</div>
									<div>
										<div className="d-flex flex-column flex-md-row justify-content-between mb-4">
											<div className="author">
												<h4 className="text-5 mb-0">
													alfvlx
												</h4>
												<span className="opacity-7">
													Themeforest User
												</span>
											</div>
											<span className="star-rating">
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
											</span>
										</div>
										<p className="mb-0">
											"The best template i had work
											on!!!!!"
										</p>
									</div>
									<div>
										<div className="d-flex flex-column flex-md-row justify-content-between mb-4">
											<div className="author">
												<h4 className="text-5 mb-0">
													marcoss2009
												</h4>
												<span className="opacity-7">
													Themeforest User
												</span>
											</div>
											<span className="star-rating">
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
											</span>
										</div>
										<p className="mb-0">
											"The best theme in Themeforest. I
											like it because I can customize it
											without problems."
										</p>
									</div>
									<div>
										<div className="d-flex flex-column flex-md-row justify-content-between mb-4">
											<div className="author">
												<h4 className="text-5 mb-0">
													moirajanetallen
												</h4>
												<span className="opacity-7">
													Themeforest User
												</span>
											</div>
											<span className="star-rating">
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
											</span>
										</div>
										<p className="mb-0">
											"Very impressed with the great
											customer support."
										</p>
									</div>
									<div>
										<div className="d-flex flex-column flex-md-row justify-content-between mb-4">
											<div className="author">
												<h4 className="text-5 mb-0">
													majstro7
												</h4>
												<span className="opacity-7">
													Themeforest User
												</span>
											</div>
											<span className="star-rating">
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
												<i className="fas fa-star text-color-dark" />
											</span>
										</div>
										<p className="mb-0">
											"Good code quality ! Very fast and
											good support ! I recommended it in
											100% !"
										</p>
									</div>
								</div>
							</div>
							<p className="text-center mb-5">
								<a
									className="btn btn-dark btn-modern btn-rounded btn-px-5 btn-py-3 text-2 appear-animation"
									data-appear-animation="fadeIn"
									data-appear-animation-delay={250}
									data-appear-animation-duration={600}
									href="https://themeforest.net/item/porto-responsive-html5-template/4106987"
									target="_blank"
								>
									BUY PORTO NOW
								</a>
							</p>
						</div>
						<div className="section-funnel-layer-bottom">
							<div className="section-funnel-layer bg-light" />
							<div className="section-funnel-layer bg-light" />
						</div>
					</section>
					<section
						id="support"
						className="section section-angled bg-light border-0 m-0 position-relative z-index-3 pt-0"
					>
						<div className="container pb-5 mb-5">
							<div className="row align-items-center mb-5">
								<div className="col-lg-6 pr-xl-5 mb-5 mb-lg-0">
									<h2 className="font-weight-bold text-9 mb-1">
										Professional Support
									</h2>
									<h5 className="font-weight-semibold positive-ls-2 text-4 text-primary mb-3">
										ONLINE DOCUMENTATION, VIDEOS AND FORUM
									</h5>
									<p className="ls-0 text-default fw-400 mb-5">
										Any problem while using Porto? We're
										here to help you.
									</p>
									<div className="d-flex align-items-center border border-top-0 border-right-0 border-left-0 pb-4 mb-4">
										<i className="fa fa-check text-color-primary bg-light rounded-circle box-shadow-4 p-2 mr-3" />
										<p className="mb-0">
											<b className="text-color-dark">
												Online Documentation -
											</b>{" "}
											Contains all descriptions related to
											Porto usage and features.
										</p>
									</div>
									<div className="d-flex align-items-center border border-top-0 border-right-0 border-left-0 pb-4 mb-4">
										<i className="fa fa-check text-color-primary bg-light rounded-circle box-shadow-4 p-2 mr-3" />
										<p className="mb-0">
											<b className="text-color-dark">
												Video Documentation -
											</b>{" "}
											Need visual instructions? Check our
											video tutorials.
										</p>
									</div>
									<div className="d-flex align-items-center pb-4 mb-4">
										<i className="fa fa-check text-color-primary bg-light rounded-circle box-shadow-4 p-2 mr-3" />
										<p className="mb-0">
											<b className="text-color-dark">
												Support Center -
											</b>{" "}
											Contact us if you get any issue
											while using Porto, reply within 16
											hrs.
										</p>
									</div>
								</div>
								<div className="col-lg-4 offset-lg-2">
									<div
										className="appear-animation"
										data-appear-animation="fadeIn"
										data-appear-animation-delay={500}
									>
										<img
											className="img-fluid"
											src="img/lazy.png"
											data-plugin-lazyload
											data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
											data-original="img/landing/porto_dots2.png"
											alt=""
											style={{
												position: "absolute",
												bottom: "-2%",
												left: "-43%",
												transform: "rotate(90deg)",
											}}
										/>
									</div>
									<img
										alt="Porto Support"
										src="img/lazy.png"
										data-plugin-lazyload
										data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
										data-original="img/landing/support_login.jpg"
										className="img-fluid border border-width-10 border-color-light rounded box-shadow-3 ml-5 appear-animation"
										data-appear-animation="fadeInUp"
										data-appear-animation-delay={200}
										style={{
											width: "590px",
											maxWidth: "none",
										}}
									/>
									<img
										alt="Porto Documentation"
										src="img/lazy.png"
										data-plugin-lazyload
										data-plugin-options="{'threshold': 500, 'effect':'fadeIn'}"
										data-original="img/landing/porto_docs.jpg"
										className="img-fluid  rounded box-shadow-3 position-absolute appear-animation"
										data-appear-animation="fadeInUp"
										data-appear-animation-delay={700}
										style={{
											left: "-100px",
											bottom: "50px",
										}}
									/>
								</div>
							</div>
						</div>
						<div
							className="section-angled-layer-bottom section-angled-layer-increase-angle"
							style={{ padding: "4rem 0", background: "#222529" }}
						/>
					</section>
					<section className="section bg-dark section-dark border-0 m-0">
						<div className="container">
							<div className="text-center mb-5">
								<h5
									className="font-weight-semibold positive-ls-2 text-4 text-primary mb-0 appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={250}
									data-appear-animation-duration={750}
								>
									POWERFUL AND COMPLETE
								</h5>
								<h2
									className="font-weight-bold text-9 mb-2 appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={600}
									data-appear-animation-duration={750}
								>
									Premium Plugins and Features
								</h2>
								<p
									className="custom-text-color-1 color-inherit appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={850}
									data-appear-animation-duration={750}
								>
									Create your website using premium included
									plugins and Porto's exclusive features.
									<br />
									It’s incredibly easy and fun to build and
									mantain your website design and content.
								</p>
							</div>
							<div className="row pb-5">
								<div
									className="col-12 mb-4 appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={700}
									data-appear-animation-duration={750}
								>
									<div className="bg-color-dark-scale-2 rounded p-5">
										<i className="icon-bg icon-plugin-2 mb-3" />
										<h4 className="text-4 mb-2 font-weight-bold">
											Slider Revolution
										</h4>
										<p className="custom-text-color-1 text-3 color-inherit mb-0">
											Slider Revolution is truely #1
											popular slider plugin that has 'no
											competitor'. This plugin is being
											sold on codecanyon.net store
											exclusively. You can get the plugin
											for free once you purchase Porto.
											Porto offers many beautiful slider
											sample contents that you can choose
											and apply on your website. You can
											alse create new sliders easily.
										</p>
									</div>
								</div>
								<div
									className="d-flex col-sm-6 col-lg-3 mb-4 appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={200}
									data-appear-animation-duration={750}
								>
									<div className="bg-color-dark-scale-2 rounded p-5">
										<i className="icon-bg icon-feature-1 mt-4" />
										<h4 className="text-4 mb-2">
											Top Level Support
										</h4>
										<p className="custom-text-color-1 text-3 color-inherit mb-0">
											You can expect high technical reply
											within 24 hrs, average response time
											is 14 hrs. Customer satisfication is
											our main focus. You won't be alone
											using Porto.
										</p>
									</div>
								</div>
								<div
									className="d-flex col-sm-6 col-lg-3 mb-4 appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={400}
									data-appear-animation-duration={750}
								>
									<div className="bg-color-dark-scale-2 rounded p-5">
										<i className="icon-bg icon-feature-2 mt-4" />
										<h4 className="text-4 mb-2">
											100% Fully Responsive
										</h4>
										<p className="custom-text-color-1 text-3 color-inherit mb-0">
											Not only working fine on any screen
											resolutions, Porto offers very
											beautiful layouts and avoid any
											heaviness for small resolutions.
										</p>
									</div>
								</div>
								<div
									className="d-flex col-sm-6 col-lg-3 mb-4 appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={600}
									data-appear-animation-duration={750}
								>
									<div className="bg-color-dark-scale-2 rounded p-5">
										<i className="icon-bg icon-feature-3 mt-4" />
										<h4 className="text-4 mb-2">
											Easy To Customize
										</h4>
										<p className="custom-text-color-1 text-3 color-inherit mb-0">
											Porto was created to be very easy to
											edit and customize. The source code
											is organized to help any pro or
											beginner developer.
										</p>
									</div>
								</div>
								<div
									className="d-flex col-sm-6 col-lg-3 mb-4 appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={800}
									data-appear-animation-duration={750}
								>
									<div className="bg-color-dark-scale-2 rounded p-5">
										<i className="icon-bg icon-feature-17 mt-4" />
										<h4 className="text-4 mb-2">
											Functional Forms
										</h4>
										<p className="custom-text-color-1 text-3 color-inherit mb-0">
											Porto comes with several functional
											contact forms, it's very easy to
											customize and set your email and
											fields in any page.
										</p>
									</div>
								</div>
								<div
									className="d-flex col-sm-6 col-lg-3 mb-4 appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={200}
									data-appear-animation-duration={750}
								>
									<div className="bg-color-dark-scale-2 rounded p-5">
										<i className="icon-bg icon-feature-5 mt-4" />
										<h4 className="text-4 mb-2">
											Retina Ready
										</h4>
										<p className="custom-text-color-1 text-3 color-inherit mb-0">
											Porto works fine with any retina
											devices, we've fully tested Porto on
											all retina resolutions including
											laptops and mobile devices.
										</p>
									</div>
								</div>
								<div
									className="d-flex col-sm-6 col-lg-3 mb-4 appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={400}
									data-appear-animation-duration={750}
								>
									<div className="bg-color-dark-scale-2 rounded p-5">
										<i className="icon-bg icon-feature-6 mt-4" />
										<h4 className="text-4 mb-2">
											Bootstrap Based
										</h4>
										<p className="custom-text-color-1 text-3 color-inherit mb-0">
											Porto is based on Bootstrap, the
											most popular CSS Framework for
											developing responsive and
											mobile-first websites.
										</p>
									</div>
								</div>
								<div
									className="d-flex col-sm-6 col-lg-3 mb-4 appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={600}
									data-appear-animation-duration={750}
								>
									<div className="bg-color-dark-scale-2 rounded p-5">
										<i className="icon-bg icon-feature-7 mt-4" />
										<h4 className="text-4 mb-2">
											One and Multi Pages
										</h4>
										<p className="custom-text-color-1 text-3 color-inherit mb-0">
											Porto has a bunch of demos with the
											possibility to create one and multi
											pages websites.
										</p>
									</div>
								</div>
								<div
									className="d-flex col-sm-6 col-lg-3 mb-4 appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={800}
									data-appear-animation-duration={750}
								>
									<div className="bg-color-dark-scale-2 rounded p-5">
										<i className="icon-bg icon-feature-8 mt-4" />
										<h4 className="text-4 mb-2">
											Headers and Menus
										</h4>
										<p className="custom-text-color-1 text-3 color-inherit mb-0">
											Porto comes with several headers and
											menus options for you to use on your
											website.
										</p>
									</div>
								</div>
								<div
									className="d-flex col-sm-6 col-lg-3 mb-4 appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={200}
									data-appear-animation-duration={750}
								>
									<div className="bg-color-dark-scale-2 rounded p-5">
										<i className="icon-bg icon-feature-9 mt-4" />
										<h4 className="text-4 mb-2">
											eCommerce Templates
										</h4>
										<p className="custom-text-color-1 text-3 color-inherit mb-0">
											Exclusive layouts and features makes
											you to create your online store and
											sell online.
										</p>
									</div>
								</div>
								<div
									className="d-flex col-sm-6 col-lg-3 mb-4 appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={400}
									data-appear-animation-duration={750}
								>
									<div className="bg-color-dark-scale-2 rounded p-5">
										<i className="icon-bg icon-feature-10 mt-4" />
										<h4 className="text-4 mb-2">
											CSS3 Animations
										</h4>
										<p className="custom-text-color-1 text-3 color-inherit mb-0">
											Porto is always keen to update
											latest css 3 technologies, you can
											see nice and exclusive css3
											animation effects through demos.
										</p>
									</div>
								</div>
								<div
									className="d-flex col-sm-6 col-lg-3 mb-4 appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={600}
									data-appear-animation-duration={750}
								>
									<div className="bg-color-dark-scale-2 rounded p-5">
										<i className="icon-bg icon-feature-11 mt-4" />
										<h4 className="text-4 mb-2">
											Unlimited Colors &amp; Skins
										</h4>
										<p className="custom-text-color-1 text-3 color-inherit mb-0">
											You can configure any color you
											want, for entire site or certain
											sections using our exclusive style
											switcher.
										</p>
									</div>
								</div>
								<div
									className="d-flex col-sm-6 col-lg-3 mb-4 appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={800}
									data-appear-animation-duration={750}
								>
									<div className="bg-color-dark-scale-2 rounded p-5">
										<i className="icon-bg icon-feature-12 mt-4" />
										<h4 className="text-4 mb-2">
											SEO Friendly
										</h4>
										<p className="custom-text-color-1 text-3 color-inherit mb-0">
											SEO Friendly is one of our top
											priorities while working on Porto
											improvements, you can expect high
											seo performance while using Porto.
										</p>
									</div>
								</div>
								<div
									className="d-flex col-sm-6 col-lg-3 mb-4 mb-lg-0 appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={200}
									data-appear-animation-duration={750}
								>
									<div className="bg-color-dark-scale-2 rounded p-5">
										<i className="icon-bg icon-feature-13 mt-4" />
										<h4 className="text-4 mb-2">
											Optimized for Speed
										</h4>
										<p className="custom-text-color-1 text-3 color-inherit mb-0">
											Everything is oriented for high
											speed performance based in the best
											code standards.
										</p>
									</div>
								</div>
								<div
									className="d-flex col-sm-6 col-lg-3 mb-4 mb-lg-0 appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={400}
									data-appear-animation-duration={750}
								>
									<div className="bg-color-dark-scale-2 rounded p-5">
										<i className="icon-bg icon-feature-18 mt-4" />
										<h4 className="text-4 mb-2">
											Ajax Ready + Lazy Load
										</h4>
										<p className="custom-text-color-1 text-3 color-inherit mb-0">
											Porto works perfectly with ajax
											laoding and also lazy load for a
											better performance.
										</p>
									</div>
								</div>
								<div
									className="d-flex col-sm-6 col-lg-3 mb-4 mb-sm-0 appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={600}
									data-appear-animation-duration={750}
								>
									<div className="bg-color-dark-scale-2 rounded p-5">
										<i className="icon-bg icon-feature-15 mt-4" />
										<h4 className="text-4 mb-2">
											RTL Support
										</h4>
										<p className="custom-text-color-1 text-3 color-inherit mb-0">
											Fully compatible with Right to Left
											languages, always check this feature
											before our version update.
										</p>
									</div>
								</div>
								<div
									className="d-flex col-sm-6 col-lg-3 appear-animation"
									data-appear-animation="fadeInUpShorter"
									data-appear-animation-delay={800}
									data-appear-animation-duration={750}
								>
									<div className="bg-color-dark-scale-2 rounded p-5">
										<i className="icon-bg icon-feature-19 mt-4" />
										<h4 className="text-4 mb-2">
											Social Integration
										</h4>
										<p className="custom-text-color-1 text-3 color-inherit mb-0">
											Social media integration is easy
											with Porto. You can also configure
											Twitter and Instagram feeds.
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="section section-elements m-0 section-no-border bg-bottom-slash position-relative z-index-1">
						<div className="container text-center mt-4">
							<h2 className="font-weight-bold text-9 mb-2">
								Porto Elements
							</h2>
							<p className="text-4 mb-5">
								Porto is simply a better choice for your new
								website design.
							</p>
							<div className="row justify-content-center">
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-accordions.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-bars" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-bars" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Accordions
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-toggles.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-indent" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-indent" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Toggles
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-tabs.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-columns" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-columns" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Tabs
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-icons.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-check" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-check" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Icons
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-icon-boxes.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="far fa-check-circle" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="far fa-check-circle" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Icon Boxes
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-carousels.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-ellipsis-h" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-ellipsis-h" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Carousels
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-modals.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-expand" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-expand" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Modals
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-lightboxes.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="far fa-clone" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="far fa-clone" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Lightboxes
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-buttons.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-minus" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-minus" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Buttons
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-badges.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-bars" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-bars" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Badges
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-lists.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-list-ul" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-list-ul" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Lists
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-image-gallery.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="far fa-file-image" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="far fa-file-image" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Image Gallery
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-image-frames.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="far fa-images" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="far fa-images" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Image Frames
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-image-hotspots.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="far fa-hand-point-up" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="far fa-hand-point-up" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Image Hotspots
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-testimonials.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="far fa-comments" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="far fa-comments" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Testimonials
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-blockquotes.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-quote-left" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-quote-left" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Blockquotes
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-word-rotator.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fab fa-autoprefixer" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fab fa-autoprefixer" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Word Rotator
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-before-after.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-arrows-alt-h" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-arrows-alt-h" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Before / After
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-typography.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-font" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-font" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Typography
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-call-to-action.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-external-link-alt" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-external-link-alt" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Call to Action
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-pricing-tables.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-dollar-sign" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-dollar-sign" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Pricing Tables
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-tables.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-table" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-table" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Tables
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-progressbars.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-chart-bar" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-chart-bar" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Progress Bars
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-counters.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-sort-numeric-down" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-sort-numeric-down" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Counters
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-countdowns.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="far fa-clock" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="far fa-clock" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Countdowns
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-sections-parallax.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-images" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-images" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Sections &amp; Parallax
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-tooltips-popovers.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="far fa-comment-alt" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="far fa-comment-alt" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Tooltips &amp; Popovers
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-sticky-elements.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-compress" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-compress" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Sticky Elements
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-headings.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-text-height" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-text-height" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Headings
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-dividers.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-align-center" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-align-center" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Dividers
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-animations.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-asterisk" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-asterisk" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Animations
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-medias.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="far fa-play-circle" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="far fa-play-circle" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Medias
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-maps.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="far fa-map" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="far fa-map" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Maps
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-arrows.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="far fa-arrow-alt-circle-right" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="far fa-arrow-alt-circle-right" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Arrows
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-star-ratings.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="far fa-star" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="far fa-star" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Star Ratings
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-alerts.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-exclamation-triangle" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-exclamation-triangle" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Alerts
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-posts.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="far fa-calendar-alt" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="far fa-calendar-alt" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Posts
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-forms-basic-contact.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="far fa-file-alt" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="far fa-file-alt" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Forms
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-360-image-viewer.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-sync-alt" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-sync-alt" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														360º Image Viewer
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-shape-dividers.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="fas fa-divide" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="fas fa-divide" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Shape Dividers
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
								<div className="col-6 col-sm-4 col-lg-2">
									<div className="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-2-hover-only featured-boxes-modern-style-primary m-0 mb-4 pb-3">
										<div className="featured-box featured-box-no-borders featured-box-box-shadow">
											<a
												href="elements-read-more.html"
												className="text-decoration-none"
											>
												<span className="box-content px-1 py-4 text-center d-block">
													<span className="text-primary text-8 position-relative top-3 mt-3">
														<i className="far fa-plus-square" />
													</span>
													<span className="elements-list-shadow-icon text-default">
														<i className="far fa-plus-square" />
													</span>
													<span className="font-weight-bold text-uppercase text-1 negative-ls-1 d-block text-dark pt-2">
														Read More
													</span>
												</span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section
						className="section section-dark section-angled border-0 pb-0 m-0"
						style={{
							backgroundSize: "100%",
							backgroundPosition: "top",
						}}
						data-plugin-lazyload
						data-plugin-options="{'threshold': 500}"
						data-original="img/landing/build_bg.jpg"
					>
						<div
							className="section-angled-layer-top section-angled-layer-increase-angle bg-color-light-scale-1"
							style={{ padding: "4rem 0" }}
						/>
						<div className="container text-center my-5 py-5">
							<h2
								className="font-weight-bold line-height-3 text-12 mt-5 mb-3 appear-animation"
								data-appear-animation="fadeInUpShorter"
								data-appear-animation-delay={250}
								data-appear-animation-duration={750}
							>
								Build your website with Porto
							</h2>
							<h4
								className="font-weight-bold text-9 mb-4 pb-2 appear-animation"
								data-appear-animation="fadeInUpShorter"
								data-appear-animation-delay={500}
								data-appear-animation-duration={750}
							>
								Purchase now. Only{" "}
								<span
									className="highlighted-word highlighted-word-animation-1 highlighted-word-animation-1-no-rotate highlighted-word-animation-1 highlighted-word-animation-1-light alternative-font-4 font-weight-extra-bold text-4 light appear-animation"
									data-appear-animation="blurIn"
									data-appear-animation-delay={800}
									data-appear-animation-duration={750}
								>
									$17!
								</span>
							</h4>
							<div
								className="appear-animation"
								data-appear-animation="fadeInUpShorter"
								data-appear-animation-delay={900}
								data-appear-animation-duration={750}
							>
								<h4 className="font-weight-light text-4 col-lg-6 px-0 offset-lg-3 fw-400 mb-5 opacity-8">
									Porto Template has been available on
									ThemeForest since 2013 and is one of the top
									sellers with more than 35K+ sales.
								</h4>
							</div>
							<div className="col-12 px-0 pb-2 mb-4">
								<div className="row flex-column flex-lg-row justify-content-center">
									<div className="col-auto">
										<h5
											className="font-weight-semibold text-4 positive-ls-2 appear-animation"
											data-appear-animation="fadeInRightShorter"
											data-appear-animation-delay={1100}
											data-appear-animation-duration={750}
										>
											<i className="fa fa-check" /> SUPER
											HIGH PERFORMANCE
										</h5>
									</div>
									<div className="col-auto mx-5 my-2 my-lg-0">
										<h5
											className="font-weight-semibold text-4 positive-ls-2 appear-animation"
											data-appear-animation="fadeInRightShorter"
											data-appear-animation-delay={1400}
											data-appear-animation-duration={750}
										>
											<i className="fa fa-check" /> Strict
											Coding Standards
										</h5>
									</div>
									<div className="col-auto">
										<h5
											className="font-weight-semibold text-4 positive-ls-2 appear-animation"
											data-appear-animation="fadeInRightShorter"
											data-appear-animation-delay={1600}
											data-appear-animation-duration={750}
										>
											<i className="fa fa-check" /> Free
											Lifetime Updates
										</h5>
									</div>
								</div>
							</div>
							<a
								href="https://themeforest.net/item/porto-responsive-html5-template/4106987"
								className="btn btn-dark btn-modern btn-rounded px-5 btn-py-3 text-4 appear-animation"
								data-appear-animation="fadeIn"
								data-appear-animation-delay={1800}
								data-appear-animation-duration={750}
								target="_blank"
							>
								BUY PORTO NOW
							</a>
						</div>
						<div className="row border border-left-0 border-bottom-0 border-right-0 border-color-light-2">
							<div className="col-6 col-md-3 text-center d-flex align-items-center justify-content-center py-4">
								<a
									href="http://www.okler.net/"
									className="text-decoration-none"
									target="_blank"
								>
									<div className="icon-box">
										<i className="icon-bg icon-menu-1" />
										<h4 className="text-4 mb-0">
											Customer Showcase
											<small className="d-block p-relative bottom-4 opacity-6 ls-0">
												(SAMPLE SITES)
											</small>
										</h4>
									</div>
								</a>
							</div>
							<div className="col-6 col-md-3 text-center divider-left-border border-color-light-2 d-flex align-items-center justify-content-center py-4">
								<a
									href="http://www.okler.net/open-a-ticket/"
									className="text-decoration-none"
									target="_blank"
								>
									<div className="icon-box">
										<i className="icon-bg icon-menu-2" />
										<h4 className="text-4 mb-0">
											Support Center
										</h4>
									</div>
								</a>
							</div>
							<div className="col-6 col-md-3 text-center divider-left-border border-color-light-2 d-flex align-items-center justify-content-center py-4">
								<a
									href="http://www.okler.net/"
									className="text-decoration-none"
									target="_blank"
								>
									<div className="icon-box">
										<i className="icon-bg icon-menu-3" />
										<h4 className="text-4 mb-0">
											Online Documentation
										</h4>
									</div>
								</a>
							</div>
							<div className="col-6 col-md-3 text-center divider-left-border border-color-light-2 d-flex align-items-center justify-content-center py-4">
								<a
									href="http://www.okler.net/"
									className="text-decoration-none"
									target="_blank"
								>
									<div className="icon-box">
										<i className="icon-bg icon-menu-4" />
										<h4 className="text-4 mb-0">
											Video Tutorials
										</h4>
									</div>
								</a>
							</div>
						</div>
					</section>
					<section className="section bg-color-dark-scale-2 border-0 m-0 py-4">
						<div className="container">
							<div className="row">
								<div className="col">
									<ul className="list list-unstyled list-inline d-flex align-items-center justify-content-center flex-column flex-lg-row mb-0">
										<li className="list-inline-item custom-text-color-1 color-inherit mb-lg-0 text-2 pr-2">
											Porto Versions:
										</li>
										<li className="list-inline-item mb-lg-0">
											<a
												href="https://themeforest.net/item/porto-admin-responsive-html5-template/8539472?s_rank=2"
												className="btn btn-dark btn-modern btn-rounded btn-px-4 py-3 border-0"
												target="_blank"
											>
												ADMIN HTML
											</a>
										</li>
										<li className="list-inline-item mb-lg-0">
											<a
												href="https://themeforest.net/item/porto-ecommerce-shop-template/22685562"
												className="btn btn-dark btn-modern btn-rounded btn-px-4 py-3 border-0"
												target="_blank"
											>
												SHOP HTML
											</a>
										</li>
										<li className="list-inline-item mb-lg-0">
											<a
												href="https://themeforest.net/item/porto-responsive-wordpress-ecommerce-theme/9207399"
												className="btn btn-dark btn-modern btn-rounded btn-px-4 py-3 border-0"
												target="_blank"
											>
												WORDPRESS
											</a>
										</li>
										<li className="list-inline-item mb-lg-0">
											<a
												href="https://themeforest.net/item/porto-ultimate-responsive-magento-theme/9725864"
												className="btn btn-dark btn-modern btn-rounded btn-px-4 py-3 border-0"
												target="_blank"
											>
												MAGENTO
											</a>
										</li>
										<li className="list-inline-item mb-lg-0">
											<a
												href="https://themeforest.net/item/porto-ultimate-responsive-shopify-theme/19162959"
												className="btn btn-dark btn-modern btn-rounded btn-px-4 py-3 border-0"
												target="_blank"
											>
												SHOPIFY
											</a>
										</li>
										<li className="list-inline-item mb-lg-0">
											<a
												href="https://themeforest.net/item/porto-responsive-drupal-7-theme/5219986"
												className="btn btn-dark btn-modern btn-rounded btn-px-4 py-3 border-0"
												target="_blank"
											>
												DRUPAL
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</section>
				</div>
				<footer
					id="footer"
					className="bg-color-dark-scale-2 border border-right-0 border-left-0 border-bottom-0 border-color-light-3 mt-0"
				>
					<div className="container text-center my-3 py-5">
						<a href="#" className="goto-top">
							<img
								src="img/lazy.png"
								data-plugin-lazyload
								data-plugin-options="{'threshold': 500}"
								data-original="img/landing/logo.png"
								width={102}
								height={45}
								className="mb-4 appear-animation"
								alt="Porto"
								data-appear-animation="fadeIn"
								data-appear-animation-delay={300}
							/>
						</a>
						<p className="text-4 mb-4">
							Porto is exclusively available on themeforest.net by{" "}
							<a
								href="https://themeforest.net/user/okler/"
								className="text-color-light"
								target="_blank"
							>
								Okler.
							</a>
						</p>
						<ul className="social-icons social-icons-big social-icons-dark-2">
							<li className="social-icons-facebook">
								<a
									href="http://www.facebook.com/"
									target="_blank"
									title="Facebook"
								>
									<i className="fab fa-facebook-f" />
								</a>
							</li>
							<li className="social-icons-twitter">
								<a
									href="http://www.twitter.com/"
									target="_blank"
									title="Twitter"
								>
									<i className="fab fa-twitter" />
								</a>
							</li>
							<li className="social-icons-linkedin">
								<a
									href="http://www.linkedin.com/"
									target="_blank"
									title="Linkedin"
								>
									<i className="fab fa-linkedin-in" />
								</a>
							</li>
						</ul>
					</div>
					<div className="copyright bg-dark py-4">
						<div className="container text-center py-2">
							<p className="mb-0 text-2">
								Copyright 2013 - 2020 - Porto - All Rights
								Reserved
							</p>
						</div>
					</div>
				</footer>
			</div>
		</div>
	);
};

export default HomeScreen;
