import React, { Component } from "react"
import CartTotal from './Cart/CartTotal.js';
import CartBreadcrumb from './Cart/CartBreadcrumb.js';
import ShippingInfo from './Shipping/ShippingInfo.js';

export default class ShippingDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cartDetail:{
                netAmount:0
            },
            shipping:"air"
        }
    }
    callbackFunction(state = {}) {
        this.setState(state);
    }
    componentDidMount() {
        let el = document.getElementsByClassName("header-body")[0];
        if(el){
            el.style.top = "0px";
        }
    }
    render() {
        return (
            <div>
                <div role="main" className="main shop pb-4">
                    <div className="container">
                        <CartBreadcrumb />
                        <div className="row pb-4 mb-5">
                            <ShippingInfo parentCallback={this.callbackFunction.bind(this)} shipping={this.state.shipping} />
                            <CartTotal cartDetail={this.state.cartDetail} nextURL={"checkout"} parentCallback={this.callbackFunction.bind(this)}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}