import React from 'react'
import BrandingStep1 from './BrandingStep1'
import BrandingStep2 from './BrandingStep2'
import BrandingStep3 from './BrandingStep3'

export default function Branding() {
  return (
    <>
    <BrandingStep1 />
    <BrandingStep2 />
    <BrandingStep3 />
    </>
  )
}
