import React, { Component } from "react"
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import Carts from "../components/Carts.js";
import User from '../global/User';
import axios from "axios";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import placeHolderSrc from '../img/placeholder-medium.png'
import {toast } from 'react-toastify';



export default class Cart extends Component {
	 constructor(props) {
        super(props)
        this.state = {
            userDetails:{},
            products: [],
            cart:{},
            cartDetail:{grossAmount:0},
            textValue:"",
            userEmail:User.getUser().email,
            quantity:"",
            message:"",
        }
        this.sendMessage = this.sendMessage.bind(this);
        this.apiUrl = process.env.REACT_APP_NODE_API_URL.trim() + "/"+ process.env.REACT_APP_NODE_API_PATH.trim().replace(/\/+/g,"\/");

    }
    componentDidMount(){
		this.orderDetails();
		
	}
    
    orderDetails(){
        let user = User.getUser()
        //console.log(user.email);
        if (!user)
            return user.loginUser();;
        axios
        .post(this.apiUrl+"/addtocart", {
            key: process.env.REACT_APP_MOBILE_API_KEY.trim(),
            token: process.env.REACT_APP_MOBILE_API_TOKEN.trim(),
            merchantId: user.id,
            source: "web",
            accessStatus: 0,
        })
        .then((res) => {
            let cartDetail = res.data.Response.data;
            let cart = {};
            cartDetail.products.forEach(el=>{
                cart[el.code] = {
                    productId:el._id,
                    sku:el.code,
                    quantity:el.cartQuantity
                }
            })
            this.setState({cart})
            this.setState({products:cartDetail.products,textValue:cartDetail.products});

            //fire Event
            let event = new CustomEvent("updateCart", {});
            document.dispatchEvent(event);
 
            delete cartDetail.products;
            this.setState({cartDetail})
            this.props.parentCallback({cartDetail});          
        })
        .catch((err) => {
            console.log("Error in Single Product APi", err);
        });
    }

    
    sendMessage(){
		
		let user = User.getUser()
        //console.log(user.email);
        if (!user)
            return user.loginUser();;
        axios
        .post(this.apiUrl+"/sendrfq", {
            key: process.env.REACT_APP_MOBILE_API_KEY.trim(),
            token: process.env.REACT_APP_MOBILE_API_TOKEN.trim(),
            merchantId: user.id,
            source: "web",
            product:this.state.textValue,
            message:this.state.message,
            email:this.state.userEmail,
            qty:this.state.quantity,
        })
        .then((res) => {
          // console.log("success", res);
           toast.success("Your request has been send successfully", {
					position: "top-right",
					autoClose: 1000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
           
        })
        .catch((err) => {
            toast.success("Your request has not been send", {
					position: "top-right",
					autoClose: 1000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
        });
        
        
	}
	
	
	render() {
		
        return (
            <div>
                <div className="body" style={{ 'marginTop': '45.1875px', transition: 'margin 300ms ease 0s' }}>
                    <Header search={{ ...this.props.match.params }} />

                </div>
               <div>
               <Carts/>
               <div className="row m-auto">
					<div className="col-lg-8 m-auto">
							<div className="card mb-4" style={{border: "1px solid #dee2e6"}}>
								<div className="card-body">
									<div className="row">
										<div className="col">
												<div className="row form-group">
													<label className="col-sm-2 col-form-label text-right">From:</label>
													<div className="col-sm-10">
														<input type="text" readOnly className="form-control-plaintext" id="staticEmail" name="email" defaultValue={this.state.userEmail} />
													</div>
												</div>
												<div className="row form-group">
													<label className="col-sm-2 col-form-label text-right">*Message:</label>
													<div className="col-sm-10">
														<textarea maxLength="5000"  onChange={(e)=>this.setState({message:e.target.value})}  data-msg-required="Please enter your message." rows="8" className="form-control text-3 h-auto py-2" name="message" required="">
														   
														</textarea>
													</div>
												</div>
												<div className="row form-group">
													<label className="col-sm-2 col-form-label text-right">Quantity:</label>
													<div className="col-sm-5">
														<input type="text" onChange={(e)=>this.setState({quantity:e.target.value})} data-msg-required="Please enter your name." maxLength="100" className="form-control text-3 h-auto py-2" name="qty" required="" />
													</div>
													
												</div>												
												<div className="row form-group">
													<div className="col-sm-2"></div>
													<div className="col-sm-10">
														<div className="form-check">
															<input className="form-check-input" type="checkbox" defaultValue="" name="agree" id="tabContent9Checkbox" data-msg-required="You must agree before submiting." required="" />
															<label className="form-check-label" htmlFor="tabContent9Checkbox">
																Recommend matching sippliers if this supplier doesn't contact me on Message Center within 24 hours.
															</label>
														</div>
													</div>
												</div>
												<div className="row form-group">
													<div className="col-sm-2"></div>
													<div className="col-sm-10">
														<div className="form-check">
															<input className="form-check-input" type="checkbox" defaultValue="" name="agree" id="tabContent9Checkbox" data-msg-required="You must agree before submiting." required="" />
															<label className="form-check-label" htmlFor="tabContent9Checkbox">
																I agree to share my <b>Business Card</b> to the supplier
															</label>
														</div>
													</div>
												</div>
												<div className="row form-group">
												<div className="col-sm-2"></div>
													<div className="col-sm-10">
														<input type="button" value="Send" onClick={this.sendMessage} className="btn btn-primary" data-loading-text="Loading..." />
													</div>
												</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						</div>
               
               </div>
                
                <Footer />
            </div>
        )

    }
}
